var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "credit-card-row",
      class: { expired: _vm.expired, selectable: _vm.selectable },
      on: { click: _vm.select }
    },
    [
      _vm.selectable
        ? _c(
            "div",
            { staticClass: "checkbox" },
            [
              !_vm.expired
                ? _c("FormCheckbox", { attrs: { checked: _vm.selected } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "logo" }, [
        _vm.brandLogoPath
          ? _c("img", { attrs: { alt: _vm.brand, src: _vm.brandLogoPath } })
          : _c("span", [_vm._v(_vm._s(_vm.brand))])
      ]),
      _c("div", { staticClass: "number" }, [
        _vm._v("**** **** **** " + _vm._s(_vm.lastFourDigit))
      ]),
      _c("div", [
        _vm._v(
          _vm._s(
            _vm.expired
              ? _vm.$t("creditCardRow.expired")
              : _vm.expirationDate.format("MM/YY")
          )
        )
      ]),
      _vm.removable
        ? _c("div", { staticClass: "remove" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.$emit("remove")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("creditCardRow.remove")))]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }