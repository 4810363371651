import { render, staticRenderFns } from "./LastObservations.vue?vue&type=template&id=101de52d&scoped=true&"
import script from "./LastObservations.vue?vue&type=script&lang=js&"
export * from "./LastObservations.vue?vue&type=script&lang=js&"
import style0 from "./LastObservations.vue?vue&type=style&index=0&id=101de52d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101de52d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('101de52d')) {
      api.createRecord('101de52d', component.options)
    } else {
      api.reload('101de52d', component.options)
    }
    module.hot.accept("./LastObservations.vue?vue&type=template&id=101de52d&scoped=true&", function () {
      api.rerender('101de52d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LastObservations.vue"
export default component.exports