<template>
  <portal to="app-progress">
    <div :class="['app-progress-container', position]">
      <div class="app-progress" :style="{ desktop: $media.desktop, 'last-step': current === total }">
        <div class="label">{{ current }} / {{ total }}</div>
        <div class="progress-bar" :style="{ width: `${progress}%` }"/>
        <div class="app-progress-background"></div>
      </div>
    </div>
  </portal>
</template>

<script>
  export default {
    name: 'AppProgress',
    props: {
      total: {
        type: Number,
        required: true,
      },
      current: {
        type: Number,
        required: true,
      },
      position: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      progress() {
        return this.current / this.total * 100;
      },
    },
  };
</script>

<style scoped lang="scss">
  .app-progress-container {
    @include onMobile {
      top: initial;
    }
    width: 100%;
    position: fixed;
    top: 3.7rem;
    background: white;
    height: 30px;
  }
  .app-progress {
    @include onMobile {
      top: initial;
      bottom: 0;
      position: absolute;
    }
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0.7rem;
    background-color: #E1E1E1;

    &.desktop {
      top: 3.7rem;
      left: 7.4rem;
      position: fixed;
    }
  }

  .app-progress-background {
    @include onMobile {
      height: 20px;
      width: 100%;
      position: absolute;
      top: 7px;
      background: white;
    }
    height: 20px;
    background: white;
  }

  .progress-bar {
    background-color: $mainColor;
    height: 0.7rem;
    position: absolute;
    top: 0;
  }

  .label {
    @include onMobile {
      bottom: 1.7rem;
      top: initial;
    }
    text-align: center;
    position: relative;
    top: 1rem;
    font-size: 1.4rem;
  }

  .last-step .label {
    padding-right: 1rem;
  }
</style>
