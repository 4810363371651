<template>
  <FontAwesomeIcon :icon="$icons.owner" class="owner-icon" />
</template>

<script>
  export default {
    name: 'OwnerIcon',
  };
</script>

<style lang="scss" scoped>
  .owner-icon {
    font-size: 1rem;
    transform: translate(0.6rem, -0.4rem);
    color: $successColor;
  }
</style>
