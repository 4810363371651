var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "practitioner-card" }, [
    _c(
      "div",
      [
        _c("UserAvatar", {
          attrs: { src: _vm.practitioner.picture, size: "9rem" }
        })
      ],
      1
    ),
    _c("div", [
      _c("p", [
        _vm._v(
          _vm._s(_vm.practitioner.title) +
            " " +
            _vm._s(_vm.practitioner.firstName) +
            " " +
            _vm._s(_vm.practitioner.lastName)
        )
      ]),
      _vm.practitioner.mainSpecialty
        ? _c("p", [_vm._v(_vm._s(_vm.practitioner.mainSpecialty.name))])
        : _vm._e(),
      _c("p", [
        _vm._v(
          _vm._s(_vm.practitioner.practiceZipCode) +
            " " +
            _vm._s(_vm.practitioner.practiceCity)
        )
      ]),
      _c("p", [_vm._v(_vm._s(_vm._f("phone")(_vm.practitioner.practicePhone)))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }