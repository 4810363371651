<template>
  <AppLayout>
    <div class="d-flex justify-content-center align-items-center flex-column p-5 gradient-background">
      <div class="p-5">
        <img alt="handshake" src="../../assets/images/picto_handShake.svg" />
      </div>
      <p class="text-center font-bold">
       {{ $t('pharmacyOrder.entry-page.content-part-one')}}
      </p>
      <p class="text-center font-bold">{{ $t('pharmacyOrder.entry-page.content-part-two')}}</p>
      <p class="text-center">{{ $t('pharmacyOrder.entry-page.content-part-three')}}</p>
      <p class="text-center font-bold">
        {{ $t('pharmacyOrder.entry-page.content-part-four')}}
      </p>
      <p>
     <ButtonGreen class="font-bold" :link-to="{ name: 'pharmacy-selection', params: { appointmentId: appointment() } }">
       {{ $t('pharmacyOrder.entry-page.btn-to-main')}}
     </ButtonGreen>
      </p>
      <ButtonTransparent :link-to="{name: 'home'}" class="text-small text-secondary text-black-50">{{ $t('pharmacyOrder.entry-page.btn-to-home')}}</ButtonTransparent>
    </div>
  </AppLayout>
</template>

<script>
import ButtonGreen from "../../components/ButtonGreen";
import AppLayout from "../../components/AppLayout";
import ButtonTransparent from "../../components/ButtonTransparent";
export default {
  name: 'PharmacyPage',
  components: {ButtonTransparent, AppLayout, ButtonGreen},
  props: {
    appointmentId: null
  },
  async mounted(){
    await this.clearCurrentAppointment();
    await this.clearCurrentPharmacyOrder();
  },
  methods: {
    async clearCurrentAppointment(){
      localStorage.removeItem('currentOrderAppointment');
    },
    async clearCurrentPharmacyOrder(){
      localStorage.removeItem('currentPharmacyOrder');
    },
    appointment() {
      return this.$store.state.appointments.current !== null ? this.$store.state.appointments.current.id : this.appointmentId;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
</style>