var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.component,
    _vm._g(
      {
        tag: "component",
        staticClass: "base-button",
        class: {
          gradient: _vm.gradient,
          block: _vm.block,
          outlined: _vm.outlined,
          small: _vm.small,
          big: _vm.big,
          busy: _vm.busy,
          long: _vm.long,
          bold: _vm.bold,
          rounded: _vm.rounded,
          "no-border": _vm.noBorder,
          "icon-to-left": _vm.iconToLeft
        },
        attrs: {
          type: _vm.typeAttribute,
          disabled: _vm.busy || _vm.disabled,
          to: _vm.toAttribute
        }
      },
      _vm.$listeners
    ),
    [
      _vm.$slots.default ? _c("span", [_vm._t("default")], 2) : _vm._e(),
      _vm.busy || _vm.icon
        ? _c("FontAwesomeIcon", {
            attrs: {
              icon: _vm.busy ? _vm.$icons.spinner : _vm.icon,
              spin: _vm.busy,
              fixedWidth: ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }