<template>
  <component
    :is="component"
    class="base-button"
    :type="typeAttribute"
    :disabled="busy || disabled"
    :class="{gradient, block, outlined, small, big, busy, long, bold, rounded, 'no-border': noBorder, 'icon-to-left': iconToLeft}"
    :to="toAttribute"
    v-on="$listeners"
  >
    <span v-if="$slots.default"><slot /></span>
    <FontAwesomeIcon
      v-if="busy || icon"
      :icon="busy ? $icons.spinner : icon"
      :spin="busy"
      fixedWidth
    />
  </component>
</template>

<script>
  import ExternalLink from './ExternalLink';

  export default {
    name: 'BaseButton',
    components: { ExternalLink },
    props: {
      type: {
        type: String,
        default: 'button',
        validator(value) {
          return [
            'button',
            'submit',
            'reset',
          ].indexOf(value) !== -1;
        },
      },
      icon: {
        type: Object,
      },
      iconToLeft: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      noBorder: {
        type: Boolean,
        default: false,
      },
      big: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: false,
      },
      long: {
        type: Boolean,
        default: false,
      },
      gradient: {
        type: Boolean,
        default: false,
      },
      busy: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      rounded: {
        type: Boolean,
        default: false
      },
      linkTo: {
        type: [String, Object],
      },
    },
    computed: {
      component() {
        if (!this.linkTo) {
          return 'button';
        }

        if (typeof this.linkTo === 'string' && this.linkTo.match(/(^|:)\/\//)) {
          return 'ExternalLink';
        }

        return 'router-link';
      },
      toAttribute() {
        if(this.linkTo) {
          return this.linkTo;
        }
      },
      typeAttribute() {
        if (!this.linkTo) {
          return this.type;
        }
      },
    },
  };
</script>
