<template>
  <AppLoader v-if="isLoadingAppointment"> {{ $t('appointmentAnswerPage.loader') }} </AppLoader>
  <FullscreenNotification v-else-if="appointment" :title="title">
    <p>{{ description }}</p>
    <template #buttons>
      <ButtonGreen v-if="!isConfirmed && isEditable" :disabled="isDisabled" :busy="isAccepting" @click="acceptAppointment"> {{ $t('appointmentAnswerPage.confirm') }}
      </ButtonGreen>
      <ButtonWhite outlined v-if="isEditable" :disabled="isDisabled" :busy="isCancelling" @click="cancelAppointment"> {{ $t('appointmentAnswerPage.refuse') }} </ButtonWhite>
    </template>
    <template #links>
      <router-link :to="{ name: 'home' }">
        <span> {{ $t('appointmentAnswerPage.return') }} </span>
      </router-link>
    </template>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import ButtonGreen from '../components/ButtonGreen';
  import ButtonRed from '../components/ButtonRed';
  import ButtonWhite from '../components/ButtonWhite';
  import { formatFullName } from '@/helpers/format';
  import AppLoader from '@/components/AppLoader';
  import moment from 'moment';

  export default {
    name: 'AppointmentAnswerPage',
    components: {ButtonGreen, ButtonRed, FullscreenNotification, AppLayout, SectionTitle, AppLoader, ButtonWhite},
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      token: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.loadAppointment();
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      practitioner() {
        return this.appointment.payload.practitioner;
      },
      isLoadingAppointment() {
        return this.$store.state.appointments.isFetchingOne;
      },
      isAccepting() {
        return this.$store.state.appointments.isAccepting;
      },
      isCancelling() {
        return this.$store.state.appointments.isCancelling;
      },
      isConfirmed() {
        return this.appointment.payload.status === 'CONFIRMED';
      },
      isEditable() {
        return ['NEW_BY_PRACTITIONER', 'NEW_BY_PATIENT', 'CONFIRMED'].includes(this.appointment.payload.status);
      },
      isDisabled() {
        return this.isAccepting !== this.isCancelling;
      },
      practitionerFullName() {
        return formatFullName(this.practitioner);
      },
      encounterChannel() {
        return this.appointment.payload.product.channel === 'remote' ? 'téléconsultation' : 'consultation';
      },
      title() {
        if (this.isEditable) {
          if (this.isConfirmed) {
            return this.$t('appointmentAnswerPage.isConfirmed.title.confirm')
          }
          return this.$t('appointmentAnswerPage.isConfirmed.title.ask')
        }
        return this.$t('appointmentAnswerPage.isConfirmed.title.cancel')
      },
      description() {
        let date = moment(this.appointment.payload.startAt).format('dddd DD MMMM YYYY') + " " + this.appointment.time;
        if (this.isEditable) {
          if (this.isConfirmed) {
            return this.$t('appointmentAnswerPage.isConfirmed.description.confirm', { date: date, practitioner: this.practitionerFullName });
          }
          return this.$t('appointmentAnswerPage.isConfirmed.description.proposal', { practitioner : this.practitionerFullName, encounterChannel: this.encounterChannel, date: this.date });
        }
        return this.$t('appointmentAnswerPage.isConfirmed.description.proposal', { date: date, practitioner: this.practitionerFullName });
      }
    },
    methods: {
      async loadAppointment() {
        try {
          await this.$store.dispatch('appointmentsFetchOne', {
            id: this.appointmentId,
            abortSignal: this.abortSignal,
            token: this.token,
          });
        } catch (e) {
          if (!this.isAbortError(e)) {
            this.$addError(this.$t('appointmentAnswerPage.error'));
            throw e;
          }
        }
      },
      async acceptAppointment() {
        await this.$store.dispatch('appointmentsAccept', {
          id: this.appointment.payload.id,
          token: this.token,
        });
        this.$emit('accept');
        await this.$router.push({name: 'appointment-approved'});
      },
      async cancelAppointment() {
        await this.$store.dispatch('appointmentsCancel', {
          id: this.appointment.payload.id,
          token: this.token,
        });
        this.$emit('cancel');
        await this.$router.push({name: 'appointment-rejected'});
      },
    },
  };
</script>
