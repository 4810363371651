<template>
  <router-link :class="statusClass" :to="path" class="appointment-list-item">
    <div class="date-container">
      <div class="day">{{ day }}</div>
      <div class="date">{{ date }}</div>
      <div class="time">{{ time }}</div>
    </div>
    <div class="content">
      <p class="patient-name">{{ patientName }}</p>
      <p class="practitioner-name">{{ practitionerName }}</p>
      <p class="description">{{ description }}</p>
      <p class="info">
        <span class="channel">{{ channel | channel }}</span>
        |
        <span class="status">
          <AppointmentStatusIcon :status="status" />
          {{ status | appointmentStatus(canBeStarted) }}
        </span>
      </p>
    </div>
  </router-link>
</template>

<script>
  import { appointmentStatusClass } from '@/helpers/format';
  import AppointmentStatusIcon from './AppointmentStatusIcon';

  export default {
    name: 'AppointmentListItem',
    components: { AppointmentStatusIcon },
    props: {
      day: {
        type: String,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
      time: {
        type: String,
        required: true,
      },
      expired: {
        type: Boolean,
        default: false,
      },
      practitionerName: {
        type: String,
        required: true,
      },
      patientName: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      status: {
        type: String,
        required: true,
      },
      path: {
        type: String,
        required: true,
      },
      channel: {
        type: String,
        required: true,
      },
      canBeStarted: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      statusClass() {
        return appointmentStatusClass(this.status, this.canBeStarted);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-list-item {
    display: flex;
    align-items: stretch;
    width: 31rem;
    height: 10rem;
    margin: 1rem;
    color: inherit;
    border: 1px solid #D8D8D8;
    border-radius: 0.3rem;
  }

  .date-container {
    display: flex;
    align-self: stretch;
    flex-basis: 80px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    text-align: center;
    color: #1F1F1F;
    background-color: #FAFAFA;
    font-family: $fontGotham;
    font-weight: 600;

    .pending &, .terminated &, .waiting-payment & {
      color: white;
      background-color: #F46868;
    }

    .available & {
      color: white;
      background-color: $successColor;
    }
  }

  .day {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  .date, .time {
    font-size: 1.2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.4rem 1rem;

    > p {
      margin: 0;
    }
  }

  .icon-container {
    font-size: 1.4rem;
    flex-basis: 32px;
    flex-shrink: 0;
    text-align: center;
  }

  .patient-name {
    font-size: 1rem;
  }

  .practitioner-name {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .description {
    font-size: 1.2rem;
    overflow: hidden;
    overflow: hidden;
    max-height: 5rem;
    white-space: break-spaces;

  }

  .info {
    font-size: 1rem;
  }

  .channel {
    margin-right: 0.2rem;
  }

  .status {
    display: inline-block;
    margin-left: 0.2rem;

    .confirmed & {
      color: $successColor;
    }

    .available & {
      font-weight: bold;
      animation: shake 10s infinite cubic-bezier(.36, .07, .19, .97) both;
      color: $successColor;
    }

    .pending & {
      font-weight: bold;
      color: $grey;
    }

    .terminated &, .closed & {
      font-style: italic;
      color: $grey;
    }

    .cancelled & {
      font-style: italic;
      color: $errorColor;
    }

    .waiting-payment & {
      font-weight: bold;
      animation: shake 10s infinite cubic-bezier(.36, .07, .19, .97) both;
      color: $errorColor;
    }
  }
</style>
