import api from './';

export default {
    create: (
        idFromProvider,
        noFinessEt,
        profileId,
        appointmentId,
        providerName,
        siret
    ) => api.post('pharmacies/orders', {
        json: {
            idFromProvider: idFromProvider,
            noFinessEt: noFinessEt,
            siret: siret,
            userId: profileId,
            appointmentId: appointmentId,
            provider: providerName
        },
    }).json(),
    update: (idFromProvider,
             noFinessEt,
             pharmacyOrderId,
             type,
             deliveryAddress,
             fullAddress,
             commentAddress,
             complete,
             paymentMethod,
             providerName,
             premium
    ) => api.put('pharmacies/orders/'+pharmacyOrderId, {
        json: {
            idFromProvider: idFromProvider,
            noFinessEt: noFinessEt,
            type: type,
            deliveryAddress: deliveryAddress,
            fullAddress: fullAddress,
            commentAddress: commentAddress,
            complete: complete,
            paymentMethod: paymentMethod,
            premium: premium,
            provider: providerName
        },
    }).json(),
    sessionGateway: (pharmacyOrderTransactionId, providerName) => api.post('pharmacies/transactions/gateway', {
        json: {
            pharmacyOrderTransaction: pharmacyOrderTransactionId,
            providerName: providerName
        }
    }).json(),
    updateGateway: (pharmacyOrderTransactionId, providerName) => api.put('pharmacies/transactions/gateway', {
        json: {
            pharmacyOrderTransaction: pharmacyOrderTransactionId,
            providerName: providerName
        }
    }).json(),
    get: (pharmacyOrderId) => api.get('pharmacies/'+pharmacyOrderId).json(),
    finished: (pharmacyOrderId) => api.put('pharmacies/orders/'+pharmacyOrderId+'/finished', {}).json(),
    cancel: (pharmacyOrderId) => api.put('pharmacies/orders/'+pharmacyOrderId+'/cancel', {}).json(),
    available: (appointmentId) => api.get('pharmacies/orders/appointment/'+appointmentId+'/available').json(),
    closedAppHasPrescriptions: (appointmentId) => api.get('pharmacies/orders/appointment/'+appointmentId+'/prescriptions')
};
