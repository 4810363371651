var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "module-loader" },
    [
      _c("AppLogoAnimated"),
      _c("p", [_vm._v(_vm._s(_vm.$t("moduleLoader.loader")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }