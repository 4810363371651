export default {
  data() {
    return {
      autoFilled: false,
    };
  },
  methods: {
    checkAutoFill($event) {
      switch ($event.animationName) {
        case 'onAutoFillStart':
          return this.autoFilled = true;
        case 'onAutoFillCancel':
          return this.autoFilled = false;
      }
    },
  },
};
