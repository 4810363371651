import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { toLower, toUpper, words } from 'lodash-es';
import moment from 'moment';
import i18n from '../config/i18n'




export function capitalize(value) {
  return toLower(value).replace(/(^|[ '-])[^ '-]/g, (x) => x.toLocaleUpperCase());
}

export function formatFullName({ firstName, lastName }) {
  return `${capitalize(firstName)} ${toUpper(lastName)}`;
}

export function formatCivility(civility, short = false) {
  switch (civility) {
    case 'dr':
      return short ? i18n.t('civility.short.dr') : i18n.t('civility.long.dr');
    case 'dre':
      return short ? i18n.t('civility.short.dre') : i18n.t('civility.long.dre');
    case 'pr':
      return short ? i18n.t('civility.short.pr') : i18n.t('civility.long.pr');
    case 'pre':
      return short ? i18n.t('civility.short.pre') : i18n.t('civility.long.pre');
    case 'm':
      return short ? i18n.t('civility.short.mr') : i18n.t('civility.long.mr');
    case 'mme':
      return short ? i18n.t('civility.short.mme') : i18n.t('civility.long.mme');
    default:
      return '';
  }
}

const MoneyFormatter = new Intl.NumberFormat(
  'fr-FR',
  { style: 'currency', currency: 'EUR' },
);

export function formatMoney(value) {
  return MoneyFormatter.format(value / 100);
}

export function getInitials(fullName) {
  return words(fullName)
    .map(word => word.charAt(0).toUpperCase())
    .join('');
}

export const convertDateToDash = (date) => {
  if (date) {
    return date
      .split('/')
      .reverse()
      .join('-');
  }

  return null;
};

export const convertDateToSlash = (date) => {
  if (date) {
    return date
      .split('-')
      .reverse()
      .join('/');
  }

  return null;
};

export const appointmentStatusClass = (status, canBeStarted) => {
  switch (status) {
    case 'CONFIRMED':
      return canBeStarted ? 'available' : 'confirmed';
    case 'CLOSED':
      return 'closed';
    case 'TERMINATED':
      return 'terminated';
    case 'NEW_BY_PATIENT':
    case 'NEW_BY_PRACTITIONER':
      return 'pending';
    case 'CANCELLED_BY_PATIENT':
    case 'CANCELLED_BY_PRACTITIONER':
      return 'cancelled';
    case 'PAYMENT_REQUIRED':
      return 'waiting-payment';
    default:
      return null;
  }
};

export const normalize = (value) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();

export function age(birthDate) {
  const years = moment().diff(birthDate, 'years');
  const months = moment().diff(birthDate, 'months');
  const days = moment().subtract(months, 'months').diff(birthDate, 'days');

  if (months < 1) {
    if(days <= 1){
      return i18n.tc('agePatient.days', 1,{n: days})
    }
    return i18n.tc('agePatient.days',2, {n: days})
  }
  if (months >= 1 && months < 6) {
    if (days == 0){
      if (months == 1) {
        return i18n.tc('agePatient.months', 1, {n: months});
      }
      return i18n.tc('agePatient.months', 2, {n: months});
    }
    if (months == 1 && days == 1) {
      return `${i18n.tc('agePatient.months', 1, {n: months})} ${i18n.tc('agePatient.days', 1,{n: days})}`;
    }
    if (months == 1 && days > 1) {
      return `${i18n.tc('agePatient.months', 1, {n: months})} ${i18n.tc('agePatient.days', 2,{n: days})}`;
    }
    if (months > 1 && days == 1) {
      return `${i18n.tc('agePatient.months', 2, {n: months})} ${i18n.tc('agePatient.days', 1,{n: days})}`;
    }
    if (months > 1 && days > 1) {
      return `${i18n.tc('agePatient.months', 2, {n: months})} ${i18n.tc('agePatient.days', 2,{n: days})}`;
    }
  }
    if (months >= 6 && months <= 36) {
      return `${i18n.tc('agePatient.months', 2, {n: months})}`;
    }
    if (months > 36) {
      return `${i18n.tc('agePatient.years', 2, {n: years})}`;
    }
}
export const formatPhone = (value) => {
  if (!value) {
    return '';
  }
  const phoneNumberParsed = parsePhoneNumberFromString(value);
  return phoneNumberParsed && phoneNumberParsed.formatInternational();
};