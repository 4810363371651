var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-notification-item animated bounceInRight",
      class: _vm.type,
      on: { click: _vm.remove }
    },
    [
      _vm.icon
        ? _c(
            "div",
            { staticClass: "icon-container" },
            [_c("FontAwesomeIcon", { attrs: { icon: _vm.icon } })],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "message" }, [_vm._t("default")], 2),
      _vm.duration
        ? _c(
            "div",
            [
              _c("AppPieTimer", {
                ref: "timer",
                attrs: { duration: _vm.duration, paused: _vm.paused },
                on: { ended: _vm.remove }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }