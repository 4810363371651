var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    [
      _c("AppProgress", { attrs: { current: 1, total: 8, position: "b-0" } }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("SectionTitle", [
            _vm._v(_vm._s(_vm.$t("newAppointmentPage.title")))
          ]),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("FormInput", {
                    staticClass: "col _3",
                    attrs: {
                      id: "practitioner-name",
                      label: _vm.$t(
                        "newAppointmentPage.form.practitioner-name.label"
                      )
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.$v.form.practitionerName.$error
                          ? {
                              key: "error",
                              fn: function() {
                                return [
                                  !_vm.$v.form.practitionerName.required
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "newAppointmentPage.form.practitioner-name.required"
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    : !_vm.$v.form.practitionerName.minLength
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "newAppointmentPage.form.practitioner-name.min-length",
                                                {
                                                  min:
                                                    _vm.$v.form.practitionerName
                                                      .$params.minLength.min
                                                }
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.form.practitionerName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "practitionerName", $$v)
                      },
                      expression: "form.practitionerName"
                    }
                  }),
                  _c("FormSelectSpecialties", {
                    staticClass: "col _3",
                    attrs: {
                      id: "specialty",
                      loading: _vm.isLoadingSpecialties,
                      specialties: _vm.specialties
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.$v.form.specialty.$error
                          ? {
                              key: "error",
                              fn: function() {
                                return [
                                  !_vm.$v.form.specialty.required
                                    ? [_vm._v(" ")]
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.form.specialty,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "specialty", $$v)
                      },
                      expression: "form.specialty"
                    }
                  }),
                  _c("FormInput", {
                    staticClass: "col _2",
                    attrs: {
                      id: "location",
                      label: _vm.$t(
                        "newAppointmentPage.form.postal-code.label"
                      ),
                      maxlength: "20"
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.$v.form.location.$error
                          ? {
                              key: "error",
                              fn: function() {
                                return [
                                  !_vm.$v.form.location.required
                                    ? [_vm._v(" ")]
                                    : _vm._e(),
                                  !_vm.$v.form.location.zipCode
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "newAppointmentPage.form.postal-code.format"
                                            )
                                          )
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.form.location,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "location", $$v)
                      },
                      expression: "form.location"
                    }
                  }),
                  _c(
                    "FormSelect",
                    {
                      staticClass: "col _2",
                      attrs: {
                        id: "channel",
                        label: _vm.$t("newAppointmentPage.form.channel.label")
                      },
                      model: {
                        value: _vm.form.channel,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "channel", $$v)
                        },
                        expression: "form.channel"
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }),
                      _c("option", { attrs: { value: "remote" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("newAppointmentPage.form.channel.remote")
                          )
                        )
                      ]),
                      _c("option", { attrs: { value: "medical_office" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "newAppointmentPage.form.channel.medical-office"
                            )
                          )
                        )
                      ]),
                      _c("option", { attrs: { value: "home" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("newAppointmentPage.form.channel.home"))
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col _2" },
                    [
                      _c("ButtonGreen", {
                        staticClass: "submit-button",
                        attrs: {
                          icon: _vm.$icons.search,
                          outlined: "",
                          rounded: "",
                          type: "submit"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              false
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col _12" },
                      [
                        _c(
                          "FormCheckbox",
                          {
                            model: {
                              value: _vm.form.remoteOnly,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remoteOnly", $$v)
                              },
                              expression: "form.remoteOnly"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("newAppointmentPage.form.checkBox")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c("hr", { staticClass: "separator" }),
          _c("p", {
            staticClass: "airy",
            domProps: {
              innerHTML: _vm._s(_vm.$t("newAppointmentPage.informations.0"))
            }
          }),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("newAppointmentPage.informations.1"))
            }
          }),
          _c("p", {
            staticClass: "italic",
            domProps: {
              innerHTML: _vm._s(_vm.$t("newAppointmentPage.informations.2"))
            }
          }),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("newAppointmentPage.informations.3"))
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }