<template>
  <AppLayout>
    <AppProgress :current="4" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />
    <main class="container">
      <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
      <form @submit.prevent="handleSubmit">
        <SectionTitle>{{ $t('newAppointmentReasonPage.title') }}</SectionTitle>
        <span class="description">{{ $t('newAppointmentReasonPage.text') }}</span>

        <FormInput
          id="reason"
          v-model="reason"
          type="textarea"
          :placeholder="$t('newAppointmentReasonPage.write')"
          class="textarea"
        >
          <template #label>
            <span>{{ label }}</span>
          </template>
          <template v-if="$v.reason.$error" #error>
            <template v-if="!$v.reason.required ">{{ $t('newAppointmentReasonPage.input.required') }}</template>
            <template v-else-if="!$v.reason.minLength">{{ $t('newAppointmentReasonPage.input.min-length') }}</template>
          </template>
        </FormInput>

        <div class="information">
          <img src="../assets/images/valide.png" alt="valide"/>
          <p v-html="$t('newAppointmentReasonPage.information')"/>
        </div>

        <div class="buttons">
          <ButtonTransparent
            :busy="busy"
            :icon="$icons.chevronLeft"
            icon-to-left
            @click="$router.back()"
          >
            {{ $t('newAppointmentReasonPage.back')}}
          </ButtonTransparent>
          <ButtonGreen
            :busy="busy"
            :icon="$icons.chevronRight"
            rounded
            type="submit"
          >
            {{ $t('newAppointmentReasonPage.continue') }}
          </ButtonGreen>
        </div>
      </form>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../components/AppLayout';
  import SectionTitle from '../components/SectionTitle';
  import ButtonTransparent from '../components/ButtonTransparent';
  import ButtonGreen from '../components/ButtonGreen';
  import AppProgress from '../components/AppProgress';
  import { minLength, required } from '@byscripts/vuelidate/lib/validators';
  import FormInput from '@/components/Form/FormInput';
  import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
  import FormWidget from "@/components/Form/FormWidget.vue";
  import { dateAppointment}  from "@/date"

  export default {
    name: 'new-appointment-reason',
    components: {
      FormWidget,
      RecapTitle,
      FormInput,
      AppProgress,
      ButtonGreen,
      ButtonTransparent,
      SectionTitle,
      AppLayout,
    },
    data() {
      return {
        label: '',
        reason: ''
      };
    },
    async beforeMount() {
      if (this.$store.state.newAppointment
          && null === this.$store.state.newAppointment.product) {
        return this.$router.push({ name: 'new-appointment-search' });
      }

      if (this.$getLocalStorage('new-appointment-status')) {
        this.label = this.$t('newAppointmentReasonPage.status');
      }
    },
    async beforeDestroy() {
      await this.$setLocalStorage('new-appointment-reason', `${this.reason} \n ${this.label}`);
      this.label = ""
    },
    validations: {
      reason: {
        required: required,
        minLength: minLength(5),
      },
    },
    computed: {
      busy() {
        return this.$store.state.paymentMethods.isFetchingAll;
      },
      hasPaymentMethod() {
        return this.$store.state.paymentMethods.all.length > 0;
      },
      practitioner() {
        return this.$store.state.newAppointment.practitioner;
      },
      newAppointment() {
        return this.$store.state.newAppointment;
      },
    },
    methods: {
      dateAppointment,
      async handleSubmit() {
        this.$v.$touch();

        if (this.$v.$anyError) {
          return await this.$addError(this.$t('newAppointmentReasonPage.error'), false, 5000);
        }

        await this.$store.dispatch('newAppointmentSetReason', { reason: `${this.reason}\n${this.label}` });
        await this.$router.push({ name: 'new-appointment-first-time' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  main {
    max-width: 97rem;
  }

  .main-container {
    margin-top: 1rem;
  }
  main.container {
    padding: 1rem 8rem 8rem;
    @include onMobile {
      margin: 3rem auto 8rem;
      padding: 0 3rem;
      display: block;
    }
  }

  .section-title {
    margin-bottom: 0;
  }

  .description {
    @include onMobile {
      font-size: 1.3rem;
    }
    color: #0000007B;
    font-weight: 500;
    display: flex;
    margin-bottom: 3rem;
  }

  .information {
    @include onMobile {
      display: flex;
      flex-direction: column;
      padding-bottom: 10rem;
    }
    padding-top: 3rem;
    display: flex;
    align-items: center;

    img {
      width: 3rem;
      height: inherit;
      margin-right: 1rem;
    }
    p {
      @include onMobile {
        font-size: 1.3rem;
      }
      font-weight: 500;
    }
  }

  .buttons {
    @include onMobile {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      justify-content: space-evenly;
      gap: 1rem;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }
    background: white;
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
  }

  .form-input::v-deep {
    background: white;
    border: 1px solid #00000048;
    border-radius: 9px;
    .label {
      color: #0000007B;
      font-weight: 500;
    }
  }

  .form-input::v-deep.focused{
    border-bottom-color: #00000048;
  }

  .form-input::v-deep .label{
    @include onMobile {
      font-size: 1.3rem;
    }
    transform: none;
    transition: none;
  }

  .reason-label {
    font-weight: bold;
    color: #0000007B;
  }

  textarea {
    resize: none;
    height: 136px;
  }
  .textarea {
    height: 136px;
  }

  ::v-deep .auto-height-textarea {
    height: inherit!important;
  }
</style>
