var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormSelect",
    {
      attrs: {
        id: _vm.id,
        label: _vm.loading
          ? _vm.$t("formSelectSpecialties.select.loading")
          : _vm.$t("formSelectSpecialties.select.label"),
        value: _vm.value,
        disabled: _vm.loading
      },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.$slots.help && !_vm.$slots.error
            ? {
                key: "help",
                fn: function() {
                  return [_vm._t("help")]
                },
                proxy: true
              }
            : null,
          _vm.$slots.error
            ? {
                key: "error",
                fn: function() {
                  return [_vm._t("error")]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c("option", { attrs: { value: "" } }),
      _c(
        "optgroup",
        { attrs: { label: _vm.$t("formSelectSpecialties.frequent-searches") } },
        _vm._l(_vm.frequentSpecialties, function(specialty) {
          return _c("option", { domProps: { value: specialty.id } }, [
            _vm._v("\n      " + _vm._s(specialty.name) + "\n    ")
          ])
        }),
        0
      ),
      _c(
        "optgroup",
        { attrs: { label: _vm.$t("formSelectSpecialties.all-specialties") } },
        _vm._l(_vm.specialties, function(specialty) {
          return _c("option", { domProps: { value: specialty.id } }, [
            _vm._v("\n      " + _vm._s(specialty.name) + "\n    ")
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }