<template>
  <div>
    <!-- modal cancel -->
    <AppConfirmation
        ref="confirmCancel"
        :title="$t('pharmacyOrder.appointment-panel-card.cancel-order')"
        :icon="$icons.box"
    >
      <template v-slot:description="scoped">
        <div>
          <p>{{$t('pharmacyOrder.appointment-panel-card.cancel')}}</p>
        </div>
      </template>
      <div>
        <ConfirmButton>{{$t('pharmacyOrder.no')}}</ConfirmButton>
        <ConfirmButton color="red" @click="cancelOrder" :icon="$icons.no">{{$t('pharmacyOrder.yes')}}</ConfirmButton>
      </div>
    </AppConfirmation>
    <!-- modal finished -->
    <AppConfirmation
        ref="confirmFinished"
        :title="$t('pharmacyOrder.appointment-panel-card.is-received')"
        :icon="$icons.box"
    >
      <template v-slot:description="scoped">
        <div>
          <p>{{$t('pharmacyOrder.appointment-panel-card.confirm-received')}}</p>
        </div>
      </template>
      <div>
        <ConfirmButton color="red">{{$t('pharmacyOrder.no')}}</ConfirmButton>
        <ConfirmButton @click="finishedOrder" :icon="$icons.no">{{$t('pharmacyOrder.yes')}}</ConfirmButton>
      </div>
    </AppConfirmation>
    <!-- card -->
    <p><strong>{{$t('pharmacyOrder.appointment-panel-card.title')}}</strong></p>
    <p>{{$t('pharmacyOrder.appointment-panel-card.subtitle-after')}}</p>
    <div v-if="status === 'finished'">
      {{$t('pharmacyOrder.appointment-panel-card.order-received')}} {{ order.finished_at | date('DD/MM/YYYY') }} {{$t('pharmacyOrder.appointment-panel-card.at')}} {{ order.finished_at | date('HH:mm') }}.
    </div>
    <div class="click-collect__pharmacy-detail">
      <div>
        <p>{{ order.pharmacy.rs_longue ? order.pharmacy.rs_longue : order.pharmacy.rs }}</p>
        <p>
          {{ order.pharmacy.num_voie ? order.pharmacy.num_voie : '' }}
          {{ order.pharmacy.typ_voie ? order.pharmacy.typ_voie : '' }}
          {{ order.pharmacy.voie ? order.pharmacy.voie : '' }}
        </p>
        <p>{{ order.pharmacy.libdepartement }}</p>
        <p><b>{{ statusText }}</b></p>
      </div>
      <div v-if="imageName">
        <img  v-if="imageName==='new'" src="../../assets/images/new.gif" alt="new">
        <img  v-if="imageName==='preparing'" src="../../assets/images/preparing.gif" alt="preparing">
        <img  v-if="imageName==='terminated'" src="../../assets/images/terminated.gif" alt="terminated">
        <img  v-if="imageName==='delivering'" src="../../assets/images/delivering.gif" alt="terminated">
      </div>
    </div>
    <div v-if="canCancel">
      <ButtonGrey @click="confirmCancel" class="button-cancel" v-if="onlineCancel()">{{$t('pharmacyOrder.appointment-panel-card.cancel-preparation')}}</ButtonGrey>
      <p class="click-collect__cancel_number" v-else>
        {{$t('pharmacyOrder.appointment-panel-card.cancel-phone')}} <a :href="'tel:+'+order.pharmacy.telephone">{{order.pharmacy.telephone}}</a>
      </p>
    </div>
    <div v-if="status === 'ready' ">
      <ButtonGreen @click="confirmFinished" class="button-cancel">{{$t('pharmacyOrder.appointment-panel-card.btn-received')}}</ButtonGreen>
    </div>
  </div>
</template>

<script>
import ButtonGrey from "../ButtonGrey";
import ButtonGreen from "../ButtonGreen";
import AppConfirmation from "../AppConfirmation";
import ConfirmButton from "../AppConfirmation/ConfirmButton"

export default {
  name: 'PharmacyOrderCard',
  components: {ButtonGrey, ButtonGreen, AppConfirmation, ConfirmButton},
  props: {
    order: {
      required: true
    },
    statusText: {
      required: true,
      type: String,
      default: null
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    imageName: {
      type: String,
      default: null
    },
    status: {
      type: String,
      required: true
    }
  },
  methods: {
    onlineCancel() {
      if(this.order.pharmacy && this.order.pharmacy.provider) {
        return this.order.pharmacy.provider.online_cancel;
      }
      return false;
    },
    async cancelOrder() {
      await this.$store.dispatch('pharmaciesOrderCancel', {
        pharmacyOrderId: this.order.id
      });
      await this.isOrderAvailable();
    },
    async finishedOrder() {
      await this.$store.dispatch('pharmacieOrderFinished', {
        pharmacyOrderId: this.order.id
      });
      await this.isOrderAvailable();
    },
    confirmFinished() {
      this.$confirm(this.$refs.confirmFinished);
    },
    confirmCancel() {
      this.$confirm(this.$refs.confirmCancel);
    },
    async isOrderAvailable() {
      await this.$store.dispatch('pharmaciesOrderAvailable', {
        appointmentId: this.appointmentId
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.click-collect{
  padding: 3rem 0 3rem 0;
  a,p{
    font-weight: bold;
  }
  .click-collect__cancel_number{
    font-size: 13px;
    color: #424242FF;
    font-weight: normal;
    a{
      color: gray;
    }
  }
}
.click-collect__pharmacy-detail{
  display: flex;
  justify-content: flex-start;
  p {
    font-size: 13px;
    color: #424242FF;
    text-transform: lowercase;
    font-weight: normal;
    &:first-letter{
      text-transform: capitalize;
    }
  }
  img{
    max-width: 100px;
    height: auto;
  }
}
</style>