var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-cards-pictograms", class: { small: _vm.small } },
    [
      _c("img", {
        attrs: { alt: "", src: require("../assets/images/visa-logo.svg") }
      }),
      _c("img", {
        attrs: { alt: "", src: require("../assets/images/mastercard-logo.svg") }
      }),
      _c("img", {
        attrs: { alt: "", src: require("../assets/images/amex-logo.svg") }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }