var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenNotification",
    {
      attrs: {
        icon: _vm.$icons.thumbsUp,
        title: _vm.$t("appointmentRejectedPage.title")
      },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function() {
            return [
              _c("ButtonGreen", { attrs: { "link-to": { name: "home" } } }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("appointmentRejectedPage.confirm")) + " "
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", [
        _vm._v(" " + _vm._s(_vm.$t("appointmentRejectedPage.refuse")) + " ")
      ]),
      _c("p", [
        _vm._v(" " + _vm._s(_vm.$t("appointmentRejectedPage.mailSend")) + " ")
      ]),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.$t("appointmentRejectedPage.text")) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }