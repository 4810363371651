var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("div", { staticClass: "click_collect_container" }, [
      _c("div", { staticClass: "w-100" }, [
        _c(
          "div",
          { staticClass: "p-5" },
          [_c("BreadCrumb", { attrs: { pages: _vm.breadCrumbPages() } })],
          1
        )
      ]),
      _vm.pharmacyOrder
        ? _c(
            "div",
            {
              staticClass:
                "delivery-block d-flex justify-content-center align-items-center flex-column p-5"
            },
            [
              _c("div", { staticClass: "p-5" }, [
                _c("img", {
                  attrs: {
                    alt: "handshake",
                    src: require("../../assets/images/picto_handShake.svg")
                  }
                })
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col _6 left py-5 delivery__form" }, [
                  _vm.pharmacyOrder.delivery_address
                    ? _c("p", { staticClass: "col mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "pharmacyOrder.address-page.enter-other-address"
                              )
                            )
                          )
                        ])
                      ])
                    : _c("p", { staticClass: "col mb-3" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.address-page.enter-address")
                            )
                          )
                        ])
                      ]),
                  _c(
                    "form",
                    {
                      staticClass: "address_form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.handleSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-3" },
                        [
                          _c("FormInput", {
                            staticClass: "mb-3 col _12",
                            attrs: {
                              id: "number",
                              label: "Numéro",
                              type: "number"
                            },
                            model: {
                              value: _vm.form.number,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex mb-3" },
                        [
                          _c("FormInput", {
                            staticClass: "mb-3 col _12",
                            class: _vm.hasValue ? "has-value" : "",
                            attrs: { id: "address", label: "Adresse" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "input",
                                  fn: function() {
                                    return [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.form.address,
                                            expression: "form.address"
                                          }
                                        ],
                                        ref: "inputAddress",
                                        attrs: {
                                          type: "text",
                                          placeholder: ""
                                        },
                                        domProps: { value: _vm.form.address },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.form,
                                              "address",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                _vm.$v.form.address.$error
                                  ? {
                                      key: "error",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            "\n                    Vous devez entrer une adresse\n                  "
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-wrap"
                        },
                        [
                          _c("FormInput", {
                            staticClass: "mb-3 col _6",
                            attrs: { id: "postalCode", label: "Code postal" },
                            model: {
                              value: _vm.form.postalCode,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "postalCode", $$v)
                              },
                              expression: "form.postalCode"
                            }
                          }),
                          _c("FormInput", {
                            staticClass: "mb-3 col _6",
                            attrs: { id: "country", label: "Ville" },
                            model: {
                              value: _vm.form.city,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "city", $$v)
                              },
                              expression: "form.city"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex mb-3" },
                        [
                          _c("FormInput", {
                            staticClass: "col _12",
                            attrs: { id: "postalCode", label: "Pays" },
                            model: {
                              value: _vm.form.country,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "country", $$v)
                              },
                              expression: "form.country"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("p", { staticClass: "col mt-4 mb-3" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pharmacyOrder.address-page.delivery-comment"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("FormInput", {
                            attrs: {
                              id: "comment",
                              label: "Les indications pour le livreur"
                            },
                            model: {
                              value: _vm.form.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "comment", $$v)
                              },
                              expression: "form.comment"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("p", { staticClass: "col mt-4 mb-3" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("pharmacyOrder.address-page.ssn"))
                              )
                            ])
                          ]),
                          _c("FormInput", {
                            attrs: {
                              id: "comment",
                              label: _vm.$t("profileEditForm.form.ssn.label")
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.$v.form.ssn.$error
                                  ? {
                                      key: "error",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profileEditForm.form.ssn.invalid"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.form.ssn,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "ssn", $$v)
                              },
                              expression: "form.ssn"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex col py-5" },
                        [
                          _c(
                            "ButtonGreen",
                            {
                              attrs: {
                                busy: _vm.submitting,
                                icon: _vm.$icons.chevronRight,
                                small: "",
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(
                                "\n                 Valider\n                "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm.maximumMessage
                    ? _c("p", {
                        staticClass: "maximum__message p-4",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "pharmacyOrder.address-page.delivery-distance"
                            )
                          )
                        }
                      })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-separator" }),
                _c("div", { staticClass: "col _6 right  py-5" }, [
                  _c("div", { staticClass: "delivery_recap row" }, [
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.address-page.subtitle-one")
                        )
                      )
                    ]),
                    _vm.submitting
                      ? _c(
                          "div",
                          { staticClass: "text-center py-5" },
                          [_c("Spinner", { attrs: { color: "green" } })],
                          1
                        )
                      : _c("div", [
                          _vm.pharmacyOrder.delivery_address &&
                          _vm.pharmacyOrder.delivery_address !== "false"
                            ? _c("p", { staticClass: "address" }, [
                                _vm._v(
                                  _vm._s(_vm.pharmacyOrder.delivery_address)
                                )
                              ])
                            : _vm._e(),
                          _vm.profile.email
                            ? _c("p", { staticClass: "me_phone" }, [
                                _vm._v(_vm._s(_vm.profile.email))
                              ])
                            : _vm._e(),
                          _vm.profile.phone
                            ? _c("p", { staticClass: "me_phone" }, [
                                _vm._v(_vm._s(_vm.profile.phone))
                              ])
                            : _vm._e(),
                          _vm.profile.ssn
                            ? _c("p", { staticClass: "me_ssn" }, [
                                _vm._v(
                                  "Numéro de sécurité sociale : " +
                                    _vm._s(_vm.profile.ssn)
                                )
                              ])
                            : _vm._e()
                        ]),
                    _c("p", { staticClass: "subtitle col-form-label pt-4" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.address-page.subtitle-two")
                        )
                      )
                    ]),
                    _vm.submitting
                      ? _c(
                          "div",
                          { staticClass: "text-center py-5" },
                          [_c("Spinner", { attrs: { color: "green" } })],
                          1
                        )
                      : _c("div", [
                          _vm.pharmacyOrder.pharmacy.rs
                            ? _c("p", { staticClass: "rs" }, [
                                _vm._v(
                                  _vm._s(_vm.pharmacyOrder.pharmacy.rs) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm.pharmacyOrder.pharmacy.telephone
                            ? _c("p", { staticClass: "ph_phone" }, [
                                _vm._v(
                                  _vm._s(_vm.pharmacyOrder.pharmacy.telephone) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.pharmacyOrder.pharmacy.voie &&
                          _vm.pharmacyOrder.pharmacy.num_voie &&
                          _vm.pharmacyOrder.pharmacy.typ_voie
                            ? _c("p", { staticClass: "ph_phone" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.pharmacyOrder.pharmacy.num_voie
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.pharmacyOrder.pharmacy.typ_voie
                                    ) +
                                    " " +
                                    _vm._s(_vm.pharmacyOrder.pharmacy.voie) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.pharmacyOrder.pharmacy.ligne_acheminement
                            ? _c("p", { staticClass: "ph_phone" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.pharmacyOrder.pharmacy
                                      .ligne_acheminement
                                  ) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("hr"),
                          _vm.pharmacyOrder.transaction &&
                          _vm.pharmacyOrder.transaction.amount !== "false"
                            ? _c("div", { staticClass: "price" }, [
                                _c("div", [
                                  _vm.checkSlot
                                    ? _c("p", [_vm._v("Gratuite")])
                                    : _c("p", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.pharmacyOrder.transaction
                                                .amount
                                            ) + " €"
                                          )
                                        ])
                                      ])
                                ])
                              ])
                            : _vm._e()
                        ])
                  ])
                ])
              ]),
              _vm.pharmacyOrder.provider.steps.step_payment &&
              _vm.pharmacyOrder.delivery_address &&
              (_vm.pharmacyOrder.transaction.amount !== "false" ||
                _vm.pharmacyOrder.provider.name === "phacil")
                ? _c(
                    "div",
                    { staticClass: "py-5 text-center mb-5" },
                    [
                      _c(
                        "ButtonGreen",
                        {
                          attrs: { busy: _vm.submitting },
                          on: { click: _vm.nextPage }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.address-page.btn-next-step")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm.pharmacyOrder.delivery_address
                ? _c(
                    "div",
                    { staticClass: "py-5 text-center mb-5" },
                    [
                      _c(
                        "ButtonGreen",
                        {
                          attrs: { busy: _vm.submitting },
                          on: { click: _vm.nextPage }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.address-page.btn-next-step")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }