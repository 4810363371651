import Vue from 'vue';
import App from './App.vue';
import router from './config/router';
import store from './config/store';
import i18n from './config/i18n';
import './config/filters';
import './config/font_awesome';
import './config/datetime_picker';
import './config/moment';
import './config/media_query';
import './config/mixins';
import './config/inputmask';
import './config/form-validation';
import './config/guide';
import './config/portal';
import './config/autoclick';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { currentEnvironment, getSetting, isDevelopment, isProduction } from './helpers/tools.js';

if (getSetting('SENTRY_ENABLED') && !isDevelopment) {
  Sentry.init({
    environment: currentEnvironment,
    dsn: 'https://bc7f1001a1424840933907c15759fe30@o393256.ingest.sentry.io/5242139',
    integrations: [new VueIntegration({ Vue, logErrors: !isProduction })],
  });
}

Vue.config.productionTip = false;
Vue.prototype.$gMapApiKey = getSetting('GMAPS_API_KEY');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
