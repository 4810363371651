<template>
  <header class="profile-tab-bar">
    <router-link :to="{ name: 'profile-edit' }">{{ $t('profileTabBar.profile') }}</router-link>
    <!-- TODO : Enable when available -->
    <!--    <router-link :to="{ name: 'profile-health' }">Santé</router-link>-->
<!--
    <router-link :to="{ name: 'profile-status' }">{{ $t('profileTabBar.status') }}</router-link>
-->
    <router-link :to="{ name: 'profile-payment' }">{{ $t('profileTabBar.payment') }}</router-link>
    <router-link :to="{ name: 'profile-commands' }">{{ $t('profileTabBar.commands') }}</router-link>
    <router-link :to="{ name: 'profile-beneficiaries' }">{{ $t('profileTabBar.beneficiaries') }}</router-link>
    <router-link :to="{ name: 'profile-access' }">{{ $t('profileTabBar.access') }}</router-link>
  </header>
</template>

<script>
  export default {
    name: 'ProfileTabBar',
  };
</script>

<style lang="scss" scoped>
  .profile-tab-bar {
    display: flex;
    justify-content: center;
    text-align: center;
    > a {
      font-family: "Gotham Rounded", sans-serif;
      font-size: 2rem;
      font-weight: normal;
      margin: 0 2.4rem;
      padding: 0.6rem 0;
      text-decoration: none;
      color: #1F1F1F;

      &.router-link-exact-active {
        font-weight: bold;
        border-bottom: solid .5rem #27D7AB;
      }
    }

    @include onMobile {
      flex-wrap: wrap;
      align-items: center;
    }
  }
</style>
