var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appointment-details" }, [
    _vm.isCancelledByPatient
      ? _c("div", { staticClass: "cancelled" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("appointmentPanelDetails.cancelled.by-patient")) +
              "\n  "
          )
        ])
      : _vm.isCancelledByPractitioner
      ? _c("div", { staticClass: "cancelled" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("appointmentPanelDetails.cancelled.by-practitioner")
              ) +
              "\n  "
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { class: { busy: _vm.isReplacingPatient } },
      [
        _c("FontAwesomeIcon", {
          attrs: {
            icon: _vm.isReplacingPatient ? _vm.$icons.spinner : _vm.$icons.user,
            spin: _vm.isReplacingPatient,
            "fixed-width": ""
          }
        }),
        _c("strong", [
          _vm._v(_vm._s(_vm._f("fullName")(_vm.appointment.payload.patient)))
        ]),
        _vm.canReplacePatient && !_vm.isReplacingPatient
          ? _c("span", { staticClass: "replace-patient" }, [
              _vm._v("\n      ( "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.$confirm(_vm.$refs.replacePatient)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "appointmentPanelDetails.replace-patient.link-label"
                      )
                    )
                  )
                ]
              ),
              _vm._v(" )\n    ")
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "AppConfirmation",
          {
            ref: "replacePatient",
            attrs: {
              icon: _vm.$icons.user,
              title: _vm.$t(
                "appointmentPanelDetails.replace-patient.confirmation-title"
              )
            }
          },
          [
            _c("BeneficiaryList", {
              attrs: {
                patient: _vm.profile,
                selected: _vm.appointment.payload.patient,
                cancelable: ""
              },
              on: {
                change: _vm.handleReplacePatient,
                cancel: function($event) {
                  return _vm.$refs.replacePatient.close()
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FontAwesomeIcon", {
          attrs: { icon: _vm.$icons.calendar, "fixed-width": "" }
        }),
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm._f("date")(
                _vm.appointment.payload.startAt,
                "dddd DD MMMM YYYY"
              )
            )
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FontAwesomeIcon", {
          attrs: { icon: _vm.$icons.clock, "fixed-width": "" }
        }),
        _c("strong", [_vm._v(_vm._s(_vm.appointment.time))]),
        _vm.isStartWarningVisible
          ? _c("strong", { staticClass: "start-warning" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("appointmentPanelDetails.start-warning")) +
                  "\n    "
              )
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FontAwesomeIcon", {
          attrs: { icon: _vm.$icons.eye, "fixed-width": "" }
        }),
        _c("strong", [
          _vm._v(
            _vm._s(_vm._f("channel")(_vm.appointment.payload.product.channel))
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FontAwesomeIcon", {
          attrs: { icon: _vm.$icons.stethoscope, "fixed-width": "" }
        }),
        _c("strong", [_vm._v(_vm._s(_vm.productName))])
      ],
      1
    ),
    _c(
      "div",
      [
        _c("AppointmentStatusIcon", {
          attrs: { status: _vm.appointment.payload.status }
        }),
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm._f("appointmentStatus")(
                _vm.appointment.payload.status,
                _vm.canBeStarted
              )
            )
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }