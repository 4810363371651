import api from './';
import {normalizePharmacies} from "../helpers/normalizers";

export default {
    search: (lat, lng, radius) => {
        const searchParams = new URLSearchParams({
            'lat': lat,
            'lng': lng,
            'radius': radius
        });
         return api.get('pharmacies', {searchParams}).json().then(pharmacies => pharmacies)
    }
}