var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AppConfirmation",
        {
          ref: "confirmCancel",
          attrs: {
            title: _vm.$t("pharmacyOrder.appointment-panel-card.cancel-order"),
            icon: _vm.$icons.box
          },
          scopedSlots: _vm._u([
            {
              key: "description",
              fn: function(scoped) {
                return [
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.appointment-panel-card.cancel")
                        )
                      )
                    ])
                  ])
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("ConfirmButton", [_vm._v(_vm._s(_vm.$t("pharmacyOrder.no")))]),
              _c(
                "ConfirmButton",
                {
                  attrs: { color: "red", icon: _vm.$icons.no },
                  on: { click: _vm.cancelOrder }
                },
                [_vm._v(_vm._s(_vm.$t("pharmacyOrder.yes")))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "AppConfirmation",
        {
          ref: "confirmFinished",
          attrs: {
            title: _vm.$t("pharmacyOrder.appointment-panel-card.is-received"),
            icon: _vm.$icons.box
          },
          scopedSlots: _vm._u([
            {
              key: "description",
              fn: function(scoped) {
                return [
                  _c("div", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "pharmacyOrder.appointment-panel-card.confirm-received"
                          )
                        )
                      )
                    ])
                  ])
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("ConfirmButton", { attrs: { color: "red" } }, [
                _vm._v(_vm._s(_vm.$t("pharmacyOrder.no")))
              ]),
              _c(
                "ConfirmButton",
                {
                  attrs: { icon: _vm.$icons.no },
                  on: { click: _vm.finishedOrder }
                },
                [_vm._v(_vm._s(_vm.$t("pharmacyOrder.yes")))]
              )
            ],
            1
          )
        ]
      ),
      _c("p", [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("pharmacyOrder.appointment-panel-card.title")))
        ])
      ]),
      _c("p", [
        _vm._v(
          _vm._s(_vm.$t("pharmacyOrder.appointment-panel-card.subtitle-after"))
        )
      ]),
      _vm.status === "finished"
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("pharmacyOrder.appointment-panel-card.order-received")
                ) +
                " " +
                _vm._s(_vm._f("date")(_vm.order.finished_at, "DD/MM/YYYY")) +
                " " +
                _vm._s(_vm.$t("pharmacyOrder.appointment-panel-card.at")) +
                " " +
                _vm._s(_vm._f("date")(_vm.order.finished_at, "HH:mm")) +
                ".\n  "
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "click-collect__pharmacy-detail" }, [
        _c("div", [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.order.pharmacy.rs_longue
                  ? _vm.order.pharmacy.rs_longue
                  : _vm.order.pharmacy.rs
              )
            )
          ]),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.order.pharmacy.num_voie ? _vm.order.pharmacy.num_voie : ""
                ) +
                "\n        " +
                _vm._s(
                  _vm.order.pharmacy.typ_voie ? _vm.order.pharmacy.typ_voie : ""
                ) +
                "\n        " +
                _vm._s(_vm.order.pharmacy.voie ? _vm.order.pharmacy.voie : "") +
                "\n      "
            )
          ]),
          _c("p", [_vm._v(_vm._s(_vm.order.pharmacy.libdepartement))]),
          _c("p", [_c("b", [_vm._v(_vm._s(_vm.statusText))])])
        ]),
        _vm.imageName
          ? _c("div", [
              _vm.imageName === "new"
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/images/new.gif"),
                      alt: "new"
                    }
                  })
                : _vm._e(),
              _vm.imageName === "preparing"
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/images/preparing.gif"),
                      alt: "preparing"
                    }
                  })
                : _vm._e(),
              _vm.imageName === "terminated"
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/images/terminated.gif"),
                      alt: "terminated"
                    }
                  })
                : _vm._e(),
              _vm.imageName === "delivering"
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/images/delivering.gif"),
                      alt: "terminated"
                    }
                  })
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm.canCancel
        ? _c(
            "div",
            [
              _vm.onlineCancel()
                ? _c(
                    "ButtonGrey",
                    {
                      staticClass: "button-cancel",
                      on: { click: _vm.confirmCancel }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "pharmacyOrder.appointment-panel-card.cancel-preparation"
                          )
                        )
                      )
                    ]
                  )
                : _c("p", { staticClass: "click-collect__cancel_number" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t(
                            "pharmacyOrder.appointment-panel-card.cancel-phone"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "tel:+" + _vm.order.pharmacy.telephone }
                      },
                      [_vm._v(_vm._s(_vm.order.pharmacy.telephone))]
                    )
                  ])
            ],
            1
          )
        : _vm._e(),
      _vm.status === "ready"
        ? _c(
            "div",
            [
              _c(
                "ButtonGreen",
                {
                  staticClass: "button-cancel",
                  on: { click: _vm.confirmFinished }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "pharmacyOrder.appointment-panel-card.btn-received"
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }