<!--
Emitted events:
- change(beneficiary)
- cancel
-->
<template>
  <div class="beneficiary-list">
    <div class="beneficiaries">
      <div
          v-for="beneficiary in beneficiaries"
          :class="{ selected: selected && selected.id === beneficiary.id }"
          class="beneficiary"
          @click="$emit('change', beneficiary)"
      >
        <UserAvatar :src="beneficiary.picture" size="5.5rem" />
        <div>
          <div class="name">{{ beneficiary | fullName }}</div>
        </div>
        <!-- TODO : Enable when beneficiary is deletable (+ add confirmation dialog and handler) -->
        <!--          <FontAwesomeIcon-->
        <!--            v-if="!beneficiary.isOwner"-->
        <!--            :icon="$icons.trashOutlined"-->
        <!--            class="delete-icon"-->
        <!--            @click.stop="handleDelete"-->
        <!--          />-->
      </div>
    </div>
    <a class="add-beneficiary" href="#" @click.prevent="isPanelOpened = true">
      {{ $t('beneficiaryList.add-beneficiary') }}
    </a>
    <div v-if="cancelable" class="cancel">
      <a href="#" @click.prevent="$emit('cancel')">{{ $t('beneficiaryList.cancel') }}</a>
    </div>
    <AppPanel
        :opened="isPanelOpened"
        :title="$t('beneficiaryList.add-beneficiary')"
        @close="isPanelOpened = false"
    >
      <ProfileEditForm
          hide-avatar
          @create="handleCreate"
          :beneficiary="true"
      />
    </AppPanel>
  </div>
</template>

<script>
import AppPanel from '@/components/AppPanel.vue';
import ProfileEditForm from '@/components/Form/ProfileEditForm.vue';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'BeneficiaryList',
  components: { ProfileEditForm, AppPanel, UserAvatar },
  props: {
    selected: {
      type: Object,
      required: false,
    },
    selectDefault : {
      default: false
    },
    cancelable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPanelOpened: false,
    };
  },
  created() {
    if (this.selectDefault) {
      this.$emit('change', this.beneficiaries[0]);
    }
  },
  computed: {
    profile() {
      return this.$store.state.patients.profile;
    },
    beneficiaries() {
      return [this.profile, ...this.profile.children];
    },
  },
  methods: {
    handleCreate(beneficiary) {
      this.$emit('change', beneficiary);
      this.isPanelOpened = false;
    },
    handleDelete(id) {
      this.$store.dispatch('beneficiariesDelete', { id });
    },
  },
};
</script>

<style lang="scss" scoped>
.beneficiary {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0.4rem 2.4rem 0.4rem 0.4rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 3.3rem;

  &:hover {
    border-color: #E9E9E9;
    background-color: #F9F9F9;
  }

  &.selected {
    border-color: #27D7AB;
    background-color: #DFF9F3;
  }
}

.user-avatar {
  margin-right: 2.3rem;
}

.name {
  font-size: 2rem;
  font-weight: 500;
}

.add-beneficiary {
  text-decoration: underline;
  color: $successColor;
}

.delete-icon {
  font-size: 2rem;
  margin-right: 2rem;
  margin-left: auto;
  color: $grey;

  &:hover {
    color: $errorColor;
  }
}

.cancel {
  margin-top: 2rem;

  a {
    color: $errorColor;
  }
}
</style>