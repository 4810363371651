<template>
  <div class="app-notification" @mouseenter="paused = true" @mouseleave="paused = false">
    <AppNotificationItem
      v-for="notification in notifications"
      :id="notification.id"
      :key="notification.id"
      :duration="notification.duration"
      :icon="notification.icon"
      :paused="paused"
      :type="notification.type"
    >
      <span v-if="notification.asHTML" v-html="notification.message" />
      <span v-else>{{ notification.message }}</span>
    </AppNotificationItem>
  </div>
</template>

<script>
  import AppNotificationItem from './AppNotification/AppNotificationItem';

  export default {
    name: 'AppNotification',
    components: { AppNotificationItem },
    data() {
      return {
        paused: false,
      };
    },
    watch: {
      'notifications.length'(length) {
        if (length === 0) {
          this.paused = false;
        }
      },
    },
    computed: {
      notifications() {
        return this.$store.getters.displayableNotifications;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-notification {
    position: fixed;
    z-index: 2050;
    top: 2rem;
    right: 2rem;
    width: 40rem;
    @include onMobile {
      width: auto;
      right: 0;
    }
  }

  .app-notification-item {
    margin-bottom: 1rem;
  }
</style>
