<!--
Emitted events:
- select(card.id)
-->
<template>
  <div class="credit-cards">
    <div v-if="isLoadingCards">{{ $t('creditCards.loading') }}</div>
    <div v-else-if="!cards.length">
      {{ $t('creditCards.no-cards') }}
    </div>
    <CreditCardRow
      :key="card.id"
      v-for="card in cards"
      v-else
      :brand="card.brand"
      :expiration-month="card.expirationMonth"
      :expiration-year="card.expirationYear"
      :last-four-digit="card.lastFourDigit"
      :removable="removable"
      :selectable="selectable"
      :selected="card.id === selectedCardId"
      @remove="$confirm($refs.deletePaymentMethod, card)"
      @select="$emit('select', card.id)"
    />
    <AppConfirmation
      ref="deletePaymentMethod"
      :title="$t('creditCards.remove-confirmation')"
      @confirm="remove"
    />
    <div v-if="!isLoadingCards" class="add-card">
      <a href="#" @click="addFormOpened = true" @click.prevent>{{ $t('creditCards.add-card') }}</a>
    </div>
    <AppPanel
      :opened="addFormOpened"
      max-width="70rem"
      :title="$t('creditCards.add-payment-method')"
      @close="addFormOpened = false"
    >
      <CreditCardsPictograms />
      <CreditCardAddForm @close="addFormOpened = false" @create="handleCreated" />
    </AppPanel>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import AppPanel from '@/components/AppPanel';
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonRed from '@/components/ButtonRed';
  import CreditCardRow from '@/components/CreditCardRow';
  import CreditCardsPictograms from '@/components/CreditCardsPictograms';
  import CreditCardAddForm from '@/components/Form/CreditCardAddForm';
  import FormCheckbox from '@/components/Form/FormCheckbox';

  export default {
    name: 'CreditCards',
    model: {
      event: 'select',
      prop: 'selectedCardId',
    },
    components: {
      AppConfirmation,
      CreditCardRow,
      CreditCardAddForm,
      ButtonGreen,
      ButtonRed,
      CreditCardsPictograms,
      AppPanel,
      FormCheckbox,
    },
    props: {
      removable: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      selectedCardId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        addFormOpened: false,
      };
    },
    async created() {
      await this.reloadCards();
      if (this.cards.length === 1) {
        this.$emit('select', this.cards[0].id);
      }
      if(this.selectedCardId === null){
        this.$emit('select', this.cards[0].id);
      }
    },
    computed: {
      cards() {
        return this.$store.state.paymentMethods.all;
      },
      isLoadingCards() {
        return this.$store.state.paymentMethods.isFetchingAll;
      },
    },
    methods: {
      handleCreated() {
        this.addFormOpened = false;
        this.$emit('select', this.cards[0].id);
      },
      async reloadCards() {
        await this.$store.dispatch('paymentMethodsFetchAll');
      },
      async remove(card) {
        await this.$store.dispatch('paymentMethodsDelete', { id: card.id });
        await this.reloadCards();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .credit-cards-pictograms {
    margin: 4rem auto;
  }

  .add-card {
    margin-top: 2rem;
    margin-left: 1.5rem;

    a {
      text-decoration: underline;
      color: $successColor;
    }
  }
</style>
