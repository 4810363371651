<template>
  <AppLayout>
  <main>
    <ProfileTabBar />
    <div class="container">
      <div class="status-container">
        <div class="status-container__offer" :class="[isPremium ? 'status-container__offer--premium' : null]">
            <div v-if="!isPremium" class="status-offer">
                <div class="status-offer__container">
                    <div class="status-offer__title-container">
                        <h3 class="status-offer__title">BASIC</h3>
                        <p class="status-offer__title">Paiement à l'acte</p>
                    </div>
                    <div class="status-offer__descriptions">
                        <span class="status-offer__description">TÉLÉCONSULTATION SUR RDV</span>
                        <p class="status-offer__description--free-price">INCLUS</p>
                    </div>
                    <div class="status-offer__descriptions">
                        <span class="status-offer__description">CLICK & COLLECT PHARMACIE</span>
                        <p class="status-offer__description--free-price">INCLUS</p>
                    </div>
                    <div class="status-offer__descriptions">
                        <span class="status-offer__description">TÉLÉCONSULTATION EN - DE 1H</span>
                        <p class="status-offer__description status-offer__description--no-free">6€/TÉLÉCONSULTATION/</p>
                    </div>
                    <div class="status-offer__descriptions">
                        <span class="status-offer__description">LIVRAISON DE MÉDICAMENTS</span>
                        <p class="status-offer__description status-offer__description--no-free">À partir de 6€/LIVRAISON</p>
                    </div>
                    <div class="status-offer__descriptions">
                        <span class="status-offer__description">CONCIERGERIE MÉDICALE</span>
                        <p class="status-offer__description--no-free">9,90€/APPEL</p>
                    </div>
                </div>
            </div>
            <div class="status-offer">
                <div v-if="!isPremium">
                    <div class="status-offer__container">
                        <div class="status-offer__title-container status-offer--premium">
                            <h3 class="status-offer__title">PREMIUM</h3>
                            <p class="status-offer__title">6,99€/mois TOUT INCLUS</p>
                        </div>
                        <AppLogoPremium class="status-offer__img" large/>
                        <div class="status-offer__descriptions">
                            <span class="status-offer__description">TÉLÉCONSULTATION SUR RDV</span>
                            <p class="status-offer__description--free-price">INCLUS</p>
                        </div>
                        <div class="status-offer__descriptions">
                            <span class="status-offer__description">CLICK & COLLECT PHARMACIE</span>
                            <p class="status-offer__description--free-price">INCLUS</p>
                        </div>
                        <div class="status-offer__descriptions">
                            <span class="status-offer__description status-offer">TÉLÉCONSULTATION EN - DE 1H</span>
                            <p class="status-offer__description--free-price">INCLUS & ILLIMITÉ</p>
                        </div>
                        <div class="status-offer__descriptions">
                            <span class="status-offer__description status-offer">LIVRAISON DE MÉDICAMENTS</span>
                            <p class="status-offer__description--free-price">INCLUS 1 LIVRAISON /MOIS</p>
                        </div>
                        <div class="status-offer__descriptions">
                        <span class="status-offer__description status-offer">CONCIERGERIE MÉDICALE</span>
                        <p class="status-offer__description--free-price">INCLUS 3 APPELS /MOIS</p>
                    </div>
                    </div>
                </div>
                <div v-if="isPremium" class="status-offer__container-premium">
                    <div class="presentation-container">
                        <h3 class="presentation-container__title">Bonjour {{this.$store.state.patients.profile.firstName}}</h3>
                        <p class="presentation-container__desc">Bienvenue sur votre tableau de bord</p>
                    </div>
                    <div class="container-premium" :class="[this.isPremium ? 'container-premium--is-premium': null ]">
                        <AppLogoPremium class="status-offer__img" large/>
                        <div class="status-offer__container status-offer__container--premium">
                            <div class="status-offer__title-container status-offer--premium">
                                <h3 class="status-offer__title">Votre statut: <br>
                                  PREMIUM</h3>
                            </div>
                            <div class="status-offer__descriptions">
                                <span class="status-offer__description">TÉLÉCONSULTATION SUR RDV</span>
                                <p class="status-offer__description--free-price">ILLIMITÉES</p>
                            </div>
                            <div class="status-offer__descriptions">
                                <span class="status-offer__description">CLICK & COLLECT PHARMACIE</span>
                                <p class="status-offer__description--free-price">ILLIMITÉES</p>
                            </div>
                            <div class="status-offer__descriptions">
                                <span class="status-offer__description">TÉLÉCONSULTATION EN - DE 1H</span>
                                <p class="status-offer__description--free-price">ILLIMITÉES</p>
                            </div>
                            <div class="status-offer__descriptions">
                                <span class="status-offer__description">LIVRAISON DE MÉDICAMENTS</span>
                                <p class="status-offer__description--free-price" :class="[maxSLotDelivery ? 'max-slot': null ]" v-if="this.totalSlots">{{ `${amountSlotDelivery}/1` }}</p>
                                <p class="status-offer__description--free-price">{{ `${deliveryCreditsPerMonth - amountSlotDelivery} livraison restante` }}</p>
                            </div>
                            <div class="status-offer__descriptions">
                                <span class="status-offer__description" v-if="this.totalSlots">CONCIERGERIE MÉDICALE</span>
                                <p class="status-offer__description--free-price" :class="[maxSLotConciergerie ? 'max-slot': null ]" v-if="this.totalSlots">{{ `${amountSlotConciergerie}/3` }}</p>
                                <p class="status-offer__description--free-price">{{ `${conciergerieCreditsPerMonth - amountSlotConciergerie} ${amountSlotConciergerie >= 2 ? 'appel restant' : 'appels restants'}` }}</p>
                            </div>
                        </div>
                      <div class="status-premium">
                        <a v-if="isPremium" class="status-premium__button" @click="unsubscribeWindow" block>{{ $t('profileStatusPage.status.premium.unsubscription') }}</a>
                      </div>
                    </div>
                    <div class="status-offer__description-premium">
                        <div>
                            <img src="../../assets/images/ordre-du-jour@2x.png" alt="ordre du jour">
                            <span>JE PRENDS RENDEZ-VOUS</span>
                        </div>
                        <div>
                            <img src="../../assets/images/ordinateur@2x.png" alt="ordinateur">
                            <span>JE TÉLÉCONSULTE MAINTENANT</span>
                        </div>
                        <div>
                            <img src="../../assets/images/livraison-rapide@2x.png" alt="livraison">
                            <span>JE ME FAIS LIVRER MES MÉDICAMENTS</span>
                        </div>
                        <div>
                            <img src="../../assets/images/service-client@2x.png" alt="service client">
                            <span>JE CONTACTE MON CONCIERGE MÉDICAL</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-container" v-if="!isPremium">
                    <a href="https://hellocare.com/presentation-premium" class="standart-status__button" target="_blank"><span>EN SAVOIR PLUS</span></a>
                <div class="button-sub-container" v-if="!isPremium">
                    <span>SANS ENGAGEMENT</span>
                    <AppLogoPremium class="button-sub-container__img" xmedium/>
                    <CardPayment class="button-sub-container__button-sub">PASSER AU PREMIUM</CardPayment>
                </div>
            </div>
        </div>
      </div>
    </div>
    <AppConfirmation
        ref="unsubscribeWindow"
        :title="$t('profileStatusPage.confirmation.title')"
    >
      <template v-slot:description="scoped">{{ $t('profileStatusPage.confirmation.description.1') }}<br>{{ $t('profileStatusPage.confirmation.description.2') }}</template>
        <CancelButton  :icon="$icons.no">Non</CancelButton>
        <ConfirmButton color="red" @click="unsubscribe" :icon="$icons.no">Oui</ConfirmButton>
    </AppConfirmation>
  </main>
    </AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue'
import ProfileTabBar from '@/components/ProfileTabBar.vue'
import ButtonWhite from '@/components/ButtonWhite.vue'
import AppLogoPremium from '@/components/AppLogoPremium.vue'
import ProfileSummaryPage from "@/pages/Profile/ProfileSummaryPage.vue";
import AppConfirmation from "@/components/AppConfirmation.vue";
import ButtonTransparent from "../../components/ButtonTransparent.vue";
import ConfirmButton from "../../components/AppConfirmation/ConfirmButton.vue";
import CancelButton from "../../components/AppConfirmation/CancelButton.vue";
import CardPayment from "../../components/Premium/CardPayment.vue";
import {mapGetters} from "vuex";
import ButtonGreen from "@/components/ButtonGreen.vue";
import ButtonRed from "@/components/ButtonRed.vue";
export default {
  name: 'ProfileStatusPage',
  components: {
      ButtonGreen,
    CardPayment,
    CancelButton,
    ConfirmButton,
    ButtonTransparent,
    AppConfirmation,
    ProfileSummaryPage,
    ProfileTabBar,
    AppLayout,
    ButtonWhite,
    AppLogoPremium,
    ButtonRed
  },
  data() {
    return {
      showSummary: false,
      submitting: false,
      amountSlotDelivery: null,
      amountSlotConciergerie: null,
      maxSLotConciergerie: false,
      maxSLotDelivery: false,
      conciergerieCreditsPerMonth: 3,
      deliveryCreditsPerMonth: 1,
    }
  },
   mounted() {
     this.loadData();
     this.successOnSubscribe()
  },
  methods: {
     availableSlot(typeSlot) {
      if(this.totalSlots) {
        return  this.totalSlots.filter(type => type === typeSlot).length
      }
    },
    async unsubscribeWindow() {
      return this.$confirm(this.$refs.unsubscribeWindow)
    },
    successOnSubscribe() {
        this.$store.dispatch('patientFetchProfile');
        if (window.location.href.includes('?premium=true')) {
            this.$addSuccess("Votre abonnement a bien été pris en compte. Vous pouvez désormais profiter de tous les avantages de votre abonnement.");
            window.history.pushState({}, document.title, "/profile/status");
        }
        if (window.location.href.includes('?premium=false')) {
            this.$addSuccess("Une erreur est survenue lors de la prise en compte de votre abonnement. Veuillez réessayer ultérieurement.");
            window.history.pushState({}, document.title, "/profile/status");
        }
    },
    async unsubscribe() {
      this.submitting = true;
      await this.$store.dispatch('premiumCancel', {}).catch((error) => {
        throw error;
      })
      this.submitting = false;
      await this.$store.dispatch('patientFetchProfile');
      return this.$router.push({ name: 'profile-view', query: { premium: 'cancel' } });
    },

    async loadData() {
      await this.$store.dispatch('appLoaderShow');
      try {
        await Promise.all([
          await this.$store.dispatch('getSlotAll')
      ]);
      } catch (e) {
        await this.$addError('Une erreur s\'est produite lors du chargement de vos avantages restant.');
        throw e;
      } finally {
        this.amountSlotDelivery = await this.availableSlot("delivery_premium")
        this.amountSlotConciergerie = await this.availableSlot("conciergerie_premium")
        if(this.amountSlotDelivery >= 3) {
            this.maxSLotDelivery = true

        }
        if(this.amountSlotConciergerie >= 1) {
            this.maxSLotConciergerie = true
        }
        await this.$store.dispatch('appLoaderHide');
      }
    },
  },

  computed: {
    ...mapGetters(['totalSlots']),
    isPremium() {
      return this.$store.state.patients.profile.premium
    },

  }
};

</script>

<style lang="scss" scoped>
.container {
  margin-bottom: -2rem;
}

.status-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    @include onMobile {
        overflow: initial;
    }
    &__offer {
        background: #D1606B;
        padding: 2.5rem 5rem;
        display: flex;
        gap: 2.5rem;
        border-radius:2rem;
        flex-wrap: wrap;
        @media (max-width: 992px) {
            flex-direction: column;
        }
        @include onMobile {
            padding: 2.5rem;
        }
        @media (max-width: 410px) {
            padding: .8rem;
        }
        &--premium {
            padding: 1rem;
            flex-direction: column;
        }
    }
}

.status-offer {
  position: relative;
  flex: 1;
  min-width: 300px;
  @include onMobile {
    min-width: auto;
  }
  &__container-premium {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0rem 1rem 2rem;
    @include onMobile {
      padding: 0rem 0rem 2rem;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__description-premium {
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    max-width: 141px;
    text-align: center;
    height: 492px;
    @include onMobile {
      margin-top: 2rem;
      row-gap: 2rem;
    }
    @media (max-width: 600px) {
      margin-top: 0;
      margin-bottom: 2rem;
    }
      div {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
              width: 50px;
              margin-bottom: 1rem;
          }
      }
  }
  &__title-container {
      min-width: 256px;
      padding: 6rem 2rem 2rem;
      border-radius: 0rem 0rem 3rem 2rem;
      background: #D1606BD6;
      transform: skewY(4deg);
      position: relative;
      top: -11.5rem;
      margin-bottom: -7.5rem;
    @include onMobile {
      min-width: auto;
    }
  }
    &--is-premium {
        justify-content: space-between;
    }
    &--premium {
        background: #FFE130;
    }
  &__title {
    color: white;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8rem;
    transform: skewY(-4deg);
  }
  &__img {
    position: absolute;
    top:-22px;
    right: -22px;
    z-index: 1;
  }
  &__container {
    border-radius: 2rem;
    background: white;
    color: #0000005C;
    padding: 8rem 2.7rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 461px;
    @include onMobile {
      padding: 8rem 1rem 0rem;
      height: auto;
    }
    &--premium {
      height: auto;
    }
  }
  &__descriptions {
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    @include onMobile {
      white-space: normal;
    ;
    }
  }
  &__description {
      white-space: initial;
      font-weight: bold;
      text-align: center;
      color: #0000005C;
    &--no-free {
      color: #D1606B;
      margin: 0;
      font-weight: bold;
    }
      &--free-price {
          margin: 0;
          color: #67D4AD;
          font-weight: bold;
          &:nth-child(3) {
              color: #0000005C;
          }
      }
  }
  &--standart {
    display: none;
    @include onDesktop {
      display: block;
    }
  }
}
.container-premium {
    position: relative;
    @media (max-width: 600px) {
    order: 3;
  }
  &--is-premium {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
.presentation-container {
    flex: 100%;
    color: white;
    margin: 2rem;
    @media (max-width: 768px) {
        text-align: center;
    }
    &__title {
        font-weight: bold;
        font-size: 1.5rem;
    }
    &__desc {
        font-weight: normal;
        font-size: 1.5rem;
        margin-top: 0;
    }
}
.standart-status {
  font-weight: normal;
    &__button {
        border-radius: 10px;
        font-weight: bold;
        background: white;
        color: #D1606B;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.6rem 3.2rem;
        outline: none;
        border: 1px solid transparent;
        font-family: "Gotham Rounded", sans-serif;
        transition: 0.2s;
        font-size: 1.8rem;
        width: 264.42px;
      @media (max-width: 410px) {
        padding: 1.6rem 0.4rem;
        width: 100%;
      }
        span {
            transform: translateY(0.1rem);
        }
    }
}
.status-premium {
  text-align: center;
  margin-top: 1rem;
  &__desc {
    color: #D87982;
    font-weight: 400;
    text-align: center;
  }
  &__total-price {
    font-weight: bold;
  }
  &__button {
    border-radius: 10px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
}
.unsub {
  text-align: center;
  color: white;
  font-weight: bold;
   font-size: 1.3rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.max-slot {
    color: #D1606B;
}

.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
            gap: 4rem;
            margin-top: 0;
        }
}

.button-sub-container {
    position: relative;
    width: 264.42px;
    align-self: center;
  @include onMobile {
    width: auto;
  }
  @media (max-width: 410px) {
    width: 100%;
  }
    span {
        position: absolute;
        width: 100%;
        text-align: center;
        top: -2.5rem;
        color: white;
    }
    &__img {
        position: absolute;
        top: -17.5px;
        right: -17.5px;
    }
    &__button-sub ::v-deep.base-button {
        background: white;
        color: #D1606B;
        font-size: 1.8rem;
      @media (max-width: 410px) {
        padding: 1.6rem 0.4rem;
        width: 100%;
      }
    }
}

::v-deep .button {
  @media (max-width: 410px) {
    width: 100%;
  }
}

</style>