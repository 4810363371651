var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    [
      _c("AppProgress", {
        attrs: {
          current: 6,
          total: 8,
          position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
        }
      }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("RecapTitle", {
            attrs: {
              title: _vm.practitioner.title + " " + _vm.practitioner.lastName,
              date: _vm.dateAppointment(this.newAppointment.startAt)
            }
          }),
          _c("SectionTitle", [_vm._v("Tarif de la consultation")]),
          _c("span", { staticClass: "description" }, [
            _vm._v(
              "Ce tarif prévisionnel repose sur le motif que vous avez sélectionné lors de la prise de rendez-vous et sur les honoraires spécifiques du praticien. Le montant final peut varier si le motif choisi ne correspond pas à l’acte réalisé. Si tel est le cas, le praticien vous en informera au début de la téléconsultation."
            )
          ]),
          _c("div", { staticClass: "recap-container" }, [
            _c("div", { staticClass: "recap" }, [
              _c("div", { staticClass: "recap__title" }, [
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.productPrice))
                ]),
                _c("span", [
                  _vm._v("Motif choisi: " + _vm._s(_vm.product.name))
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  class: { "collapse-open": _vm.openCollapse }
                },
                [
                  _c("div", { staticClass: "price-cols" }, [
                    _vm._v("Honoraires "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatMoney(this.product.unitPrice)))
                    ])
                  ]),
                  _c("div", { staticClass: "price-cols" }, [
                    _c("span", [
                      _vm._v("Frais de service\n              "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf",
                            target: "_blank"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "icon-question",
                            attrs: {
                              alt: "icon-question",
                              src: require("@/assets/images/question.png")
                            }
                          })
                        ]
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.formatMoney(
                              _vm.getServiceCharge(
                                _vm.patient,
                                _vm.serviceCharge,
                                this.practitioner
                              )
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "price-cols" }, [
                    _vm._v(
                      "\n            Frais de mise en relation\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.formatMoney(
                              _vm.getConnectionFees(
                                _vm.firstTimeWithPractitioner,
                                _vm.markup,
                                _vm.patient,
                                this.practitioner
                              )
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "recap-container__buttons" }, [
              _c(
                "span",
                {
                  staticClass: "change-pattern",
                  on: { click: _vm.goToSearch }
                },
                [_vm._v("Changer de motif")]
              ),
              _c("input", {
                staticClass: "details-button",
                attrs: { type: "checkbox" },
                on: {
                  click: function($event) {
                    _vm.openCollapse = !_vm.openCollapse
                  }
                }
              })
            ])
          ]),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "ButtonTransparent",
                {
                  attrs: { icon: _vm.$icons.chevronLeft, "icon-to-left": "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentReasonPage.back")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "ButtonGreen",
                {
                  attrs: {
                    busy: _vm.busy,
                    icon: _vm.$icons.chevronRight,
                    rounded: "",
                    type: "submit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleFirstTime(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentReasonPage.continue")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }