<template>
  <FullscreenNotification :icon="$icons.thumbsUp" :title="$t('appointmentApprovedPage.title')">
    <p> {{ $t('appointmentApprovedPage.accepted') }} </p>
    <p> {{ $t('appointmentApprovedPage.mailConfirm') }} </p>
    <p> {{ $t('appointmentApprovedPage.visibleAppointment') }} </p>
    <template #buttons>
      <ButtonGreen :link-to="{ name: 'home' }"> {{ $t('appointmentApprovedPage.confirm') }} </ButtonGreen>
    </template>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import ButtonGreen from '../components/ButtonGreen';

  export default {
    name: 'AppointmentApprovedPage',
    components: { ButtonGreen, FullscreenNotification, AppLayout, SectionTitle },
  };
</script>
