<template>
  <div :class="{ small }" class="credit-cards-pictograms">
    <img alt="" src="../assets/images/visa-logo.svg" />
    <img alt="" src="../assets/images/mastercard-logo.svg" />
    <img alt="" src="../assets/images/amex-logo.svg" />
  </div>
</template>

<script>
  export default {
    name: 'CreditCardsPictograms',
    props: {
      small: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .credit-cards-pictograms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 25rem;
    margin: 1rem auto;

    &.small {
      max-width: 14rem;

      img {
        width: 3.5rem;
      }
    }
  }
</style>
