export function normalizeEncounter(encounter) {
  return {
    id: encounter.id,
    practitioner: normalizePractitioner(encounter.practitioner),
    patient: encounter.patient,
    appointment: normalizeConnectAppointment(encounter.appointment),
    products: encounter.products ? normalizeProducts(encounter.products) : null,
    startAt: encounter.start_at,
    endAt: encounter.end_at,
    patientRating: encounter.patient_rating,
    practitionerRating: encounter.practitioner_rating,
    medicalObservation: encounter.medical_observation,
    prescription: encounter.prescription,
    report: encounter.report,
    conclusion: encounter.conclusion,
    comments: encounter.comments,
    motives: encounter.motives,
    paymentStatus: encounter.payment_status,
    paymentValidationSecret: encounter.payment_validation_secret,
    authorized: encounter.authorized,
    claims: encounter.claims,
    doc2uData: encounter.doc2u_data ? {
      token: encounter.doc2u_data[0]?.token_key,
      room: encounter.doc2u_data[0]?.room,
    } : {}
  };
}

export function normalizeEncounters(encounters) {
  return encounters.map(normalizeEncounter);
}

export function normalizeAppointments(appointments) {
  return appointments.map(normalizeAppointment);
}

export function normalizeConnectAppointment(appointment) {
  const order = appointment.order ? normalizeConnectOrder(appointment.order) : null;

  return {
    cancelReason: appointment.cancel_reason,
    encounters: appointment.encounters,
    markup: appointment.markup,
    markupPartner: appointment.markup_partner,
    // encounters: appointment.encounters ? normalizeEncounters(appointment.encounters) : null,
    endAt: appointment.end_at,
    hasClaim: appointment.has_claim,
    hasPrescription: appointment.has_prescription,
    hasReport: appointment.has_report,
    id: appointment.id,
    description: appointment.message,
    order,
    // order: appointment.order ? normalizeOrder(appointment.order) : null,
    patient: appointment.patient ? {
      id: appointment.patient.id,
      firstName: appointment.patient.first_name,
      lastName: appointment.patient.last_name,
    } : null,
    practitioner: appointment.practitioner ? {
      id: appointment.practitioner.id,
      firstName: appointment.practitioner.first_name,
      lastName: appointment.practitioner.last_name,
      phone: appointment.practitioner.practice_phone,
    } : null,
    prescriptions: appointment.prescriptions || [],
    product: appointment.product ? {
      channel: appointment.product.channel,
      duration: appointment.product.duration,
      id: appointment.product.id,
      name: appointment.product.name,
      unitPrice: appointment.product.unit_price,
    } : null,
    startAt: appointment.start_at,
    status: (appointment.status === 'CLOSED' && order?.paymentStatus === 'not_paid') ? 'PAYMENT_REQUIRED' : appointment.status,
  };
}

export function normalizeConnectAppointments(appointments) {
  return appointments.map(normalizeConnectAppointment);
}

export function normalizeAppointment(appointment) {
  let failed = true;

  appointment.order?.transactions.forEach(transaction => {
    if (transaction.failed || !transaction.confirmed) {
      return;
    }

    failed = false;
  });

  return {
    id: appointment.id,
    status: failed ? 'waiting_payment' : appointment.status,
    practitioner: appointment.practitioner ? {
      id: appointment.practitioner.id,
      firstName: appointment.practitioner.first_name,
      lastName: appointment.practitioner.last_name,
      status: appointment.practitioner.status,
      phone: appointment.practitioner.phone,
    } : null,
    patient: appointment.patient ? {
      id: appointment.patient.id,
      firstName: appointment.patient.first_name,
      lastName: appointment.patient.last_name,
      status: appointment.patient.status,
      email: appointment.patient.email,
      phone: appointment.patient.phone,
      weight: appointment.patient.weight,
      gender: appointment.patient.gender,
      birthDate: appointment.patient.birth_date,
    } : null,
    channel: appointment.channel,
    sid: appointment.sid,
    product: appointment.product ? {
      channel: appointment.product.channel,
      id: appointment.product.id,
      name: appointment.product.name,
      unitPrice: appointment.product.unit_price,
      duration: appointment.product.duration,
      rank: appointment.product.rank,
      deleted: appointment.product.deleted,
      createdAt: appointment.product.created_at,
      updatedAt: appointment.product.updated_at,
    } : null,
    hasReport: appointment.has_report,
    hasPrescription: appointment.has_prescription,
    hasClaim: appointment.has_claim,
    description: appointment.description,
    startAt: appointment.start_at,
    endAt: appointment.end_at,
    encounters: appointment.encounters ? normalizeEncounters(appointment.encounters) : null,
    order: appointment.order ? normalizeOrder(appointment.order) : null,
    prescriptions: appointment.prescriptions || [],
    createdAt: appointment.created_at,
    updatedAt: appointment.updated_at,
  };
}

export function normalizeConnectTransaction(transaction) {
  return {
    id: transaction.id,
    paymentStatus: transaction.payment_status,
    stripeClientSecret: transaction.payment_validation_secret,
    stripePaymentMethodId: transaction.stripe_payment_method_id,
    order: transaction.order ? normalizeConnectOrder(transaction.order) : null,
    createdAt: new Date(transaction.created_at),
    amount: transaction.amount,
  };
}

export function normalizeConnectTransactions(transactions) {
  return transactions.map(normalizeConnectTransaction);
}

export function normalizeConnectOrder(order) {
  return {
    id: order.id,
    paymentStatus: order.payment_status,
    paid: order.paid,
    refunded: order.refunded,
    total: order.total,
    transactions: order.transactions ? normalizeConnectTransactions(order.transactions) : [],
    appointment: order.appointment ? normalizeConnectAppointment(order.appointment) : null,
  };
}

export function normalizeOrder(order) {
  return {
    id: order.id,
    total: order.total,
    transactions: normalizeTransactions(order.transactions),
  };
}

export function normalizeTransaction(transaction) {
  return {
    id: transaction.id,
    stripeClientSecret: transaction.payment_validation_secret || transaction.stripe_client_secret,
    failed: transaction.failed,
  };
}

export function normalizeTransactions(transactions) {
  return transactions.map(normalizeTransaction);
}

export function normalizePatient(patient) {
  return {
    id: patient.id,
    firstName: patient.first_name,
    lastName: patient.last_name,
    ssn: patient.ssn,
    birthDate: patient.birth_date,
    weight: patient.weight,
    gender: patient.gender,
    email: patient.email,
    phone: patient.phone,
    acceptEmailCommunication: patient.accept_email_communication,
    acceptSmsCommunication: patient.accept_sms_communication,
    timezone: patient.timezone,
    addresses: patient.addresses,
    isRestricted: patient.is_restricted,
    isBeta: patient.is_beta,
    mutualName: patient.mutual_name,
    mutualNumber: patient.mutual_number,
    picture: patient.picture,
    parent: null,
    isOwner: true,
    children: normalizePatientChildren(patient.children || []),
    metadata: patient.metadata || {},
    acceptToBeContacted: patient.acceptToBeContacted,
    pharmacyOrders: patient.pharmacy_orders,
    address: patient.address,
    city: patient.city,
    postalCode: patient.postal_code,
    country: patient.country,
    deletedAt: patient.deleted_at,
    premium: patient.premium,
    tokens: patient.tokens,
    measure: patient.measure,
    discountCode: patient.discount_code,
  };
}

export function normalizePatientChild(patientChild) {
  return {
    id: patientChild.id,
    firstName: patientChild.first_name,
    lastName: patientChild.last_name,
    birthDate: patientChild.birth_date,
    gender: patientChild.gender,
    parent: patientChild.parent,
    weight: patientChild.weight,
    ssn: patientChild.ssn,
    isOwner: false,
  }
}

export function normalizePatientChildren(patientChildren) {
  return patientChildren.map(normalizePatientChild);
}


export function normalizeProduct(product) {
  return {
    id: product.id,
    name: product.name,
    channel: product.channel,
    duration: product.duration,
    unitPrice: product.unit_price,
    rules: product.rules
  };
}

export function normalizeProducts(products) {
  return products.map(normalizeProduct);
}

export function normalizePractitioner(practitioner) {
  return {
    products: practitioner.products ? normalizeProducts(practitioner.products) : undefined,
    slots: practitioner.slots,
    convention: practitioner.convention,
    title: practitioner.title,
    firstName: practitioner.first_name,
    lastName: practitioner.last_name,
    civility: practitioner.civility,
    birthDate: practitioner.birth_date,
    email: practitioner.email,
    phone: practitioner.phone,
    address: practitioner.address,
    zipCode: practitioner.zip_code,
    city: practitioner.city,
    country: practitioner.country,
    rpps: practitioner.rpps,
    adeli: practitioner.adeli,
    description: practitioner.description,
    siren: practitioner.siren,
    iban: practitioner.iban,
    companyName: practitioner.company_name,
    companyAddress: practitioner.company_address,
    companyZipCode: practitioner.company_zip_code,
    companyCity: practitioner.company_city,
    practiceAddress: practitioner.practice_address,
    practiceZipCode: practitioner.practice_zip_code,
    practiceCity: practitioner.practice_city,
    practicePhone: practitioner.practice_phone,
    securedEmail: practitioner.secured_email,
    acceptPayment: practitioner.accept_payment,
    cardPaymentActivated: practitioner.card_payment_activated,
    searchable: practitioner.searchable,
    claimFormEnabled: practitioner.claim_form_enabled,
    autoConfirmAppointment: practitioner.auto_confirm_appointment,
    timezone: practitioner.timezone,
    mainSpecialty: practitioner.main_specialty,
    specialties: practitioner.specialties,
    languages: practitioner.languages,
    uploadedSignature: practitioner.uploaded_signature,
    uploadedLegalDocument: practitioner.uploaded_legal_document,
    id: practitioner.id,
    isRestricted: practitioner.is_restricted,
    isBeta: practitioner.is_beta,
    picture: practitioner.picture,
    instantEncountersEnabled: practitioner.instant_encounters_enabled,
    comeFrom: practitioner.slug_come_from,
  };
}

export function normalizePractitioners(practitioners) {
  return practitioners.map(normalizePractitioner);
}

export function normalizeSetupIntent(setupIntent) {
  return {
    customerId: setupIntent.customer_id,
    clientSecret: setupIntent.client_secret,
  };
}

export function normalizePaymentMethod(paymentMethod) {
  return {
    id: paymentMethod.id,
    brand: paymentMethod.brand,
    lastFourDigit: paymentMethod.last4,
    expirationMonth: paymentMethod.exp_month,
    expirationYear: paymentMethod.exp_year,
  };
}

export function normalizePaymentMethods(paymentMethods) {
  return paymentMethods.map(normalizePaymentMethod);
}

export function normalizeObservation(observation) {
  return {
    id: observation.id,
    patient: observation.patient,
    status: observation.status,
    code: observation.code,
    value: observation.value,
    display_value: observation.display_value,
    encounter: observation.encounter,
    created_at: observation.created_at
  }
}

export function normalizeObservations(observations) {
  return observations.map(normalizeObservation);
}

export function normalizeSlot(slot) {
  return {
    id: slot.id,
    active: slot.active,
    month: slot.month,
    type: slot.type,
    pharmacyOrder: slot.refunded_pharmacy_order
  };
}

export function normalizeSlots(slots) {
   return slots.map(normalizeSlot);
}
