<template>
  <a :href="to" target="_blank">
    <slot />
  </a>
</template>

<script>
  export default {
    name: 'ExternalLink',
    props: {
      to: {
        type: String,
        required: true,
      },
    },
  };
</script>
