<template>
  <AppLayout>
    <AppProgress :current="1" :total="8" :position="'b-0'" />
    <main class="container">
      <SectionTitle>{{ $t('newAppointmentPage.title') }}</SectionTitle>
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <FormInput
            id="practitioner-name"
            v-model="form.practitionerName"
            class="col _3"
            :label="$t('newAppointmentPage.form.practitioner-name.label')"
          >
            <template v-if="$v.form.practitionerName.$error" #error>
              <template v-if="!$v.form.practitionerName.required ">
                {{ $t('newAppointmentPage.form.practitioner-name.required') }}
              </template>
              <template v-else-if="!$v.form.practitionerName.minLength">
                {{ $t('newAppointmentPage.form.practitioner-name.min-length', { min: $v.form.practitionerName.$params.minLength.min }) }}
              </template>
            </template>
          </FormInput>

          <FormSelectSpecialties
            id="specialty"
            v-model="form.specialty"
            :loading="isLoadingSpecialties"
            :specialties="specialties"
            class="col _3"
          >
            <template v-if="$v.form.specialty.$error" #error>
              <template v-if="!$v.form.specialty.required">&nbsp;</template>
            </template>
          </FormSelectSpecialties>

          <FormInput
            id="location"
            v-model="form.location"
            class="col _2"
            :label="$t('newAppointmentPage.form.postal-code.label')"
            maxlength="20"
          >
            <template v-if="$v.form.location.$error" #error>
              <template v-if="!$v.form.location.required">&nbsp;</template>
              <template v-if="!$v.form.location.zipCode ">{{ $t('newAppointmentPage.form.postal-code.format') }}</template>
            </template>
          </FormInput>

          <FormSelect id="channel" v-model="form.channel" class="col _2" :label="$t('newAppointmentPage.form.channel.label')">
            <option value="" />
            <option value="remote">{{ $t('newAppointmentPage.form.channel.remote') }}</option>
            <option value="medical_office">{{ $t('newAppointmentPage.form.channel.medical-office') }}</option>
            <option value="home">{{ $t('newAppointmentPage.form.channel.home') }}</option>
          </FormSelect>

          <div class="col _2">
            <ButtonGreen
              :icon="$icons.search"
              class="submit-button"
              outlined
              rounded
              type="submit"
            />
          </div>
        </div>
        <div v-if="false" class="row">
          <div class="col _12">
            <FormCheckbox v-model="form.remoteOnly">
              {{ $t('newAppointmentPage.form.checkBox') }}
            </FormCheckbox>
          </div>
        </div>
      </form>

      <hr class="separator">

      <p class="airy" v-html="$t('newAppointmentPage.informations.0')" />
      <p v-html="$t('newAppointmentPage.informations.1')" />
      <p class="italic" v-html="$t('newAppointmentPage.informations.2')" />
      <p v-html="$t('newAppointmentPage.informations.3')" />
    </main>
  </AppLayout>
</template>

<script>
  import ButtonGreen from '../components/ButtonGreen';
  import { helpers, minLength, requiredIf } from '@byscripts/vuelidate/lib/validators';
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import AppNotificationItem from '../components/AppNotification/AppNotificationItem';
  import FormCheckbox from '../components/Form/FormCheckbox';
  import AppProgress from '../components/AppProgress';
  import { getLocalStorage, hasLocalStorage, setLocalStorage } from '@/helpers/tools.js';
  import AppLoader from '@/components/AppLoader.vue';
  import FormSelectSpecialties from '@/components/Form/FormSelectSpecialties';
  import FormInput from '@/components/Form/FormInput';
  import FormSelect from '@/components/Form/FormSelect';

  const LOCAL_STORAGE_KEY = 'new-appointment-search';

  export default {
    name: 'NewAppointmentPage',
    components: {
      FormSelect,
      FormInput,
      AppLoader,
      AppProgress,
      FormCheckbox,
      AppNotificationItem,
      AppLayout,
      SectionTitle,
      ButtonGreen,
      FormSelectSpecialties,
    },
    beforeRouteEnter(to, from, next) {
      if ('new-appointment-search' !== from.name || !hasLocalStorage(LOCAL_STORAGE_KEY)) {
        return next();
      }

      next(vm => {
        vm.form = getLocalStorage(LOCAL_STORAGE_KEY);
      });
    },
    async mounted() {
      await this.loadSpecialties();
    },
    data() {
      return {
        form: {
          practitionerName: '',
          specialty: '',
          location: '',
          channel: '',
        },
      };
    },
    validations: {
      form: {
        practitionerName: {
          required: requiredIf((vm) => !vm.specialty && !vm.location),
          minLength: minLength(2),
        },
        specialty: {
          required: requiredIf((vm) => !vm.practitionerName && !vm.location),
        },
        location: {
          required: requiredIf((vm) => !vm.practitionerName && !vm.specialty),
        },
      },
    },
    computed: {
      isLoadingSpecialties() {
        return this.$store.state.specialties.isFetchingAll;
      },
      specialties() {
        return this.$store.state.specialties.all;
      },
    },
    methods: {
      async loadSpecialties() {
        await this.$store.dispatch('specialtiesFetchAll');
      },
      async handleSubmit() {
        this.$v.$touch();

        if (this.$v.$anyError) {
          this.$addError(this.$t('newAppointmentPage.error'), false, 5000);
          return;
        }

        setLocalStorage(LOCAL_STORAGE_KEY, this.form);

        return this.$router.push({
          name: 'new-appointment-search',
          query: {
            q: this.form.practitionerName,
            s: this.form.specialty,
            l: this.form.location,
            c: this.form.channel,
            practiceCountry: this.$store.state.patients.profile.country ?? 'FR',
          },
        });
      },
    },
  };
</script>


<style lang="scss" scoped>
main.container {
  @include onMobile {
    padding: 0 3rem;
    display: block;
  }
}
  .submit-button {
    font-size: 1.5rem;
    display: block;
    width: 100%;
    margin-top: 0.9rem;
    padding-bottom: 1.2rem;
  }

  .separator {
    border: none;
    border-top: 2px dashed $textColor;
    background: none;
  }

  p {
    &.italic {
      font-style: italic;
    }

    &.airy {
      margin-bottom: 4rem;
    }
  }

  a {
    text-decoration: underline;
    color: #27D7AB;

    &:hover {
      text-decoration: none;
    }
  }

  .multiselect::v-deep {
    min-height: 33px;

    .multiselect__placeholder {
      color: #1F1F1F;
      font-size: 1.1em;
      font-weight: normal;
    }

    .multiselect__content {
      font-weight: normal;
    }

    .multiselect__tags {
      border: none;
      height: 33px;
      overflow: hidden;
      min-height: 33px;
      padding: 3px 40px 0 8px;
      background: transparent;
    }

    .multiselect__option--highlight,
    .multiselect__tag {
      background: $successColor;
    }

    .multiselect__select {
      height: 28px;
    }

    .multiselect__option {
      padding: 9px 12px;
      font-size: 1em;
      min-height: 0;
    }

    .multiselect__single {
      font-weight: normal;
    }

    .multiselect__tag-icon:hover {
      background: #F46868;
      border-radius: 0;
    }

    input {
      padding: 0;
    }
  }
</style>
