<template>
  <FontAwesomeIcon
    :icon="icon"
    class="appointment-status-icon"
  />
</template>

<script>
  export default {
    name: 'AppointmentStatusIcon',
    props: {
      status: {
        type: String,
        required: true,
      },
    },
    computed: {
      icon() {
        switch (this.status) {
          case 'NEW_BY_PATIENT':
          case 'NEW_BY_PRACTITIONER':
            return this.$icons.appointmentStatusNew;
          case 'CANCELLED_BY_PATIENT':
          case 'CANCELLED_BY_PRACTITIONER':
            return this.$icons.appointmentStatusCancelled;
          case 'TERMINATED':
            return this.$icons.appointmentStatusReport;
          case 'CLOSED':
            return this.$icons.appointmentStatusClosed;
          case 'PAYMENT_REQUIRED':
            return this.$icons.appointmentStatusWaitingPayment;
          default:
            return this.$icons.appointmentStatusDefault;
        }
      },
    },
  };
</script>

