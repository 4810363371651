var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("portal", { attrs: { to: "app-progress" } }, [
    _c("div", { class: ["app-progress-container", _vm.position] }, [
      _c(
        "div",
        {
          staticClass: "app-progress",
          style: {
            desktop: _vm.$media.desktop,
            "last-step": _vm.current === _vm.total
          }
        },
        [
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.current) + " / " + _vm._s(_vm.total))
          ]),
          _c("div", {
            staticClass: "progress-bar",
            style: { width: _vm.progress + "%" }
          }),
          _c("div", { staticClass: "app-progress-background" })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }