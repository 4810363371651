var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.orderIsObject
      ? _c("div", [
          this.$store.state.pharmacies_order.current.status === "new"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande enregistrée",
                      "can-cancel": true,
                      imageName: "new",
                      status: "new"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "sent"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande validée",
                      "can-cancel": true,
                      imageName: "new",
                      status: "sent"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "preparing"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "En cous de préparation",
                      "can-cancel": true,
                      imageName: "preparing",
                      status: "preparing"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "ready" &&
              this.$store.state.pharmacies_order.current.type ===
                "click_collect"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande prête",
                      "can-cancel": false,
                      imageName: "preparing",
                      status: "ready"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "ready" &&
              this.$store.state.pharmacies_order.current.type === "delivery"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande en cours de récupération",
                      "can-cancel": false,
                      imageName: "preparing",
                      status: "readyForDelivery"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "collected"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande récupérée par le livreur",
                      "can-cancel": true,
                      imageName: "delivering",
                      status: "delivering"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "delivering"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande en cours de livraison",
                      "can-cancel": true,
                      imageName: "delivering",
                      status: "delivering"
                    }
                  })
                ],
                1
              )
            : this.$store.state.pharmacies_order.current.status === "finished"
            ? _c(
                "div",
                { staticClass: "click-collect" },
                [
                  _c("PharmacyOrderCard", {
                    attrs: {
                      order: _vm.order,
                      "status-text": "Commande reçue",
                      "can-cancel": false,
                      imageName: "terminated",
                      status: "finished"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          this.$store.state.pharmacies_order.current.status === "external_order"
            ? _c("div", [
                _c("div", { staticClass: "click-collect" }, [
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.appointment-panel-card.title")
                        )
                      )
                    ])
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "pharmacyOrder.appointment-panel-card.subtitle-before-external"
                        )
                      )
                    )
                  ]),
                  _c("p", [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "pharmacyOrder.appointment-panel-card.subtitle-partner"
                          )
                        )
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "btn_partner" },
                    [
                      _c(
                        "ButtonGreen",
                        {
                          attrs: { small: "" },
                          on: { click: _vm.sendToPartner }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "pharmacyOrder.appointment-panel-card.keep-partner"
                              )
                            )
                          )
                        ]
                      ),
                      _c(
                        "ButtonGreen",
                        {
                          attrs: {
                            "link-to": {
                              name: "pharmacy-selection",
                              params: { appointmentId: this.appointmentId }
                            },
                            small: ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "pharmacyOrder.appointment-panel-card.pick-pharmacy"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ])
      : _c("div", [
          _vm.hasPrescriptionForPharmacy
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "click-collect" },
                  [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pharmacyOrder.appointment-panel-card.title")
                          )
                        )
                      ])
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "pharmacyOrder.appointment-panel-card.subtitle-before"
                          )
                        )
                      )
                    ]),
                    _c(
                      "ButtonGreen",
                      {
                        attrs: {
                          "link-to": {
                            name: "pharmacy-selection",
                            params: { appointmentId: _vm.appointmentId }
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "pharmacyOrder.appointment-panel-card.pick-pharmacy"
                            )
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _c("div", [
                _c("p", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pharmacyOrder.appointment-panel-card.title")
                      )
                    )
                  ])
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "pharmacyOrder.appointment-panel-card.no-prescription"
                      )
                    )
                  )
                ])
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }