var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appointment-panel-actions" }, [
    _vm.isNewByPatient
      ? _c("div", { staticClass: "new-or-cancelled" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("appointmentPanelActions.not-validated")) +
              "\n  "
          )
        ])
      : _vm.isNewByPractitioner
      ? _c(
          "div",
          [
            _c("AppConfirmation", {
              ref: "acceptAppointment",
              attrs: {
                title: _vm.$t("appointmentPanelActions.accept.confirmation")
              },
              on: { confirm: _vm.acceptAppointment }
            }),
            _c(
              "ButtonGreen",
              {
                attrs: { icon: _vm.$icons.accept },
                on: {
                  click: function($event) {
                    return _vm.$confirm(_vm.$refs.acceptAppointment)
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("appointmentPanelActions.accept.label")) +
                    "\n    "
                )
              ]
            )
          ],
          1
        )
      : _vm.canBeStarted
      ? _c(
          "div",
          [
            _c(
              "ButtonGreen",
              {
                staticClass: "start-encounter",
                attrs: {
                  "link-to": {
                    name: "consultation",
                    params: { appointmentId: _vm.appointment.payload.id }
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("appointmentPanelActions.available")) +
                    "\n      "
                ),
                _c(
                  "span",
                  { staticClass: "go" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("appointmentPanelActions.go")) +
                        "\n        "
                    ),
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.$icons.arrowRight }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.canBeCancelled
      ? _c(
          "div",
          { staticClass: "cancel-postpone" },
          [
            _c("AppConfirmation", {
              ref: "cancelAppointment",
              attrs: { title: _vm.cancelAppointmentDialogMessage },
              on: { confirm: _vm.cancelAppointment },
              scopedSlots: _vm._u(
                [
                  {
                    key: "description",
                    fn: function() {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("appointmentPanelActions.cancel.warning")
                            )
                          )
                        ]),
                        _c("AutoHeightTextarea", {
                          staticClass: "cancel-reason",
                          attrs: {
                            placeholder: _vm.$t(
                              "appointmentPanelActions.cancel.reason"
                            ),
                            "max-length": _vm.$options.cancelReasonMaxLength
                          },
                          model: {
                            value: _vm.cancelReason,
                            callback: function($$v) {
                              _vm.cancelReason = $$v
                            },
                            expression: "cancelReason"
                          }
                        }),
                        _c("div", { staticClass: "cancel-reason-tips" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "appointmentPanelActions.cancel.reason-char-count",
                                  {
                                    num: _vm.cancelReason.length,
                                    max: _vm.$options.cancelReasonMaxLength
                                  }
                                )
                              )
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "appointmentPanelActions.cancel.warning-canceled"
                                )
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://hellocare.com/blog/conditions-generales-dutilisation-cgu/",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "icon-question",
                                  attrs: {
                                    src: require("@/assets/images/question.png")
                                  }
                                })
                              ]
                            )
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1210891372
              )
            }),
            _c(
              "span",
              {
                staticClass: "link-with-icon cancel-appointment",
                on: {
                  click: function($event) {
                    return _vm.$confirm(_vm.$refs.cancelAppointment)
                  }
                }
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.cancelAppointmentButtonMessage))
                ]),
                _c("FontAwesomeIcon", {
                  attrs: { icon: _vm.$icons.appointmentStatusCancelled }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }