import { normalizePatient, normalizePatientChild } from '@/helpers/normalizers';
import api from './';

export default {
  me: () => api.get('patients/me').json().then(normalizePatient),
  /**
   * @param {string} id
   * @param {object} data
   * @param {string} data.gender
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.address
   * @param {string} data.postalCode
   * @param {string} data.city
   * @param {string} data.country
   * @param {string} data.ssn
   * @param {string} data.weight
   * @param {string} data.birthDate
   * @param {string} data.timezone
   * @param {object} data.metadata
   * @param {object} data.acceptToBeContacted
   *
   * @returns {Promise<unknown>}
   */
  update: (id = 'me', data) => api.put(`patients/${id}`, {
    json: {
      gender: data.gender,
      first_name: data.firstName,
      last_name: data.lastName,
      ssn: data.ssn,
      birth_date: data.birthDate,
      timezone: data.timezone,
      metadata: data.metadata,
      weight: data.weight,
      address: data.address,
      city: data.city,
      postal_code: data.postalCode,
      mutual_name: data.mutualName,
      mutual_number: data.mutualNumber,
      country: data.country,
      acceptEmailCommunication: data.acceptToBeContacted,
    },
  }).json().then(id === 'me' ? normalizePatient : normalizePatientChild),
  uploadAvatar: (id = 'me', file) => {
    const body = new FormData();
    body.append('file', file);
    return api.post(`patients/${id}/profile_picture`, { body });
  },
  /**
   * @param {string} data.gender
   * @param {string} data.birthDate
   * @param {string} data.lastName
   * @param {string} data.firstName
   * @param {string} data.email
   * @param {string} data.phone
   * @param {boolean} data.acceptEmailCommunication
   * @param {boolean} data.acceptSmsCommunication
   * @param {string} data.timezone
   * @param {string} data.weight
   * @return {Promise<unknown>}
   */
  createChild: (data) => api.post('patients', {
    json: {
      gender: data.gender,
      birthDate: data.birthDate,
      lastName: data.lastName,
      firstName: data.firstName,
      email: data.email,
      phone: data.phone,
      acceptEmailCommunication: data.acceptEmailCommunication,
      acceptSmsCommunication: data.acceptSmsCommunication,
      timezone: data.timezone,
      weight: data.weight,
    },
  }).json().then(normalizePatientChild),

  delete: () => api.delete(`patients/me`),

};
