<template>
  <div class="practitioner-card">
    <div>
      <UserAvatar :src="practitioner.picture" size="9rem" />
    </div>
    <div>
      <p>{{ practitioner.title }} {{ practitioner.firstName }} {{ practitioner.lastName }}</p>
      <p v-if="practitioner.mainSpecialty">{{ practitioner.mainSpecialty.name }}</p>
      <p>{{ practitioner.practiceZipCode }} {{ practitioner.practiceCity }}</p>
      <p>{{ practitioner.practicePhone | phone }}</p>
    </div>
  </div>
</template>

<script>
  import UserAvatar from '@/components/UserAvatar';

  export default {
    name: 'PractitionerCard',
    components: { UserAvatar },
    props: {
      practitioner: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .practitioner-card {
    display: flex;

    div:first-child {
      margin-right: 1rem;
    }

    p {
      margin: 0.3rem 0;

      &:first-child {
        font-weight: 400;
      }
    }
  }
</style>
