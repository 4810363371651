<template>
  <label class="form-radio">
    <slot />
    <input
        type="radio"
        :checked="checked"
        @change="$emit('change', $event.target.checked)"
    >
    <span class="radio">
      <span class="radio-mark" />
    </span>
  </label>
</template>

<script>
export default {
  name: 'FormRadio',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: Boolean,
  },
};
</script>

<style scoped lang="scss">
label {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  font-family: $checkboxFont;
  font-size: 1.6rem;
  color: $checkboxLabelColor;
  font-weight: 400;
  cursor: pointer;
}

input {
  display: none;
}

.radio {
  margin-right: 1rem;
  height: 2rem;
  width: 2rem;
  border: 1px solid $checkboxBorderColor;
  border-radius: 4px;
}

.radio-mark {
  display: block;
  height: 1.8rem;
  width: 1.8rem;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
}

label:hover .radio-mark {
  background-color: $checkboxHoverColor;
}

input:checked ~ .radio .radio-mark {
  background-color: $checkboxCheckedColor;
}
</style>
