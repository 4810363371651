var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "appointment-panel-payment" }, [
    _c("p", [_vm._v(_vm._s(_vm.$t("appointmentPanelPayment.message")))]),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleSubmit($event)
          }
        }
      },
      [
        _c("CreditCards", {
          attrs: { selectable: "" },
          model: {
            value: _vm.selectedCardId,
            callback: function($$v) {
              _vm.selectedCardId = $$v
            },
            expression: "selectedCardId"
          }
        }),
        _c("div", { staticClass: "amount-detail" }, [
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("appointmentPanelPayment.amount.paid")))
          ]),
          _c("div", { staticClass: "separator" }),
          _c("div", { staticClass: "amount" }, [
            _vm._v(_vm._s(_vm._f("money")(_vm.order.paid)))
          ])
        ]),
        _c("div", { staticClass: "amount-detail" }, [
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$t("appointmentPanelPayment.amount.due")))
          ]),
          _c("div", { staticClass: "separator" }),
          _c("div", { staticClass: "amount" }, [
            _vm._v(_vm._s(_vm._f("money")(_vm.order.total - _vm.order.paid)))
          ])
        ]),
        _c("div", { staticClass: "total-amount" }, [
          _c("h5", [
            _vm._v(_vm._s(_vm.$t("appointmentPanelPayment.amount.total")))
          ]),
          _c("p", [_vm._v(_vm._s(_vm._f("money")(_vm.order.total)))])
        ]),
        _c(
          "ButtonGreen",
          {
            attrs: {
              busy: _vm.busy,
              disabled: !_vm.selectedCardId,
              type: "submit"
            }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("appointmentPanelPayment.amount.confirm")) +
                "\n    "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }