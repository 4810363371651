var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center align-items-center flex-column p-5 gradient-background"
      },
      [
        _c("div", { staticClass: "p-5" }, [
          _c("img", {
            attrs: {
              alt: "handshake",
              src: require("../../assets/images/picto_handShake.svg")
            }
          })
        ]),
        _c("p", { staticClass: "text-center font-bold" }, [
          _vm._v(
            "\n     " +
              _vm._s(_vm.$t("pharmacyOrder.entry-page.content-part-one")) +
              "\n    "
          )
        ]),
        _c("p", { staticClass: "text-center font-bold" }, [
          _vm._v(_vm._s(_vm.$t("pharmacyOrder.entry-page.content-part-two")))
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.$t("pharmacyOrder.entry-page.content-part-three")))
        ]),
        _c("p", { staticClass: "text-center font-bold" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("pharmacyOrder.entry-page.content-part-four")) +
              "\n    "
          )
        ]),
        _c(
          "p",
          [
            _c(
              "ButtonGreen",
              {
                staticClass: "font-bold",
                attrs: {
                  "link-to": {
                    name: "pharmacy-selection",
                    params: { appointmentId: _vm.appointment() }
                  }
                }
              },
              [
                _vm._v(
                  "\n     " +
                    _vm._s(_vm.$t("pharmacyOrder.entry-page.btn-to-main")) +
                    "\n   "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "ButtonTransparent",
          {
            staticClass: "text-small text-secondary text-black-50",
            attrs: { "link-to": { name: "home" } }
          },
          [_vm._v(_vm._s(_vm.$t("pharmacyOrder.entry-page.btn-to-home")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }