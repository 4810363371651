var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-layout",
      class: [
        _vm.isCalling ? "has-navigation--show" : "",
        {
          "has-navigation": _vm.navigation,
          "has-footer": _vm.footer,
          fullscreen: _vm.fullscreen
        }
      ]
    },
    [
      _vm.navigation
        ? [
            _vm.$media.desktop
              ? _c("AppNavigation", { key: "desktop", attrs: { desktop: "" } })
              : _c("AppNavigation", { key: "mobile" })
          ]
        : _vm._e(),
      _vm.header
        ? _c("AppHeader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$media.mobile && _vm.hidden === false,
                expression: "!$media.mobile && hidden === false"
              }
            ],
            attrs: { withNavigation: _vm.navigation }
          })
        : _vm._e(),
      _vm.globalWarningMessage
        ? _c(
            "div",
            { staticClass: "global-warning-message" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "icon",
                attrs: { icon: _vm.$icons.inputError }
              }),
              _vm._v("\n    " + _vm._s(_vm.globalWarningMessage) + "\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm.isBrowserUnsupported
        ? _c(
            "div",
            { staticClass: "global-warning-message" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "icon",
                attrs: { icon: _vm.$icons.inputError }
              }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("appLayout.unsupported-browser")) +
                  "\n    "
              ),
              _vm._m(0)
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { "fullscreen-content": _vm.fullscreen }
        },
        [_vm._t("default")],
        2
      ),
      _vm.nearest ? _c("NearestAppointment") : _vm._e(),
      _vm.footer
        ? _c(
            "footer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$media.mobile && _vm.hidden === false,
                  expression: "!$media.mobile && hidden === false"
                }
              ],
              staticClass: "main-footer"
            },
            [
              _c(
                "router-link",
                { staticClass: "logo", attrs: { to: { name: "home" } } },
                [
                  _c("AppLogo", {
                    attrs: { text: _vm.$media.desktop, beta: _vm.beta }
                  })
                ],
                1
              ),
              _c("ul", { staticClass: "links" }, [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://hellocare.fr/mentions-legales/",
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("appLayout.legal-notice.label")))
                      ])
                    ]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://hellocare.com/confidentialite",
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("appLayout.privacy-policy.label")))
                      ])
                    ]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://hellocare.com/blog/conditions-generales-dutilisation-cgu/",
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("appLayout.terms-of-use.label")))
                      ])
                    ]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf",
                        target: "_blank"
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("appLayout.tarification.label")))
                      ])
                    ]
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "white-space": "nowrap" } }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.mozilla.org/fr/firefox/new/"
          }
        },
        [_vm._v("Mozilla Firefox")]
      ),
      _vm._v(",\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.google.com/intl/fr_fr/chrome/"
          }
        },
        [_vm._v("Google Chrome")]
      ),
      _vm._v(" ou\n      "),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.microsoft.com/fr-fr/edge"
          }
        },
        [_vm._v("Microsoft Edge")]
      ),
      _vm._v(".\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }