import {normalizeSlots} from '@/helpers/normalizers';
import api from './';

export default {
    getSlot: (id) => api.get(`premium/slot/patient/${id}`).json().then(normalizeSlots),
    /**
     * @param {object}  data
     * @param {string}  data.patient         Patient id
     * @param {string}  data.pharmacyOrder     Pharmacy order
     * @param {string}  data.sum         Slot used
     * @param {string}  data.type         Type markup
     * @returns {Promise}
     */
    create: (data) => {
        return api.post('premium/slot', {
            json: {
                patient: data.patient,
                type: data.type,
                sum: data.sum,
                pharmacyOrder: data.pharmacyOrder
            },
        }).json();
    },
    subscribe: () => api.post('premium/stripe/subscribe', {}).json(),
    cancel: () => api.post('premium/stripe/cancel', {}).json(),
};
