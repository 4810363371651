var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "beneficiary-list" },
    [
      _c(
        "div",
        { staticClass: "beneficiaries" },
        _vm._l(_vm.beneficiaries, function(beneficiary) {
          return _c(
            "div",
            {
              staticClass: "beneficiary",
              class: {
                selected: _vm.selected && _vm.selected.id === beneficiary.id
              },
              on: {
                click: function($event) {
                  return _vm.$emit("change", beneficiary)
                }
              }
            },
            [
              _c("UserAvatar", {
                attrs: { src: beneficiary.picture, size: "5.5rem" }
              }),
              _c("div", [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm._f("fullName")(beneficiary)))
                ])
              ])
            ],
            1
          )
        }),
        0
      ),
      _c(
        "a",
        {
          staticClass: "add-beneficiary",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.isPanelOpened = true
            }
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("beneficiaryList.add-beneficiary")) +
              "\n  "
          )
        ]
      ),
      _vm.cancelable
        ? _c("div", { staticClass: "cancel" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("cancel")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("beneficiaryList.cancel")))]
            )
          ])
        : _vm._e(),
      _c(
        "AppPanel",
        {
          attrs: {
            opened: _vm.isPanelOpened,
            title: _vm.$t("beneficiaryList.add-beneficiary")
          },
          on: {
            close: function($event) {
              _vm.isPanelOpened = false
            }
          }
        },
        [
          _c("ProfileEditForm", {
            attrs: { "hide-avatar": "", beneficiary: true },
            on: { create: _vm.handleCreate }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }