import Vue from 'vue';
import { uniqueId } from 'lodash-es';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

export default {
  state: {
    notifications: {},
  },
  mutations: {
    APP_NOTIFICATION_ADD(state, { id, type, message, duration, afterRedirect, asHTML, icon }) {
      Vue.set(state.notifications, id, { id, type, message, duration, afterRedirect, asHTML, icon });
    },
    APP_NOTIFICATION_REMOVE(state, { id }) {
      Vue.delete(state.notifications, id);
    },
  },
  actions: {
    appNotificationAdd({ commit }, { type, message, duration = 7000, afterRedirect, asHTML, icon }) {
      const id = uniqueId('notification-');
      commit('APP_NOTIFICATION_ADD', { id, type, message, duration, afterRedirect, asHTML, icon });
      return id;
    },
    appNotificationAddError({ dispatch }, { message, duration, afterRedirect, asHTML }) {
      return dispatch('appNotificationAdd', {
        type: 'error',
        message,
        duration,
        afterRedirect,
        asHTML,
        icon: faTimesCircle,
      });
    },
    appNotificationAddSuccess({ dispatch }, { message, duration, afterRedirect, asHTML }) {
      return dispatch('appNotificationAdd', {
        type: 'success',
        message,
        duration,
        afterRedirect,
        asHTML,
        icon: faCheckCircle,
      });
    },
    appNotificationRemove({ commit }, { id }) {
      commit('APP_NOTIFICATION_REMOVE', { id });
    },
    appNotificationRedirected({ commit, state }) {
      for (let [id, notification] of Object.entries(state.notifications)) {
        if (notification.afterRedirect) {
          commit('APP_NOTIFICATION_ADD', { ...notification, afterRedirect: false });
        } else {
          commit('APP_NOTIFICATION_REMOVE', { id });
        }
      }
    },
  },
  getters: {
    displayableNotifications(state) {
      return Object.values(state.notifications).filter(notification => !notification.afterRedirect);
    },
  },
};
