var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.pages, function(page, index) {
      return _c("div", [
        _c(
          "div",
          { staticClass: "breadcrumb__link", class: { current: page.current } },
          [
            _c("div", { staticClass: "number" }, [
              _c("span", [_vm._v(_vm._s(index + 1))])
            ]),
            _c("router-link", { attrs: { to: { name: page.url } } }, [
              _vm._v(_vm._s(page.name))
            ]),
            index !== _vm.pages.length - 1
              ? _c("div", { staticClass: "breadcrumb__link__split" }, [
                  _vm._v("-")
                ])
              : _vm._e()
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }