<!--
Emitted events:
- change(selectedMonth: Moment)
-->

<template>
  <div class="month-selector">
    <div v-if="canGoToPreviousMonth" @click.prevent="goToPreviousMonth" class="change-month enabled">
      <FontAwesomeIcon :icon="$icons.chevronLeft"/>
    </div>
    <div v-else class="change-month">
      <FontAwesomeIcon :icon="$icons.chevronLeft"/>
    </div>
    <div class="title">{{ monthNameAndYear }}</div>
    <div @click.prevent="goToNextMonth()" class="change-month enabled">
      <FontAwesomeIcon :icon="$icons.chevronRight"/>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'MonthSelector',
    data() {
      return {
        selectedMonth: moment(),
      };
    },
    computed: {
      canGoToPreviousMonth() {
        return this.selectedMonth.isAfter(undefined, 'month');
      },
      monthNameAndYear() {
        return this.selectedMonth.format('MMMM YYYY');
      },
    },
    methods: {
      goToPreviousMonth() {
        if (!this.canGoToPreviousMonth) {
          return;
        }

        this.selectedMonth = this.selectedMonth.clone().subtract(1, 'month');
      },
      goToNextMonth() {
        this.selectedMonth = this.selectedMonth.clone().add(1, 'month');
      },
    },
    watch: {
      selectedMonth(selectedMonth) {
        this.$emit('change', selectedMonth);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .month-selector {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    text-transform: capitalize;
  }

  .change-month {
    padding: 1rem 2rem;
    cursor: not-allowed;
    color: lightgrey;
    user-select: none;

    &.enabled {
      cursor: pointer;
      color: black;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  .title {
    padding: 1rem;
  }
</style>
