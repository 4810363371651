var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingAppointment
    ? _c("AppLoader", [
        _vm._v(" " + _vm._s(_vm.$t("appointmentAnswerPage.loader")) + " ")
      ])
    : _vm.appointment
    ? _c(
        "FullscreenNotification",
        {
          attrs: { title: _vm.title },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function() {
                return [
                  !_vm.isConfirmed && _vm.isEditable
                    ? _c(
                        "ButtonGreen",
                        {
                          attrs: {
                            disabled: _vm.isDisabled,
                            busy: _vm.isAccepting
                          },
                          on: { click: _vm.acceptAppointment }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("appointmentAnswerPage.confirm")) +
                              "\n    "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.isEditable
                    ? _c(
                        "ButtonWhite",
                        {
                          attrs: {
                            outlined: "",
                            disabled: _vm.isDisabled,
                            busy: _vm.isCancelling
                          },
                          on: { click: _vm.cancelAppointment }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("appointmentAnswerPage.refuse")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "links",
              fn: function() {
                return [
                  _c("router-link", { attrs: { to: { name: "home" } } }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("appointmentAnswerPage.return")) +
                          " "
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("p", [_vm._v(_vm._s(_vm.description))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }