var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentStep
    ? _c(
        "div",
        { staticClass: "app-guide" },
        [
          _c("div", { staticClass: "sub-mask" }),
          _c("div", {
            staticClass: "mask",
            style: _vm.isMaskVisible ? _vm.maskStyle : {}
          }),
          _c("AppGuideStep", {
            key: _vm.currentStep.id,
            ref: "popper",
            attrs: {
              actions: _vm.currentStep.actions,
              content: _vm.currentStep.content,
              title: _vm.currentStep.title
            },
            on: { action: _vm.refreshPopper }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }