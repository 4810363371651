<!--
Emitted events:
- end
-->

<template>
  <svg v-if="angle > 0" class="app-pie-timer" viewBox="0 0 250 250">
    <path ref="loader" :d="anim" transform="translate(125, 125)" />
  </svg>
</template>

<script>
  export default {
    name: 'AppPieTimer',
    props: {
      duration: {
        type: Number,
        required: false,
        default: 7000,
      },
      paused: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        intervalId: null,
        angle: 360,
      };
    },
    watch: {
      paused: {
        handler(paused) {
          paused ? this.pause() : this.start();
        },
        immediate: true,
      },
    },
    computed: {
      step() {
        return 360 / this.duration * 30;
      },
      anim() {
        const r = this.angle * Math.PI / 180;

        return `M 0 0 v -125 A 125 125 1 ${(this.angle > 180) ? 1 : 0} 1 ${Math.sin(r) * 125} ${Math.cos(r) * -125} z`;
      },
    },
    methods: {
      start() {
        this.intervalId = this.$setInterval(() => {
          this.angle -= this.step;

          if (this.angle <= 0) {
            window.clearInterval(this.intervalId);
            this.$emit('ended');
          }
        }, 30, true);
      },
      pause() {
        window.clearInterval(this.intervalId);
      },
    },
  };
</script>

<style scoped>
  .app-pie-timer {
    opacity: 0.2;
  }
</style>
