export default {
  state: {
    isExpanded: false,
  },
  mutations: {
    APP_NAVIGATION_SET_EXPANDED(state, { expanded }) {
      state.isExpanded = expanded;
    },
  },
  actions: {
    appNavigationExpand({ commit }) {
      commit('APP_NAVIGATION_SET_EXPANDED', { expanded: true });
    },
    appNavigationShrink({ commit }) {
      commit('APP_NAVIGATION_SET_EXPANDED', { expanded: false });
    },
    appNavigationToggle({ commit, state }) {
      commit('APP_NAVIGATION_SET_EXPANDED', { expanded: !state.isExpanded });
    },
  }
};
