import accessTokens from './access_tokens';
import appNavigation from './app_navigation';
import appointments from './appointments';
import chat from './chat';
import encounters from './encounters';
import appLoader from './app_loader';
import appNotification from './app_notification';
import newAppointment from './new_appointment';
import patients from './patients';
import paymentMethods from './payment_methods';
import jwt from './jwt';
import practitioners from './practitioners';
import sharedFiles from './shared_files';
import specialties from './specialties';
import pharmacies from "./pharmacies";
import pharmacies_order from "./pharmacies_order";
import doc2u from "./doc2u";
import observations from "./observations";
import premium from "./premium";

export default {
  accessTokens,
  appNotification,
  appLoader,
  appNavigation,
  appointments,
  chat,
  encounters,
  newAppointment,
  patients,
  paymentMethods,
  jwt,
  practitioners,
  sharedFiles,
  specialties,
  pharmacies,
  pharmacies_order,
  doc2u,
  observations,
  premium
};
