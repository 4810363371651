var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "redirect-card" },
    [
      _c("div", [
        _c("img", { attrs: { src: require("@/assets/images/" + _vm.imgCard) } })
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c("p", [_vm._v(" " + _vm._s(_vm.description) + " ")]),
      _c(
        "ButtonGreen",
        {
          staticClass: "button",
          attrs: { rounded: "" },
          on: { click: _vm.toLink }
        },
        [_vm._v(" " + _vm._s(_vm.button) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }