import Vue from 'vue';
import {
  buildDelayedFunction,
  getLocalStorage,
  hasLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  isDevelopment,
} from '../helpers/tools';

Vue.mixin({
  computed: {
    $isDev() {
      return isDevelopment;
    },
  },
  methods: {
    $confirm(appConfirmation, payload) {
      appConfirmation.open(payload);
    },
    $setTimeout: buildDelayedFunction(window.setTimeout, window.clearTimeout),
    $setInterval: buildDelayedFunction(window.setInterval, window.clearInterval),
    $hasLocalStorage: hasLocalStorage,
    $getLocalStorage: getLocalStorage,
    $setLocalStorage: setLocalStorage,
    $removeLocalStorage: removeLocalStorage,
    /**
     * @param   {string}  message        The message to be displayed
     * @param   {boolean} afterRedirect  Whether the message should wait redirection to be displayed
     * @param   {number}  duration       The notification duration in milliseconds.
     * @param   {boolean} asHTML         If true, message will be rendered as HTML
     * @returns {Promise<any>}
     */
    $addError(message, afterRedirect = false, duration = 14000, asHTML = false) {
      return this.$store.dispatch('appNotificationAddError', {
        message,
        afterRedirect,
        duration,
        asHTML
      });
    },
    /**
     * @param   {string}  message        The message to be displayed
     * @param   {boolean} afterRedirect  Whether the message should wait redirection to be displayed
     * @param   {number}  duration       The notification duration in milliseconds.
     * @param   {boolean} asHTML         If true, message will be rendered as HTML
     * @returns {Promise<any>}
     */
    $addSuccess(message, afterRedirect = false, duration = 7000, asHTML = false) {
      return this.$store.dispatch('appNotificationAddSuccess', {
        message,
        afterRedirect,
        duration,
        asHTML
      });
    },
    $inputToWeight(input) {
      return parseFloat(input.replace(/[^0-9,.]/g, '').replace(',', '.')) || '';
    },
  },
});
