var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    [
      _c(
        "main",
        [
          _c("ProfileTabBar"),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "header",
                { staticClass: "header" },
                [
                  _c("GroupTitle", { attrs: { icon: _vm.$icons.creditCard } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("profileBeneficiariesPage.manage")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "ButtonGreen",
                    {
                      attrs: { small: "", icon: _vm.$icons.beneficiaryAdd },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.isPanelOpened = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profileBeneficiariesPage.add")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("AppConfirmation", {
                ref: "confirmBeneficiaryDeletion",
                attrs: {
                  title: _vm.$t("profileBeneficiariesPage.remove-confirmation")
                },
                on: { confirm: _vm.handleDelete }
              }),
              _c("div", { staticClass: "list-beneficiary" }, [
                _c(
                  "table",
                  { staticClass: "table table-hover beneficiaries" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("profileBeneficiariesPage.identity"))
                          )
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("profileBeneficiariesPage.birth-date")
                            )
                          )
                        ]),
                        _c("th")
                      ])
                    ]),
                    _vm.profile.children.length > 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.profile.children, function(beneficiary) {
                            return _c("tr", { staticClass: "beneficiary" }, [
                              _c(
                                "td",
                                { staticClass: "full-name" },
                                [
                                  _c("UserAvatar", {
                                    attrs: { size: "1.6rem" }
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(beneficiary.firstName) +
                                      " " +
                                      _vm._s(beneficiary.lastName) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("date")(
                                        beneficiary.birthDate,
                                        "DD/MM/YYYY"
                                      )
                                    ) +
                                    "\n              - " +
                                    _vm._s(
                                      _vm.$t("profileBeneficiariesPage.age", {
                                        age: _vm.toAge(beneficiary.birthDate)
                                      })
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "OptionsMenu",
                                    [
                                      _c(
                                        "OptionsMenuItem",
                                        {
                                          attrs: { icon: _vm.$icons.edit },
                                          on: {
                                            click: function($event) {
                                              _vm.editedBeneficiary = beneficiary
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "profileBeneficiariesPage.edit"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "no-result",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "profileBeneficiariesPage.no-results"
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "AppPanel",
        {
          attrs: {
            opened: _vm.isPanelOpened,
            title: _vm.editedBeneficiary
              ? _vm.$t("profileBeneficiariesPage.edit-long")
              : _vm.$t("profileBeneficiariesPage.add")
          },
          on: {
            close: function($event) {
              _vm.isPanelOpened = false
              _vm.editedBeneficiary = null
            }
          }
        },
        [
          _c("ProfileEditForm", {
            attrs: {
              beneficiary: true,
              user: _vm.editedBeneficiary,
              "hide-avatar": ""
            },
            on: {
              create: function($event) {
                _vm.isPanelOpened = false
              },
              update: function($event) {
                _vm.editedBeneficiary = null
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }