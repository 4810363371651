<template>
  <div :class="{ opened: areOptionsVisible }" class="options-menu">
    <div @click="areOptionsVisible = !areOptionsVisible" class="button">
      Options&nbsp;<FontAwesomeIcon :icon="$icons.verticalDots" fixed-width />

      <div v-if="areOptionsVisible" class="dropdown">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OptionsMenu',
    data() {
      return {
        areOptionsVisible: false,
      };
    },
    mounted() {
      document.addEventListener('click', this.closeDropdown);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.closeDropdown);
    },
    methods: {
      closeDropdown(event) {
        if (!this.$el.contains(event.target)) {
          this.areOptionsVisible = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .options-menu {
    position: relative;
    min-width: 7.5rem;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &.opened {
      color: $successColor;
    }
  }

  .button {
    position: relative;
    text-align: right;
  }

  .dropdown {
    position: absolute;
    z-index: 1;
    top: 2.1rem;
    right: 0rem;
    border: 1px solid $lightGrey;
    border-radius: 0.5rem;
    background-color: white;
  }
</style>
