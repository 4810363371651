<template>
<div class="container-card" :class="{'card-slide': cardSlide}">
  <slot/>
</div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    cardSlide: {
      Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  @include onMobile {
    place-content: space-between
  }
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.selected {
  border-color: #27D7AB;
  background-color: #DFF9F3;
}

.container-card {
  @include onMobile {
    width: 150px;
    height: 100px;
  }
  @media (max-width: 379px) {
    width: 140px;
  }
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  border-radius: 9px;
  width: 237px;
  border: 1px solid #28DBB6;
  height: 130px;
  justify-content: center;
  align-content: center;
}

.card-slide {
  @include onMobile {
    width: 237px;
    height: 130px;
  }
}
</style>
