<template>
  <div class="app-loader">
    <div class="app-loader-content">
      <AppLogoAnimated white />
      <h1 class="app-loader-text">
        <slot />
      </h1>
    </div>
  </div>
</template>

<script>
  import AppLogoAnimated from './AppLogoAnimated';

  export default {
    name: 'AppLoader',
    components: { AppLogoAnimated },
  };
</script>

<style lang="scss" scoped>
  .app-loader {
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $gradientBackground;
    z-index: 999999;
  }

  .app-loader-content {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .app-loader-text {
    font-family: $titleFont;
    color: #FFFFFF;
    font-size: 2.2rem;
    margin-top: 0.2em;
  }

  .app-logo-animated {
    max-width: 200px;
  }
</style>
