var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nearestAppointmentId
    ? _c(
        "div",
        { staticClass: "nearest-appointment" },
        [
          _c("span", [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("nearestAppointment.title")) + "\n  "
            )
          ]),
          _c(
            "router-link",
            {
              staticClass: "go-to",
              attrs: { to: "/consultation/" + _vm.nearestAppointmentId }
            },
            [_vm._v(_vm._s(_vm.$t("nearestAppointment.go-to")))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }