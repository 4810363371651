<template>
  <div :id="this.pharmacy.siret+'-'+this.pharmacy.no_finess_et" :class="pharmacy.provider ? ' card-pharmacy-container  card-pharmacy-container__provider ' : 'card-pharmacy-container' ">

    <!-- modal -->
    <AppConfirmation
        ref="selectPharmacy"
        :title="$t('pharmacyOrder.main-page.card.pharmacy-choice')"
        :icon="false"
    >
      <template v-slot:title="scoped">
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='livmed'" class="provider_logo" src="../../assets/images/logo_livmed.png"  alt="logo livmed"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='pharmao'" class="provider_logo" src="../../assets/images/logo_pharmao.png"  alt="logo pharmao"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='phacil'" class="provider_logo" src="../../assets/images/logo_phacil.png"  alt="logo phacil"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='mmcm'" class="provider_logo" src="../../assets/images/logo_mmcm.png"  alt="logo mmcm"/>
        <div v-if="pharmacy.provider&&pharmacy.provider.name==='phacil'"> MMCM </div>
        <img v-if="!pharmacy.provider" class="provider_logo" src="../../assets/images/logo_box.png"  alt="logo box"/>
      </template>
        <template v-slot:description="scoped">
          <div v-if="pharmacy.provider">
            <p>
              {{$t('pharmacyOrder.main-page.card.pharmacy-choice-p1')}} <span>{{ pharmacy.rs }}</span> {{$t('pharmacyOrder.main-page.card.pharmacy-choice-p2')}}
              <span v-if="pharmacy.provider.has_click_collect&&!pharmacy.provider.has_delivery">{{$t('pharmacyOrder.click_collect')}}</span>
              <span v-else-if="pharmacy.provider.has_delivery&&!pharmacy.provider.has_click_collect">{{$t('pharmacyOrder.delivery')}}</span>
              <span v-else-if="pharmacy.provider.has_click_collect&&pharmacy.provider.has_delivery">{{$t('pharmacyOrder.click_collect_delivery')}}</span>
              .
            </p>
            <p v-if="!pharmacy.provider.allow_order">
              <b>
                {{$t('pharmacyOrder.main-page.card.partner')}}
              </b>
            </p>
          </div>
          <div v-else>
            <p v-html="$t('pharmacyOrder.main-page.card.no-click-collect-delivery')">
            </p>
          </div>
        </template>
        <div v-if="pharmacy.provider" class="btn-modal-card">
          <div class="d-flex flex-wrap"  v-if="!pharmacy.provider.allow_order">
            <ConfirmButton color="red" :icon="$icons.no">{{$t('pharmacyOrder.main-page.card.btn-cancel')}}</ConfirmButton>
            <ConfirmButton class="btn_partner" @click="createExternal">{{$t('pharmacyOrder.main-page.card.btn-order-partner')}}</ConfirmButton>
          </div>
          <div v-else>
              <ConfirmButton color="red" :icon="$icons.no">{{$t('pharmacyOrder.main-page.card.btn-cancel')}}</ConfirmButton>
              <ConfirmButton @click="updatePharmacyOrder" v-if="pharmacyOrder">{{$t('pharmacyOrder.main-page.card.btn-order-confirm')}}</ConfirmButton>
              <ConfirmButton @click="createPharmacyOrder" v-else>{{$t('pharmacyOrder.main-page.card.btn-order-confirm')}}</ConfirmButton>
          </div>
        </div>
        <div v-else>
          <ConfirmButton color="red" :icon="$icons.no" @click="createNoProvider">{{$t('pharmacyOrder.main-page.card.btn-cancel')}}</ConfirmButton>
        </div>
    </AppConfirmation>
  <!-- -->

  <div class="cardpharmacy mt-4">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex flex-column cardpharmacy__content">
        <div class="text-bold card__title py-2 font-bold" @click="handleSelect">{{ pharmacy.rs }}</div>
        <div class="card__content py-2">
          <div>{{ pharmacy.num_voie }} <span>{{ pharmacy.typ_voie}} {{ pharmacy.voie}}</span></div>
        </div>
          <div class="card__content py-2">
            <div>{{ pharmacy.lib_departement }}</div>
          </div>
        <div class="card__bottom pt-2">
          <a :href="'tel:+'+pharmacy.telephone" class="card__telephone">{{ pharmacy.telephone }}</a>
        </div>
        <div class="card__provider pt-2" v-if="pharmacy.provider">
          <span>{{$t('partenaire')}}</span> <FontAwesomeIcon :icon="$icons.badgeCheck"/>
        </div>
        <p class="font-bold">{{pharmacy.distance}} m</p>
<!--        <div class="premium" v-if="premium">
          <p class="mx-2">{{$t('pharmacyOrder.main-page.card.premium')}}</p>
        </div>-->
        <div v-if="getSchedule()" class="card__schedule">
          <div @click="toggleSchedule" class="d-flex align-items-center toggle-title">
            <FontAwesomeIcon :icon="isOpen ? $icons.panelToggleOpened : $icons.panelToggleClosed" />
            <p class="mx-2">{{$t('pharmacyOrder.main-page.card.see-timetables')}}</p>
          </div>
          <div class="toggle__schedule" ref="toggle__schedule">
            <div v-for="(value, key, index) in getSchedule()" :key="`${ key }-${ index }`">
              <div>{{ key }} : {{ value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpharmacy__logo_small_partner">
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='livmed'" class="livmed_small" src="../../assets/images/livmed_small_logo.png"  alt="logo livmed"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='pharmao'" class="pharmao_small" src="../../assets/images/pharmao_small_logo.png"  alt="logo pharmao"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='phacil'" class="phacil_small" src="../../assets/images/phacil_small_logo.png"  alt="logo phacil"/>
        <img v-if="pharmacy.provider&&pharmacy.provider.name==='mmcm'" class="mmcm_small" src="../../assets/images/mmcm_small_logo.png"  alt="logo mmcm"/>
      </div>
      <div class="cardpharmacy__next_btn">
        <FontAwesomeIcon class="card__arrow"  @click="handleSelect" :icon="$icons.arrowCircleRightSolid"/>
      </div>
    </div>
    <hr>
  </div>
  </div>
</template>

<script>
import AppConfirmation from "../AppConfirmation";
import ConfirmButton from "../AppConfirmation/ConfirmButton";

export default {
  name: 'PharmacyCard',
  components: {AppConfirmation, ConfirmButton},
  props: {
    pharmacy: {},
    profileId: null,
    appointmentId: null,
    premium: null
  },
  data (){
    return {
      pharmacyOrder: null,
      selectedPharmacy: '',
      showModal: false,
      isOpen : false
    }
  },
  mounted() {
    this.setPharmacyOrder();
  },
  methods: {
    handleSelect() {
      this.$confirm(this.$refs.selectPharmacy);
    },
    toggleSchedule() {
      this.$refs.toggle__schedule.classList.toggle('open');
      this.isOpen = !this.isOpen
    },
    getSchedule() {
      if(this.pharmacy.schedule) {
        let data = this.pharmacy.schedule;
        const sorter = {
          "lundi": 1,
          "mardi": 2,
          "mercredi": 3,
          "jeudi": 4,
          "vendredi": 5,
          "samedi": 6,
          "dimanche": 7
        }
        let tmp = [];
        Object.keys(data).forEach(function(key) {
          let value = data[key];
          let index = sorter[key.toLowerCase()];
          tmp[index] = {
            key: key,
            value: value
          };
        });
        let orderedData = {};
        tmp.forEach(function(obj) {
          orderedData[obj.key] = obj.value;
        });
        return orderedData;
      }
    },
    setPharmacyOrder() {
      this.pharmacyOrder = JSON.parse(localStorage.getItem('currentPharmacyOrder'));
    },
    async createNoProvider() {
      await this.$store.dispatch('pharmaciesOrderCreate', {
        noFinessEt: this.pharmacy.no_finess_et,
        profileId: this.profileId,
        appointmentId: this.appointmentId,
        siret: this.pharmacy.siret,
      })
    },
    async createExternal() {

      if(this.pharmacy
          && this.pharmacy.provider.name
      ) {
        await this.$store.dispatch('pharmaciesOrderCreate', {
          idFromProvider: this.pharmacy.pharmacy_id_from_provider ?? null,
          providerName: this.pharmacy.provider.name,
          noFinessEt: this.pharmacy.no_finess_et,
          profileId: this.profileId,
          appointmentId: this.appointmentId,
          siret: this.pharmacy.siret,
        });

        const pharmacyOrder = this.$store.state.pharmacies_order.current;
        // clean storage
        localStorage.removeItem('currentOrderAppointment');
        // send to partner
        window.location = this.pharmacy.provider.external_order_url
            + '?pharma='
            + pharmacyOrder.pharmacy.pharmacy_provider_id;
      }
    },

    async createPharmacyOrder() {

      let statusCode = 201;
      await this.$store.dispatch('pharmaciesOrderCreate', {
        idFromProvider: this.pharmacy.pharmacy_id_from_provider ?? null,
        noFinessEt: this.pharmacy.no_finess_et,
        profileId: this.profileId,
        appointmentId: this.appointmentId,
        providerName: this.pharmacy.provider.name,
        siret: this.pharmacy.siret,
      }).catch((error)=>{
        statusCode = error.response.status
        this.$addError("Erreur lors de la création d'une commande.");
        throw error;
      });
      // store pharmacy order
      localStorage.setItem('currentPharmacyOrder', JSON.stringify(this.$store.state.pharmacies_order.current));

     if (statusCode < 400) {
       await this.$addSuccess('La pharmacie \'' + this.pharmacy.rs + '\' est bien enregistrée.');

       if(this.pharmacy.provider.steps.step_type) {
         return this.$router.push({name: 'pharmacy-order-type'});
       } else if (this.pharmacy.provider.steps.step_address) {
         return this.$router.push({name: 'pharmacy-order-address'});
       }
      }
    },
    async updatePharmacyOrder() {

      let statusCode = 200;
      await this.$store.dispatch('pharmaciesOrderUpdate', {
        idFromProvider: this.pharmacy.pharmacy_id_from_provider ?? null,
        noFinessEt: this.pharmacy.no_finess_et,
        pharmacyOrderId: this.pharmacyOrder.id,
        complete: false,
        providerName: this.pharmacy.provider.name
      }).catch((error)=>{
        statusCode = error.response.status
        this.$addError("Erreur lors de la mise à jour d'une commande.");
        throw error;
      });

      // store pharmacy order
      localStorage.setItem('currentPharmacyOrder', JSON.stringify(this.$store.state.pharmacies_order.current));
      if (statusCode < 400) {
        await this.$addSuccess('La pharmacie \'' + this.pharmacy.rs + '\' est bien enregistrée.');

        if (this.pharmacy.provider.steps.step_type) {
          return this.$router.push({name: 'pharmacy-order-type'});
        } else if (this.pharmacy.provider.steps.step_address) {
          return this.$router.push({name: 'pharmacy-order-address'});
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
.card-pharmacy-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #424242FF;
  margin: auto;
  padding: 10px;
}
.card-pharmacy-container__provider{
  color: black;
}
.card_pharmacy_active{
  background: #27D7AB;
  &.card-pharmacy-container__provider {
    background: #166387;
  }
  color: white;
  transition: all 1s ease-in-out;
  .cardpharmacy{
    .premium{
      color: white;
      transition: all 1s ease-in-out;
    }
    .card__telephone{
      color: white;
      transition: all 1s ease-in-out;
    }
    .card__provider {
      p {
        color: white;
        transition: all 1s ease-in-out;
      }
      svg{
        color: white;
      }
    }
    .card__title {
      &:hover {
        color: white;
      }
    }
    .card__arrow{
      &:hover{
        color:white;
      }
    }
  }
}
.card__title{
  cursor: pointer;
}
.card__telephone{
  color: #219477;
  text-decoration: underline;
}
.cardpharmacy{
  width: 100%;
  font-size:15px;
  text-transform: lowercase;
  .card__provider{
    align-items: center;
    display: flex;
    p{
      color: #219477;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    svg{
      width: 12px;
      margin: 0 10px;
      color: #219477;
    }
  }
  .card__schedule{
    .toggle-title {
      cursor: pointer;
      p{
        font-weight: bold;
      }
      &:hover{
        color: #219477 ;
      }
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .toggle__schedule{
      overflow: hidden;
      height: 0;
      transition: all 0.5s ease-in-out;
      &.open{
        height: 150px;
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .card__title{
    font-size:18px;
    &:first-letter{
      text-transform: capitalize;
    }
    &:hover{
      color:#27D7AB;
      transition: all 0.5s ease-in-out;
    }
  }
  .card__arrow{
    font-size: 21px;
    cursor: pointer;
    &:hover{
      color:#27D7AB;
      transition: all 0.5s ease-in-out;
    }
  }
  .card__content{
    &:first-letter {
      text-transform: capitalize;
    }
  }
  hr{
    margin: 1rem 0;
  }
  .cardpharmacy__content {
    width: 45%;
  }
  .cardpharmacy__logo_small_partner {
    width: 45%;
    min-width: 200px;
    margin: 10px 0;
    img {
      width: 80px;
    }
  }
  .cardpharmacy__next_btn {
    width: 5%;
    margin: 10px 0;
  }
}
.btn-modal-card{
  button{
    margin-top: 10px;
  }
}
.btn_partner{
  &.base-button {
    @include onDesktop {
      width: 355px;
    }
  }
}
.provider_logo{
  max-width: 250px;
}
.premium{
  color: black;
  font-weight: bold;
  &::first-letter{
    text-transform: uppercase;
  }
}
</style>