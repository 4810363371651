<template>
  <div class="breadcrumb">
    <div v-for="(page, index) in pages">
      <div v-bind:class="{'current':page.current}" class="breadcrumb__link">
        <div class="number">
          <span>{{index+1}}</span>
        </div>
        <router-link :to="{name: page.url}">{{page.name}}</router-link>
        <div v-if="index !== pages.length - 1" class="breadcrumb__link__split">-</div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'BreadCrumb',
  props: {
    pages: {
      required: true,
      type: Array
    }
  }

}
</script>

<style lang="scss" scoped>
.breadcrumb{
  color: black;
  display: flex;
  flex-wrap: wrap;
  @include onMobile {
    flex-direction: column;
  }
  .breadcrumb__link{
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;
    .number{
      height: 15px;
      width: 15px;
      background: darkgray;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-right: 10px;
      font-weight: bold;
    }
    a{
      color: black;
      &:hover{
        text-decoration: underline;
        text-underline: black;
      }
    }
    .breadcrumb__link__split{
      margin: 0 10px;
      @include onMobile {
        display: none;
      }
    }
  }
  .current{
    a{
      text-decoration: underline;
      text-decoration-color: #27D7AB;
      cursor: unset;
    }
    .number{
      background: #27D7AB;
    }
  }
}
</style>