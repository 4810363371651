var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.angle > 0
    ? _c(
        "svg",
        { staticClass: "app-pie-timer", attrs: { viewBox: "0 0 250 250" } },
        [
          _c("path", {
            ref: "loader",
            attrs: { d: _vm.anim, transform: "translate(125, 125)" }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }