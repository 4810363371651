var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "status-container" }, [
            _c(
              "div",
              {
                staticClass: "status-container__offer",
                class: [
                  _vm.isPremium ? "status-container__offer--premium" : null
                ]
              },
              [
                !_vm.isPremium
                  ? _c("div", { staticClass: "status-offer" }, [
                      _c("div", { staticClass: "status-offer__container" }, [
                        _c(
                          "div",
                          { staticClass: "status-offer__title-container" },
                          [
                            _c("h3", { staticClass: "status-offer__title" }, [
                              _vm._v("BASIC")
                            ]),
                            _c("p", { staticClass: "status-offer__title" }, [
                              _vm._v("Paiement à l'acte")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "status-offer__descriptions" },
                          [
                            _c(
                              "span",
                              { staticClass: "status-offer__description" },
                              [_vm._v("TÉLÉCONSULTATION SUR RDV")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "status-offer__description--free-price"
                              },
                              [_vm._v("INCLUS")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "status-offer__descriptions" },
                          [
                            _c(
                              "span",
                              { staticClass: "status-offer__description" },
                              [_vm._v("CLICK & COLLECT PHARMACIE")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "status-offer__description--free-price"
                              },
                              [_vm._v("INCLUS")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "status-offer__descriptions" },
                          [
                            _c(
                              "span",
                              { staticClass: "status-offer__description" },
                              [_vm._v("TÉLÉCONSULTATION EN - DE 1H")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "status-offer__description status-offer__description--no-free"
                              },
                              [_vm._v("6€/TÉLÉCONSULTATION/")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "status-offer__descriptions" },
                          [
                            _c(
                              "span",
                              { staticClass: "status-offer__description" },
                              [_vm._v("LIVRAISON DE MÉDICAMENTS")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "status-offer__description status-offer__description--no-free"
                              },
                              [_vm._v("À partir de 6€/LIVRAISON")]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "status-offer__descriptions" },
                          [
                            _c(
                              "span",
                              { staticClass: "status-offer__description" },
                              [_vm._v("CONCIERGERIE MÉDICALE")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "status-offer__description--no-free"
                              },
                              [_vm._v("9,90€/APPEL")]
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "status-offer" }, [
                  !_vm.isPremium
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "status-offer__container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "status-offer__title-container status-offer--premium"
                              },
                              [
                                _c(
                                  "h3",
                                  { staticClass: "status-offer__title" },
                                  [_vm._v("PREMIUM")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "status-offer__title" },
                                  [_vm._v("6,99€/mois TOUT INCLUS")]
                                )
                              ]
                            ),
                            _c("AppLogoPremium", {
                              staticClass: "status-offer__img",
                              attrs: { large: "" }
                            }),
                            _c(
                              "div",
                              { staticClass: "status-offer__descriptions" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "status-offer__description" },
                                  [_vm._v("TÉLÉCONSULTATION SUR RDV")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "status-offer__description--free-price"
                                  },
                                  [_vm._v("INCLUS")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "status-offer__descriptions" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "status-offer__description" },
                                  [_vm._v("CLICK & COLLECT PHARMACIE")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "status-offer__description--free-price"
                                  },
                                  [_vm._v("INCLUS")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "status-offer__descriptions" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "status-offer__description status-offer"
                                  },
                                  [_vm._v("TÉLÉCONSULTATION EN - DE 1H")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "status-offer__description--free-price"
                                  },
                                  [_vm._v("INCLUS & ILLIMITÉ")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "status-offer__descriptions" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "status-offer__description status-offer"
                                  },
                                  [_vm._v("LIVRAISON DE MÉDICAMENTS")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "status-offer__description--free-price"
                                  },
                                  [_vm._v("INCLUS 1 LIVRAISON /MOIS")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "status-offer__descriptions" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "status-offer__description status-offer"
                                  },
                                  [_vm._v("CONCIERGERIE MÉDICALE")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "status-offer__description--free-price"
                                  },
                                  [_vm._v("INCLUS 3 APPELS /MOIS")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.isPremium
                    ? _c(
                        "div",
                        { staticClass: "status-offer__container-premium" },
                        [
                          _c("div", { staticClass: "presentation-container" }, [
                            _c(
                              "h3",
                              { staticClass: "presentation-container__title" },
                              [
                                _vm._v(
                                  "Bonjour " +
                                    _vm._s(
                                      this.$store.state.patients.profile
                                        .firstName
                                    )
                                )
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "presentation-container__desc" },
                              [_vm._v("Bienvenue sur votre tableau de bord")]
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "container-premium",
                              class: [
                                this.isPremium
                                  ? "container-premium--is-premium"
                                  : null
                              ]
                            },
                            [
                              _c("AppLogoPremium", {
                                staticClass: "status-offer__img",
                                attrs: { large: "" }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "status-offer__container status-offer__container--premium"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "status-offer__title-container status-offer--premium"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "status-offer__title" },
                                        [
                                          _vm._v("Votre statut: "),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                PREMIUM"
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-offer__descriptions"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "status-offer__description"
                                        },
                                        [_vm._v("TÉLÉCONSULTATION SUR RDV")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "status-offer__description--free-price"
                                        },
                                        [_vm._v("ILLIMITÉES")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-offer__descriptions"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "status-offer__description"
                                        },
                                        [_vm._v("CLICK & COLLECT PHARMACIE")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "status-offer__description--free-price"
                                        },
                                        [_vm._v("ILLIMITÉES")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-offer__descriptions"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "status-offer__description"
                                        },
                                        [_vm._v("TÉLÉCONSULTATION EN - DE 1H")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "status-offer__description--free-price"
                                        },
                                        [_vm._v("ILLIMITÉES")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-offer__descriptions"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "status-offer__description"
                                        },
                                        [_vm._v("LIVRAISON DE MÉDICAMENTS")]
                                      ),
                                      this.totalSlots
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "status-offer__description--free-price",
                                              class: [
                                                _vm.maxSLotDelivery
                                                  ? "max-slot"
                                                  : null
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.amountSlotDelivery + "/1"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "status-offer__description--free-price"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.deliveryCreditsPerMonth -
                                                _vm.amountSlotDelivery +
                                                " livraison restante"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "status-offer__descriptions"
                                    },
                                    [
                                      this.totalSlots
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "status-offer__description"
                                            },
                                            [_vm._v("CONCIERGERIE MÉDICALE")]
                                          )
                                        : _vm._e(),
                                      this.totalSlots
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "status-offer__description--free-price",
                                              class: [
                                                _vm.maxSLotConciergerie
                                                  ? "max-slot"
                                                  : null
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.amountSlotConciergerie +
                                                    "/3"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "status-offer__description--free-price"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.conciergerieCreditsPerMonth -
                                                _vm.amountSlotConciergerie +
                                                " " +
                                                (_vm.amountSlotConciergerie >= 2
                                                  ? "appel restant"
                                                  : "appels restants")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "status-premium" }, [
                                _vm.isPremium
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "status-premium__button",
                                        attrs: { block: "" },
                                        on: { click: _vm.unsubscribeWindow }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "profileStatusPage.status.premium.unsubscription"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "status-offer__description-premium"
                            },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/ordre-du-jour@2x.png"),
                                    alt: "ordre du jour"
                                  }
                                }),
                                _c("span", [_vm._v("JE PRENDS RENDEZ-VOUS")])
                              ]),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/ordinateur@2x.png"),
                                    alt: "ordinateur"
                                  }
                                }),
                                _c("span", [
                                  _vm._v("JE TÉLÉCONSULTE MAINTENANT")
                                ])
                              ]),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/livraison-rapide@2x.png"),
                                    alt: "livraison"
                                  }
                                }),
                                _c("span", [
                                  _vm._v("JE ME FAIS LIVRER MES MÉDICAMENTS")
                                ])
                              ]),
                              _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/service-client@2x.png"),
                                    alt: "service client"
                                  }
                                }),
                                _c("span", [
                                  _vm._v("JE CONTACTE MON CONCIERGE MÉDICAL")
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                !_vm.isPremium
                  ? _c("div", { staticClass: "button-container" }, [
                      _c(
                        "a",
                        {
                          staticClass: "standart-status__button",
                          attrs: {
                            href: "https://hellocare.com/presentation-premium",
                            target: "_blank"
                          }
                        },
                        [_c("span", [_vm._v("EN SAVOIR PLUS")])]
                      ),
                      !_vm.isPremium
                        ? _c(
                            "div",
                            { staticClass: "button-sub-container" },
                            [
                              _c("span", [_vm._v("SANS ENGAGEMENT")]),
                              _c("AppLogoPremium", {
                                staticClass: "button-sub-container__img",
                                attrs: { xmedium: "" }
                              }),
                              _c(
                                "CardPayment",
                                {
                                  staticClass:
                                    "button-sub-container__button-sub"
                                },
                                [_vm._v("PASSER AU PREMIUM")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          ])
        ]),
        _c(
          "AppConfirmation",
          {
            ref: "unsubscribeWindow",
            attrs: { title: _vm.$t("profileStatusPage.confirmation.title") },
            scopedSlots: _vm._u([
              {
                key: "description",
                fn: function(scoped) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.$t("profileStatusPage.confirmation.description.1")
                      )
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(
                        _vm.$t("profileStatusPage.confirmation.description.2")
                      )
                    )
                  ]
                }
              }
            ])
          },
          [
            _c("CancelButton", { attrs: { icon: _vm.$icons.no } }, [
              _vm._v("Non")
            ]),
            _c(
              "ConfirmButton",
              {
                attrs: { color: "red", icon: _vm.$icons.no },
                on: { click: _vm.unsubscribe }
              },
              [_vm._v("Oui")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }