import Vue from 'vue';
import { mask } from 'vue-the-mask';

// Vue.use(VueTheMask);
Vue.directive('mask', {
  bind(el, binding) {
    if (binding.value) {
      mask(el, binding);
    }
  }
});
