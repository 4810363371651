<!--
Emitted events:
- change(selectedDateTime: string)
-->

<template>
  <div class="time">
    <div class="hour" v-for="slot in availableHours" @click="$emit('change', slot.start)">
      {{ displayTime(slot) }}
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'Time',
    props: {
      appointmentDuration: {
        type: Number,
        required: true,
        default: 15,
      },
      availableHours: {
        type: Array,
        required: true,
        default: [],
      },
    },
    data() {
      return {
        minimumSlotTime: moment(),
      };
    },
    mounted() {
      this.$setInterval(() => { this.minimumSlotTime = moment() }, 5000);
    },
    methods: {
      displayTime(slot) {
        return moment(slot.start).format('HH:mm');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .time {
    flex: 1;
  }

  .hour {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 25px;
    font-size: 1.7rem;
    margin: 8px 4px;
    cursor: pointer;

    &:hover, &.selected {
      background-color: $successColor;
      color: #ffffff;
    }
  }
</style>
