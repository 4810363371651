import Files from '../../api/files';
import { partition } from 'lodash-es';

export default {
  state: {
    isFetchingAll: false,
    isUploading: false,
    practitionerFiles: [],
    patientFiles: [],
    uploadingFileName: null,
  },
  mutations: {
    SHARED_FILES_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    SHARED_FILES_FETCH_ALL_SUCCESS(state, { patientFiles, practitionerFiles }) {
      state.practitionerFiles = practitionerFiles;
      state.patientFiles = patientFiles;
      state.isFetchingAll = false;
    },
    SHARED_FILES_FETCH_ALL_FAIL(state) {
      state.practitionerFiles = [];
      state.patientFiles = [];
      state.isFetchingAll = false;
    },
    SHARED_FILES_UPLOAD(state, { fileName }) {
      state.isUploading = true;
      state.uploadingFileName = fileName;
    },
    SHARED_FILES_UPLOAD_SUCCESS(state) {
      state.isUploading = false;
      state.uploadingFileName = null;
    },
    SHARED_FILES_UPLOAD_FAIL(state) {
      state.isUploading = false;
      state.uploadingFileName = null;
    },
    SHARED_FILES_ATTACH(state) {
      state.isAttaching = true;
    },
    SHARED_FILES_ATTACH_SUCCESS(state) {
      state.isAttaching = false;
    },
    SHARED_FILES_ATTACH_FAIL(state) {
      state.isAttaching = false;
    },
  },
  actions: {
    async sharedFilesFetchAllByAppointment({ commit }, { appointmentId }) {
      commit('SHARED_FILES_FETCH_ALL');
      try {
        const files = await Files.getAllByAppointment(appointmentId);
        const [patientFiles, practitionerFiles] = partition(files, 'patient');
        commit('SHARED_FILES_FETCH_ALL_SUCCESS', { patientFiles, practitionerFiles });
      } catch (e) {
        commit('SHARED_FILES_FETCH_ALL_FAIL');
        throw e;
      }
    },
    /**
     * @param {function}    dispatch
     * @param {Blob}        file            The file to upload
     * @param {string}      fileName        The name of the file
     * @param {string|null} appointmentId   Optional. The appointment to attach the file to
     * @param {string|null} practitionerId  Optional. The practitioner to attach the file to
     *
     * @returns {Promise}
     */
    async sharedFilesUploadAndAttach({ dispatch }, { file, fileName, appointmentId = null, practitionerId = null }) {
      const uploadedFile = await dispatch('sharedFilesUpload', { file, fileName });
      await dispatch('sharedFilesAttach', { fileId: uploadedFile.id, appointmentId, practitionerId });
    },
    /**
     * @param {function}    commit
     * @param {Blob}        file            The file to upload
     * @param {string}      fileName        The name of the file
     *
     * @returns {Promise}
     */
    async sharedFilesUpload({ commit }, { file, fileName }) {
      commit('SHARED_FILES_UPLOAD', { fileName });
      try {
        const uploadedFile = await Files.upload(file, fileName);
        commit('SHARED_FILES_UPLOAD_SUCCESS');
        return uploadedFile;
      } catch (e) {
        commit('SHARED_FILES_UPLOAD_FAIL');
        throw e;
      }
    },
    /**
     * @param {function}      commit
     * @param {string}        fileId          The file to attach
     * @param {string|null}   appointmentId   Optional. The appointment to attach the file to
     * @param {string|null}   practitionerId  Optional. The practitioner to attach the file to
     *
     * @returns {Promise}
     */
    async sharedFilesAttach({ commit }, { fileId, appointmentId = null, practitionerId = null }) {
      commit('SHARED_FILES_ATTACH');
      try {
        await Files.attach(fileId, appointmentId, practitionerId);
        commit('SHARED_FILES_ATTACH_SUCCESS');
      } catch (e) {
        commit('SHARED_FILES_ATTACH_FAIL');
        throw e;
      }
    },
  },
};
