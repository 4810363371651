var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "form-switch",
      class: { disabled: _vm.busy || _vm.disabled }
    },
    [
      _c("input", {
        staticClass: "checkbox",
        attrs: {
          id: _vm.id,
          "aria-checked": _vm.checked,
          "aria-disabled": _vm.busy || _vm.disabled,
          disabled: _vm.busy || _vm.disabled,
          readonly: _vm.busy,
          role: "switch",
          type: "checkbox"
        },
        domProps: { checked: _vm.checked },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event.target.checked)
          }
        }
      }),
      _c("span", { staticClass: "track" }),
      _c("span", { staticClass: "knob" }),
      _vm.busy ? _c("span", { staticClass: "loader" }) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }