var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.downloadableClaims.length > 0
    ? _c(
        "div",
        { staticClass: "appointment-panel-claims" },
        _vm._l(_vm.downloadableClaims, function(claim) {
          return _c(
            "div",
            [
              _c(
                "ButtonRed",
                {
                  attrs: {
                    busy: _vm.isDownloadingClaim,
                    icon: _vm.$icons.pdf,
                    small: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadClaim(claim.id)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("appointmentPanelClaims.download")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }