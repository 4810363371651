<template>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <header class="header">
          <GroupTitle :icon="$icons.creditCard">
            {{ $t('profileBeneficiariesPage.manage') }}
          </GroupTitle>
          <ButtonGreen @click.prevent="isPanelOpened = true" small :icon="$icons.beneficiaryAdd">
            {{ $t('profileBeneficiariesPage.add') }}
          </ButtonGreen>
        </header>

        <AppConfirmation
          ref="confirmBeneficiaryDeletion"
          :title="$t('profileBeneficiariesPage.remove-confirmation')"
          @confirm="handleDelete"
        />

        <div class="list-beneficiary">
          <table class="table table-hover beneficiaries">
            <thead>
            <tr>
              <th>{{ $t('profileBeneficiariesPage.identity') }}</th>
              <th>{{ $t('profileBeneficiariesPage.birth-date') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody v-if="profile.children.length > 0">
            <tr v-for="beneficiary in profile.children" class="beneficiary">
              <td class="full-name">
                <UserAvatar size="1.6rem" />
                {{ beneficiary.firstName }} {{ beneficiary.lastName }}
              </td>
              <td>
                {{ beneficiary.birthDate | date('DD/MM/YYYY') }}
                - {{ $t('profileBeneficiariesPage.age', { age: toAge(beneficiary.birthDate) }) }}
              </td>
              <td>
                <OptionsMenu>
                  <OptionsMenuItem :icon="$icons.edit" @click="editedBeneficiary = beneficiary">
                    {{ $t('profileBeneficiariesPage.edit') }}
                  </OptionsMenuItem>
                  <!--                <OptionsMenuItem-->
                  <!--                  @click="$confirm($refs.confirmBeneficiaryDeletion, beneficiary.id)"-->
                  <!--                  :icon="$icons.removeProduct"-->
                  <!--                >-->
                  <!--                  Supprimer-->
                  <!--                </OptionsMenuItem>-->
                </OptionsMenu>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3" class="no-result">{{ $t('profileBeneficiariesPage.no-results') }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </main>
    <AppPanel
      :opened="isPanelOpened"
      :title="editedBeneficiary ? $t('profileBeneficiariesPage.edit-long') : $t('profileBeneficiariesPage.add')"
      @close="isPanelOpened = false; editedBeneficiary = null"
    >
      <ProfileEditForm
        :beneficiary="true"
        :user="editedBeneficiary"
        hide-avatar
        @create="isPanelOpened = false"
        @update="editedBeneficiary = null"
      />
    </AppPanel>
  </AppLayout>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation.vue';
  import AppLayout from '@/components/AppLayout';
  import AppPanel from '@/components/AppPanel';
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonGrey from '@/components/ButtonGrey';
  import FormInput from '@/components/Form/FormInput';
  import ProfileEditForm from '@/components/Form/ProfileEditForm.vue';
  import GroupTitle from '@/components/GroupTitle';
  import OptionsMenu from '@/components/OptionsMenu';
  import OptionsMenuItem from '@/components/OptionsMenuItem';
  import OwnerIcon from '@/components/OwnerIcon';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import SectionTitle from '@/components/SectionTitle';
  import UserAvatar from '@/components/UserAvatar';
  import { age } from '@/helpers/format';

  export default {
    name: 'ProfileBeneficiariesPage',
    components: {
      ProfileEditForm,
      AppConfirmation,
      GroupTitle,
      OwnerIcon,
      OptionsMenuItem,
      OptionsMenu,
      ProfileTabBar,
      ButtonGrey,
      FormInput,
      AppPanel,
      ButtonGreen,
      UserAvatar,
      SectionTitle,
      AppLayout,
    },
    watch: {
      editedBeneficiary(editedBeneficiary) {
        this.isPanelOpened = !!editedBeneficiary;
      },
    },
    data() {
      return {
        areOptionsVisible: false,
        isPanelOpened: false,
        editedBeneficiary: null,
      };
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      },
    },
    methods: {
      handleAdd() {
        this.isPanelOpened = false;
      },
      handleDelete(id) {
        this.$store.dispatch('patientDeleteChild', { id });
      },
      toAge(birthDate) {
        return age(birthDate);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .beneficiaries {
    margin-bottom: 4rem;
  }

  .beneficiary {
    .full-name {
      display: flex;
    }
  }

  .user-avatar {
    margin-right: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .group-title {
    margin: 0;
  }

.no-result {
  text-align: center;
  font-size: 1.6rem;
  color: $grey;
}

.list-beneficiary {
  overflow-x: auto;
}
</style>
