<template>
  <FullscreenNotification :title="$t('newAppointmentRequestedPage.title')" :imgIcon="$t('newAppointmentRequestedPage.img-icon')">
  <div class="container">
    <p v-html="$t('newAppointmentRequestedPage.text')"/>
    <div class="container__cards">
      <CardWithRedirection
          :imgCard="$t('newAppointmentRequestedPage.redirect-card.test-device.img-card')"
          :title="$t('newAppointmentRequestedPage.redirect-card.test-device.title')"
          :description="$t('newAppointmentRequestedPage.redirect-card.test-device.description')"
          :button="$t('newAppointmentRequestedPage.redirect-card.test-device.button')"
          :to-link="checkDevice"
      />
      <CardWithRedirection
          :imgCard="$t('newAppointmentRequestedPage.redirect-card.exchange-document.img-card')"
          :title="$t('newAppointmentRequestedPage.redirect-card.exchange-document.title')"
          :description="$t('newAppointmentRequestedPage.redirect-card.exchange-document.description')"
          :button="$t('newAppointmentRequestedPage.redirect-card.exchange-document.button')"
          :to-link="appointmentPanel"
      />
    </div>
    <ButtonGreen @click="appointmentPanel" rounded class="button"> {{ $t('newAppointmentRequestedPage.skip-step') }} </ButtonGreen>
  </div>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import ButtonGreen from '../components/ButtonGreen';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import CardWithRedirection from "@/components/NewAppointment/CardWithRedirection.vue";
  export default {
    name: 'NewAppointmentRequestedPage',
    components: {
      FullscreenNotification,
      AppLayout,
      ButtonGreen,
      SectionTitle,
      CardWithRedirection
    },

    data: {
      id: null
    },

    async mounted() {
      await this.loadAppointments();
    },

    computed: {
      newAppointmentId() {
        return this.$store.state.newAppointment.appointmentId;
      },
    },
    methods: {
      checkDevice() {
        window.open("https://test.hellocare.com/auto", "_blank");
      },
      async appointmentPanel() {
        await this.$router.push(`/appointments/${this.newAppointmentId}`);
        await this.$store.dispatch('newAppointmentClear');
      },
      async loadAppointments() {
        try {
          await this.$store.dispatch('appointmentsFetchAll', { page: this.currentPage });
        } catch (e) {
          this.$addError(this.$t('appointmentsPage.error-load'));
          throw e;
        }
      },
      async skipStep() {
        await this.$router.push(`/appointments/${this.newAppointmentId}`);
      }
    }
  };
</script>
<style lang="scss" scoped>
.container {
  @include onMobile {
    margin-bottom: 0;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
  }
  &> p {
    flex: 1 100%;
    font-weight: 500;
  }
}

.button {
  width: max-content;
  align-self: center;
}

::v-deep.content {
  margin-bottom: 0;
}

::v-deep.fullscreen-notification {
  @include onMobile {
    height: auto;
    min-height: 100%;
    padding: 7rem 5rem 0 5rem;
    position: absolute;
  }
  position: initial;
  min-height: 100vh;
  width: 100%;
  padding: 3rem 3rem 0;
}

::v-deep.buttons {
  margin-bottom: 0;
}
</style>