<!--
This panel is animated for 600ms.

For best experience, surround it with a transition:

<transition :duration="600">
  <AppointmentPanel .../>
</transition>
-->

<template>
  <div class="appointment-panel">
    <router-link :to="backPath" class="backdrop" />
    <div class="panel">
      <AppLoader v-if="isLoadingAppointment"> {{ $t('appointmentPanel.loader') }} </AppLoader>
      <div v-else-if="appointment" class="content">
        <SectionTitle class="panel-title">
          {{ $t('appointmentPanel.title') }}
          <router-link :to="backPath" class="close-panel">
            <FontAwesomeIcon :icon="$icons.closePanel" class="close-panel-icon" />
          </router-link>
        </SectionTitle>
        <div class="summary">
          <section class="section">
            <h4 class="practitioner-full-name">{{ practitioner | fullName }}</h4>
            <div v-if="practitioner.phone">{{ practitioner.phone | phone }}</div>
          </section>

          <AppointmentPanelDetails :can-be-started="canBeStarted" class="section" />

          <p class="section description">{{ appointment.payload.description }}</p>

          <div class="click_collect_container">
            <div v-if="this.$store.state.pharmacies_order.isFetchingOne || this.$store.state.pharmacies_order.isCancelling || this.$store.state.pharmacies_order.isFinishing">
              <Spinner />
            </div>
            <div v-else>
              <div v-if="appointment.payload.status==='CLOSED'||appointment.payload.status==='TERMINATED'">
                <PanelPharmacyOrderCard :order="getOrder" :orderIsObject="orderIsObject" :appointmentId="appointmentId"/>
              </div>
            </div>
          </div>

          <div class="doc2u_container">
            <div v-if="this.$store.state.observations.isFetching" class="container-spinner">
              <Spinner />
            </div>
            <div v-else>
              <div v-if="searchLength>0">
                <LastObservations :search-length="searchLength" :observations="this.$store.state.observations.search"/>
              </div>
              <div v-else>
                <p>Aucune mesure enregistrée.</p>
              </div>
            </div>
          </div>
          <AppointmentPanelPayment v-if="isPaymentRequired" class="section" @paid="loadAppointment" />

          <div v-else-if="isCancelled" class="section cancel-reason">
            <h4> {{ $t('appointmentPanel.title-cancel-reason') }} </h4>
            {{ appointment.payload.cancelReason || $t('appointmentPanel.no-reason-provide') }}
          </div>

          <template v-else>
            <template v-if="isClosed">
              <p v-if="isLoadingEncounter" class="section">
                <FontAwesomeIcon :icon="$icons.spinner" spin />
                {{ $t('appointmentPanel.verification-files') }}
              </p>
              <template v-else>
                <AppointmentPanelPrescriptions class="section" />
                <AppointmentPanelPatientAdvice class="section" />
                <AppointmentPanelClaims class="section" />
              </template>
            </template>

            <SharedFiles
              :appointment-id="appointmentId"
              :practitioner-id="practitioner.id"
              class="section"
            />
          </template>
        </div>

        <AppointmentPanelActions
          v-if="!isPaymentRequired && !isClosed && !isTerminated && !isCancelled"
          :can-be-started="canBeStarted"
          @accept="reloadAppointments"
          @cancel="reloadAppointments"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import AppLoader from '@/components/AppLoader';
  import AppointmentPanelActions from '@/components/AppointmentPanel/AppointmentPanelActions';
  import AppointmentPanelClaims from '@/components/AppointmentPanel/AppointmentPanelClaims';
  import AppointmentPanelDetails from '@/components/AppointmentPanel/AppointmentPanelDetails';
  import AppointmentPanelPatientAdvice from '@/components/AppointmentPanel/AppointmentPanelPatientAdvice';
  import AppointmentPanelPayment from '@/components/AppointmentPanel/AppointmentPanelPayment';
  import AppointmentPanelPrescriptions from '@/components/AppointmentPanel/AppointmentPanelPrescriptions';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import SectionTitle from '@/components/SectionTitle';
  import SharedFiles from '@/components/SharedFiles';
  import { AbortMixin } from '@/helpers/mixins';
  import moment from 'moment';
  import AppConfirmation from "./AppConfirmation";
  import ConfirmButton from "./AppConfirmation/ConfirmButton";
  import PanelPharmacyOrderCard from "./Pharmacy/PanelPharmacyOrderCard";
  import Spinner from "./Pharmacy/Spinner";
  import LastObservations from "./LastObservations";


  export default {
    name: 'AppointmentPanel',
    components: {
      LastObservations,
      PanelPharmacyOrderCard,
      AppointmentPanelPayment,
      AppointmentPanelPatientAdvice,
      AppointmentPanelPrescriptions,
      AppointmentPanelActions,
      AppointmentPanelClaims,
      AppointmentPanelDetails,
      SharedFiles,
      AutoHeightTextarea,
      SectionTitle,
      AppLoader,
      AppConfirmation,
      ConfirmButton,
      Spinner
    },
    mixins: [AbortMixin],
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      backPath: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchLength: 0
      }
    },
    async mounted() {
      await this.clearCurrentOrderAppointment();
      await this.clearCurrentPharmacyOrder();
      await this.isOrderAvailable();
      await this.loadAppointment();
      await this.getLastObservations()
    },
    beforeDestroy() {
      this.$store.dispatch('appointmentsClearCurrent');
    },
    computed: {
      orderIsObject(){
        return typeof this.$store.state.pharmacies_order.current === 'object';
      },
      getOrder(){
        return this.$store.state.pharmacies_order.current;
      },
      isLoadingAppointment() {
        return this.$store.state.appointments.isFetchingOne;
      },
      isLoadingEncounter() {
        return this.$store.state.encounters.isFetchingOne;
      },
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      encounter() {
        return this.$store.state.encounters.current;
      },
      status() {
        return this.appointment?.payload.status;
      },
      patient() {
        return this.appointment.payload.patient;
      },
      practitioner() {
        return this.appointment.payload.practitioner;
      },
      product() {
        return this.appointment.payload.product;
      },
      isPaymentRequired() {
        return this.status === 'PAYMENT_REQUIRED';
      },
      isTerminated() {
        return this.status === 'TERMINATED';
      },
      isClosed() {
        return this.status === 'CLOSED';
      },
      isRemote() {
        return this.appointment.payload.product.channel === 'remote';
      },
      isConfirmed() {
        return this.appointment.payload.status === 'CONFIRMED';
      },
      canBeStarted() {
        if (!this.isConfirmed || !this.isRemote) {
          return false;
        }

        return this.$store.state.currentTime.isAfter(this.appointmentMinimumStartTime);
      },
      appointmentMinimumStartTime() {
        return moment(this.appointment.payload.startAt).subtract(15, 'minutes');
      },
      isCancelled() {
        return this.status === 'CANCELLED_BY_PATIENT' || this.status === 'CANCELLED_BY_PRACTITIONER';
      },
    },
    methods: {
      profileId() {
        return this.$store.state.patients.profile.id;
      },
      async getLastObservations() {
        await this.$store.dispatch('observationsFetch', {
          patient: this.profileId(),
          lastObs: true,
          code: null
        })
        if(this.$store.state.observations && this.$store.state.observations.search) {
          this.searchLength = this.$store.state.observations.search.length;
        }
      },
      async clearCurrentOrderAppointment(){
        localStorage.removeItem('currentOrderAppointment');
      },
      async clearCurrentPharmacyOrder(){
        localStorage.removeItem('currentPharmacyOrder');
      },
      async loadAppointment() {
        await this.$store.dispatch('appointmentsClearCurrent');

        try {
          await this.$store.dispatch('appointmentsFetchOne', {
            id: this.appointmentId,
            abortSignal: this.abortSignal,
          });
          if (!this.isPaymentRequired && this.isClosed && this.isRemote) {
            const encounter = this.appointment.payload.encounters?.[0];
            await this.$store.dispatch('encountersFetchOne', {
              id: encounter.id,
              abortSignal: this.abortSignal,
            });
          }
        } catch (e) {
          if (!this.isAbortError(e)) {
            this.$addError(this.$t('appointmentPanel.error'));
            throw e;
          }
        }
      },
      async reloadAppointments() {
        this.loadAppointment();
        await this.$store.dispatch('appointmentsFetchAll');
      },
      async isOrderAvailable() {
        await this.$store.dispatch('pharmaciesOrderAvailable', {
          appointmentId: this.appointmentId
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
 .appointment-panel {
    font-size: 1.4rem;
    font-weight: 200;
    position: fixed;
    z-index: 2010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .backdrop {
    display: block;
    width: 100%;
    height: 100%;
    transition: opacity 0.6s;
    opacity: 0.9;
    background: $gradientBackground;

    .v-enter &,
    .v-leave-to & {
      opacity: 0;
    }
  }

  .panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    width: 100%;
    max-width: 80rem;
    transition: transform 0.6s;
    transform: translate3d(0, 0, 0);
    background-color: #FFFFFF;

    .v-enter &,
    .v-leave-to & {
      transform: translate3d(40vw, 0px, 0px);
    }

    @media (max-width: 1000px) {
      width: calc(100vw - 7.4rem);

      .v-enter &,
      .v-leave-to & {
        transform: translate3d(calc(100vw - 7.4rem), 0px, 0px);
      }
    }

    @media (max-width: 768px) {
      width: 100vw;

      .v-enter &,
      .v-leave-to & {
        transform: translate3d(100vw, 0px, 0px);
      }
    }

    hr {
      height: 1px;
      margin: 3rem 0;
      border: none;
      background: #EFEFEF;
    }
  }

  .close-panel {
    position: absolute;
    right: 0;
  }

  .close-panel-icon {
    font-size: 3rem;
    color: #F46868;
  }

  .content {
    padding: 3rem 2rem 0;

    @include onDesktop {
      padding: 10rem 10rem 0;
    }
  }

  .summary {
    font-family: $titleFont;
    font-size: 1.5rem;
  }

  .section {
    margin: 0;
    padding: 2rem 0;
    border-bottom: 1px solid $hrColor;

    &:first-child {
      padding-top: 0;
    }
  }

  .practitioner-full-name {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .shared-files::v-deep .files-group:first-child {
    padding-top: 0;
  }

  .cancel-reason {
    font-family: $fontRoboto;
    font-weight: 300;

    h4 {
      font-family: $fontGotham;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  .first-letter-capitalize{
    &:first-letter {
      text-transform: capitalize;
    }
  }
  .button-cancel{
    border: 1px solid lightgray;
    background: transparent;
    color: #424242FF;
  }
  .click_collect_container{
    padding: 2rem 0;
  }
  .doc2u_container{
    padding: 2rem 0;
  }
 .description {
   max-height: 5rem;
   margin: 0.5rem 0;
   font-size: 1.4rem;
   white-space: break-spaces;
 }
</style>
