import api from './index';

export default {
  getPlatformJWT: (patientId) => {
    const searchParams = new URLSearchParams({ type: 'platform' });
    if (patientId) {
      searchParams.append('patient', patientId);
    }
    return api.get('credentials', { searchParams }).json();
  },
  getDoc2uCredentials: (patientId) => {
    const searchParams = new URLSearchParams({ type: 'doc2u' });
    if (patientId) {
      searchParams.append('patient', patientId);
    }
    return api.get('credentials', { searchParams }).json();
  }
};
