<!--
  Don't use AppConfirmationInner directly.
  Use AppConfirmation.
-->

<template>
  <div class="app-confirmation">
    <FontAwesomeIcon v-if="icon!==false" :icon="icon || $icons.exclamation" class="icon" size="5x" />
    <SectionTitle center light>
      <slot name="title">{{ title }}</slot>
    </SectionTitle>
    <div v-if="hasDescription" class="comment">
      <slot name="description" />
    </div>
    <div :class="{ multiple: hasMultipleButtons, vertical }" class="buttons">
      <slot>
        <CancelButton />
        <ConfirmButton @click="$listeners.confirm" />
      </slot>
    </div>
  </div>
</template>

<script>
  import SectionTitle from './SectionTitle';
  import CancelButton from '@/components/AppConfirmation/CancelButton';
  import ConfirmButton from '@/components/AppConfirmation/ConfirmButton';

  export default {
    name: 'AppConfirmationInner',
    components: { ConfirmButton, CancelButton, SectionTitle },
    props: {
      payload: {
        required: false,
      },
      busy: {
        type: Boolean,
        required: false,
      },
      title: {
        type: String,
        required: false,
        default: function() {
          return this.$t('appConfirmationInner.label-default')
        }
      },
      description: {
        type: String,
        require: false,
      },
      vertical: {
        type: Boolean,
        required: false,
        default: false,
      },
      icon: {
        required: false,
      }
    },
    data() {
      return {
        status: {
          isBusy: false,
          payload: this.payload,
        },
      };
    },
    provide() {
      return {
        appConfirmationStatus: this.status,
        appConfirmationClose: () => this.$emit('close'),
        appConfirmationSetBusy: (isBusy) => this.$emit('busy', isBusy),
      };
    },
    watch: {
      busy(busy) {
        this.status.isBusy = busy;
      },
    },
    computed: {
      hasDescription() {
        return null != this.$scopedSlots.description?.();
      },
      hasMultipleButtons() {
        return !this.$slots.default || this.$slots.default.length > 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-confirmation {
    position: fixed;
    z-index: 2100;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .icon {
    margin-bottom: 4rem;
    color: $lightTextColor;
  }

  .comment {
    margin: 0 0 4rem;
    color: $lightTextColor;
    font-size: 1.5rem;
    padding: 0 1.5rem;
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin: 0 auto;

    &.vertical {
      flex-direction: column;

      ::v-deep button {
        width: 50rem;
        margin: 1rem 0;
      }
    }

    &.multiple {
      justify-content: center;
    }

    ::v-deep button {
      width: 20rem;
      margin: 0 1rem 1rem;
    }
  }
</style>
