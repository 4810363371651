<template>
  <div class="nearest-appointment" v-if="nearestAppointmentId">
    <span>
      {{ $t('nearestAppointment.title') }}
    </span>
    <router-link :to="`/consultation/${nearestAppointmentId}`" class="go-to">{{ $t('nearestAppointment.go-to') }}</router-link>
  </div>
</template>

<script>
  import moment from 'moment';
  import Appointments from '../api/appointments';

  export default {
    name: 'NearestAppointment',
    data() {
      return {
        nearestAppointmentId: null,
      };
    },
    mounted() {
      this.checkNearest();
      this.startPolling();
    },
    methods: {
      startPolling() {
        this.$setInterval(() => {
          this.checkNearest();
        }, 900000);
      },
      async checkNearest() {
        const nearestAppointment = await Appointments.getNearest();

        if (!nearestAppointment) {
          return this.nearestAppointmentId = null;
        }

        const startAt = moment(nearestAppointment.startAt);

        if(startAt.isAfter(moment().add(15, 'minutes'))) {
          return this.nearestAppointmentId = null;
        }

        this.nearestAppointmentId = nearestAppointment.id;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .nearest-appointment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.6rem;
    color: $lightTextColor;
    background: $mainColor;
    position: fixed;
    bottom: 6rem;
    left: 0;
    right: 0;
    font-size: 1.3rem;
  }

  .go-to {
    cursor: pointer;
    color: $mainColor;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0.4rem 1rem;
    border-radius: 0.8rem;
    border: none;
    background: #ffffff;
    margin-left: 1rem;
    animation: beat 6s infinite ease-in-out;
  }
</style>
