var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practitioner
    ? _c(
        "AppLayout",
        [
          _c("AppProgress", {
            attrs: {
              current: 8,
              total: 8,
              position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
            }
          }),
          _c("main", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "summary" },
              [
                _c("RecapTitle", {
                  attrs: {
                    title:
                      _vm.practitioner.title + " " + _vm.practitioner.lastName,
                    date: _vm.dateAppointment(this.newAppointment.startAt)
                  }
                }),
                _c("SectionTitle", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentSummaryPage.title")) +
                      "\n      "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("div", { staticClass: "details" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("../assets/images/person.png") }
                        }),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.toFullName(_vm.newAppointment.beneficiary)
                            )
                          )
                        ])
                      ]),
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("../assets/images/calendar.png")
                          }
                        }),
                        _c("strong", [
                          _vm._v(
                            "Le " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.newAppointment.startAt,
                                  "dddd Do MMMM YYYY"
                                )
                              )
                          )
                        ])
                      ]),
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("../assets/images/hour.png") }
                        }),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("date")(
                                _vm.newAppointment.startAt,
                                "HH[h]mm"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("../assets/images/camera.png") }
                        }),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("channel")(
                                _vm.newAppointment.product.channel
                              )
                            )
                          )
                        ])
                      ]),
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: require("../assets/images/folder.png") }
                        }),
                        _c("strong", [_vm._v(_vm._s(_vm.productName))])
                      ]),
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("../assets/images/stethoscope.png")
                          }
                        }),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              "Avec " +
                                _vm.practitioner.title +
                                " " +
                                _vm.practitioner.lastName
                            )
                          )
                        ])
                      ])
                    ]),
                    _vm.isFingerPrint
                      ? _c("CreditCards", {
                          staticClass: "credit-card",
                          attrs: { card: _vm.cardSelected }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "info",
                        class: { "not-fingerprint": !_vm.isFingerPrint }
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _vm.isFingerPrint
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "newAppointmentSummaryPage.fingerPrint"
                                      )
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatMoney(
                                          _vm.fingerPrintPrice(_vm.totalPrice)
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://hellocare.com/blog/conditions-generales-dutilisation-cgu/",
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon-question",
                                        attrs: {
                                          src: require("@/assets/images/question.png")
                                        }
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c("br"),
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("newAppointmentSummaryPage.no-show-fee")
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://hellocare.com/blog/conditions-generales-dutilisation-cgu/",
                                  target: "_blank"
                                }
                              },
                              [
                                _c("img", {
                                  staticClass: "icon-question",
                                  attrs: {
                                    src: require("@/assets/images/question.png")
                                  }
                                })
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "ButtonTransparent",
                      {
                        attrs: {
                          busy: _vm.isCreating,
                          icon: _vm.$icons.chevronLeft,
                          "icon-to-left": ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("newAppointmentSummaryPage.cancel")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "ButtonGreen",
                      {
                        attrs: {
                          busy: _vm.isCreating,
                          icon: _vm.$icons.chevronRight,
                          rounded: ""
                        },
                        on: { click: _vm.handleSubmit }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "newAppointmentSummaryPage.validate-appointment"
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }