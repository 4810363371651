<template>
  <form @submit.prevent="handleSubmit">
    <GroupTitle :icon="$icons.user">{{ $t('profileEditForm.personal-informations') }}</GroupTitle>
    <div class="row container-avatar" v-if="!hideAvatar">
      <div class="col _3">
        <UserAvatar editable size="7rem" :src="form.picture" :user-id="form.id" />
      </div>
<!--
      <AppLogoPremium v-if="isPremium" alt="premium" class="container-avatar__logo-premium" medium/>
-->
    </div>
    <div class="row">
      <FormSelect id="gender" v-model="form.gender" :label="$t('profileEditForm.form.civility.label')" class="col _3" required>
        <option value="m">{{ $t('profileEditForm.form.civility.mr') }}</option>
        <option value="mme">{{ $t('profileEditForm.form.civility.mrs') }}</option>
      </FormSelect>
      <FormInput id="first-name" v-model="form.firstName" class="col _9" :label="$t('profileEditForm.form.first-name.label')" required>
        <template v-if="$v.form.firstName.$error" #error>
          {{ $t('profileEditForm.form.first-name.required') }}
        </template>
      </FormInput>
    </div>
    <div class="row">
      <FormInput id="last-name" v-model="form.lastName" class="col _12" :label="$t('profileEditForm.form.last-name.label')" required>
        <template v-if="$v.form.lastName.$error" #error>
          {{ $t('profileEditForm.form.last-name.required') }}
        </template>
      </FormInput>
    </div>
    <GroupTitle :icon="$icons.user">{{ $t('profileEditForm.health-informations') }}</GroupTitle>
    <div class="row">
      <FormInput id="ssn" v-model="form.ssn" class="col _12" :label="$t('profileEditForm.form.ssn.label')">
        <template v-if="$v.form.ssn.$error" #error>
          {{ $t('profileEditForm.form.ssn.invalid') }}
        </template>
      </FormInput>
    </div>
    <div class="row">
      <FormInput id="mutualName" v-model="form.mutualName" class="col _12" :label="$t('profileEditForm.form.mutualName.label')">
        <template v-if="$v.form.mutualName.$error" #error>
          {{ $t('profileEditForm.form.mutualName.invalid') }}
        </template>
      </FormInput>
    </div>
    <div class="row">
      <FormInput id="mutualNumber" v-model="form.mutualNumber" class="col _12" :label="$t('profileEditForm.form.mutualNumber.label')">
        <template v-if="$v.form.mutualNumber.$error" #error>
          {{ $t('profileEditForm.form.mutualNumber.invalid') }}
        </template>
      </FormInput>
    </div>
    <div class="row">
      <FormInput
          id="birth-date"
          v-model="form.birthDate"
          class="col"
          :class="isOwner ? '_4' : '_6'"
          :label="$t('profileEditForm.form.birth-date.label')"
          mask="##/##/####"
          :placeholder="$t('profileEditForm.form.birth-date.placeholder')"
          required
      >
        <template v-if="$v.form.birthDate.$error" #error>
          {{ $t('profileEditForm.form.birth-date.invalid') }}
        </template>
      </FormInput>
      <FormInput
          id="weight"
          :value="form.weight"
          @input="form.weight = $inputToWeight($event)"
          class="col"
          :class="isOwner ? '_4' : '_6'"
          :label="$t('profileEditForm.form.weight.label')"
      />
      <TimezoneSelector id="timezone" v-model="form.timezone" class="col _4" v-if="isOwner" />
    </div>
    <div v-if="!this.beneficiary">
      <div class="row">
        <FormInput id="address" class="col _12 has-value">
          <template #label>Entrez votre adresse</template>
          <template #input>
            <input type="text"  ref="inputAddress" v-model="form.address" placeholder=""/>
          </template>
        </FormInput>
      </div>
      <div class="row">
        <FormInput id="postalCode" v-model="form.postalCode" class="col _6"  label="Code postal"/>
        <FormInput id="postalCode" v-model="form.city"  class="col _6" label="Ville"/>
      </div>
      <div >
        <FormWidget>
          <label for="countries">Pays *</label>
          <MultiSelect
              v-model="form.country"
              :options="countries"
              :show-labels="false"
              id="countries"
              placeholder="Sélectionnez..."
              track-by="code"
              label="country"
          >
          </MultiSelect>
<!--          <template #error v-if="$v.form.countries.$error">
            Champ obligatoire
          </template>-->
        </FormWidget>
      </div>
      <hr/>
      <Observations v-if="profile.measure" :profile="profile"/>
      <hr/>
      <div>
        <p>{{ $t('profileEditForm.form.advice-agree') }}</p>
        <div class="row checkbox-container">
          <FormRadio
              class="checkbox"
              @change="consentCheckbox(true)"
              :checked="this.form.acceptToBeContacted"
          >
            Oui
          </FormRadio>
          <FormRadio
              class="checkbox"
              :checked="!this.form.acceptToBeContacted"
              @change="consentCheckbox(false)"
          >
            Non
          </FormRadio>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col _12 actions">
        <ButtonGreen
            :busy="submitting"
            :icon="$icons.chevronRight"
            class="edit"
            outlined
            small
            type="submit"
        >
          {{ $t('profileEditForm.form.submit.label') }}
        </ButtonGreen>
      </div>
    </div>
  </form>
</template>

<script>
  import ButtonGreen from '@/components/ButtonGreen.vue';
  import FormInput from '@/components/Form/FormInput.vue';
  import FormSelect from '@/components/Form/FormSelect.vue';
  import GroupTitle from '@/components/GroupTitle.vue';
  import TimezoneSelector from '@/components/TimezoneSelector.vue';
  import UserAvatar from '@/components/UserAvatar.vue';
  import { convertDateToDash } from '@/helpers/format.js';
  import { checkDate, checkSsn } from '@/helpers/validation.js';
  import moment from 'moment';
  import { required } from 'vuelidate/lib/validators/index.js';
  import FormWidget from "@/components/Form/FormWidget";
  import FormRadio from "./FormRadio";
  import {Loader} from "@googlemaps/js-api-loader";
  import AutoCompletedInput from '../Form/AutoCompletedInput';
  import MultiSelect from 'vue-multiselect';
  import AppLogoPremium from '@/components/AppLogoPremium.vue'
  import Observations from "@/components/Consultation/CallingInterface/Observations.vue";
  import { countries } from 'countries-list';

  export default {
    name: 'ProfileEditForm',
    components: {
        Observations,
        AppLogoPremium,
        FormRadio,
        FormWidget,
        ButtonGreen,
        TimezoneSelector,
        FormInput,
        FormSelect,
        UserAvatar,
        GroupTitle,
        AutoCompletedInput,
        MultiSelect,
    },
    props: {
      user: {
        type: Object,
        required: false,
      },
      country: {
        type: Object
      },
      beneficiary: {
        default: false
      },
      hideAvatar: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        submitting: false,
        google: null,
        searchbox: null,
        form: {
          gender: null,
          civility: null,
          firstName: null,
          lastName: null,
          birthDate: null,
          ssn: null,
          mutualName: null,
          mutualNumber: null,
          mutualjustification: null,
          timezone: null,
          weight: null,
          acceptToBeContacted: null,
          address: null,
          postalCode: null,
          city: null,
          country: null,
        },
        typedText: null,
        selectable: {
          type: Boolean,
          default: false,
        },
        checked: {
          type: Boolean,
          default:false
        },
      };
    },
    created() {
      this.loadData();
    },
    async mounted() {
      const googleMapApi = await new Loader({
        apiKey: this.$gMapApiKey,
        libraries: ["places"]
      });
      this.typedText = this.form.country;
      this.google = await googleMapApi.load();
      this.initSearchBox();
      this.addAddressOnInput();
    },
    validations: {
      form: {
        firstName: { required },
        lastName: { required },
        ssn: {
          format: checkSsn,
        },
        mutualName: {
        },
        mutualNumber: {
        },
        birthDate: {
          required,
          checkDate,
        },
        acceptToBeContacted: {
          default: true
        },
      },
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      },
      isPremium() {
        return this.profile.premium
      },
      isOwner() {
        return this.user?.isOwner;
      },
      countries() {
        return this.getAllCountries();
      },
    },
    methods: {
      getAllCountries() {
        return Object.entries(countries).map(country => {
            return {
                code: country[0],
                country: country[1].name
            };
        });
      },
      getPracticeCountryData(code) {
        return this.getAllCountries().find(country => country.code === code);
      },
      async loadData() {
        await this.$store.dispatch('appLoaderShow');
        try {
          await Promise.all([]);
        } catch (e) {
          await this.$addError('Une erreur s\'est produite lors du chargement de votre profil.');
          throw e;
        } finally {
          this.form =  {
            gender: this.user?.gender,
            civility: this.user?.civility,
            firstName: this.user?.firstName,
            lastName: this.user?.lastName,
            birthDate: this.user?.birthDate ? moment(this.user.birthDate).format('DD/MM/YYYY') : null,
            ssn: this.user?.ssn,
            timezone: this.user?.timezone,
            weight: this.user?.weight,
            acceptToBeContacted: this.user?.acceptEmailCommunication,
            address: this.user?.address,
            postalCode: this.user?.postalCode,
            city: this.user?.city,
            country: this.getPracticeCountryData(this.user?.country),
            mutualName: this.user?.mutualName,
            mutualNumber: this.user?.mutualNumber
          };
          await this.$store.dispatch('appLoaderHide');
        }
      },
      handleTypedText(typedText) {
        this.typedText = typedText
      },
      initSearchBox() {
        const input = this.$refs.inputAddress;
        this.searchBox = new this.google.maps.places.Autocomplete(input, {
          componentRestrictions: {country:  [
              "fr",
              "gp",
              "mq",
              "gf",
              "re",
              "pm",
              "yt",
              "nc",
              "pf",
              "mf",
              "tf"
            ] }
        });
      },
      addAddressOnInput() {
        this.searchBox.addListener("place_changed", async () => {
          this.form.address = '';
          const places = this.searchBox.getPlace();
          places.address_components.map(address => {
            address.types.map(type =>  {
              if(type === 'street_number') { this.form.address += address.long_name + ' ';}
              if(type === 'route') { this.form.address += address.long_name;}
              if(type === 'locality') { this.form.city = address.long_name; }
              if(type === 'postal_code') { this.form.postalCode = address.long_name; }
            })
          })
        });
      },
      async handleSubmit() {
        this.$v.$touch();
        if (this.$v.$anyError) {
          return false;
        }

      this.submitting = true;

      try {
        const data = {
          gender: this.form.gender,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          ssn: this.form.ssn ? this.form.ssn.replace(/\s+/g, '') : null,
          birthDate: convertDateToDash(this.form.birthDate),
          weight: this.form.weight,
          mutualName: this.form.mutualName,
          mutualNumber: this.form.mutualNumber,
          acceptToBeContacted: this.form.acceptToBeContacted,
          address: this.form.address,
          postalCode: this.form.postalCode,
          city: this.form.city,
          country: this.form.country ? this.form.country.code : null,
        };

        if (this.isOwner) {
          data.timezone = this.form.timezone;
        }

        if (this.user) {
          await this.$store.dispatch('patientEditProfile', this.user.isOwner ? { data } : { id: this.user.id, data });
          await this.$store.dispatch('countriesFetch')
          await this.$addSuccess(this.user.isOwner ? this.$t('profileEditForm.form.update')  : this.$t('profileEditForm.beneficiary.update') );
          this.$emit('update');
        } else {
          const child = await this.$store.dispatch('patientCreateChild', {data});
          await this.$addSuccess(this.$t('profileEditForm.beneficiary.add'));
          this.$emit('create', child);
        }
      } catch (e) {
        if (!e.response?.json) {
          await this.$addError(this.$t('profileEditForm.error'));
          throw e;
        }
        e.response.json().then(
            body => {
              if (body.error_message) {
                this.$addError(this.$t('profileEditForm.error-message')  + body.error_message);
              } else {
                this.$addError(this.$t('profileEditForm.error'));
              }
            },
        );
        throw e;
      } finally {
        this.submitting = false;
      }
    },
    consentCheckbox(check) {
      this.acceptToBeContacted = check
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
@import "../../assets/grid";
.row {
  margin-bottom: 2rem;
  .checkbox {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .checkbox-container {
    margin-bottom: 1rem;
    @include onMobile {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
.error {
  color: #F46868;
  font-weight: bold;
  margin: 1rem 0;
}
.form-widget-inner label{
  padding: 0.8rem 0.5rem 0.5rem;
}
.form-widget{
  padding: 0;
}
.multiselect::v-deep {
  min-height: 33px;

  .multiselect__placeholder {
    color: #1F1F1F;
    font-size: 1.1em;
    font-weight: normal;
  }

  .multiselect__tags {
    border: none;
    min-height: 33px;
    padding: 3px 40px 0 8px;
    background: transparent;
  }

  .multiselect__option--highlight,
  .multiselect__tag {
    background: $successColor;
  }

  .multiselect__select {
    height: 28px;
  }

  .multiselect__option {
    padding: 9px 12px;
    font-size: 1em;
    min-height: 0;
  }

  .multiselect__tag-icon:hover {
    background: #F46868;
    border-radius: 0;
  }
}

::v-deep .multiselect__single {
  background: initial;
  font-weight: normal;
}

.container-avatar {
  position: relative;
  width: fit-content;
  &__logo-premium {
    position: absolute;
    top: 0;
    right: 12.5px;
  }
}

</style>
