var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _vm.patient.id
      ? _c("div", { staticClass: "profile-view" }, [
          _c("main", { staticClass: "container-body" }, [
            _c(
              "div",
              { staticClass: "profile" },
              [
                _c("SectionTitle", [
                  _vm._v(" " + _vm._s(_vm.$t("profileViewPage.title")) + " ")
                ]),
                _c("div", { staticClass: "userProfile" }, [
                  _c(
                    "div",
                    { staticClass: "userProfile__container-avatar" },
                    [
                      _c("UserAvatar", {
                        staticClass: "avatar",
                        attrs: {
                          size: _vm.$media.desktop ? "13rem" : "8rem",
                          src: _vm.avatarURL,
                          "user-name": _vm.patientFullName,
                          editable: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", [
                    _c("div", { staticClass: "name" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm._f("capitalize")(_vm.patient.firstName))
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm._f("uppercase")(_vm.patient.lastName))
                        )
                      ])
                    ])
                  ])
                ]),
                _c(
                  "ButtonGreen",
                  {
                    staticClass: "edit",
                    attrs: {
                      bold: "",
                      "link-to": "/profile/edit",
                      long: "",
                      small: ""
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("profileViewPage.profile-edit")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "infos" },
              [
                _c("SectionTitle", [
                  _vm._v(_vm._s(_vm.$t("profileViewPage.title-info")))
                ]),
                _vm.patient.ssn
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.idCard }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.patient.ssn) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.patient.birthDate
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.birthDate }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.patient.birthDate,
                                "DD/MM/YYYY"
                              )
                            ) +
                            " (" +
                            _vm._s(_vm._f("age")(_vm.patient.birthDate)) +
                            ")\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.patient.phone
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.mobile }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("phone")(_vm.patient.phone)) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.patient.email
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.mail }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.patient.email) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.patient.weight
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.weight }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.patient.weight) +
                            " kg\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.patient.timezone
                  ? _c(
                      "p",
                      { staticClass: "profile-label-text" },
                      [
                        _c("FontAwesomeIcon", {
                          staticClass: "profile-label-icon",
                          attrs: { icon: _vm.$icons.globe }
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.patient.timezone) +
                            "\n          "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }