import Pharmacies from "../../api/pharmacies_orders";

export default {
    state: {
        isPosting: false,
        isFetchingOne: false,
        current: null,
        isCancelling: false,
        isFinishing: false,
        checkout: null
    },
    mutations: {
        PHARMACIES_ORDER_CREATE(state) {
            state.isPosting = true;
        },
        PHARMACIES_ORDER_CREATE_SUCCESS(state, { pharmacy }) {
            state.current = pharmacy;
            state.isPosting = false;
        },
        PHARMACIES_ORDER_CREATE_FAIL(state) {
            state.isPosting = false;
        },
        PHARMACIES_ORDER_UPDATE(state) {
            state.isPosting = true;
        },
        PHARMACIES_ORDER_UPDATE_SUCCESS(state, { pharmacy }) {
            state.current = pharmacy;
            state.isPosting = false;
        },
        PHARMACIES_ORDER_UPDATE_FAIL(state) {
            state.isPosting = false;
        },
        PHARMACIES_ORDER_FETCH_ONE(state) {
            state.isFetchingOne = true;
        },
        PHARMACIES_ORDER_FETCH_ONE_SUCCESS(state, { pharmacy }) {
            state.current = pharmacy;
            state.isFetchingOne = false;
        },
        PHARMACIES_ORDER_FETCH_ONE_FAIL(state) {
            state.current = null;
            state.isFetchingOne = false;
        },
        PHARMACIES_ORDER_CANCEL(state) {
            state.isCancelling = true;
        },
        PHARMACIES_ORDER_CANCEL_SUCCESS(state) {
            state.isCancelling = false;
        },
        PHARMACIES_ORDER_CANCEL_FAIL(state) {
            state.isCancelling = false;
        },
        PHARMACIES_ORDER_FINISHED(state) {
            state.isFinishing = true;
        },
        PHARMACIES_ORDER_FINISHED_SUCCESS(state) {
            state.isFinishing = false;
        },
        PHARMACIES_ORDER_FINISHED_FAIL(state) {
            state.isFinishing = false;
        },
        PHARMACIES_ORDER_AVAILABLE(state){
            state.current = null;
            state.isFetchingOne = true;
        },
        PHARMACIES_ORDER_AVAILABLE_SUCCESS(state, { pharmacy }){
            state.current = pharmacy;
            state.isFetchingOne = false;
        },
        PHARMACIES_ORDER_AVAILABLE_FAIL(state){
            state.current = null;
            state.isFetchingOne = false;
        },
        PHARMACIES_STRIPE_CHECKOUT(state) {
            state.isPosting = true;
            state.checkout = null;
        },
        PHARMACIES_STRIPE_CHECKOUT_SUCCESS(state, {checkout}) {
            state.isPosting = false;
            state.checkout = checkout;
        },
        PHARMACIES_STRIPE_CHECKOUT_FAIL(state) {
            state.isPosting = false;
            state.checkout = null;
        },
    },
    actions: {
        async pharmaciesOrderCreate(
            {commit},
            {
                idFromProvider,
                noFinessEt,
                profileId,
                appointmentId,
                providerName,
                siret
            }) {
            commit('PHARMACIES_ORDER_CREATE');

            try {
                const pharmacy = await Pharmacies.create(
                    idFromProvider,
                    noFinessEt,
                    profileId,
                    appointmentId,
                    providerName,
                    siret
                );
                commit('PHARMACIES_ORDER_CREATE_SUCCESS', {pharmacy});
            } catch (e) {
                commit('PHARMACIES_ORDER_CREATE_FAIL');
                throw e;
            }
        },
    async pharmaciesOrderUpdate(
        {commit},
        {
            idFromProvider,
            noFinessEt,
            pharmacyOrderId,
            type,
            deliveryAddress,
            fullAddress,
            commentAddress,
            complete,
            paymentMethod,
            providerName,
            premium
        }) {
            commit('PHARMACIES_ORDER_UPDATE');
            try {
                const pharmacy = await Pharmacies.update(
                    idFromProvider,
                    noFinessEt,
                    pharmacyOrderId,
                    type,
                    deliveryAddress,
                    fullAddress,
                    commentAddress,
                    complete,
                    paymentMethod,
                    providerName,
                    premium
                );
                commit('PHARMACIES_ORDER_UPDATE_SUCCESS', {pharmacy});
            } catch (e) {
                commit('PHARMACIES_ORDER_UPDATE_FAIL');
                throw e;
            }
        },
        async pharmaciesOrderCheckout({commit}, {pharmacyOrderTransactionId, providerName}) {
            commit('PHARMACIES_STRIPE_CHECKOUT');
            try {
                const checkout = await Pharmacies.sessionGateway(pharmacyOrderTransactionId, providerName);
                commit('PHARMACIES_STRIPE_CHECKOUT_SUCCESS', {checkout});
            } catch (e) {
                commit('PHARMACIES_STRIPE_CHECKOUT_FAIL');
                throw e;
            }
        },
        async pharmaciesOrderCheckoutUpdate({commit}, {pharmacyOrderTransactionId, providerName}) {
            commit('PHARMACIES_STRIPE_CHECKOUT');
            try {
                const checkout = await Pharmacies.updateGateway(pharmacyOrderTransactionId, providerName);
                commit('PHARMACIES_STRIPE_CHECKOUT_SUCCESS', {checkout});
            } catch (e) {
                commit('PHARMACIES_STRIPE_CHECKOUT_FAIL');
                throw e;
            }
        },
        async pharmaciesOrderCancel({commit}, { pharmacyOrderId }){
            commit('PHARMACIES_ORDER_CANCEL');
            try {
                const pharmacy = await Pharmacies.cancel(pharmacyOrderId);
                commit('PHARMACIES_ORDER_CANCEL_SUCCESS', {pharmacy});
            } catch (e) {
                commit('PHARMACIES_ORDER_CANCEL_FAIL');
                throw e;
            }
        },
        async pharmacieOrderFinished({commit}, {pharmacyOrderId}){
            commit('PHARMACIES_ORDER_FINISHED');
            try {
                const pharmacy = await Pharmacies.finished(pharmacyOrderId);
                commit('PHARMACIES_ORDER_FINISHED_SUCCESS', {pharmacy});
            } catch (e) {
                commit('PHARMACIES_ORDER_FINISHED_FAIL');
                throw e;
            }
        },
        async pharmaciesOrderAvailable({commit}, {appointmentId}) {
            commit('PHARMACIES_ORDER_AVAILABLE');
            try {
                const pharmacy = await Pharmacies.available(appointmentId);
                commit('PHARMACIES_ORDER_AVAILABLE_SUCCESS', {pharmacy});
            } catch (e) {
                commit('PHARMACIES_ORDER_AVAILABLE_FAIL');
                throw e;
            }
        },
        async pharmaciesOrderFetchOne({ commit, dispatch }, { pharmacyOrderId }) {
            commit('PHARMACIES_ORDER_FETCH_ONE');
            try {
                const pharmacy = await Pharmacies.get(pharmacyOrderId);
                commit('PHARMACIES_ORDER_FETCH_ONE_SUCCESS', { pharmacy });
            } catch (e) {
                commit('PHARMACIES_ORDER_FETCH_ONE_FAIL');
                throw e;
            }
        }
    },

};