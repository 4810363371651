<!--
Emitted events:
- paid
-->
<template>
  <div class="appointment-panel-payment">
    <p>{{ $t('appointmentPanelPayment.message') }}</p>

    <form @submit.prevent="handleSubmit">
      <CreditCards v-model="selectedCardId" selectable />

      <div class="amount-detail">
        <div class="label">{{ $t('appointmentPanelPayment.amount.paid') }}</div>
        <div class="separator" />
        <div class="amount">{{ order.paid | money }}</div>
      </div>

      <div class="amount-detail">
        <div class="label">{{ $t('appointmentPanelPayment.amount.due') }}</div>
        <div class="separator" />
        <div class="amount">{{ order.total - order.paid | money }}</div>
      </div>

      <div class="total-amount">
        <h5>{{ $t('appointmentPanelPayment.amount.total') }}</h5>
        <p>{{ order.total | money }}</p>
      </div>

      <ButtonGreen :busy="busy" :disabled="!selectedCardId" type="submit">
        {{ $t('appointmentPanelPayment.amount.confirm') }}
      </ButtonGreen>
    </form>
  </div>
</template>

<script>
  import Transactions from '@/api/transactions';
  import ButtonGreen from '@/components/ButtonGreen';
  import CreditCards from '@/components/CreditCards';
  import { stripe } from '@/helpers/stripe';

  export default {
    name: 'AppointmentPanelPayment',
    components: { CreditCards, ButtonGreen },
    data() {
      return {
        busy: false,
        selectedCardId: null,
      };
    },
    computed: {
      order() {
        return this.$store.state.appointments.current.order;
      },
      currentTransaction() {
        return this.order.transactions.find(transaction => {
          if (transaction.stripePaymentMethodId !== this.selectedCardId) {
            return false;
          }

          return ['not_paid', 'not_captured', 'need_3ds'].includes(transaction.paymentStatus);
        });
      },
    },
    methods: {
      async handleSubmit() {
        this.busy = true;

        try {
          let transaction = this.currentTransaction;

          if (!transaction) {
            transaction = await Transactions.create(this.order.id, this.selectedCardId);
          }

          if (transaction.paymentStatus === 'paid') {
            return this.$emit('paid');
          }

          // Get the transaction from Stripe
          const { paymentIntent } = await stripe.retrievePaymentIntent(transaction.stripeClientSecret);

          // If transaction is invalid on Stripe, create a new one
          if (['requires_source', 'requires_payment_method'].includes(paymentIntent.status)) {
            transaction = await Transactions.create(this.order.id, this.selectedCardId);
          }

          const result = await stripe.handleCardAction(transaction.stripeClientSecret);

          if (result.error) {
            await this.$store.dispatch('appointmentsReloadCurrent');
            return this.$addError(result.error.message);
          }

          await Transactions.confirm(transaction.id);

          this.$emit('paid');
        } catch (e) {
          await this.$addError(this.$t('appointmentPanelPayment.error'));
          console.error(e);
        } finally {
          this.busy = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .amount-detail {
    display: flex;
    margin-bottom: 2rem;

    .label {
      margin-right: 2rem;
    }

    .separator {
      flex: 1;
      transform: translateY(-0.8rem);
      border-bottom: 2px dashed $hrColor;
    }

    .amount {
      margin-left: 2rem;
    }
  }

  .total-amount {
    text-align: right;

    p {
      font-size: 3rem;
      font-weight: 700;
      margin: 0 0 2rem;
    }
  }

  .credit-cards {
    margin-bottom: 2rem;
  }
</style>
