import Encounters from '../../api/encounters';

export default {
  state: {
    isFetchingOne: false,
    isRating: false,
    isSendingReport: false,
    current: null,
    isAuthorizing: false,
  },
  mutations: {
    ENCOUNTERS_FETCH_ONE(state) {
      state.current = null;
      state.isFetchingOne = true;
    },
    ENCOUNTERS_FETCH_ONE_SUCCESS(state, { encounter }) {
      state.isFetchingOne = false;
      state.current = encounter;
    },
    ENCOUNTERS_FETCH_ONE_FAIL(state) {
      state.isFetchingOne = false;
      state.current = null;
    },
    ENCOUNTERS_CREATE() {},
    ENCOUNTERS_CREATE_SUCCESS(state, { encounter }) {
      state.current = encounter;
    },
    ENCOUNTERS_CREATE_FAIL() {},
    ENCOUNTERS_CLEAR_CURRENT(state) {
      state.current = null;
    },
    ENCOUNTERS_RATE(state) {
      state.isRating = true;
    },
    ENCOUNTERS_RATE_SUCCESS(state) {
      state.isRating = false;
    },
    ENCOUNTERS_RATE_FAIL(state) {
      state.isRating = false;
    },
    ENCOUNTERS_AUTHORIZE(state) {
      state.isAuthorizing = true;
    },
    ENCOUNTERS_AUTHORIZE_SUCCESS(state, { updateCurrent }) {
      state.isAuthorizing = false;

      if (updateCurrent) {
        state.current.authorized = true;
      }
    },
    ENCOUNTERS_AUTHORIZE_FAIL(state, { status, secret }) {
      state.isAuthorizing = false;
      state.current.paymentStatus = status;
      state.current.paymentValidationSecret = secret ;
    },
  },
  actions: {
    async encountersFetchOne({ commit }, { id, abortSignal }) {
      commit('ENCOUNTERS_FETCH_ONE');
      try {
        const encounter = await Encounters.getOne(id, abortSignal);
        commit('ENCOUNTERS_FETCH_ONE_SUCCESS', { encounter });
      } catch (e) {
        commit('ENCOUNTERS_FETCH_ONE_FAIL');
        throw e;
      }
    },
    async encountersCreate({ commit }, { appointmentId, practitionerId, productId }) {
      commit('ENCOUNTERS_CREATE');
      try {
        const encounter = await Encounters.create(appointmentId, practitionerId, productId);
        commit('ENCOUNTERS_CREATE_SUCCESS', { encounter });
      } catch (e) {
        commit('ENCOUNTERS_CREATE_FAIL');
        throw e;
      }
    },
    async encountersRate({ commit }, { id, rate }) {
      commit('ENCOUNTERS_RATE');
      try {
        await Encounters.rate(id, rate);
        commit('ENCOUNTERS_RATE_SUCCESS');
      } catch (e) {
        commit('ENCOUNTERS_RATE_FAIL');
        throw e;
      }
    },
    async encountersClearCurrent({ commit }) {
      commit('ENCOUNTERS_CLEAR_CURRENT');
    },
    async encountersAuthorize({ commit }, { id, stripePaymentMethodId, updateCurrent = false }) {
      commit('ENCOUNTERS_AUTHORIZE');

      try {
        const encounter = await Encounters.authorize(id, stripePaymentMethodId);

        if (encounter.authorized) {
          commit('ENCOUNTERS_AUTHORIZE_SUCCESS', { updateCurrent });
        } else {
          commit('ENCOUNTERS_AUTHORIZE_FAIL', {
            status: encounter.paymentStatus,
            secret: encounter.paymentValidationSecret,
          });
        }
      } catch (e) {
        commit('ENCOUNTERS_AUTHORIZE_FAIL');
      }
    },
  },
};
