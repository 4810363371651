<template>
  <AppLayout>
    <AppProgress :current="5" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />
    <main class="container">
      <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
      <SectionTitle>Avez-vous déjà consulté avec ce praticien ?</SectionTitle>
      <span class="description">Renseignez le motif précis de votre demande de rendez-vous.</span>

      <div class="first-time">
        <FormRadio
            @change="consentCheckbox(true)"
            :checked="this.firstTimeWithPractitioner"
        >
          Non
        </FormRadio>
        <FormRadio
            @change="consentCheckbox(false)"
            :checked="!this.firstTimeWithPractitioner"
        >
          Oui
        </FormRadio>
      </div>

      <div class="buttons">
        <ButtonTransparent
            :icon="$icons.chevronLeft"
            icon-to-left
            @click="$router.back()"
        >
          {{ $t('newAppointmentReasonPage.back')}}
        </ButtonTransparent>
        <ButtonGreen
            :icon="$icons.chevronRight"
            rounded
            type="submit"
            @click="handleFirstTime(true)"
        >
          {{ $t('newAppointmentReasonPage.continue') }}
        </ButtonGreen>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from '../components/AppLayout';
import SectionTitle from '../components/SectionTitle';
import ButtonTransparent from '../components/ButtonTransparent';
import ButtonGreen from '../components/ButtonGreen';
import AppProgress from '../components/AppProgress';
import { minLength, required } from '@byscripts/vuelidate/lib/validators';
import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
import FormRadio from "@/components/Form/FormRadio.vue";
import { dateAppointment } from "@/date";

export default {
  name: 'new-appointment-first-time',
  components: {
    FormRadio,
    RecapTitle,
    AppProgress,
    ButtonGreen,
    ButtonTransparent,
    SectionTitle,
    AppLayout,
  },
  data() {
    return {
      reason: '',
      firstTimeWithPractitioner: true
    };
  },
  validations: {
    reason: {
      required: required,
      minLength: minLength(5),
    },
  },
  async beforeMount() {
    if (this.$store.state.newAppointment
        && null === this.$store.state.newAppointment.product) {
      return this.$router.push({ name: 'new-appointment-search' });
    }
  },
  computed: {
    practitioner() {
      return this.$store.state.newAppointment.practitioner;
    },
    newAppointment() {
      return this.$store.state.newAppointment;
    },
  },
  methods: {
    dateAppointment,
    async handleFirstTime() {
      if (this.firstTimeWithPractitioner && !this.practitioner.instantEncountersEnabled) {
        return await this.$addError(this.$t('newAppointmentFirstTime.error'), false, 5000);
      }
      await this.$store.commit('NEW_APPOINTMENT_FIRST_TIME', this.firstTimeWithPractitioner);
      await this.goToNextStep();
    },
    async goToNextStep() {
      await this.$router.push({ name: 'new-appointment-price-product' });
    },
    consentCheckbox(check) {
      this.firstTimeWithPractitioner = check
    }
  },
};
</script>

<style lang="scss" scoped>
main {
  margin: 0 auto;
}

.main-container {
  margin-top: 1rem;
}
main.container {
  @include onMobile {
    margin: 3rem auto 8rem;
    padding: 0 3rem;
    display: block;
  }
}

form {
  font-size: 1.2rem;
}

.section-title {
  margin-bottom: 0;
}

.description {
  @include onMobile {
    font-size: 1.3rem;
  }
  color: #0000007B;
  font-weight: 500;
  display: flex;
  margin-bottom: 3rem;
}

.information {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 2px dashed $textColor;
  display: flex;
  align-items: center;

  img {
    height: inherit;
    margin-right: 1rem;
  }
}

.buttons {
  @include onMobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    justify-content: space-evenly;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }
  background: white;
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}

.form-widget::v-deep .form-widget-inner {
  min-height: 150px;
  background: white;
  border: 1px solid #00000048;
  border-radius: 9px;
}

.reason-label {
  font-weight: bold;
  color: #0000007B;
}

.first-time {
  display: flex;
  flex-direction: row;
  gap: 10rem;
}

.form-radio::v-deep {
  flex-direction: column-reverse;
  align-items: center;
  gap: 1rem;
  .radio {
    margin-right: 0;
  }
}
</style>