<template>
  <div :class="[isCalling ? 'show-navigation' : '']">
    <nav
        class="app-navigation"
        :class="{ expanded, desktop }"
        v-on="desktop ? { mouseenter: expandNavigation, mouseleave: shrinkNavigation } : {}"
    >
      <header class="mobile-header" v-if="!desktop">
        <FontAwesomeIcon
            :icon="toggleIcon"
            class="toggle"
            @click="toggleNavigation"
        />
        <AppLogoMobile white />
        <span class="spacer" />
      </header>
      <AppNavigationItem :label="$t('appNavigation.new-appointment')" to="/new-appointment" :icon="$icons.clock" />
      <AppNavigationItem :label="$t('appNavigation.profile')" to="/profile/view" :icon="$icons.user" />
      <AppNavigationItem :label="$t('appNavigation.appointments')" to="/appointments" :icon="$icons.calendar" />
      <AppNavigationItem :label="$t('appNavigation.constantes')" to="/constantes" :icon="$icons.heartBeat" />
      <AppNavigationItem
          :label="$t('appNavigation.help')"
          to="https://support.hellocare.com/fr/"
          :icon="$icons.info"
          external
      />
      <AppNavigationItem :label="$t('appNavigation.logout')" to="/logout" :icon="$icons.logout" />
<!--      <div class="premium" :class="{ expanded, desktop }" v-if="!isPremium">
        <h3 class="premium__title">{{ $t('appNavigation.title-premium') }}</h3>
        <div class="premium__modal">
          <AppLogoPremium class="premium__logo-premium" xmedium/>
          <router-link :to="{ name: 'profile-status' }" class="premium__link">
            <p class="premium__content"><span>{{ $t('appNavigation.description-1') }}</span><br>
              {{ $t('appNavigation.description-2') }}</p>
          </router-link>
        </div>
      </div>-->
    </nav>

  </div>
</template>

<script>
  import AppNavigationItem from './AppNavigationItem';
  import AppNavigationItemChild from './AppNavigationItemChild';
  import AppLogo from './AppLogo';
  import AppLogoMobile from "@/components/AppLogoMobile.vue";
  import AppLogoPremium from "@/components/AppLogoPremium.vue";
  export default {
    name: 'AppNavigation',
    components: {
      AppLogoMobile,
      AppLogo,
      AppNavigationItemChild,
      AppNavigationItem,
      AppLogoPremium,
    },
    props: {
      desktop: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      expanded() {
        return this.$store.state.appNavigation.isExpanded;
      },
      toggleIcon() {
        return this.toggled
          ? this.$icons.navigationToggleOff
          : this.$icons.navigationToggle;
      },
      isPremium() {
        return this.$store.state.patients.profile.premium;
      },
      isStepCalling() {
        if(typeof this.$store.state.sdk !== 'undefined')
          return this.$store.state.sdk.step;
      },
      isCalling() {
        return typeof this.$store.state.sdk !== 'undefined' && (this.isStepCalling === 'CALLING' && this.$media.desktop)
      },
    },
    methods: {
      toggleNavigation() {
        this.$store.dispatch('appNavigationToggle');
      },
      expandNavigation() {
        if(this.desktop) {
          this.$store.dispatch('appNavigationExpand');
        }
      },
      shrinkNavigation() {
        if (this.desktop) {
          this.$store.dispatch('appNavigationShrink');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 60px;
    padding-top: 0;
    padding-bottom: 6rem;
    transition: height 0.475s ease-out 0s;
    overflow-y: hidden;
    background: linear-gradient(145deg, #F46868, #dd5269);
    font-size: 1.5rem;
    z-index: 100;
    font-family: $titleFont;
    line-height: 7.4rem;
    display: flex;
    flex-direction: column;

    &.desktop, &.expanded {
      height: 100vh;
      overflow-y: auto;
    }

    &.desktop {
      display: flex;
      flex-direction: column;
      width: 40rem;
      padding-top: 8rem;
      padding-bottom: 0;
      transform: translate3d(-32.6rem, 0px, 0px);
      transition: transform 0.475s ease-out 0s;
      background: $gradientBackground;

      &.expanded {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .toggle {
      width: 32px;
      text-align: center;
      color: #ffffff;
      margin-left: 1rem;
      cursor: pointer;
      font-size: 2rem;
    }

    .spacer {
      width: 32px;
    }
  }
  .nav-item {
    position: relative;
  }

  .premium {
    width: 100%;
    bottom: 15rem;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 17.75px;
    line-height: normal;
    flex-grow: 1;
    justify-content: flex-end;
    margin-bottom: 4rem;

    &.desktop {
      width: 40rem;
      margin-bottom: 8rem;
      transform: translate3d(-36.6rem, 0px, 0px);
      transition: transform 0.475s ease-out 0s;
    }
    &.expanded {
      transform: translate3d(0, 0, 0);
    }
    &__title {
      color: white;
      font-weight: bold;
      font-size: 2rem;
    }
    &__logo-premium {
      top: -17.75px;
      left: -17.75px;
      position: absolute;    }
    &__modal {
      background: white;
      width: 30rem;
      border-radius: 1.8rem;
      position: relative;
      @include onMobile {
        width: auto;
      }
    }
    &__link {
      display: block;
      width: 100%;
      margin: 0;
    }
    &__content {
      margin: 0;
      padding: 0.7rem 2rem;
      text-align: center;
      color: black;
      font-size: 1.5rem;
      font-weight: bold;
    }
    span {
      color: #F46868;
      font-size: 1.6rem;
    }
  }
  .show-navigation {
    display: none;
  }
</style>
