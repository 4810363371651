var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    { attrs: { hidden: _vm.hidden } },
    [
      _c("AppProgress", {
        attrs: {
          current: 7,
          total: 8,
          position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
        }
      }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("RecapTitle", {
            attrs: {
              title: _vm.practitioner.title + " " + _vm.practitioner.lastName,
              date: _vm.dateAppointment(this.newAppointment.startAt)
            }
          }),
          _c("SectionTitle", [
            _vm._v(
              " " + _vm._s(_vm.$t("newAppointmentPaymentPage.title")) + " "
            )
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("newAppointmentPaymentPage.description")) +
                " "
            )
          ]),
          _vm.cardPaymentLoader && (!_vm.hasPaymentMethod || _vm.isAddingCard)
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "card-accepted" },
                    [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("newAppointmentPaymentPage.list-card")
                            ) +
                            " "
                        )
                      ]),
                      _c("CreditCardsPictograms", { attrs: { small: "" } })
                    ],
                    1
                  ),
                  _c("CreditCardAddForm", {
                    attrs: { shouldSave: _vm.shouldSave },
                    on: {
                      close: _vm.returnMainPaymentPage,
                      create: function($event) {
                        return _vm.goToSummaryPage(true)
                      },
                      change: _vm.handleSelect,
                      ignore: _vm.goToSummaryPage
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "check-card-registered",
                          fn: function() {
                            return [
                              _c(
                                "FormCheckbox",
                                {
                                  staticClass: "card-registered",
                                  on: { change: _vm.handleClick }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "newAppointmentPaymentPage.accepted-registered-card"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "submit-button",
                          fn: function() {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("newAppointmentPaymentPage.continue")
                                  ) +
                                  " "
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "cancel-button",
                          fn: function() {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("newAppointmentPaymentPage.return")
                                  ) +
                                  " "
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2642433511
                    )
                  })
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.hasPaymentMethod
                    ? _c("div", { staticClass: "container-slide" }, [
                        _c(
                          "div",
                          { staticClass: "select-card" },
                          [
                            _vm._l(_vm.cards, function(card) {
                              return _c(
                                "Card",
                                {
                                  class: {
                                    selected:
                                      _vm.selectedCard &&
                                      _vm.selectedCard.id === card.id
                                  },
                                  attrs: { "card-slide": true }
                                },
                                [
                                  _c("CreditCards", {
                                    staticClass: "card-slide",
                                    attrs: {
                                      cards: _vm.cards,
                                      card: card,
                                      title: _vm.$t(
                                        "newAppointmentPaymentPage.creditCard.title"
                                      ),
                                      col: true
                                    },
                                    on: { change: _vm.handleSelect }
                                  })
                                ],
                                1
                              )
                            }),
                            _vm.$media.desktop
                              ? _c("Card", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "add-card",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.handleCardRegistered(true)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n             " +
                                          _vm._s(
                                            _vm.$t(
                                              "newAppointmentPaymentPage.add-card"
                                            )
                                          ) +
                                          "\n             "
                                      ),
                                      _c("CreditCardsPictograms")
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm.busy
                    ? _c("FontAwesomeIcon", {
                        directives: [{ name: "else", rawName: "v-else" }],
                        attrs: {
                          icon: _vm.busy ? _vm.$icons.spinner : _vm.icon,
                          spin: _vm.busy,
                          fixedWidth: ""
                        }
                      })
                    : _vm._e(),
                  _vm.$media.mobile
                    ? _c(
                        "a",
                        {
                          staticClass: "add-card",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleCardRegistered(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "newAppointmentPaymentPage.add-card-mobile"
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "infos" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "newAppointmentPaymentPage.information-payment"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _c(
                        "ButtonTransparent",
                        {
                          attrs: {
                            icon: _vm.$icons.chevronLeft,
                            "icon-to-left": ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$router.back()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("newAppointmentReasonPage.back")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "ButtonGreen",
                        {
                          attrs: {
                            icon: _vm.$icons.chevronRight,
                            rounded: "",
                            type: "submit"
                          },
                          on: { click: _vm.goToSummaryPage }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("newAppointmentReasonPage.continue")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }