<template>
  <AppLayout>
    <div class="click_collect_container">
      <div class="w-100">
        <div class="p-5">
          <BreadCrumb :pages="breadCrumbPages()"/>
        </div>
      </div>
      <div class="delivery-block d-flex justify-content-center align-items-center flex-column p-5" v-if="pharmacyOrder">
        <div class="p-5">
          <img alt="handshake" src="../../assets/images/picto_handShake.svg" />
        </div>
        <div class="row">
          <div class="col _6 left py-5 delivery__form">
            <p v-if="pharmacyOrder.delivery_address" class="col mb-3"><strong>{{$t('pharmacyOrder.address-page.enter-other-address')}}</strong></p>
            <p v-else class="col mb-3"><strong>{{$t('pharmacyOrder.address-page.enter-address')}}</strong></p>
            <form class="address_form" @submit.prevent="handleSubmit">
              <div class="d-flex mb-3">
                  <FormInput
                    id="number"
                    v-model="form.number"
                    class="mb-3 col _12"
                    label="Numéro"
                    type="number"
                  />
              </div>
              <div class="d-flex mb-3">
                <FormInput id="address" label="Adresse" class="mb-3 col _12" :class="hasValue ? 'has-value' : ''">
                  <template #input>
                    <input type="text"  ref="inputAddress" v-model="form.address" placeholder="" />
                  </template>
                  <template v-if="$v.form.address.$error" #error>
                    Vous devez entrer une adresse
                  </template>
                </FormInput>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <FormInput id="postalCode" v-model="form.postalCode" class="mb-3 col _6"  label="Code postal"/>
                <FormInput id="country" v-model="form.city" class="mb-3 col _6" label="Ville"/>
              </div>
              <div class="d-flex mb-3">
                <FormInput id="postalCode" v-model="form.country" label="Pays" class="col _12" />
              </div>
              <div>
                <p class="col mt-4 mb-3"><strong>{{$t('pharmacyOrder.address-page.delivery-comment')}}</strong></p>
                <FormInput
                    id="comment"
                    v-model="form.comment"
                    label="Les indications pour le livreur"
                />
              </div>
              <div>
                <p class="col mt-4 mb-3"><strong>{{$t('pharmacyOrder.address-page.ssn')}}</strong></p>
                <FormInput
                    id="comment"
                    v-model="form.ssn"
                    :label="$t('profileEditForm.form.ssn.label')"
                >
                  <template v-if="$v.form.ssn.$error" #error>
                    {{ $t('profileEditForm.form.ssn.invalid') }}
                  </template>
                </FormInput>
              </div>
              <div class="d-flex col py-5">
                <ButtonGreen
                    :busy="submitting"
                    :icon="$icons.chevronRight"
                    small
                    type="submit"
                >
                 Valider
                </ButtonGreen>
              </div>
            </form>
            <p v-if="maximumMessage" class="maximum__message p-4" v-html="$t('pharmacyOrder.address-page.delivery-distance')"></p>
          </div>
          <div class="col-separator"></div>
          <div class="col _6 right  py-5">
              <div class="delivery_recap row">
                <p class="subtitle">{{$t('pharmacyOrder.address-page.subtitle-one')}}</p>
                <div v-if="submitting" class="text-center py-5">
                  <Spinner color="green"/>
                </div>
                <div v-else>
                  <p class="address" v-if="pharmacyOrder.delivery_address&&pharmacyOrder.delivery_address!=='false'">{{ pharmacyOrder.delivery_address }}</p>
                  <p class="me_phone" v-if="profile.email">{{profile.email}}</p>
                  <p class="me_phone" v-if="profile.phone">{{profile.phone}}</p>
                  <p class="me_ssn" v-if="profile.ssn">Numéro de sécurité sociale : {{profile.ssn}}</p>
                </div>
                <p class="subtitle col-form-label pt-4">{{$t('pharmacyOrder.address-page.subtitle-two')}}</p>
                <div v-if="submitting" class="text-center py-5">
                  <Spinner color="green"/>
                </div>
                <div v-else>
                  <p class="rs" v-if="pharmacyOrder.pharmacy.rs">{{ pharmacyOrder.pharmacy.rs }} </p>
                  <p class="ph_phone" v-if="pharmacyOrder.pharmacy.telephone">{{ pharmacyOrder.pharmacy.telephone }} </p>
                  <p class="ph_phone" v-if="pharmacyOrder.pharmacy.voie&&pharmacyOrder.pharmacy.num_voie&&pharmacyOrder.pharmacy.typ_voie"> {{ pharmacyOrder.pharmacy.num_voie }} {{ pharmacyOrder.pharmacy.typ_voie}} {{ pharmacyOrder.pharmacy.voie}} </p>
                  <p class="ph_phone" v-if="pharmacyOrder.pharmacy.ligne_acheminement">{{ pharmacyOrder.pharmacy.ligne_acheminement }} </p>
                  <hr />
                  <div class="price" v-if="pharmacyOrder.transaction&&pharmacyOrder.transaction.amount!=='false'">
<!--                    <div v-if="isPremium">
                      <div v-if="amountSlotDelivery>=1">
                        <p>Vous avez déjà utilisé votre livraison gratuite ce mois-ci !</p>
                      </div>
                      <FormCheckbox v-else-if="amountSlotDelivery<1" v-model="checkSlot" :checked="checkSlot">
                        Utilisez votre livraison gratuite premium
                      </FormCheckbox>
                    </div>-->
                    <div>
                      <p v-if="checkSlot">Gratuite</p>
                      <p v-else><strong>{{ pharmacyOrder.transaction.amount }} €</strong> </p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div
          class="py-5 text-center mb-5"
          v-if="pharmacyOrder.provider.steps.step_payment
            && pharmacyOrder.delivery_address
            && (pharmacyOrder.transaction.amount !== 'false'
              || pharmacyOrder.provider.name === 'phacil')
          "
        >
          <ButtonGreen :busy="submitting" @click="nextPage">{{$t('pharmacyOrder.address-page.btn-next-step')}}</ButtonGreen>
        </div>
        <div class="py-5 text-center mb-5" v-else-if="pharmacyOrder.delivery_address">
          <ButtonGreen :busy="submitting" @click="nextPage">{{$t('pharmacyOrder.address-page.btn-next-step')}}</ButtonGreen>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>

import AppLayout from "../../components/AppLayout";
import ButtonGreen from "../../components/ButtonGreen";
import AutoHeightTextarea from "../../components/Form/AutoHeightTextarea";
import FormInput from "../../components/Form/FormInput";
import { Loader } from "@googlemaps/js-api-loader";
import ButtonGrey from "../../components/ButtonGrey";
import BreadCrumb from "../../components/Pharmacy/BreadCrumb";
import Spinner from "../../components/Pharmacy/Spinner";
import { required } from 'vuelidate/lib/validators/index.js';
import { checkSsn } from '@/helpers/validation.js';
import FormCheckbox from '@/components/Form/FormCheckbox.vue'
import {mapGetters} from "vuex";


export default {
  name: 'PharmacyOrderAddressPage',
  components: {Spinner, BreadCrumb, ButtonGrey, ButtonGreen, FormInput, AppLayout, AutoHeightTextarea, FormCheckbox},
  computed: {
    profile() {
      return this.$store.state.patients.profile;
    },
    isPremium() {
      return this.profile.premium
    },
    ...mapGetters(['totalSlots'])
  },
  data() {
    return {
      submitting: false,
      google: null,
      searchbox: null,
      pharmacyOrder:null,
      maximumMessage: false,
      checkSlot: false,
      amountSlotDelivery: null,
      hasValue: false,
      form: {
        number: null,
        address: null,
        postalCode: null,
        city: null,
        country: null,
        comment: null,
        ssn: null,
      },
    }
  },
  validations: {
    form: {
      ssn: {
        required,
        format: checkSsn,
      },
      address: {
        required
      }
    },
  },
  async mounted() {
    this.setPharmacyOrder();
    this.redirectToHomeIfNoAppointment();
    this.redirectToHomeIfNoPharmacyOrder();
    this.setCurrentAddress();
    const googleMapApi = await new Loader({
      apiKey: this.$gMapApiKey,
      libraries: ["places"]
    });
    this.google = await googleMapApi.load();
    this.initSearchBox();
    this.addAddressOnInput();
    this.initFormData();
    this.loadData();
  },
  methods: {
    availableSlot(typeSlot) {
      if (this.totalSlots) {
        return this.totalSlots.filter(type => type === typeSlot).length
      }
    },
    async loadData() {
      await this.$store.dispatch('appLoaderShow');
      try {
        await Promise.all([
          await this.$store.dispatch('getSlotAll')
        ]);
      } catch (e) {
        await this.$addError('Une erreur s\'est produite lors du chargement de vos avantages restant.');
        throw e;
      } finally {
        this.amountSlotDelivery = await this.availableSlot("delivery_premium")
        this.amountSlotConciergerie = await this.availableSlot("conciergerie_premium")

        await this.$store.dispatch('appLoaderHide');
      }
    },
    setCurrentAddress() {
      if (!this.pharmacyOrder.delivery_address) {
        this.postalCode = this.profile.postalCode;
        this.address = this.profile.address;
        this.city = this.profile.city;
        this.country = this.profile.country;
      }
    },
    initFormData() {
      this.form.address = this.profile?.address
      this.form.postalCode = this.profile?.postalCode
      this.form.city = this.profile?.city
      this.form.country = this.profile?.country
      this.form.comment = this.profile?.comment
      this.form.ssn = this.profile?.ssn
    },
    redirectToHomeIfNoAppointment() {
      if (typeof localStorage.getItem('currentOrderAppointment') !== 'string') {
        this.$router.push({name: 'home'});
        this.$addError("Le rendez-vous n'existe pas.");
      }
    },
    redirectToHomeIfNoPharmacyOrder() {
      if (typeof JSON.parse(localStorage.getItem('currentPharmacyOrder')) !== 'object') {
        this.$router.push({name: 'home'});
        this.$addError("La commande n'existe pas.");
      }
    },
    setPharmacyOrder() {
      this.pharmacyOrder = JSON.parse(localStorage.getItem('currentPharmacyOrder'));
    },
    initSearchBox() {
      const input = this.$refs.inputAddress;
      this.searchBox = new this.google.maps.places.Autocomplete(input, {
        componentRestrictions: {
          country: [
            "fr",
            "gp",
            "mq",
            "gf",
            "re",
            "pm",
            "yt",
            "nc",
            "pf",
            "mf",
            "tf"
          ]
        }
      });
    },
    addAddressOnInput() {
      this.searchBox.addListener("place_changed", async () => {
        this.form.address = '';
        const places = this.searchBox.getPlace();

        places.address_components.map(address => {
          address.types.map(type => {
            if (type === 'street_number') {
              this.form.number = parseInt(address.long_name);
            }
            if (type === 'route') {
              this.form.address += address.long_name;
            }
            if (type === 'locality') {
              this.form.city = address.long_name;
            }
            if (type === 'postal_code') {
              this.form.postalCode = address.long_name;
            }
            if (type === 'country') {
              this.form.country = address.long_name;
            }
          })
        });

        this.hasValue = true;
      });
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }
      this.submitting = true;
      this.maximumMessage = false;

      if (this.form.ssn !== this.profile.ssn) {
        let data = {ssn: this.form.ssn}
        await this.$store.dispatch(
            'patientEditProfile', {data}
        ).catch(async (error) => {
          this.$addError("Erreur lors de l'ajout du numéro de sécurité sociale.");
          this.submitting = false;
          throw error;
        })
      }

      let fullAddress = this.form.number
          + ' ' + this.form.address
          + ' ' + this.form.postalCode
          + ' ' + this.form.city
          + ' ' + this.form.country;

      await this.$store.dispatch('pharmaciesOrderUpdate', {
        pharmacyOrderId: this.pharmacyOrder.id,
        fullAddress: {
          'number': this.form.number,
          'postalCode': this.form.postalCode,
          'address': this.form.address,
          'city': this.form.city,
          'country': this.form.country,
          'comment': this.form.comment,
        },
        deliveryAddress: fullAddress,
        complete: false,
        providerName: this.pharmacyOrder.provider.name
      }).catch(async (error) => {
        this.$addError("Erreur lors de l'ajout de l'adresse.");
        this.submitting = false;
        throw error;
      })

      const pharmacyOrderCurrent = this.$store.state.pharmacies_order.current

      if (pharmacyOrderCurrent.provider.name === 'phacil'
        && pharmacyOrderCurrent.delivery_address !== 'false'
        || (pharmacyOrderCurrent.transaction
          && pharmacyOrderCurrent.transaction.amount !== 'false')
      ) {
        localStorage.setItem('currentPharmacyOrder', JSON.stringify(this.$store.state.pharmacies_order.current));
        this.pharmacyOrder = pharmacyOrderCurrent;
        this.$addSuccess("Adresse prise en compte.");
        this.submitting = false;
      } else {
        if (pharmacyOrderCurrent.delivery_address === 'false') {
          this.$addError("L'adresse renseignée est invalide.");
          this.submitting = false;
          throw this.pharmacyOrder;
        }
        if (pharmacyOrderCurrent.transaction
            && pharmacyOrderCurrent.transaction.amount === 'false'
        ) {
          this.$addError("L'adresse renseignée est trop éloignée de la pharmacie.");
          this.maximumMessage = true
          this.submitting = false;
          throw this.pharmacyOrder;
        }
      }

      this.submitting = false;
    },
    nextPage() {
      const currentOrder = JSON.parse(localStorage.getItem('currentPharmacyOrder'));

      if (currentOrder.provider.steps.step_payment
        && !currentOrder.transaction.amount
      ) {
        this.$addError("Aucun prix n'est attribué à la commande.");
      }

      if (!currentOrder.delivery_address) {
        this.$addError("Aucune adresse n'est attribué à la commande.");
      }

      if (currentOrder.provider.name === 'phacil') {
        return this.goToSuccess();
      }

      if (currentOrder.transaction.amount && currentOrder.delivery_address) {
        if(this.pharmacyOrder.provider.steps.step_payment) {
          return this.$router.push({name: 'pharmacy-order-payment'});
        } else {
          return this.$router.push({name: 'pharmacy-success'});
        }
      }
    },
    async goToSuccess() {
      let statusCode = 201;
      this.submitting = true;

      await this.$store.dispatch('pharmaciesOrderUpdate', {
        pharmacyOrderId: this.pharmacyOrder.id,
        providerName: this.pharmacyOrder.provider.name,
        complete: true,
        premium: true
      }).catch((error) => {
        statusCode = error.response.status
        this.$addError("Erreur lors de la mise à jour de la commande.");
        throw error;
      });

      if (statusCode < 400) {
        this.submitting = false;
        const data = {
          patient: this.profile.id,
          pharmacyOrderId: this.pharmacyOrder.id,
          type: "delivery_premium",
          sum: this.pharmacyOrder.provider.name !== 'phacil' ? this.pharmacyOrder.transaction.amount : 0
        };

        if (this.isPremium && this.pharmacyOrder.provider.name !== 'phacil') {
          await this.$store.dispatch('newSlotCreate', {data});
        }

        return this.$router.push({name: 'pharmacy-success', params: {pharmacyrs: this.pharmacyOrder.pharmacy.rs}});
      }
    },
    goToPayment() {
        let currentOrder = JSON.parse(localStorage.getItem('currentPharmacyOrder'));
        if (!currentOrder.transaction.amount) {
            this.$addError("Aucun prix n'est attribué à la commande.");
        }
        if (!currentOrder.delivery_address) {
            this.$addError("Aucune adresse n'est attribué à la commande.");
        }
        if (currentOrder.transaction.amount && currentOrder.delivery_address) {
            return this.$router.push({name: 'pharmacy-order-payment'});
        }
    },
    breadCrumbPages() {
      return [
        {
          'name': 'Choix de la pharmacie',
          'url': 'pharmacy-selection',
          'current': false
        },
        {
          'name': 'Livraison ou click & collect ?',
          'url': 'pharmacy-order-type',
          'current': false
        },
        {
          'name': "Choix de l'adresse",
          'url': '',
          'current': true
        },
        {
          'name': "Paiement de la livraison",
          'url': '',
          'current': false
        }
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
.delivery_recap{
  hr{
    background: $lightGrey;
  }
  text-align: left;
  .subtitle {
    font-weight: bold;
    color: black;
  }
  .price{
    display: flex;
    gap: 2rem;
    justify-content: end;
    text-align: right;
    font-weight: bold;
    align-items: center;
    flex-wrap: wrap;
  }
  .address{
    font-weight: bold;
  }
}

.delivery-block{
  max-width: 1024px;
  width: 100%;
  margin: auto;
  .col{
    margin: 0;
  }
}
.delivery__form{
  min-width: fit-content;
}
.address-button{
  text-align: center;
  button,a{
    width: 300px;
    margin: 0 15px 30px 15px;
  }
}
.col-separator {
  width: 1px;
  margin: 0 5rem;
  padding: 0;
  background-color: $lightGrey;
}
.maximum__message{
  word-break: break-word;
  background: rgba(244, 102, 102, 0.7);
  border-radius: 5px;
}
</style>