<template>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <div class="row">
          <div class="col _7">
            <GroupTitle :icon="$icons.medicalHistory"> {{ $t('profileHealthPage.medical-history') }} </GroupTitle>

            <div class="row">
              <FormInput
                id="medical-history"
                v-model="form.medicalHistory"
                class="col _12"
                :label="$t('profileHealthPage.medical-history')"
                type="textarea"
              />
            </div>

            <div class="row">
              <FormInput
                id="surgical-history"
                v-model="form.surgicalHistory"
                class="col _12"
                :label="$t('profileHealthPage.surgical-history')"
                type="textarea"
              />
            </div>

            <GroupTitle :icon="$icons.allergies">{{ $t('profileHealthPage.allergies') }}</GroupTitle>

            <div class="row">
              <FormInput
                id="allergies"
                v-model="form.allergies"
                class="col _12"
                :label="$t('profileHealthPage.label-allergies')"
                type="textarea"
              />
            </div>

            <GroupTitle :icon="$icons.treatment">{{ $t('profileHealthPage.treatment') }}</GroupTitle>

            <div class="row">
              <FormInput
                id="treatment"
                v-model="form.treatment"
                class="col _12"
                :label="$t('profileHealthPage.label-treatment')"
                type="textarea"
              />
            </div>
          </div>
          <div class="col-separator" />
          <div class="col _5">
            <GroupTitle :icon="$icons.biometrics">{{ $t('profileHealthPage.biometrics') }}</GroupTitle>

            <div class="row">
              <FormInput id="weight" v-model="form.weight" class="col _12" :label="$t('profileHealthPage.weight')" />
            </div>

            <div class="row">
              <FormInput id="height" v-model="form.height" class="col _12" :label="$t('profileHealthPage.height')" />
            </div>

            <div class="row">
              <FormSelect id="blood-group" v-model="form.bloodGroup" class="col _12" :label="$t('profileHealthPage.blood-group')">
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
              </FormSelect>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import FormInput from '@/components/Form/FormInput';
  import FormSelect from '@/components/Form/FormSelect';
  import FormWidget from '@/components/Form/FormWidget';
  import GroupTitle from '@/components/GroupTitle';
  import ProfileTabBar from '@/components/ProfileTabBar';

  export default {
    name: 'ProfileHealthPage',
    components: { ProfileTabBar, FormSelect, FormInput, FormWidget, AutoHeightTextarea, GroupTitle, AppLayout },
    data() {
      return {
        form: {
          medicalHistory: null,
          surgicalHistory: null,
          allergies: null,
          treatment: null,
          weight: null,
          height: null,
          bloodGroup: null,
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .row {
    margin-bottom: 2rem;
  }

  .col-separator {
    width: 1px;
    margin: 0 5rem;
    background-color: $lightGrey;
  }
</style>
