import { normalizeConnectAppointment, normalizeConnectAppointments } from '@/helpers/normalizers';
import api from './';

export default {
  getOne: (id, abortSignal, token) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
    }
    return api.get(`appointments/${id}`, {
      signal: abortSignal,
      searchParams,
    }).json().then(normalizeConnectAppointment);
  },
  getAll: (page = 1) => {
    const count = 12;
    const searchParams = new URLSearchParams({ _page: `${page}`, _count: `${count}`, withRelatives: 'true' });

    return api.get('appointments', { searchParams }).json().then(response => ({
      page: response.page,
      pages: Math.ceil(response.total / count),
      data: normalizeConnectAppointments(response.data),
    }));
  },
  getNearest: (channel = 'remote') => {
    const searchParams = new URLSearchParams({ channel });
    return api.get('appointments/nearest', { searchParams }).json().then(normalizeConnectAppointment);
  },
  accept: (id, token) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
      searchParams.set('type', 'patient');
    }
    return api.post(`appointments/${id}/confirm`, { searchParams }).json();
  },
  cancel: (id, token, reason) => {
    let searchParams = new URLSearchParams();
    if (token) {
      searchParams.set('token', token);
      searchParams.set('type', 'patient');
    }
    return api.post(`appointments/${id}/decline`, { searchParams, json: { cancelReason: reason } }).json();
  },
  /**
   * @param {object}  data
   * @param {string}  data.startAt         Start date time in ATOM format
   * @param {string}  data.endAt           End date time in ATOM format
   * @param {string}  data.message         The reason of the appointment
   * @param {string}  data.practitionerId  ID of the practitioner
   * @param {string}  data.patientId       ID of the patient
   * @param {string}  data.productId       ID of the product
   * @param {boolean} data.firstTimeWithPractitioner Whether or not the patient has already seen the practitioner
   * @param {string} data.card
   * @returns {Promise}
   */
  create: (data) => {
    return api.post('appointments', {
      json: {
        start_at: data.startAt,
        end_at: data.endAt,
        message: data.message,
        practitioner: data.practitionerId,
        patient: data.patientId,
        product: data.productId,
        new_patient: data.firstTimeWithPractitioner,
        payment_method: data.card
      },
    }).json();
  },
  replacePatient: (id, newPatientId) => {
    return api.patch(`appointments/${id}`, {
      json: {
        patient: newPatientId,
      },
    }).json().then(normalizeConnectAppointment);
  },
};
