var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col _8" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.payments } }, [
                  _vm._v(_vm._s(_vm.$t("profilePaymentPage.payment-summary")))
                ]),
                _c("div", { staticClass: "list-payment" }, [
                  _c("table", { staticClass: "table table-hover" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("profilePaymentPage.practitioner"))
                          )
                        ]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("profilePaymentPage.date")))
                        ]),
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("profilePaymentPage.amount")))
                        ]),
                        _c("th", [
                          _vm._v(
                            _vm._s(_vm.$t("profilePaymentPage.status.label"))
                          )
                        ]),
                        _c("th")
                      ])
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.transactions, function(transaction) {
                        return _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  transaction.order.appointment.practitioner
                                    .firstName
                                ) +
                                "\n                  " +
                                _vm._s(
                                  transaction.order.appointment.practitioner
                                    .lastName
                                ) +
                                "\n                "
                            )
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(
                                  transaction.createdAt,
                                  "DD/MM/YYYY"
                                )
                              )
                            )
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm._f("money")(transaction.amount)))
                          ]),
                          transaction.paymentStatus === "paid"
                            ? _c("td", { staticClass: "paid" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "profilePaymentPage.status.validated"
                                    )
                                  )
                                )
                              ])
                            : _c("td", { staticClass: "unpaid" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "profilePaymentPage.status.not-validated"
                                    )
                                  )
                                )
                              ]),
                          _c(
                            "td",
                            [
                              _c(
                                "OptionsMenu",
                                [
                                  _c(
                                    "OptionsMenuItem",
                                    {
                                      attrs: {
                                        icon: _vm.$icons.file,
                                        to: {
                                          name: "appointment-view",
                                          params: {
                                            id: transaction.order.appointment.id
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.$t(
                                              "profilePaymentPage.appointment-detail"
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ],
              1
            ),
            _c("div", { staticClass: "col-separator" }),
            _c(
              "div",
              { staticClass: "col _4, payment-method" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.creditCard } }, [
                  _vm._v(_vm._s(_vm.$t("profilePaymentPage.payment-method")))
                ]),
                _c("CreditCards", { attrs: { removable: "" } })
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }