var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppPopIn",
    { attrs: { "max-width": "50rem" }, on: { close: _vm.close } },
    [
      !_vm.isLoaded
        ? [
            _c("FontAwesomeIcon", {
              staticClass: "icon",
              attrs: { icon: _vm.$icons.spinner, spin: "" }
            }),
            _vm._v("\n    " + _vm._s(_vm.$t("avatarForm.loader")) + "\n  ")
          ]
        : [
            !_vm.imageSource
              ? _c(
                  "div",
                  [
                    _c("SectionTitle", { attrs: { center: "" } }, [
                      _vm._v(_vm._s(_vm.$t("avatarForm.title")))
                    ]),
                    _c("p", { staticClass: "description" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("avatarForm.description")) +
                          "\n      "
                      )
                    ]),
                    _c("FileUploadButton", {
                      attrs: { label: _vm.$t("avatarForm.file-upload") },
                      on: { change: _vm.handleFileSelected }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "crop-container" }, [
                  _c("img", {
                    ref: "preview",
                    staticClass: "crop-preview",
                    attrs: { src: _vm.imageSource, alt: "" },
                    on: { load: _vm.initCropper }
                  })
                ]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c(
                  "ButtonRed",
                  {
                    attrs: {
                      busy: _vm.isUploading,
                      "no-border": "",
                      outlined: "",
                      small: ""
                    },
                    on: { click: _vm.close }
                  },
                  [_vm._v("Annuler")]
                ),
                _vm.imageSource
                  ? _c(
                      "ButtonGreen",
                      {
                        attrs: { busy: _vm.isUploading, small: "" },
                        on: { click: _vm.upload }
                      },
                      [_vm._v("Valider")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }