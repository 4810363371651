<!--
Emitted events:
- change(result)
- update:typedText(string)
- update:searching(boolean)

Slot attributes:
- result

Example:
<AutoCompletedInput
  id="foobar-input"
  v-model="foobar"
  :search-method="searchForFoobar"
  :searching.sync="isSearchingFoobar"
  :typed-text.sync="typedFoobar"
  v-slot="{ result }"
>
  <h4>{{ result.foo }}</h4>
  <p>{{ result.bar }}</p>
</AutoCompletedInput>
-->
<template>
  <div class="auto-completed-input">
    <input
      @keydown.up.prevent="moveSelectionUp"
      @keydown.down.prevent="moveSelectionDown"
      @keydown.enter.prevent="confirmSelection"
      @keydown.tab="handleTab"
      @keydown.esc.prevent="foundResults = []"
      @input="handleInput($event.target.value)"
      :value="typedText"
      :id="id"
      ref="textField"
      autocomplete="off"
    >
    <div class="found-results" v-show="areResultsVisible" @mouseenter="focus">
      <div
        v-for="(foundResult, index) of foundResults"
        :class="{ selected: selectionIndex === index}"
        @mouseenter="selectionIndex = index"
        @click="confirmSelection"
        ref="result"
      >
        <slot :result="foundResult">{{ foundResult }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash-es';

  export default {
    name: 'AutoCompletedInput',
    abortController: new AbortController(),
    props: {
      value: {
        required: true,
      },
      id: {
        type: String,
      },
      searchMethod: {
        type: Function,
        required: true,
      },
      searching: {
        type: Boolean,
        required: false,
        default: false,
      },
      minLength: {
        type: Number,
        required: false,
        default: 3,
      },
      typedText: {
        type: String,
        required: false,
        default: '',
      },
      selectWithTab: {
        type: Boolean,
        required: false,
        default: true,
      }
    },
    model: {
      prop: 'value',
      event: 'change',
    },
    data() {
      return {
        foundResults: [], // Has a watcher
        selectionIndex: 0,
      };
    },
    created() {
      this.search = debounce(this.search, 300);
    },
    computed: {
      areResultsVisible() {
        return this.foundResults.length > 0;
      },
    },
    methods: {
      handleInput(typedText) {
        this.$emit('update:typedText', typedText);

        if (this.value) {
          this.$emit('change', null);
        }

        this.search(typedText);
      },
      async search(searchTerm) {
        this.$options.abortController.abort();
        this.$options.abortController = new AbortController();

        this.foundResults = [];

        if (searchTerm.length < this.minLength) {
          return this.$emit('update:searching', false);
        }

        this.$emit('update:searching', true);

        try {
          this.foundResults = await this.searchMethod(searchTerm, this.$options.abortController.signal);
          this.$emit('update:searching', false);
        } catch (e) {
          if (e.name !== 'AbortError') {
            this.$emit('update:searching', false);
            throw e;
          }
        }
      },
      moveSelectionUp() {
        if (this.selectionIndex <= 0) {
          this.selectionIndex = 0;
        } else {
          this.selectionIndex -= 1;
        }
      },
      moveSelectionDown() {
        const lastIndex = this.foundResults.length - 1;

        if (this.selectionIndex >= lastIndex) {
          this.selectionIndex = lastIndex;
        } else {
          this.selectionIndex += 1;
        }
      },
      confirmSelection() {
        const result = this.foundResults[this.selectionIndex];
        this.foundResults = [];
        this.$emit('change', result);
      },
      focus() {
        this.$nextTick(() => this.$refs.textField.focus());
      },
      handleTab() {
        if (!this.areResultsVisible) {
          return;
        }

        if (this.selectWithTab) {
          this.confirmSelection();
        } else {
          this.foundResults = [];
        }
      },
    },
    watch: {
      selectionIndex(selectionIndex) {
        const element = this.$refs.result[selectionIndex];

        if (element.offsetTop < element.offsetParent.scrollTop) {
          element.offsetParent.scrollTop = element.offsetTop;
        } else if (element.offsetTop + element.offsetHeight > element.offsetParent.scrollTop + element.offsetParent.offsetHeight) {
          element.offsetParent.scrollTop = element.offsetTop + element.offsetHeight - element.offsetParent.offsetHeight;
        }
      },
      foundResults() {
        this.selectionIndex = 0;
      },
    },
  };
</script>

<style scoped lang="scss">
  .auto-completed-input {
    position: relative
  }

  .found-results {
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    width: 100%;
    max-height: 33rem;
    overflow: auto;

    > div {
      cursor: pointer;
      padding: 1rem 2rem;
      border-bottom: 1px solid $lightTextColor;

      &.selected {
        background-color: $successColor;
        color: white;
        font-weight: bold;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }
</style>
