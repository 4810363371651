import Premium from "@/api/premium";
import Patient from "@/api/patients"

export default {
    state: {
        isFetch: false,
        slots: null
    },
    mutations: {
        NEW_SLOT_CREATE(state) {
            state.isCreating = true;
        },
        NEW_SLOT_CREATE_SUCCESS(state) {
            state.isCreating = false;
        },
        NEW_SLOT_CREATE_FAIL(state) {
            state.isCreating = false;
        },
        GET_SLOT(state) {
            state.isFetch = true;
        },
        GET_SLOT_SUCCESS(state, {slot}) {
            state.slots = slot
            state.isFetch = false;
        },
        GET_SLOT_FAIL(state) {
            state.isFetch = false;
        },
        PREMIUM_STRIPE_CHECKOUT(state) {
            state.isPosting = true;
            state.checkout = null;
        },
        PREMIUM_STRIPE_CHECKOUT_SUCCESS(state, {checkout}) {
            state.isPosting = false;
            state.checkout = checkout;
        },
        PREMIUM_STRIPE_CHECKOUT_FAIL(state) {
            state.isPosting = false;
            state.checkout = null;
        },
        PREMIUM_CANCEL(state) {
            state.isPosting = true;
        },
        PREMIUM_CANCEL_SUCCESS(state) {
            state.isPosting = false;
        },
        PREMIUM_CANCEL_FAIL(state) {
            state.isPosting = false;
        }
    },
    actions: {
        async premiumCancel({commit}) {
            commit('PREMIUM_CANCEL');
            try {
                await Premium.cancel();
                commit('PREMIUM_CANCEL_SUCCESS');
            }  catch (e) {
                commit('PREMIUM_CANCEL_FAIL');
                throw e;
            }
        },
        async newSlotCreate({commit}, {data}) {
            commit('NEW_SLOT_CREATE');
            try {
                await Premium.create(data)
                commit('NEW_SLOT_CREATE_SUCCESS', {data});
            } catch (e) {
                commit('NEW_SLOT_CREATE_FAIL');
                throw e;
            }
        },
        async getSlotAll({commit}) {
            commit('GET_SLOT');
            try {
                const patient = await Patient.me()
                let slot = await Premium.getSlot(patient.id)
                commit('GET_SLOT_SUCCESS', { slot });
            } catch (e) {
                commit('GET_SLOT_FAIL');
                throw e;
            }
        },
        async premiumCheckout({commit}, {}) {
            commit('PREMIUM_STRIPE_CHECKOUT');
            try {
                const checkout = await Premium.subscribe();
                commit('PREMIUM_STRIPE_CHECKOUT_SUCCESS', {checkout});
            } catch (e) {
                commit('PREMIUM_STRIPE_CHECKOUT_FAIL');
                throw e;
            }
        }
    },
    getters: {
         totalSlots(state) {
             if(state.slots) {
                 return state.slots.map(slot => slot.type)
             }
        },
    },
};
