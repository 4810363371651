var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "profile-tab-bar" },
    [
      _c("router-link", { attrs: { to: { name: "profile-edit" } } }, [
        _vm._v(_vm._s(_vm.$t("profileTabBar.profile")))
      ]),
      _c("router-link", { attrs: { to: { name: "profile-payment" } } }, [
        _vm._v(_vm._s(_vm.$t("profileTabBar.payment")))
      ]),
      _c("router-link", { attrs: { to: { name: "profile-commands" } } }, [
        _vm._v(_vm._s(_vm.$t("profileTabBar.commands")))
      ]),
      _c("router-link", { attrs: { to: { name: "profile-beneficiaries" } } }, [
        _vm._v(_vm._s(_vm.$t("profileTabBar.beneficiaries")))
      ]),
      _c("router-link", { attrs: { to: { name: "profile-access" } } }, [
        _vm._v(_vm._s(_vm.$t("profileTabBar.access")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }