import { render, staticRenderFns } from "./SharedFiles.vue?vue&type=template&id=339e7a12&scoped=true&"
import script from "./SharedFiles.vue?vue&type=script&lang=js&"
export * from "./SharedFiles.vue?vue&type=script&lang=js&"
import style0 from "./SharedFiles.vue?vue&type=style&index=0&id=339e7a12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339e7a12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('339e7a12')) {
      api.createRecord('339e7a12', component.options)
    } else {
      api.reload('339e7a12', component.options)
    }
    module.hot.accept("./SharedFiles.vue?vue&type=template&id=339e7a12&scoped=true&", function () {
      api.rerender('339e7a12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SharedFiles.vue"
export default component.exports