var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col _6 left" },
              [_c("ProfileEditForm", { attrs: { user: _vm.profile } })],
              1
            ),
            _c("div", { staticClass: "col-separator" }),
            _c(
              "div",
              { staticClass: "col _6 right" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.lock } }, [
                  _vm._v(_vm._s(_vm.$t("profileEditPage.credentials")))
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "email",
                        value: _vm.profile.email,
                        disabled: "",
                        label: _vm.$t("profileEditPage.email"),
                        type: "email"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "edit-credential" }),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "phone",
                        value: _vm._f("phone")(_vm.profile.phone),
                        disabled: "",
                        label: _vm.$t("profileEditPage.mobile-phone"),
                        type: "phone"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "edit-credential" }),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "password",
                        disabled: "",
                        label: _vm.$t("profileEditPage.password"),
                        value: "************"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "edit-credential" }),
                !this.profile.deletedAt
                  ? _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "delete",
                          attrs: { href: "#" },
                          on: {
                            click: [
                              function($event) {
                                $event.preventDefault()
                              },
                              function($event) {
                                return _vm.$confirm(_vm.$refs.deleteProfile)
                              }
                            ]
                          }
                        },
                        [
                          _vm._v(
                            "\n              Supprimer mon compte\n            "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _c("AppConfirmation", {
                  ref: "deleteProfile",
                  attrs: {
                    title: _vm.$t("profileEditPage.title-confirmation")
                  },
                  on: { confirm: _vm.deleteAccount },
                  scopedSlots: _vm._u([
                    {
                      key: "description",
                      fn: function(scoped) {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "profileEditPage.description-confirmation"
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }