var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "form-select",
      class: {
        focused: _vm.focused,
        "has-value": !!_vm.value,
        "has-error": !!_vm.$slots.error
      },
      on: {
        focusin: function($event) {
          _vm.focused = true
        },
        focusout: function($event) {
          _vm.focused = false
        }
      }
    },
    [
      _c("FontAwesomeIcon", {
        staticClass: "icon",
        attrs: { icon: _vm.$icons.selectIndicator }
      }),
      _c(
        "span",
        { staticClass: "label", attrs: { id: _vm.id } },
        [_vm._v(_vm._s(_vm.label)), _vm.required ? [_vm._v(" *")] : _vm._e()],
        2
      ),
      _c(
        "select",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.internalValue,
                expression: "internalValue"
              }
            ],
            ref: "input",
            attrs: { "aria-labelledby": _vm.id },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.internalValue = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          "select",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      ),
      _vm.$slots.help && !_vm.$slots.error
        ? _c("span", { staticClass: "help" }, [_vm._t("help")], 2)
        : _vm._e(),
      _vm.$slots.error
        ? _c("span", { staticClass: "error" }, [_vm._t("error")], 2)
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }