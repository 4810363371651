import PharmaciesSearch from '../../api-search/pharmacies';

export default {
    state: {
        search: [],
        isFetching: false
    },
    mutations: {
        PHARMACIES_FETCH(state) {
            state.isFetching = true;
        },
        PHARMACIES_FETCH_SUCCESS(state, { pharmacies }) {
            state.isFetching = false;
            state.search = pharmacies;
        },
        PHARMACIES_FETCH_FAIL(state) {
            state.isFetching = false;
        }
    },
    actions: {
        async pharmaciesFetch({ state, commit }, {lat, lng, radius} ) {
            commit('PHARMACIES_FETCH');
            try {
                const pharmacies = await PharmaciesSearch.search(lat, lng, radius);
                commit('PHARMACIES_FETCH_SUCCESS', { pharmacies });
            } catch (e) {
                commit('PHARMACIES_FETCH_FAIL');
                throw e;
            }
        }
    }
};