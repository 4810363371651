import getInstance from '@/helpers/pusher';

let channel;

export default {
  state: {
    isOpen: false,
    isOnline: false,
    isRemoteOnline: false,
    isRemoteTyping: false,
    messages: [],
    unreadMessagesCount: 0,
  },
  mutations: {
    CHAT_OPEN(state) {
      state.isOpen = true;
      state.unreadMessagesCount = 0;
    },
    CHAT_CLOSE(state) {
      state.isOpen = false;
    },
    CHAT_JOIN(state, { appointmentId }) {},
    CHAT_JOIN_SUCCESS(state) {
      state.isOnline = true;
    },
    CHAT_LEAVE_SUCCESS(state) {
      state.isOpen = false;
      state.isOnline = false;
      state.isRemoteOnline = false;
      state.isRemoteTyping = false;
      state.messages = [];
      state.unreadMessagesCount = 0;
    },
    CHAT_REMOTE_JOINED(state) {
      state.isRemoteOnline = true;
      state.messages.push({ content: 'Votre correspondant a rejoint la conversation', type: 'remote' });
    },
    CHAT_REMOTE_LEFT(state) {
      state.isRemoteOnline = false;
      state.messages.push({ content: 'Votre correspondant a quitté la conversation', type: 'remote' });
    },
    CHAT_REMOTE_TYPING_START(state) {
      state.isRemoteTyping = true;
    },
    CHAT_REMOTE_TYPING_STOP(state) {
      state.isRemoteTyping = false;
    },
    CHAT_MESSAGE_RECEIVED(state, { message }) {
      state.messages.push({ content: message, type: 'remote' });
      state.isRemoteTyping = false;

      if (!state.isOpen) {
        state.unreadMessagesCount += 1;
      }
    },
    CHAT_NOTIFICATION_RECEIVED(state, { message }) {
      state.messages.push({ content: message, type: 'remote' });

      if (!state.isOpen) {
        state.unreadMessagesCount += 1;
      }
    },
    CHAT_MESSAGE_SENT(state, { message }) {
      state.messages.push({ content: message, type: 'local' });
    },
  },
  actions: {
    async chatJoin({ commit, dispatch }, { appointmentId }) {
      commit('CHAT_JOIN', { appointmentId });

      const channelName = `presence-chat-${appointmentId}`;

      switch (channel?.name) {
        case undefined: // No presence channel
          break;
        case channelName: // Already connected to this channel
          return;
        default: // Connected to another channel
          await dispatch('chatLeave');
      }

      const pusher = await getInstance();

      channel = pusher.subscribe(channelName);

      channel.bind('pusher:subscription_succeeded', (members) => {
        commit('CHAT_JOIN_SUCCESS');

        if (members.count > 1) {
          commit('CHAT_REMOTE_JOINED');
        }
      });

      channel.bind('pusher:member_added', () => {
        commit('CHAT_REMOTE_JOINED');
      });

      channel.bind('pusher:member_removed', () => {
        commit('CHAT_REMOTE_LEFT');
      });

      channel.bind('client-chat-message', ({ message }) => commit('CHAT_MESSAGE_RECEIVED', { message }));
      channel.bind('client-chat-start-typing', () => commit('CHAT_REMOTE_TYPING_START'));
      channel.bind('client-chat-stop-typing', () => commit('CHAT_REMOTE_TYPING_STOP'));
    },
    async chatLeave({ commit }) {
      const channelName = channel?.name;

      channel?.unbind_all();
      channel?.unsubscribe();
      channel = null;

      commit('CHAT_LEAVE_SUCCESS', { channelName });
    },
    chatSendMessage({ commit, state }, { message }) {
      channel.trigger('client-chat-message', { message });
      commit('CHAT_MESSAGE_SENT', { message });
    },
    chatStartTyping() {
      channel.trigger('client-chat-start-typing', {});
    },
    chatStopTyping() {
      channel.trigger('client-chat-stop-typing', {});
    },
    chatOpen({ commit }) {
      commit('CHAT_OPEN');
    },
    chatClose({ commit }) {
      commit('CHAT_CLOSE');
    },
  },
};
