<template>
  <form class="credit-card-add-form" @submit.prevent="handleSubmit">
    <div class="button">
      <ButtonWhite
          :busy="submitting"
          type="submit"
          block
          class="status-premium__button"
      >
        <slot></slot>
      </ButtonWhite>
    </div>
  </form>
</template>

<script>
import ButtonGreen from '@/components/ButtonGreen';
import ButtonWhite from "../ButtonWhite.vue";

export default {
  name: 'CardPayment',
  components: {ButtonWhite, ButtonGreen },
  data() {
    return {
      submitting: false,
    };
  },
  computed: {
    profile() {
      return this.$store.state.patients.profile;
    }
  },
  methods: {
    async handleSubmit() {
      this.submitting = true;
      await this.$store.dispatch('premiumCheckout',{}).catch(( error) => {
        throw error;
      })
      const checkout = this.$store.state.premium.checkout;
      if(checkout.data.url) {
        window.location.href = checkout.data.url;
        this.submitting = false;
      } else {
        this.$addError("L'url de redirection n'existe pas.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-card-add-form{
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-premium {
  &__button {
    border-radius: 10px;
    color: #D1606B;
    font-weight: bold;
  }
}
</style>
