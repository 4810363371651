var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center align-items-center flex-column p-5 click_collect_container"
      },
      [
        _c("div", { staticClass: "p-5" }, [
          _c("img", {
            attrs: {
              alt: "handshake",
              src: require("../../assets/images/picto_handShake.svg")
            }
          })
        ]),
        _c("p", {
          staticClass: "text-center font-bold",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("pharmacyOrder.exit-page.content-success", {
                pharmacyrs: _vm.pharmacyrs
              })
            )
          }
        }),
        _c(
          "p",
          [
            _c(
              "ButtonGreen",
              {
                staticClass: "font-bold",
                attrs: { "link-to": { name: "home" } }
              },
              [_vm._v(_vm._s(_vm.$t("pharmacyOrder.exit-page.btn-to-home")))]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }