<template>
  <AppLayout>
    <div class="helloscope">
      <main class="container-body">
        <SectionTitle>{{ $t('telemetry.constantes-page.title') }}</SectionTitle>
        <div class="subtitle">
          <div v-html="$t('telemetry.constantes-page.subtitle')"></div>
          <a href="https://www.hellocare-helloscope.com/" target="_blank"><div v-html="$t('telemetry.constantes-page.learn-more')"></div></a>
        </div>
        <div class="container-spinner" v-if="this.$store.state.doc2u.isCreatingRoom">
          <Spinner />
        </div>
        <div class="container-iframe" v-else>
          <div class="container-iframe-top">
            <div v-if="this.$store.state.observations.isFetching" class="container-spinner">
              <Spinner />
            </div>
            <div class="container-iframe__datablock" v-else>
              <div v-if="searchLength>0">
                <LastObservations :search-length="searchLength" :observations="this.$store.state.observations.search"/>
              </div>
              <div v-else>
                <p>{{ $t('telemetry.constantes-page.no-data') }}</p>
              </div>
              <ButtonGreen @click="createObservations">{{ $t('telemetry.constantes-page.save-data') }}</ButtonGreen>
            </div>
          </div>
          <br/>
          <iframe class="doc2u-content" :src="`https://app.doc2u.fr/room?t=${this.$store.state.doc2u.room.token_key}&practitionerMode=local&postMessage=true`" ></iframe>
        </div>
      </main>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../components/AppLayout";
import SectionTitle from "../components/SectionTitle";
import Spinner from "../components/Pharmacy/Spinner";
import ButtonGreen from "../components/ButtonGreen";
import LastObservations from "../components/LastObservations";

export default {
  name: 'ConstantesPage',
  components: {ButtonGreen, Spinner, SectionTitle, AppLayout, LastObservations },
  data() {
    return {
      data: [],
      dataTemperature: [],
      dataOxygen: [],
      dataCardiacRhythm: [],
      dataDiastolicPressure: [],
      dataSystolicPressure: [],
      searchLength: 0
    }
  },
  mounted() {
    window.addEventListener("message",  (msg) =>  {
      if (msg.origin === "https://app.doc2u.fr") {
        if(msg.data.source==="oximeterManager") this.dataOxygen = msg.data;
        if(msg.data.source==="temperatureProcess") this.dataTemperature = msg.data;
        if(msg.data.source==="bloodPressureManager") this.dataTemperature = msg.data;
        this.data = {
          "dataTemperature" : this.dataTemperature,
          "dataOxygen" : this.dataOxygen,
          "dataCardiacRhythm" : this.dataCardiacRhythm,
          "dataDiastolicPressure" : this.dataDiastolicPressure,
          "dataSystolicPressure" : this.dataSystolicPressure,
        }
      }
    });
    this.getDoc2uData();
    this.getLastObservations()
  },
  methods: {
    profileId() {
      return this.$store.state.patients.profile.id;
    },
    async createObservations() {
      if(this.data!==null) {
        let statusCode = 201;
        await this.$store.dispatch('observationsCreate', {
          patient: this.profileId(),
          data: this.data,
          encounter: null
        }).catch((error)=>{
          statusCode = error.response.status
        });
        if(statusCode >= 400) {
          this.$addError("Aucune mesure enregistrée.");
        } else {
          this.searchLength = null;
          await this.getLastObservations();
          this.$addSuccess("Vos mesures ont bien été enregistrées.");
        }
      }
    },
    async getLastObservations() {
      await this.$store.dispatch('observationsFetch', {
        patient: this.profileId(),
        lastObs: true,
        code: null
      })
      if(this.$store.state.observations.search) {
        this.searchLength = this.$store.state.observations.search.length;
      }
    },
    async getDoc2uData() {
      await this.$store.dispatch('createFullAccessRoom', {
        patientId: this.profileId(),
      });
    }
  },
  computed: {
      localAudioStream() {
          return this.$store.state.sdk.local.audio.stream;
      },
      localVideoStream() {
          return this.$store.state.sdk.local.video.stream;
      },
      remoteAudioStream() {
          return this.$store.state.sdk.remote.audio.stream;
      },
      remoteVideoStream() {
          return this.$store.state.sdk.remote.video.stream;
      },
  }
}
</script>

<style lang="scss" scoped>
.helloscope {
  .subtitle{
    font-family: $titleFont;
    font-size: 1.5rem;
    position: relative;
    margin-bottom: 3.5rem;
    padding-bottom: 1.2rem;
    text-align: left;
    color: #1F1F1F;
    a{
      &:hover{
       text-decoration: underline;
      }
    }
  }
  .doc2u-content {
    border: 1px solid $successColor;
    width: 100%;
    height: 78vh;
    border-radius: 3px;
    @include onMobile {
      height: 84.5vh;
    }
  }

  .doc2u-button-finish {
    display: block;
    margin: 1rem auto;
    @include onMobile {
      margin: 0.5rem auto 0;
    }
  }
  .container-iframe {
    .container-iframe-top {
      .container-iframe__datablock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        button {
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .container-spinner {
    margin: 0 20px 20px 0;
  }
}
</style>