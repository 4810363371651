<template>
  <AppLoader v-if="!isReady">{{ $t('newAppointmentSearchPage.loading') }}</AppLoader>
  <AppLayout v-else>
    <AppProgress :current="2" :total="8" :position="'b-0'" />
    <AppLoader v-if="isSearching">
      {{ $t('newAppointmentSearchPage.searching') }}
    </AppLoader>
    <main v-else class="container">
      <div v-if="$media.desktop" class="filter">
        <div>
          <SectionTitle>{{ $t('newAppointmentSearchPage.filters') }}</SectionTitle>
          <form @submit.prevent="handleReload">
            <FormInput
              id="practitioner-name"
              v-model="form.practitionerName"
              :label="$t('newAppointmentSearchPage.form.practitioner-name.label')"
            >
              <template v-if="$v.form.practitionerName.$error" #error>
                <template v-if="!$v.form.practitionerName.required ">
                  {{ $t('newAppointmentSearchPage.form.practitioner-name.required') }}
                </template>
                <template v-else-if="!$v.form.practitionerName.minLength">
                  {{ $t('newAppointmentSearchPage.form.practitioner-name.min-length', { min: $v.form.practitionerName.$params.minLength.min }) }}
                </template>
              </template>
            </FormInput>

            <FormSelectSpecialties id="specialty" v-model="form.specialty" :specialties="specialties">
              <template v-if="$v.form.specialty.$error" #error>
                <template v-if="!$v.form.specialty.required">&nbsp;</template>
              </template>
            </FormSelectSpecialties>

            <FormInput id="location" v-model="form.location" :label="$t('newAppointmentSearchPage.form.postal-code.label')" maxlength="20">
              <template v-if="$v.form.location.$error" #error>
                <template v-if="!$v.form.location.required">&nbsp;</template>
                <template v-if="!$v.form.location.zipCode ">{{ $t('newAppointmentSearchPage.form.postal-code.format') }}</template>
              </template>
            </FormInput>

            <FormSelect id="channel" v-model="form.channel" :label="$t('newAppointmentSearchPage.form.channel.label')">
              <option value="" />
              <option value="remote">{{ $t('newAppointmentSearchPage.form.channel.remote') }}</option>
              <option value="medical_office">{{ $t('newAppointmentSearchPage.form.channel.medical-office') }}</option>
              <option value="home">{{ $t('newAppointmentSearchPage.form.channel.home') }}</option>
            </FormSelect>

            <ButtonBlack :icon="$icons.reload" block class="reload-button" icon-to-left outlined rounded type="submit">
              {{ $t('newAppointmentSearchPage.form.reload') }}
            </ButtonBlack>
          </form>
        </div>
      </div>
      <div class="results">
        <div>
          <SectionTitle>{{ $t('newAppointmentSearchPage.search-result') }}</SectionTitle>
          <div v-if="!practitioners.length">{{ $t('newAppointmentSearchPage.no-result') }}</div>
          <template v-else>
            <div v-for="practitioner in practitioners" :key="practitioner.id">
              <SearchResultsItem :practitioner="practitioner" :preferred-channel="channel" @select="handleSelect" />
              <hr>
            </div>
            <Paginator
              v-if="totalPages"
              v-model="form.page"
              :click-handler="handleReload"
              :no-li-surround="true"
              :page-count="totalPages"
              class="paginator"
              :next-text="$t('newAppointmentSearchPage.paginator.next')"
              :prev-text="$t('newAppointmentSearchPage.paginator.previous')"
            />
          </template>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import { minLength, requiredIf } from '@byscripts/vuelidate/lib/validators';
  import Paginator from 'vuejs-paginate';
  import SearchPractitioners from '@/api-search/practitioners.js';
  import AppLayout from '@/components/AppLayout';
  import AppLoader from '@/components/AppLoader';
  import AppProgress from '@/components/AppProgress';
  import ButtonBlack from '@/components/ButtonBlack';
  import FormInput from '@/components/Form/FormInput';
  import FormSelect from '@/components/Form/FormSelect';
  import FormSelectSpecialties from '@/components/Form/FormSelectSpecialties';
  import SearchResultsItem from '@/components/NewAppointment/SearchResultsItem';
  import SectionTitle from '@/components/SectionTitle';
  import { formatFullName } from '@/helpers/format';
  import { setLocalStorage } from '@/helpers/tools';

  export default {
    name: 'NewAppointmentSearchPage',
    components: {
      FormSelect,
      FormSelectSpecialties,
      FormInput,
      ButtonBlack,
      AppProgress,
      SearchResultsItem,
      AppLoader,
      SectionTitle,
      AppLayout,
      Paginator,
    },
    props: {
      practitionerName: String,
      specialty: String,
      location: String,
      channel: String,
      practiceCountry: String,
      page: Number,
      seed: Number,
    },
    data() {
      return {
        isReady: false,
        form: {
          practitionerName: this.practitionerName,
          specialty: this.specialty,
          location: this.location,
          practiceCountry: this.practiceCountry,
          channel: this.channel,
          page: this.page,
        },
      };
    },
    validations: {
      form: {
        practitionerName: {
          required: requiredIf(vm => !vm.specialty && !vm.location),
          minLength: minLength(2),
        },
        specialty: {
          required: requiredIf(vm => !vm.practitionerName && !vm.location),
        },
        location: {
          required: requiredIf(vm => !vm.practitionerName && !vm.specialty),
        },
      },
    },
    async beforeMount() {
      const { autoMode, autoModeData } = this.$store.state.newAppointment;

      if (autoMode) {
        try {
          const practitioner = await SearchPractitioners.getOne(autoModeData.practitionerId);
          const product = practitioner.products.find(p => p.id === autoModeData.productId);

          setLocalStorage('new-appointment-search', {
            practitionerName: formatFullName(practitioner),
            specialty: practitioner.mainSpecialty.id,
            location: practitioner.practiceZipCode,
          });

          return await this.handleSelect(practitioner, product, autoModeData.startAt);
        } catch (e) {
          this.$addError(this.$t('newAppointmentSearchPage.error.error-search'));
          return this.$router.push({ name: 'new-appointment' });
        }
      }

      this.isReady = true;

      this.loadSpecialties();
      this.search();
    },
    computed: {
      isSearching() {
        return this.$store.state.newAppointment.isSearching;
      },
      practitioners() {
        return this.$store.state.newAppointment.searchResults;
      },
      specialties() {
        return this.$store.state.specialties.all;
      },
      currentPage() {
        return this.$store.state.newAppointment.searchPage;
      },
      totalPages() {
        return this.$store.state.newAppointment.searchPages;
      },
      searchSeed() {
        return this.$store.state.newAppointment.searchSeed;
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.form.practitionerName = to.query.q;
      this.form.specialty = to.query.s;
      this.form.location = to.query.l;
      this.form.channel = to.query.c;
      this.form.practiceCountry = to.query.practiceCountry;
      this.form.page = to.query.page;

      next();
    },
    methods: {
      async loadSpecialties() {
        await this.$store.dispatch('specialtiesFetchAll');
      },
      async search() {
        try {
          await this.$store.dispatch('newAppointmentSearch', {
            practitionerName: this.practitionerName,
            practiceCountry: this.$store.state.patients.profile.country ?? 'FR',
            specialty: this.specialty,
            location: this.location,
            channel: this.channel,
            page: this.page,
            seed: this.seed,
          });
          if (this.currentPage > this.totalPages) {
            this.form.page = this.totalPages;
            this.handleReload();
          }
        } catch (e) {
          this.$router.push({ name: 'new-appointment' });
          throw e;
        }
      },
      async handleSelect(practitioner, product, startAt, rules) {
        try {
          await this.$store.dispatch('newAppointmentSelect', {
            practitioner,
            product,
            startAt,
          });

          if(!rules) {
            await this.$router.push({ name: 'new-appointment-beneficiary' });
          }

        } catch (e) {
          await this.$addError(this.$t('newAppointmentSearchPage.error.error-creation'));
          throw e;
        }
      },
      async handleReload() {
        this.$v.$touch();

        if (this.$v.$anyError) {
          return;
        }

        setLocalStorage('new-appointment-search', this.form);

        await this.$router.push({
          name: 'new-appointment-search',
          query: {
            q: this.form.practitionerName,
            s: this.form.specialty,
            l: this.form.location,
            c: this.form.channel,
            practiceCountry: this.$store.state.patients.profile.country ?? 'FR',
            page: this.form.page,
            seed: this.searchSeed,
          },
        });

        this.search();
      },
    },
  };
</script>

<style lang="scss" scoped>
  main.container {
    display: flex;
    max-width: none;
    margin: 0;
    padding: 0;
    @include onMobile {
      margin: 5rem auto;
      padding: 0 3rem;
      display: block;
    }
  }

  .filter {
    width: 40rem;
    min-height: 100vh;
    padding: 8rem;
    background-color: $lighterGrey;
    @media (min-width:769px) AND (max-width: 1279px) {
      width: 35rem;
      padding: 8rem 2rem;
    }

    .form-input, .form-select {
      margin-bottom: 2rem;
    }

    > div {
      position: sticky;
      top: 2rem;
    }
  }

  .results {
    flex: 1;

    > div {
      margin: 0 auto;
      padding: 0rem;

      @include onDesktop {
        padding: 8rem 2rem;
      }

      @media (min-width: 1280px) {
        padding: 8rem;
      }
    }
  }

  .reload-button {
    margin-top: 2rem;
  }

  .paginator {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    ::v-deep a {
      margin: 0 0.3rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: $successColor;
      border: 1px solid $successColor;
      border-radius: 0.5rem;
      outline: none;
      @include onMobile {
        place-self: center;
      }

      &.active {
        color: #FFF;
        background-color: $successColor;
      }

      &.disabled {
        cursor: default;
        color: $lightTextColor;
        border-color: $lightTextColor;
      }

      &:focus {
        box-shadow: 0 0 0.1rem 0.3rem transparentize($successColor, 0.5);
      }
    }
  }
</style>
