var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time" },
    _vm._l(_vm.availableHours, function(slot) {
      return _c(
        "div",
        {
          staticClass: "hour",
          on: {
            click: function($event) {
              return _vm.$emit("change", slot.start)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.displayTime(slot)) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }