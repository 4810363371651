<template>
  <div
    :class="{ editable }"
    :style="style"
    class="user-avatar"
    @click="openAvatarForm"
  >
    <img v-if="!style.backgroundImage" alt="" src="../assets/images/picto_no_avatar.svg" />
    <div v-if="editable" class="change-avatar" v-html="$t('userAvatar.change-avatar')"/>
    <div v-if="editable" class="pictogram">
      <img alt="" src="../assets/images/picto-change-avatar.svg" />
    </div>
    <AvatarForm v-if="$store.state.patients.isAvatarFormOpened" @change="handleNewAvatar" :user-id="userId" />
  </div>
</template>

<script>
  import AvatarForm from './AvatarForm.vue';

  export default {
    name: 'UserAvatar',
    components: { AvatarForm },
    props: {
      src: {
        type: String,
        required: false,
      },
      editable: {
        type: Boolean,
        required: false,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: '13rem',
      },
      userId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        newURL: null,
      };
    },
    computed: {
      style() {
        const url = this.newURL || this.src;

        return {
          height: this.size,
          width: this.size,
          backgroundImage: url ? `url(${url})` : undefined,
        };
      },
    },
    methods: {
      openAvatarForm() {
        if (this.editable) {
          this.$store.dispatch('patientAvatarOpenForm');
        }
      },
      handleNewAvatar(data) {
        this.newURL = data;
      },
    },
  };
</script>

<style lang="scss">
  .user-avatar {
    font-size: 4rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $avatarTextColor;
    border: 0.1rem solid $avatarBorderColor;
    border-radius: 50%;
    background-color: $avatarBackgroundColor;
    background-size: cover;

    &.editable {
      cursor: pointer;
    }

    > img {
      width: 100%;
    }
  }

  .user-avatar-background {
    background-color: transparent;
  }

  .change-avatar {
    font-size: 1.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    opacity: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.7);

    .user-avatar:hover & {
      opacity: 1;
    }
  }

  .pictogram {
    position: absolute;
    right: 0.6rem;
    bottom: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    background-color: $avatarPictogramBackgroundColor;
  }
</style>
