var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("div", { staticClass: "helloscope" }, [
      _c(
        "main",
        { staticClass: "container-body" },
        [
          _c("SectionTitle", [
            _vm._v(_vm._s(_vm.$t("telemetry.constantes-page.title")))
          ]),
          _c("div", { staticClass: "subtitle" }, [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("telemetry.constantes-page.subtitle"))
              }
            }),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.hellocare-helloscope.com/",
                  target: "_blank"
                }
              },
              [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("telemetry.constantes-page.learn-more")
                    )
                  }
                })
              ]
            )
          ]),
          this.$store.state.doc2u.isCreatingRoom
            ? _c(
                "div",
                { staticClass: "container-spinner" },
                [_c("Spinner")],
                1
              )
            : _c("div", { staticClass: "container-iframe" }, [
                _c("div", { staticClass: "container-iframe-top" }, [
                  this.$store.state.observations.isFetching
                    ? _c(
                        "div",
                        { staticClass: "container-spinner" },
                        [_c("Spinner")],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "container-iframe__datablock" },
                        [
                          _vm.searchLength > 0
                            ? _c(
                                "div",
                                [
                                  _c("LastObservations", {
                                    attrs: {
                                      "search-length": _vm.searchLength,
                                      observations: this.$store.state
                                        .observations.search
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "telemetry.constantes-page.no-data"
                                      )
                                    )
                                  )
                                ])
                              ]),
                          _c(
                            "ButtonGreen",
                            { on: { click: _vm.createObservations } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("telemetry.constantes-page.save-data")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                ]),
                _c("br"),
                _c("iframe", {
                  staticClass: "doc2u-content",
                  attrs: {
                    src:
                      "https://app.doc2u.fr/room?t=" +
                      this.$store.state.doc2u.room.token_key +
                      "&practitionerMode=local&postMessage=true"
                  }
                })
              ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }