<template>
        <form @submit.prevent="handleSubmit">
        <div class="collapse" :class="{'collapse-open': openCollapse}">
            <div @click="openCollapse = !openCollapse">
                <GroupTitle class="title-observations" :icon="openCollapse ? $icons.chevronUp : $icons.chevronDown">Observations médicales</GroupTitle>
            </div>
            <div class="observations-container">
                <FormWidget>
                    <label for="tension">Tension artérielle</label>
                    <input class="input" id="tension" type="text" v-model="form.tensionArterielle"/>
                </FormWidget>
                <FormWidget>
                    <label for="pulse">Prise de pouls</label>
                    <input class="input" id="pulse" type="text" v-model="form.priseDePouls"/>
                </FormWidget>
                <FormWidget>
                    <label for="pulse-peri">Pouls périphérique</label>
                    <input class="input" id="pulse-peri" type="text" v-model="form.frequencesRespiratoires"/>
                </FormWidget>
                <FormWidget>
                    <label for="breath">Fréquences respiratoires</label>
                    <input class="input" id="breath" type="text" v-model="form.poulsPeripheriques"/>
                </FormWidget>
                <FormWidget>
                    <label for="temperature">Température centrale</label>
                    <input class="input" id="temperature" type="text" v-model="form.temperatureCentrale"/>
                </FormWidget>
                <FormWidget>
                    <label for="saturation">Saturation en oxygène</label>
                    <input class="input" id="saturation" type="text" v-model="form.saturationOxygene"/>
                </FormWidget>
                <FormWidget>
                    <label for="Glycémie">Glycémie capillaire</label>
                    <input class="input" id="Glycémie" type="text" v-model="form.glycemieCapillaire"/>
                </FormWidget>
                <FormWidget>
                    <label for="throat">Examen de la gorge</label>
                    <input class="input" id="throat" type="text" v-model="form.examenGorge"/>
                </FormWidget>
                <FormWidget>
                    <label for="cutaneous">Examen cutané</label>
                    <input class="input" id="cutaneous" type="text" v-model="form.examenCutanee"/>
                </FormWidget>
                <FormWidget>
                    <label for="urine">Bandelette urinaire</label>
                    <input class="input" id="urine" type="text" v-model="form.bandeletteUrinaire"/>
                </FormWidget>
                <FormWidget>
                    <label for="lungs">Auscultation des poumons</label>
                    <input class="input" id="lungs" type="text" v-model="form.oscultationPoumons"/>
                </FormWidget>
                <FormWidget>
                    <label for="cough">Caractérisation d'une toux</label>
                    <input class="input" id="cough" type="text" v-model="form.caracterisationToux"/>
                </FormWidget>
            </div>
        </div>
        </form>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import FormWidget from "@/components/Form/FormWidget.vue";
import ButtonGreen from "@/components/ButtonGreen.vue";
import GroupTitle from "@/components/GroupTitle.vue";
export default {
    name: 'Observations',
    components: {GroupTitle, ButtonGreen, FormWidget, SectionTitle},
    data() {
        return {
            form: {
                tensionArterielle: null,
                priseDePouls: null,
                frequencesRespiratoires: null,
                poulsPeripheriques: null,
                temperatureCentrale: null,
                saturationOxygene: null,
                glycemieCapillaire: null,
                examenGorge: null,
                examenCutanee: null,
                bandeletteUrinaire: null,
                oscultationPoumons: null,
                caracterisationToux: null,
            },
            openCollapse: false,
            submitErrors: null
        };
    },
    props: {
        profile: {
            type: Object,
            required: true,
        },
        collapseBlock: false
    },
    async beforeMount() {
        this.form = {
            tensionArterielle: this.profile.measure.tension_arterielle,
            priseDePouls: this.profile.measure.prise_de_pouls,
            frequencesRespiratoires: this.profile.measure.frequences_respiratoires,
            poulsPeripheriques: this.profile.measure.pouls_peripheriques,
            temperatureCentrale: this.profile.measure.temperature_centrale,
            saturationOxygene: this.profile.measure.saturation_oxygene,
            glycemieCapillaire: this.profile.measure.glycemie_capillaire,
            examenGorge: this.profile.measure.examen_gorge,
            examenCutanee: this.profile.measure.examen_cutanee,
            bandeletteUrinaire: this.profile.measure.bandelette_urinaire,
            oscultationPoumons: this.profile.measure.oscultation_poumons,
            caracterisationToux: this.profile.measure.caracterisation_toux
        }
        if(this.collapseBlock) {
            return this.openCollapse = true
        }
    },
}
</script>

<style scoped>
.observations-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    pointer-events: none;
}

.collapse {
    overflow: hidden;
    max-height: 40px;
    transition: max-height .8s ease-out;
}
.collapse-open {
    overflow: visible;
    max-height: 1500px;
    transition: max-height .8s ease-out;
}

.title-observations {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 1rem;
    height: 40px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0;
}

::v-deep .form-widget {
    margin: 0;
    flex: 1 30%;
    min-width: 112px;
}

.icon-show ::v-deep.icon {
    display: none;
}

.input {
    height: inherit;
}

</style>
