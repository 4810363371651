<template>
  <div class="main-map-container">
    <!-- if user adress -->
    <div class="click-collect-find">
      <ButtonBlue @click="fetchPharmaciesAroundMe">{{$t('pharmacyOrder.main-page.btn-find-around-me')}}</ButtonBlue>
    </div>
    <!----->
    <div class="click-collect-search">
      <span>{{$t('pharmacyOrder.main-page.content-address')}}</span>
      <input class="search" type="search" :placeholder="$t('pharmacyOrder.main-page.btn-address')"  ref="inputAddress" v-model="research">
    </div>
    <div id="map" class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader'
import pictoPharmaNormal from "../../assets/images/ic_picto_map_pharma_normal.svg"
import pictoPharmaProvider from "../../assets/images/ic_picto_map_pharma_provider.svg"
import i18n from "../../config/i18n";
import ButtonBlue from "../ButtonBlue";

  export default {
    name: 'GoogleMapLoader',
    components: {ButtonBlue},
    props: {
      mapConfig: Object
    },
    data() {
      return {
        google: null,
        map: null,
        markers: [],
        research: null,
        searchbox: null
      }
    },
    async mounted() {
      const googleMapApi = await new Loader({
        apiKey: this.$gMapApiKey,
        libraries: ["places"]
      })
      this.google = await googleMapApi.load();
      this.initSearchBox();
      this.initializeMap();
      this.searchOnMapClick();
      this.searchMapOnInput();
    },
    methods: {
      initializeMap() {
        const mapContainer = this.$refs.googleMap;
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      },
      initSearchBox() {
        const input = this.$refs.inputAddress;
        this.searchBox = new this.google.maps.places.Autocomplete(input, {
                componentRestrictions: {country:  [
                    "fr",
                    "gp",
                    "mq",
                    "gf",
                    "re",
                    "pm",
                    "yt",
                    "nc",
                    "pf",
                    "mf",
                    "tf"
                  ] }
        });
      },
      searchMapOnInput() {
        this.searchBox.addListener("place_changed", async () => {
          const places = this.searchBox.getPlace();
          const latLong = {
            "lat" : places.geometry.location.lat(),
            "lng" : places.geometry.location.lng()
          };
          await this.fetchPaharmacies(latLong)
          this.handlePharmacies(this.$store.state.pharmacies.search)
          this.map.setCenter(latLong)
          this.map.setZoom(14)
          this.scrollToMap()
        });
      },
      searchOnMapClick() {
        const mapMessage = i18n.t('pharmacyOrder.main-page.map-message');
        let infoWindow = new this.google.maps.InfoWindow({
          content: mapMessage,
          position: this.mapConfig.center,
        })
        infoWindow.open(this.map);
        this.map.addListener("click", async (mapsMouseEvent) => {
          infoWindow.close();
          infoWindow = new this.google.maps.InfoWindow({
            position: mapsMouseEvent.latLng,
          });
          let latLong = mapsMouseEvent.latLng.toJSON();
          await this.fetchPaharmacies(latLong)
          this.handlePharmacies(this.$store.state.pharmacies.search)
          this.map.setCenter(latLong)
          this.map.setZoom(14)
          this.scrollToMap()
        });
      },
      fetchPharmaciesAroundMe() {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
              if(result.state === 'denied') alert("La géolocalisation n'est pas activée sur votre navigateur. Veuillez l'activer ou entrer votre adresse manuellement.")
        })
        navigator.geolocation.getCurrentPosition(async (position) => {
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            const latLong = {
              "lat": lat,
              "lng": lng
            }
            await this.fetchPaharmacies(latLong)
            this.handlePharmacies(this.$store.state.pharmacies.search)
            this.map.setCenter(latLong)
            this.map.setZoom(14)
            this.scrollToMap()
        });
      },
      async fetchPaharmacies(latLong) {
        let lng = latLong.lng;
        let lat = latLong.lat;
        const radius = 9000;
        await this.$store.dispatch('pharmaciesFetch', { lat, lng, radius })
      },
      handlePharmacies(pharmacies){
        this.cleanMarkers();
        this.placeMarkers(pharmacies)
      },
      placeMarkers(pharmacies) {
        let map = this.map;

        for (let i = 0; i < pharmacies.length; i++) {
          if(pharmacies[i]) {
            let icon = null;
            pharmacies[i].provider !== null ? icon = pictoPharmaProvider : icon = pictoPharmaNormal;

            const marker = new this.google.maps.Marker({
                position: {
                    lat: pharmacies[i].location.lat,
                    lng: pharmacies[i].location.lon
                },
                map,
                icon: icon
            });
            marker.addListener("click", () => {
                const id = pharmacies[i].siret+'-'+pharmacies[i].no_finess_et;
                console.log(id)
                const card = document.getElementById(id);
                document.querySelectorAll(".card_pharmacy_active").forEach(function (elem) {
                    elem.classList.remove('card_pharmacy_active');
                })
                card.className += ' card_pharmacy_active ';
                let y = card.getBoundingClientRect().top + window.pageYOffset - card.offsetHeight;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
            this.markers.push(marker);
          }
        }
      },
      cleanMarkers(){
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
      },
      scrollToMap() {
        const map = document.getElementById("map");
        let y = map.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }
  }
</script>

<style lang="scss" scoped>
.main-map-container{
  position: relative;
  height: calc(100vh - 95px);
  @media (max-width: 768px){
    height: calc(100vh - 165px);
  }
}
.click-collect-search,.click-collect-find{
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  input{
    border-bottom: 1px solid lightgray;
    max-width: 500px;
    &:focus{
      outline: none;
    }
    &::placeholder{
      font-style: normal;
    }
  }
}
.click-collect-find{
  padding:15px;
  bottom: 0;
  left: 0;
  button{
    font-weight: bold;
    @include onMobile{
      max-width: 175px;
      font-size: 13px;
    }
  }
}
.click-collect-search {
  padding:20px;
  @include onMobile{
    padding: 10px;
    font-size: 13px;
  }
  top: 0;
  left: 0;
  background: white;
  border-radius: 0 0 0.3rem 0;
}
.google-map{
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.search{
  width: 100%;
  background: white;
  border: gray;
  border-radius: unset;
  padding: 10px 10px 10px 0;
}
.gm-style-iw-d{
  font-weight: bold;
  padding: 10px;
}
</style>