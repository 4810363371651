var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-upload-button", class: { uploading: _vm.uploading } },
    [
      _c("label", { staticClass: "container" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(
            _vm._s(
              _vm.fileName || _vm.label || _vm.$t("fileUploadButton.label")
            )
          )
        ]),
        _c(
          "span",
          { staticClass: "button" },
          [
            _c("FontAwesomeIcon", {
              attrs: {
                spin: _vm.uploading,
                icon: _vm.uploading ? _vm.$icons.spinner : _vm.$icons.upload
              }
            })
          ],
          1
        ),
        _c("input", {
          directives: [
            {
              name: "autoclick",
              rawName: "v-autoclick",
              value: _vm.autoclick,
              expression: "autoclick"
            }
          ],
          attrs: { type: "file", disabled: _vm.uploading },
          on: { change: _vm.handleChange }
        })
      ]),
      _vm.errorMessage
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }