<template>
  <div v-if="downloadableClaims.length > 0" class="appointment-panel-claims">
    <div v-for="claim in downloadableClaims">
      <ButtonRed
        :busy="isDownloadingClaim"
        :icon="$icons.pdf"
        small
        @click="downloadClaim(claim.id)"
      >
        {{ $t('appointmentPanelClaims.download') }}
      </ButtonRed>
    </div>
  </div>
</template>

<script>
  import ButtonRed from '@/components/ButtonRed';
  import Encounters from '@/api/encounters';
  import download from 'downloadjs';

  export default {
    name: 'AppointmentPanelClaims',
    components: { ButtonRed },
    data() {
      return {
        isDownloadingClaim: false,
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      encounter() {
        return this.$store.state.encounters.current;
      },
      shortEncounterId() {
        return this.encounter.id.substring(0, 8);
      },
      downloadableClaims() {
        if (!this.appointment.payload.hasClaim || !this.encounter.claims) {
          return [];
        }

        return this.encounter.claims.filter(claim => claim.downloadable);
      },
    },
    methods: {
      async downloadClaim(claimId) {
        this.isDownloadingClaim = true;
        const claim = await Encounters.getClaim(claimId);
        download(claim, this.$t('appointmentPanelClaims.filename', { id: this.shortEncounterId }));
        this.isDownloadingClaim = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-claims div {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .base-button {
    width: 100%;
  }
</style>
