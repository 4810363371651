<template>
  <AppLayout>
    <div class="row g-0 justify-content-md-center">
      <div class="card-block order-1 col-lg-4 col-xl-3">
        <div v-if="this.$store.state.pharmacies.isFetching">
          <div class="p-5 w-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        </div>
        <div v-else>
          <div v-if="!this.$store.state.pharmacies.search.length">
            <p class="text-center p-5" v-html="$t('pharmacyOrder.main-page.no-pharmacy')"/>
          </div>
          <div v-else>
            <div v-for="pharmacy in this.$store.state.pharmacies.search">
              <PharmacyCard
                  v-if="pharmacy"
                  :pharmacy="pharmacy"
                  :profileId="profile.id"
                  :premium="profile.premium"
                  :appointmentId="localAppointmentId"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="map-container" class="order-lg-1 order-xl-1 col-lg-8 col-xl-9">
        <GoogleMapLoader
            :mapConfig="mapConfig"
        />
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../components/AppLayout";
import ButtonGreen from "../../components/ButtonGreen";
import GoogleMapLoader from "../../components/Pharmacy/GoogleMapLoader";
import PharmacyCard from "../../components/Pharmacy/PharmacyCard";
import AppPopIn from "../../components/AppPopIn";
import Spinner from "../../components/Pharmacy/Spinner";

export default {
  name: 'PharmacyMapAddressPage',
  components: {Spinner, AppPopIn, PharmacyCard, AppLayout, ButtonGreen, GoogleMapLoader},
  props: {
    appointmentId: {
      type: String
    }
  },
  data() {
    return {
      localAppointmentId: null
    }
  },
  computed: {
    mapConfig () {
      return {
        center: { lat: 48.862725, lng: 2.287592 },
        zoom: 6,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
        streetViewControl: false,
        styles: [{
          featureType: "poi",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        },
          {
            featureType: "transit",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          }
        ]
      }
    },
    profile() {
      return this.$store.state.patients.profile;
    },
  },
  mounted(){
     this.currentAppointment();
     this.redirectToHomeIfNoAppointment();
     this.setLocalAppointmentId();
  },
  methods: {
     currentAppointment(){
      if(typeof this.appointmentId === 'string') {
        localStorage.setItem('currentOrderAppointment', this.appointmentId);
      }
    },
    redirectToHomeIfNoAppointment(){
      if(typeof localStorage.getItem('currentOrderAppointment') !== 'string') {
         this.$router.push({name: 'home'});
      }
    },
    setLocalAppointmentId(){
       this.localAppointmentId = localStorage.getItem('currentOrderAppointment');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
</style>