import moment from 'moment';
import SearchPractitioners from '../../api-search/practitioners';
import Appointments from '../../api/appointments';

export default {
  state: {
    autoMode: false,
    autoModeData: {},
    isSearching: false,
    searchPage: 1,
    searchPages: 1,
    searchResults: [],
    searchSeed: null,
    practitioner: null,
    product: null,
    startAt: null,
    beneficiary: null,
    reason: null,
    isCreating: false,
    firstTimeWithPractitioner: false,
    appointmentId: null,
    totalPrice: 0,
  },
  mutations: {
    NEW_APPOINTMENT_SEARCH(state) {
      state.isSearching = true;
    },
    NEW_APPOINTMENT_SEARCH_SUCCESS(state, { page, pages, practitioners, seed }) {
      state.searchPages = pages;
      state.searchPage = page;
      state.searchResults = practitioners;
      state.searchSeed = seed;
      state.isSearching = false;
    },
    NEW_APPOINTMENT_SEARCH_FAIL(state) {
      state.searchPages = 1;
      state.searchPage = 1;
      state.searchResults = [];
      state.searchSeed = null;
      state.isSearching = false;
    },
    APPOINTMENT_ID(state,  id ) {
      state.appointmentId = id;
    },
    NEW_APPOINTMENT_SELECT(state, { practitioner, product, startAt }) {
      Object.assign(state, { practitioner, product, startAt });
    },
    NEW_APPOINTMENT_SET_REASON(state, { reason }) {
      state.reason = reason;
    },
    NEW_APPOINTMENT_FIRST_TIME(state,  firstTime ) {
      state.firstTimeWithPractitioner = firstTime;
    },
    NEW_APPOINTMENT_SET_FIRST_TIME(state, { firstTime }) {
      state.firstTimeWithPractitioner = firstTime;
    },
    NEW_APPOINTMENT_SET_BENEFICIARY(state, { beneficiary }) {
      state.beneficiary = beneficiary;
    },
    NEW_APPOINTMENT_CREATE(state) {
      state.isCreating = true;
    },
    NEW_APPOINTMENT_CREATE_SUCCESS(state) {
      state.isCreating = false;
    },
    NEW_APPOINTMENT_CREATE_FAIL(state) {
      state.isCreating = false;
    },
    NEW_APPOINTMENT_CLEAR(state) {
      state.searchPages = 1;
      state.searchPage = 1;
      state.searchResults = [];
      state.searchSeed = null;
      state.practitioner = null;
      state.product = null;
      state.startAt = null;
      state.reason = null;
    },
    NEW_APPOINTMENT_SET_AUTO_MODE(state, { patientId, practitionerId, productId, startAt }) {
      state.autoMode = true;
      state.autoModeData = {
        patientId,
        practitionerId,
        productId,
        startAt,
      };
    },
    NEW_APPOINTMENT_UNSET_AUTO_MODE(state) {
      state.autoMode = false;
      state.autoModeData = {};
    },
    NEW_APPOINTMENT_SET_TOTAL_PRICE(state, { totalPrice }) {
      state.totalPrice = totalPrice;
    }
  },
  actions: {
    async newAppointmentSetAutoMode({ commit }, { patientId, practitionerId, productId, startAt }) {
      commit('NEW_APPOINTMENT_SET_AUTO_MODE', { patientId, practitionerId, productId, startAt });
    },
    async newAppointmentUnsetAutoMode({ commit }) {
      commit('NEW_APPOINTMENT_UNSET_AUTO_MODE');
    },
    async newAppointmentSearch({ commit, state }, { practitionerName, practiceCountry, specialty, location, channel, page, seed }) {
      commit('NEW_APPOINTMENT_SEARCH');
      try {
        const result = await SearchPractitioners.search(practitionerName, specialty, location, channel, page, seed, practiceCountry);
        commit('NEW_APPOINTMENT_SEARCH_SUCCESS', {
          page: result.page,
          pages: result.pages,
          practitioners: result.data,
          seed: result.seed,
        });
      } catch (e) {
        commit('NEW_APPOINTMENT_SEARCH_FAIL');
        throw e;
      }
    },
    async newAppointmentSelect({ commit }, { practitioner, product, startAt }) {
      commit('NEW_APPOINTMENT_SELECT', { practitioner, product, startAt });
    },
    newAppointmentSetReason({ commit }, { reason }) {
      commit('NEW_APPOINTMENT_SET_REASON', { reason });
    },
    newAppointmentSetFirstTime({ commit }, firstTime) {
      commit('NEW_APPOINTMENT_SET_FIRST_TIME', firstTime);
    },
    newAppointmentSetBeneficiary({ commit }, { beneficiary }) {
      commit('NEW_APPOINTMENT_SET_BENEFICIARY', { beneficiary });
    },
    async newAppointmentCreate({ commit, state }, { card }) {
      commit('NEW_APPOINTMENT_CREATE');

      try {
        await Appointments.create({
            startAt: state.startAt.format(),
            endAt: moment(state.startAt).add(state.product.duration, 'minutes').format(),
            message: state.reason,
            practitionerId: state.practitioner.id,
            patientId: state.beneficiary.isOwner ? null : state.beneficiary.id,
            productId: state.product.id,
            firstTimeWithPractitioner: state.firstTimeWithPractitioner,
            card: card ? card.id : null,
          },
        ).then(appointment => {
          commit('APPOINTMENT_ID',  appointment.id )
        });
        commit('NEW_APPOINTMENT_CREATE_SUCCESS');
      } catch (e) {
        commit('NEW_APPOINTMENT_CREATE_FAIL');
        throw e;
      }
    },
    newAppointmentClear({ commit }) {
      commit('NEW_APPOINTMENT_CLEAR');
    },
    newAppointmentSetTotalPrice({commit}, { totalPrice }) {
      commit(`NEW_APPOINTMENT_SET_TOTAL_PRICE`, { totalPrice });
    }
  },
};
