var render, staticRenderFns
import script from "./ButtonTransparent.vue?vue&type=script&lang=js&"
export * from "./ButtonTransparent.vue?vue&type=script&lang=js&"
import style0 from "../assets/base-button.scss?vue&type=style&index=0&id=3c010a80&lang=scss&scoped=true&"
import style1 from "./ButtonTransparent.vue?vue&type=style&index=1&id=3c010a80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c010a80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c010a80')) {
      api.createRecord('3c010a80', component.options)
    } else {
      api.reload('3c010a80', component.options)
    }
    
  }
}
component.options.__file = "src/components/ButtonTransparent.vue"
export default component.exports