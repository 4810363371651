import { age, capitalize, formatCivility, formatMoney, formatPhone, formatFullName } from '@/helpers/format';
import { toUpper } from 'lodash-es';
import moment from 'moment';
import Vue from 'vue';

Vue.filter('capitalize', capitalize);
Vue.filter('uppercase', toUpper);

Vue.filter('phone', formatPhone);

Vue.filter('money', formatMoney);

Vue.filter('date', (value, format) => moment(value).format(format));

Vue.filter('channel', (channel) => {
  switch (channel) {
    case 'remote':
      return 'Téléconsultation';
    case 'medical_office':
      return 'En cabinet';
    case 'home':
      return 'À domicile';
    default:
      return null;
  }
});

Vue.filter('appointmentStatus', (status, canBeStarted) => {
  switch (status) {
    case 'CONFIRMED':
      return canBeStarted ? 'Accessible' : 'Confirmé';
    case 'TERMINATED':
      return 'En attente de rapport';
    case 'CLOSED':
      return 'Terminé';
    case 'CANCELLED_BY_PATIENT':
    case 'CANCELLED_BY_PRACTITIONER':
      return 'Annulé';
    case 'NEW_BY_PATIENT':
    case 'NEW_BY_PRACTITIONER':
      return 'En attente de validation';
    case 'PAYMENT_REQUIRED':
      return 'En attente de paiement';
    default:
      return null;
  }
});

Vue.filter('civility', (value, short = false) => {
  return formatCivility(value, short);
});

Vue.filter('age', age);

Vue.filter('fullName', formatFullName);
