import api from './';
import { Moment } from 'moment';
import { normalizeAppointment, normalizeAppointments } from '@/helpers/normalizers';

let startPath = process.env.ENVIRONMENT === "local" ? "appointments" : "Appointment"

export default {
  /**
   * @param {Moment} startDate
   * @param {Moment|null} endDate
   * @returns {Promise<unknown>}
   */
  find: (startDate, endDate = null) => {
    let searchParams = new URLSearchParams();

    if (endDate) {
      searchParams.set('start[]', `gt${startDate.format()}`);
      searchParams.set('start[]', `lt${endDate.format()}`);
    } else {
      searchParams.set('start[]', startDate.format());
    }
    searchParams.set('_count', '9999');

    return api.get(startPath, { searchParams }).json().then(response => normalizeAppointments(response.data));
  },
  checkIn: (id) => api.patch(`${startPath}/${id}`, { json: { status: 'checked-in' } }).json().then(normalizeAppointment),
};
