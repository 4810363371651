<template>
  <div class="redirect-card">
    <div><img :src="require(`@/assets/images/${imgCard}`)"/></div>
    <span> {{title}} </span>
    <p> {{ description }} </p>
    <ButtonGreen @click="toLink" rounded class="button"> {{ button }} </ButtonGreen>
  </div>
</template>

<script>
import ButtonGreen from '@/components/ButtonGreen';

export default {
  name: 'CardWithRedirection',
  components: {
    ButtonGreen,
  },

  props: {
    title: {
      type:String
    },
    description: {
      type: String
    },
    imgCard: {
      type: String,
      required: false
    },
    button: {
      type: String
    },
    toLink: {
      type: Function
}
  },

  data: {
    id: null
  },

  async mounted() {
    await this.loadAppointments();
  },

  computed: {
    newAppointmentId() {
      return this.$store.state.newAppointment.appointmentId;
    },
  },
  methods: {
    async loadAppointments() {
      try {
        await this.$store.dispatch('appointmentsFetchAll', { page: this.currentPage });
      } catch (e) {
        this.$addError(this.$t('appointmentsPage.error-load'));
        throw e;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
  &> p {
    flex: 1 100%;
    font-weight: 500;
  }
}

.button {
  width: max-content;
}

.redirect-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30rem;
  background: white;
  padding: 3rem;
  border-radius: 9px;
  height: 350px;
  & > div {
    display: flex;
    height: 45px;
    align-items: center;
  }
  & > span {
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #F46868;
    font-weight: bold;
    font-size: 2rem;
    font-family: "Gotham Rounded";
  }
  p {
    color: black;
    font-weight: bold;
    margin-bottom: 2.5rem;
  }
}
img {
  width: 3.5rem;
  margin-bottom: 1.375rem;
}
.button {
  width: max-content;
}
</style>
