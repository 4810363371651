<!--
Emitted events:
- upload
-->
<template>
  <div class="shared-files">
    <div class="files-group">
      <label> {{ $t('sharedFiles.title') }} </label>
      <ModuleLoader v-if="isFetching" />
      <div v-else class="files">
        <File
          v-for="file in patientFiles"
          :id="file.id"
          :key="file.id"
          removable
          @removed="reloadFiles"
        >
          {{ file.name }}
        </File>
        <File v-if="isAttaching" id="">
          <FontAwesomeIcon :icon="$icons.spinner" spin />
        </File>
        <p v-else-if="patientFiles.length === 0" class="no-share"> {{ $t('sharedFiles.not-shared') }} </p>
      </div>
    </div>
    <div class="files-group">
      <label> {{ $t("sharedFiles.shared-by-practitioner") }} </label>
      <ModuleLoader v-if="isFetching" />
      <div v-else class="files">
        <File
          v-for="file in practitionerFiles"
          :id="file.id"
          :key="file.id"
        >
          {{ file.name }}
        </File>
        <p v-if="practitionerFiles.length === 0" class="no-share"> {{ $t('sharedFiles.practitioner-not-shared') }} </p>
      </div>
    </div>
    <div class="files-group">
      <label> {{ $t('sharedFiles.shared-new-file') }} </label>
      <FileUploadButton :label="$t('sharedFiles.file-upload-button')"
                        @change="handleFileUpload" />
      <a class="reload-files" href="#" @click.prevent="reloadFiles">
        <FontAwesomeIcon :icon="$icons.reload" />
        {{ $t('sharedFiles.reload-list-document') }}
      </a>
    </div>
  </div>
</template>

<script>
  import ModuleLoader from './ModuleLoader';
  import FileUploadButton from './Consultation/CallingInterface/FileUploadButton';
  import File from './Consultation/CallingInterface/File';

  export default {
    name: 'SharedFiles',
    components: { File, FileUploadButton, ModuleLoader },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      practitionerId: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.reloadFiles();
    },
    computed: {
      isFetching() {
        return this.$store.state.sharedFiles.isFetchingAll;
      },
      isAttaching() {
        return this.$store.state.sharedFiles.isAttaching;
      },
      patientFiles() {
        return this.$store.state.sharedFiles.patientFiles;
      },
      practitionerFiles() {
        return this.$store.state.sharedFiles.practitionerFiles;
      },
    },
    methods: {
      async reloadFiles() {
        try {
          await this.$store.dispatch('sharedFilesFetchAllByAppointment', { appointmentId: this.appointmentId });
        } catch (e) {
          this.$addError(this.$t('sharedFiles.file-upload.error'));
          throw e;
        }
      },
      /**
       * @param {Blob}   file
       * @param {string} fileName
       */
      async handleFileUpload(file, fileName) {
        try {
          await this.$store.dispatch('sharedFilesUploadAndAttach', {
            file,
            fileName,
            appointmentId: this.appointmentId,
            practitionerId: this.practitionerId,
          });

          await this.$addSuccess(this.$t('sharedFiles.file-upload.success'));
          await this.reloadFiles();

          this.$emit('upload');
        } catch (e) {
          this.$addError(this.$t('sharedFiles.file-upload.error'));
          throw e;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .files-panel {
    font-size: 1.4rem;
    z-index: 1;
    flex: 3;
    padding: 8rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .files-group {
    padding: 2rem 0;
    border-bottom: 1px solid $lightTextColor;

    &:last-child {
      border: none;
    }

    label {
      font-size: 1.4rem;
      font-weight: bold;
      display: block;
      margin-bottom: 2rem;
    }
  }

  .files {
    display: flex;
    flex-wrap: wrap;
  }

  .upload-file-container {
    display: flex;
    align-items: center;

    p {
      margin: 0 2rem 0 0;
    }
  }

  .upload-file-button {
    font-size: 2rem;
    float: right;
    padding: 1.5rem 6rem;
    color: $lightTextColor;
    border: none;
    border-radius: 4rem;
    background-color: $altColor;
  }

  .reload-files {
    font-size: 1.4rem;
    display: block;
    text-align: center;
    color: $mainColor;

    :first-child {
      margin-right: 1rem;
    }
  }

  .no-share {
    margin: 0;
  }
</style>
