import AppointmentPanel from '@/components/AppointmentPanel';
import { extractAccessTokenFromHash, hasAccessToken, logout, redirectToAuth } from '@/helpers/auth';
import { getLocalStorage, getSetting, hasLocalStorage, isDevelopment } from '@/helpers/tools';
import AppointmentApprovedPage from '@/pages/AppointmentApprovedPage';
import AppointmentRejectedPage from '@/pages/AppointmentRejectedPage';
import AppointmentsPage from '@/pages/AppointmentsPage';
import IncompatibleBrowserPage from '@/pages/IncompatibleBrowserPage';
import NewAppointmentBeneficiary from '@/pages/NewAppointmentBeneficiary.vue';
import NewAppointmentPage from '@/pages/NewAppointmentPage';
import NewAppointmentPaymentPage from '@/pages/NewAppointmentPaymentPage';
import NewAppointmentReasonPage from '@/pages/NewAppointmentReasonPage';
import NewAppointmentRequestedPage from '@/pages/NewAppointmentRequestedPage';
import NewAppointmentSearchPage from '@/pages/NewAppointmentSearchPage';
import NewAppointmentSummaryPage from '@/pages/NewAppointmentSummaryPage';
import NotFoundPage from '@/pages/NotFoundPage';
import ProfileAccessPage from '@/pages/Profile/ProfileAccessPage';
import ProfileBeneficiariesPage from '@/pages/Profile/ProfileBeneficiariesPage.vue';
import ProfileEditPage from '@/pages/Profile/ProfileEditPage';
import ProfileHealthPage from '@/pages/Profile/ProfileHealthPage.vue';
import ProfileCommandsPage from '@/pages/Profile/ProfileCommandsPage.vue';
import ProfilePaymentPage from '@/pages/Profile/ProfilePaymentPage.vue';
import ProfileViewPage from '@/pages/Profile/ProfileViewPage';
import ProfilePage from '@/pages/ProfilePage';
import ProfileStatusPage from '@/pages/Profile/ProfileStatusPage.vue'
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import Vue from 'vue';
import Router from 'vue-router';
import AppointmentAnswerPage from '../pages/AppointmentAnswerPage';
import store from './store';
import PharmacyPage from "../pages/Pharmacy/PharmacyPage";
import PharmacyMapAddressPage from "../pages/Pharmacy/PharmacyMapAddressPage";
import PharmacySuccessPage from "../pages/Pharmacy/PharmacySuccessPage";
import PharmacyOrderTypePage from "../pages/Pharmacy/PharmacyOrderTypePage";
import PharmacyOrderAddressPage from "../pages/Pharmacy/PharmacyOrderAddressPage";
import PharmacyOrderPaymentPage from "../pages/Pharmacy/PharmacyOrderPaymentPage";
import ConstantesPage from "../pages/ConstantesPage";
import ProfileSummaryPage from "@/pages/Profile/ProfileSummaryPage.vue";
import NewAppointmentFirstTime from "@/pages/NewAppointmentFirstTime.vue";
import NewAppointmentPriceProduct from "@/pages/NewAppointmentPriceProduct.vue";

Vue.use(Router);

/*
Available routes meta:
- public: If true, allow accessing the route without being authenticated
- ignore: If true, the URL will not be accessible directly (used for children)
- title: If set, will be used as the page <title>
- reloadProfile: If true, the profile will be reloaded from server before displaying the page
*/

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { public: true },
      redirect: { name: 'appointments' },
    },
    {
      path: '/profile',
      component: ProfilePage,
      name: 'profile',
      meta: { ignore: true },
      children: [
        {
          path: 'view',
          name: 'profile-view',
          component: ProfileViewPage,
          meta: { title: 'Mon profil' },
        },
        {
          path: 'edit',
          name: 'profile-edit',
          component: ProfileEditPage,
          meta: { title: 'Editer mon profil', reloadProfile: true },
        },
/*        {
          path: 'status',
          name: 'profile-status',
          component: ProfileStatusPage,
          meta: { title: 'Mon statut' },

        },*/
/*        {
          path: 'status/payment',
          name: 'profile-summary-page',
          component: ProfileSummaryPage,
          meta: { title: 'Paiement' },
        },*/
        {
          path: 'health',
          name: 'profile-health',
          component: ProfileHealthPage,
          meta: { title: 'Santé' },
        },
        {
          path: 'payment',
          name: 'profile-payment',
          component: ProfilePaymentPage,
          meta: {
            title: 'Paiement',
            ownerOnly: true,
          },
        },
        {
          path: 'commandes',
          name: 'profile-commands',
          component: ProfileCommandsPage,
          meta: {
            title: 'Commandes',
            ownerOnly: true,
          },
        },
        {
          path: 'beneficiaries',
          name: 'profile-beneficiaries',
          component: ProfileBeneficiariesPage,
          meta: {
            title: 'Mes proches',
            ownerOnly: true,
          },
        },
        {
          path: 'access',
          name: 'profile-access',
          component: ProfileAccessPage,
          props: (route) => {
            return {
              accessTokenAction: route.query.accessTokenAction,
              accessTokenId: route.query.accessTokenId,
            };
          },
          meta: { title: 'Mes accès' },
        },
      ],
    },
    {
      path: '/appointments',
      name: 'appointments',
      component: AppointmentsPage,
      meta: { title: 'Mes rendez-vous' },
      children: [
        {
          path: ':id',
          name: 'appointment-view',
          component: AppointmentPanel,
          meta: { title: 'Votre rendez-vous' },
          props: (route) => {
            return {
              appointmentId: route.params.id,
              backPath: '/appointments',
            };
          },
        },
      ],
    },
    {
      path: '/new-appointment',
      name: 'new-appointment',
      component: NewAppointmentPage,
      meta: { title: 'Nouveau rendez-vous' },
      async beforeEnter(to, from, next) {
        if (store.state.newAppointment.autoMode) {
          return next({ name: 'new-appointment-search' });
        }

        if (!to.query.practitionerId || !to.query.productId || !to.query.date) {
          return next();
        }

        await store.dispatch('newAppointmentSetAutoMode', {
          patientId: to.query.patientId,
          practitionerId: to.query.practitionerId,
          productId: to.query.productId,
          startAt: moment(to.query.date),
        });

        return next({ name: 'new-appointment', replace: true });
      },
    },
    {
      path: '/new-appointment/search',
      name: 'new-appointment-search',
      component: NewAppointmentSearchPage,
      meta: { title: 'Nouveau rendez-vous' },
      async beforeEnter(to, from, next) {
        if (store.state.newAppointment.autoMode || to.query.q || to.query.s || to.query.l) {
          return await next();
        }

        return await next({ name: 'new-appointment' });
      },
      props: (route) => {
        return {
          practitionerName: route.query.q,
          specialty: route.query.s,
          location: route.query.l,
          channel: route.query.c,
          practiceCountry: route.query.practiceCountry,
          page: parseInt(route.query.page) || 1,
          seed: parseInt(route.query.seed) || null,
        };
      },
    },
    {
      path: '/new-appointment/reason',
      name: 'new-appointment-reason',
      meta: { title: 'Motif de consultation' },
      component: NewAppointmentReasonPage,
    },
    {
      path: '/new-appointment/first-time',
      name: 'new-appointment-first-time',
      meta: { title: 'Nouveau patient' },
      component: NewAppointmentFirstTime,
    },
    {
      path: '/new-appointment/price-product',
      name: 'new-appointment-price-product',
      meta: { title: 'Prix de la consultation' },
      component: NewAppointmentPriceProduct,
    },
    {
      path: '/new-appointment/beneficiary',
      name: 'new-appointment-beneficiary',
      meta: { title: 'Choix du patient' },
      component: NewAppointmentBeneficiary,
    },
    {
      path: '/new-appointment/payment',
      name: 'new-appointment-payment',
      meta: { title: 'Moyen de paiement' },
      component: NewAppointmentPaymentPage,
    },
    {
      path: '/new-appointment/summary',
      name: 'new-appointment-summary',
      meta: { title: 'Récapitulatif de rendez-vous' },
      component: NewAppointmentSummaryPage,
      props: (route) => {
        return {
          afterComponentPayment: route.params.afterComponentPayment,
        };
      },
    },
    {
      path: '/new-appointment/requested',
      name: 'new-appointment-requested',
      component: NewAppointmentRequestedPage,
      meta: { title: 'Demande de rendez-vous envoyée' },
    },
    {
      path: '/appointment/approved',
      name: 'appointment-approved',
      component: AppointmentApprovedPage,
      meta: {
        title: 'Demande de rendez-vous acceptée',
        public: true,
      },
    },
    {
      path: '/appointment/rejected',
      name: 'appointment-rejected',
      component: AppointmentRejectedPage,
      meta: {
        title: 'Demande de rendez-vous refusée',
        public: true,
      },
    },
    {
      path: '/appointment/:id/answer',
      name: 'appointment-answer',
      component: AppointmentAnswerPage,
      meta: {
        title: 'Réponse de la demande de rendez-vous',
        public: true,
      },
      props: (route) => {
        return {
          appointmentId: route.params.id,
          token: route.query.token,
        };
      },
    },
    {
      path: '/consultation/:appointmentId',
      name: 'consultation',
      component: () => ({ component: import('../pages/ConsultationPage') }),
      meta: { title: 'Consultation' },
      props: true,
      beforeEnter(to, from, next) {
        const handleLoaded = async () => {
          if (!store.hasModule('sdk')) {
            store.registerModule('sdk', (await import('./store_modules/sdk')).default);
          }

          next();
        };

        if (window.PlatformSDK) {
          return handleLoaded();
        }

        const SDKScriptElement = document.createElement('script');
        SDKScriptElement.src = isDevelopment
          ? `//assets.hellocareplatform.com/sdk/js/dev/release/0.6/hellocare-sdk.dev.js`
          : `//assets.hellocareplatform.com/sdk/js/v0.6.17/hellocare-sdk.min.js`;
        SDKScriptElement.async = true;
        SDKScriptElement.onload = handleLoaded;
        document.head.appendChild(SDKScriptElement);
      },
    },
    {
      path: '/pharmacy',
      name: 'pharmacy',
      component: PharmacyPage,
      meta: { title: 'Click & collect & delivery' },
      props: (route) => {
        return {
          appointmentId: route.params.appointmentId,
        };
      },
    },
    {
      name: 'pharmacy-order-type',
      path: '/pharmacy/selection/type',
      component: PharmacyOrderTypePage,
      meta: { title: 'Click & collect & delivery' },
      props: (route) => {
        return {
          appointmentId: route.params.appointmentId,
          type: route.params.type,
        };
      },
    },
    {
      name: 'pharmacy-selection',
      path: '/pharmacy/selection',
      component: PharmacyMapAddressPage,
      meta: { title: 'Click & collect & delivery' },
      props: (route) => {
        return {
          appointmentId: route.params.appointmentId
        };
      },
    },
    {
      name: 'pharmacy-order-address',
      path: '/pharmacy/selection/address',
      component: PharmacyOrderAddressPage,
      meta: { title: 'Click & collect & delivery' }
    },
    {
      name: 'pharmacy-order-payment',
      path: '/pharmacy/selection/payment',
      component: PharmacyOrderPaymentPage,
      meta: { title: 'Click & collect & delivery' }
    },
    {
      name: 'pharmacy-success',
      path: '/pharmacy/selection/success',
      component: PharmacySuccessPage,
      meta: { title: 'Click & collect & delivery' },
      props: (route) => {
        return {
          pharmacyrs: route.params.pharmacyrs
        };
      },
    },
    {
      name: 'constantes',
      path: '/constantes',
      component: ConstantesPage,
      meta: {title: 'Constantes'}
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter() {
        logout();
        redirectToAuth(false);
      },
    },
    {
      path: '/incompatible-browser',
      name: 'incompatible-browser',
      component: IncompatibleBrowserPage,
      meta: { title: 'Erreur - Navigateur incompatible' },
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundPage,
      meta: { title: 'Erreur - Page inexistante' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'not-found') {
    return next();
  }

  if (extractAccessTokenFromHash(to.hash)) {
    if (hasLocalStorage('postAuthUrl')) {
      return window.location = getLocalStorage('postAuthUrl', true);
    }

    return router.replace({ ...to, hash: undefined });
  }

  if (to.meta.ignore) {
    return next(false);
  }

  if (!to.meta.public && !hasAccessToken()) {
    redirectToAuth(true, 'signup' in to.query);
    return next(false);
  }

  await store.dispatch('appNotificationRedirected');
  await store.dispatch('appNavigationShrink');

  if (to.meta.title) {
    document.title = `${to.meta.title} - Hellocare, votre solution de télémédecine`;
  } else {
    document.title = 'Hellocare - Votre solution de télémédecine';
  }

  // Fetch profile on first app load or if route is asking for a reload
  if (!to.meta.public && (from.name === null || from.meta.public || to.meta.reloadProfile)) {
    await store.dispatch('appLoaderShow');
    await store.dispatch('patientFetchProfile');

    const viewedGuideStepsIds = store.state.patients.profile.metadata.viewedGuideStepsIds || [];

    await store.dispatch('vueGuideSetup', {
      viewedStepsIds: viewedGuideStepsIds,
    });

    if (getSetting('SENTRY_ENABLED')) {
      Sentry.configureScope(scope => scope.setUser({
        id: store.state.patients.profile.id,
        email: store.state.patients.profile.email,
      }));
    }

    await store.dispatch('appLoaderHide');
  }

  return next();
});

export default router;
