import { getSetting, removeLocalStorage, setLocalStorage } from './tools';
import jwtDecode from 'jwt-decode';

export function extractAccessTokenFromHash(hash) {
  const tokenMatch = hash.match(/^#access_token=([a-zA-Z0-9]+)/);
  if (tokenMatch) {
    localStorage.setItem('accessToken', tokenMatch[1]);
    return true;
  }
  return false;
}

/**
 * Check if JWT token is expired or about to expire within 24 hours.
 *
 * @param {string} jwt
 */
export function isJwtExpired(jwt) {
  if(!jwt) {
    return true;
  }

  const now = new Date();
  const { exp: expiration } = jwtDecode(jwt);

  return expiration * 1000 < now.getTime() + (24 * 60 * 60 * 1000);
}

export function clearAccessToken() {
  localStorage.removeItem('accessToken');
}

export function logout() {
  clearAccessToken();
}

export function hasAccessToken() {
  return null !== localStorage.getItem('accessToken');
}

export function redirectToAuth(returnToCurrentPageAfterLogin = true, goToSignup = false) {
  if (returnToCurrentPageAfterLogin) {
    setLocalStorage('postAuthUrl', window.location.href);
  } else {
    removeLocalStorage('postAuthUrl');
  }

  let url = getSetting('URL_SSO_FRONT');

  if (goToSignup) {
    url = `${url}/signup`;
  }

  window.location = url;
}
