<!--
Emitted events:
- create
- ignore
- close

Slots:
- submit-button
- cancel-button
- ignore-button
-->

<template>
  <form class="credit-card-add-form" @submit.prevent="handleSubmit">
    <div class="add-information">
      <div id="card-number"></div>
        <div id="card-expiry"></div>
        <div id="card-cvc"></div>
    </div>

    <slot name="check-card-registered"/>

    <div class="buttons">
      <ButtonTransparent
          :busy="submitting"
          :icon="$icons.chevronLeft"
          icon-to-left
          rounded
          @click="$emit('close')"
      >
        <slot name="cancel-button"> {{ $t('creditCardAddForm.button.cancel') }} </slot>
      </ButtonTransparent>
      <ButtonGreen
          :busy="submitting"
          center
          class="submit-button"
          rounded
          type="submit"
      >
        <slot name="submit-button"> {{ $t('creditCardAddForm.button.submit') }} </slot>
      </ButtonGreen>
    </div>

    <div v-if="!required" class="ignore-button">
      <ButtonTransparent
          :busy="submitting"
          :icon="$icons.chevronRight"
          rounded
          @click="$emit('ignore')"
      >
        <slot name="ignore-button"> {{ $t('creditCardAddForm.button.ignore') }} </slot>
      </ButtonTransparent>
    </div>
  </form>
</template>

<script>
import ButtonGreen from '@/components/ButtonGreen';
import ButtonTransparent from '@/components/ButtonTransparent';
import { cardCvc, cardExpiry, cardNumber, stripe } from '@/helpers/stripe';
import SetupIntents from '@/api/setup_intents';
import FormCheckbox from "@/components/Form/FormCheckbox.vue";

export default {
  name: 'CreditCardAddForm',
  components: {FormCheckbox, ButtonTransparent, ButtonGreen },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
    shouldSave: {
      required: false,
    }
  },
  data() {
    return {
      submitting: false,
      cardNumber: '',
      cardNumberError: null,
      cardExpiry: '',
      cardExpiryError: null,
      cardCvc: '',
      cardCvcError: null,
      cardRegister: false,
    };
  },
  mounted() {
    cardNumber.mount('#card-number');
    cardExpiry.mount('#card-expiry');
    cardCvc.mount('#card-cvc');
    cardNumber.update({ placeholder: 'Numéro de carte' })
    cardExpiry.update({ placeholder: this.$t('creditCardAddForm.date-expiry') });
    cardCvc.update({ placeholder: 'CVC' });
  },
  beforeDestroy() {
    cardNumber.unmount();
    cardExpiry.unmount();
    cardCvc.unmount();
  },
  methods: {
    async handleSubmit() {
      this.submitting = true;

      try {
        const { clientSecret } = await SetupIntents.create();

        const { error } = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardNumber,
          },
        });
        this.$store.commit('SAVE_CARD', this.shouldSave);

        if (error) {
          await this.$addError(error.message);
        } else {
            await this.$store.dispatch('paymentMethodsFetchAll');
            await this.$emit('change', this.$store.state.paymentMethods.all[0]);
            await this.$emit('create');
        }

      } catch (e) {
        console.error('Erreur', e);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.security {
  display: flex;
  align-items: flex-start;
  margin-top: 3.5rem;
  padding: 2.5rem 1.5rem 1.5rem;
  border-top: 1px solid $lightGrey;

  p {
    margin: 0 0 0 2rem;
  }
}

.StripeElement {
  width: 100%;
  padding: 1rem 1rem;
  color: #1F1F1F;
}

.buttons {
  @include onMobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    justify-content: space-evenly;
    gap: 1rem;
    flex-wrap: wrap;
  }
  @include onMobileSmall {
    margin-bottom: 2rem;
  }
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  text-align: center;
  background: white;

}

.ignore-button {
  margin-top: 1rem;
  text-align: center;
}

.add-information {
  display: flex;
  border: 1px solid #00000048;
  margin-bottom: 1.8rem;
  border-radius: 9px;
  div {
    flex: 1;
    @include onMobile {
      flex-direction: row;
    }
    &:first-child {
      flex: 5;
      label {
        border-right: none;
      }
    }
  }
}
</style>
