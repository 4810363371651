var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    [
      _c("AppProgress", {
        attrs: {
          current: 5,
          total: 8,
          position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
        }
      }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("RecapTitle", {
            attrs: {
              title: _vm.practitioner.title + " " + _vm.practitioner.lastName,
              date: _vm.dateAppointment(this.newAppointment.startAt)
            }
          }),
          _c("SectionTitle", [
            _vm._v("Avez-vous déjà consulté avec ce praticien ?")
          ]),
          _c("span", { staticClass: "description" }, [
            _vm._v(
              "Renseignez le motif précis de votre demande de rendez-vous."
            )
          ]),
          _c(
            "div",
            { staticClass: "first-time" },
            [
              _c(
                "FormRadio",
                {
                  attrs: { checked: this.firstTimeWithPractitioner },
                  on: {
                    change: function($event) {
                      return _vm.consentCheckbox(true)
                    }
                  }
                },
                [_vm._v("\n        Non\n      ")]
              ),
              _c(
                "FormRadio",
                {
                  attrs: { checked: !this.firstTimeWithPractitioner },
                  on: {
                    change: function($event) {
                      return _vm.consentCheckbox(false)
                    }
                  }
                },
                [_vm._v("\n        Oui\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "ButtonTransparent",
                {
                  attrs: { icon: _vm.$icons.chevronLeft, "icon-to-left": "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentReasonPage.back")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "ButtonGreen",
                {
                  attrs: {
                    icon: _vm.$icons.chevronRight,
                    rounded: "",
                    type: "submit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleFirstTime(true)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentReasonPage.continue")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }