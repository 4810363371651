var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h3",
    { staticClass: "group-title" },
    [
      _c("FontAwesomeIcon", { staticClass: "icon", attrs: { icon: _vm.icon } }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }