var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "options-menu", class: { opened: _vm.areOptionsVisible } },
    [
      _c(
        "div",
        {
          staticClass: "button",
          on: {
            click: function($event) {
              _vm.areOptionsVisible = !_vm.areOptionsVisible
            }
          }
        },
        [
          _vm._v("\n    Options "),
          _c("FontAwesomeIcon", {
            attrs: { icon: _vm.$icons.verticalDots, "fixed-width": "" }
          }),
          _vm.areOptionsVisible
            ? _c("div", { staticClass: "dropdown" }, [_vm._t("default")], 2)
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }