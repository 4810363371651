import Credentials from '@/api/credentials.js';
import { isJwtExpired } from '@/helpers/auth.js';
import { getLocalStorage, setLocalStorage } from '@/helpers/tools.js';

export default {
  state: {
    tokens: [],
    currentPatientId: null,
  },
  mutations: {
    JWT_SET_PATIENT(state, { patientId }) {
      state.currentPatientId = patientId;
    },
    JWT_SET_PATIENT_SUCCESS(state, { patientId, token }) {
      state.tokens[patientId] = token;
    },
    JWT_SET_PATIENT_FAIL(state, { patientId }) {
      state.tokens[patientId] = null;
    },
  },
  actions: {
    async jwtSetPatient({ commit, state }, { patientId }) {
      commit('JWT_SET_PATIENT', { patientId });

      try {
        const storageKey = `platform-jwt-${patientId}`;
        let token = state.tokens[patientId] || getLocalStorage(storageKey);

        if (isJwtExpired(token)) {
          token = await Credentials.getPlatformJWT(patientId);
          setLocalStorage(storageKey, token);
        }

        commit('JWT_SET_PATIENT_SUCCESS', { patientId, token });
      } catch (e) {
        commit('JWT_SET_PATIENT_FAIL', { patientId });
        throw e;
      }
    },
  },
  getters: {
    platformJwtForCurrentPatient(state) {
      return state.currentPatientId ? state.tokens[state.currentPatientId] : null;
    },
  }
};
