var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.component,
    _vm._g(
      {
        tag: "component",
        staticClass: "options-menu-item",
        attrs: { to: _vm.to }
      },
      Object.assign({}, _vm.$listeners)
    ),
    [
      _c("FontAwesomeIcon", {
        staticClass: "icon",
        attrs: { icon: _vm.icon, "fixed-width": "" }
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }