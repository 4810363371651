var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "portal",
        { attrs: { to: "app-confirmation" } },
        [
          _c(
            "AppConfirmationInner",
            _vm._g(
              _vm._b(
                {
                  attrs: { busy: _vm.isBusy, payload: _vm.payload },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_vm._t("default", null, null, scope)]
                        }
                      },
                      {
                        key: "title",
                        fn: function(scope) {
                          return [_vm._t("title", null, null, scope)]
                        }
                      },
                      {
                        key: "description",
                        fn: function(scope) {
                          return [_vm._t("description", null, null, scope)]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                "AppConfirmationInner",
                _vm.$attrs,
                false
              ),
              Object.assign({}, _vm.$listeners, {
                close: _vm.close,
                busy: _vm.busy
              })
            )
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }