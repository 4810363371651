var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    { attrs: { hidden: _vm.hidden } },
    [
      _c("AppProgress", {
        attrs: {
          current: 3,
          total: 8,
          position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
        }
      }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("RecapTitle", {
            attrs: {
              title: _vm.practitioner.title + " " + _vm.practitioner.lastName,
              date: _vm.dateAppointment(this.newAppointment.startAt)
            }
          }),
          _c("SectionTitle", [
            _vm._v(_vm._s(_vm.$t("newAppointmentBeneficiary.title")))
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.$t("newAppointmentBeneficiary.appointment-for")))
          ]),
          _c("div", { staticClass: "beneficiaries" }, [
            _c(
              "div",
              [
                _vm._l(_vm.beneficiaries, function(beneficiary) {
                  return _c(
                    "Card",
                    {
                      class: {
                        selected:
                          _vm.selectedBeneficiary &&
                          _vm.selectedBeneficiary.id === beneficiary.id
                      }
                    },
                    [
                      _c("Beneficiary", {
                        attrs: {
                          cards: _vm.beneficiaries,
                          card: beneficiary,
                          selectDefault: _vm.selectDefault
                        },
                        on: { change: _vm.handleSelect }
                      })
                    ],
                    1
                  )
                }),
                _c("Card", [
                  _c(
                    "a",
                    {
                      staticClass: "add-beneficiary",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.isPanelOpened = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("beneficiaryList.add-beneficiary")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ],
              2
            )
          ]),
          _c(
            "FormCheckbox",
            {
              staticClass: "status",
              model: {
                value: _vm.status,
                callback: function($$v) {
                  _vm.status = $$v
                },
                expression: "status"
              }
            },
            [
              _vm._v(
                "\n      Si le bénéficiaire dispose d’un statut CMU, CSS, ALD ou AME cochez la case\n    "
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "ButtonTransparent",
                {
                  attrs: { icon: _vm.$icons.chevronLeft, "icon-to-left": "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentBeneficiary.back")) +
                      "\n      "
                  )
                ]
              ),
              _c(
                "ButtonGreen",
                {
                  attrs: {
                    disabled: !_vm.selectedBeneficiary,
                    icon: _vm.$icons.chevronRight,
                    rounded: ""
                  },
                  on: { click: _vm.goToNextStep }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("newAppointmentBeneficiary.continue")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "AppPanel",
            {
              attrs: {
                opened: _vm.isPanelOpened,
                title: _vm.$t("beneficiaryList.add-beneficiary")
              },
              on: {
                close: function($event) {
                  _vm.isPanelOpened = false
                }
              }
            },
            [
              _c("ProfileEditForm", {
                attrs: { "hide-avatar": "", beneficiary: true },
                on: { create: _vm.handleCreate }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }