<template>
  <header :class="{ 'with-navigation' : withNavigation }" class="app-header">
    <div class="full-name">
      {{ profile.civility | civility(true) }} {{ profile | fullName }}
    </div>
    <div class="header-right">
      <div class="current-date-time">
        {{ currentDateTime }}
      </div>
<!--      <LangBtn />-->
    </div>
  </header>
</template>

<script>
  import moment from 'moment';
  import LangBtn from "./LangBtn";
  export default {
    name: 'AppHeader',
    components: {LangBtn},
    props: {
      withNavigation: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    mounted() {
      this.$setInterval(this.refreshCurrentDateTime, 5000, true);
    },
    data() {
      return {
        currentDateTime: '',
      };
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      },
    },
    methods: {
      refreshCurrentDateTime() {
        this.currentDateTime = moment().format('dddd DD MMMM YYYY - HH:mm');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-header {
    font-size: 1.4rem;
    font-weight: 300;
    position: sticky;
    z-index: 90;
    top: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 3.7rem;
    border-bottom: 1px solid #D8D8D8;
    background-color: white;

    &:not(.with-navigation) {
      top: 0;
    }

    @include onDesktop {
      top: 0;
    }
  }

  .full-name {
    padding: 1rem;
  }

  .current-date-time {
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .header-right{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
</style>
