import { groupBy, orderBy } from 'lodash-es';
import moment from 'moment';
import Appointments from '@/api/appointments';
import PlatformAppointments from '@/api-platform/appointments';
import { formatFullName } from '@/helpers/format';

const withMeta = (appointment) => {
  if (!appointment) {
    return null;
  }

  const startAt = moment(appointment.startAt);
  const endAt = moment(appointment.endAt);

  return {
    payload: appointment,
    date: startAt.format('DD/MM/YY'),
    day: startAt.format('DD'),
    dayShort: startAt.format('ddd'),
    dayLong: startAt.format('dddd'),
    month: startAt.format('MM'),
    monthShort: startAt.format('MMM'),
    monthLong: startAt.format('MMMM'),
    year: startAt.format('YYYY'),
    time: startAt.format('HH:mm'),
    expired: endAt.isBefore(),
  };
};

export default {
  state: {
    isFetchingOne: false,
    isFetchingAll: false,
    isCancelling: false,
    isAccepting: false,
    isClosing: false,
    isReplacingPatient: false,
    current: null,
    all: [],
    currentPage: 1,
    totalPages: 1,
  },
  mutations: {
    APPOINTMENTS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    APPOINTMENTS_FETCH_ALL_SUCCESS(state, { page, pages, appointments }) {
      state.all = appointments;
      state.currentPage = page;
      state.totalPages = pages;
      state.isFetchingAll = false;
    },
    APPOINTMENTS_FETCH_ALL_FAIL(state) {
      state.all = [];
      state.isFetchingAll = false;
    },
    APPOINTMENTS_FETCH_ONE(state) {
      state.isFetchingOne = true;
    },
    APPOINTMENTS_FETCH_ONE_SUCCESS(state, { appointment }) {
      state.current = appointment;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_FETCH_ONE_FAIL(state) {
      state.current = null;
      state.isFetchingOne = false;
    },
    APPOINTMENTS_CANCEL(state) {
      state.isCancelling = true;
    },
    APPOINTMENTS_CANCEL_SUCCESS(state) {
      state.isCancelling = false;
    },
    APPOINTMENTS_CANCEL_FAIL(state) {
      state.isCancelling = false;
    },
    APPOINTMENTS_ACCEPT(state) {
      state.isAccepting = true;
    },
    APPOINTMENTS_ACCEPT_SUCCESS(state) {
      state.isAccepting = false;
    },
    APPOINTMENTS_ACCEPT_FAIL(state) {
      state.isAccepting = false;
    },
    APPOINTMENTS_REPLACE_PATIENT(state) {
      state.isReplacingPatient = true;
    },
    APPOINTMENTS_REPLACE_PATIENT_SUCCESS(state, { appointment }) {
      state.current = appointment;
      state.isReplacingPatient = false;
    },
    APPOINTMENTS_REPLACE_PATIENT_FAIL(state) {
      state.isReplacingPatient = false;
    },
    APPOINTMENTS_CLEAR_CURRENT(state) {
      state.current = null;
    },
    APPOINTMENTS_CHECK_IN(state) {},
    APPOINTMENTS_CHECK_IN_SUCCESS(state) {},
    APPOINTMENTS_CHECK_IN_FAIL(state) {},
  },
  actions: {
    async appointmentsFetchAll({ commit }, { page }) {
      commit('APPOINTMENTS_FETCH_ALL');
      try {
        const result = await Appointments.getAll(page);
        commit('APPOINTMENTS_FETCH_ALL_SUCCESS', {
          page: result.page,
          pages: result.pages,
          appointments: result.data
        });
      } catch (e) {
        commit('APPOINTMENTS_FETCH_ALL_FAIL');
        throw e;
      }
    },
    async appointmentsFetchOne({ commit, dispatch }, { id, token, abortSignal }) {
      commit('APPOINTMENTS_FETCH_ONE');
      try {
        const appointment = await Appointments.getOne(id, abortSignal, token);
        await dispatch('jwtSetPatient', { patientId: appointment.patient.id });
        commit('APPOINTMENTS_FETCH_ONE_SUCCESS', { appointment });
      } catch (e) {
        commit('APPOINTMENTS_FETCH_ONE_FAIL');
        throw e;
      }
    },
    async appointmentsReloadCurrent({ state, dispatch }) {
      const id = state.current?.id;

      if (!id) {
        return;
      }

      await dispatch('appointmentsClearCurrent');
      await dispatch('appointmentsFetchOne', { id });
    },
    async appointmentsCancel({ commit }, { id, token, reason }) {
      commit('APPOINTMENTS_CANCEL');
      try {
        await Appointments.cancel(id, token, reason);
        commit('APPOINTMENTS_CANCEL_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_CANCEL_FAIL');
        throw e;
      }
    },
    async appointmentsAccept({ commit }, { id, token}) {
      commit('APPOINTMENTS_ACCEPT');
      try {
        await Appointments.accept(id, token);
        commit('APPOINTMENTS_ACCEPT_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_ACCEPT_FAIL');
        throw e;
      }
    },
    async appointmentsReplacePatient({ commit }, { appointmentId, patientId }) {
      commit('APPOINTMENTS_REPLACE_PATIENT');
      try {
        const appointment = await Appointments.replacePatient(appointmentId, patientId);
        commit('APPOINTMENTS_REPLACE_PATIENT_SUCCESS', { appointment });
      } catch (e) {
        commit('APPOINTMENTS_REPLACE_PATIENT_FAIL');
        throw e;
      }
    },
    appointmentsClearCurrent({ commit }) {
      commit('APPOINTMENTS_CLEAR_CURRENT');
    },
    async appointmentsCheckIn({ commit }, { id }) {
      commit('APPOINTMENTS_CHECK_IN');
      try {
        await PlatformAppointments.checkIn(id);
        commit('APPOINTMENTS_CHECK_IN_SUCCESS');
      } catch (e) {
        commit('APPOINTMENTS_CHECK_IN_FAIL');
        throw e;
      }
    },
  },
  getters: {
    currentAppointmentWithMeta(state) {
      return withMeta(state.current);
    },

    orderedAppointments(state) {
      return orderBy(state.all, ['startAt'], ['desc']);
    },

    appointmentsWithMeta(state, getters) {
      return getters.orderedAppointments.reduce((accumulator, appointment) => {
        accumulator.push(withMeta(appointment));

        return accumulator;
      }, []);
    },

    appointmentsForPlanning(state, getters) {
      return groupBy(getters.appointmentsWithMeta, (withMeta) => {
        return `${withMeta.year} ${withMeta.month} ${withMeta.day}`;
      });
    },

    currentAppointmentPatientFullName(state) {
      if (!state.current) {
        return '';
      }

      return formatFullName(state.current.patient);
    },
  },
};
