var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "nav-item-child-label", attrs: { to: _vm.to } },
    [
      _c("span", { staticClass: "nav-item-child-label-text" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c("FontAwesomeIcon", {
        staticClass: "nav-item-child-label-icon",
        attrs: { icon: _vm.$icons.chevronRight }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }