<template>
  <AppLayout>
    <div class="">
      <div class="w-100">
        <div class="p-5">
          <BreadCrumb :pages="breadCrumbPages()"/>
        </div>
      </div>
      <div class="delivery-block d-flex justify-content-center align-items-center flex-column p-5" v-if="pharmacyOrder">
        <div class="p-5">
          <img alt="handshake" src="../../assets/images/picto_handShake.svg" />
        </div>
        <div class="row w-100">
          <div class="col _6 d-flex justify-content-center align-items-center py-5">
            <div class="next_button text-center" v-if="paid">
              <p class="card_already_saved">
                {{$t('pharmacyOrder.payment-page.card-saved')}}
              </p>
              <br/>
              <ButtonGreen :busy="submitting" @click="goToSuccess" ref="nex_button">
                {{$t('pharmacyOrder.payment-page.btn-finish')}}
              </ButtonGreen>
            </div>
            <div class="next_button" v-else>
              <CardPayment :providerName="this.pharmacyOrder.provider.name" :pharmacyOrder="pharmacyOrder" @paid="updatePaid" :paid="paid"/>
            </div>
          </div>
          <div class="col-separator"></div>
          <div class="col _6 py-5">
            <div class="delivery_recap">
              <p class="subtitle">{{$t('pharmacyOrder.payment-page.subtitle-one')}}</p>
              <p class="address">{{ pharmacyOrder.delivery_address }}</p>
              <p class="me_mail">{{profile.email}}</p>
              <p class="me_phone">{{profile.phone}}</p>
              <p class="subtitle">{{$t('pharmacyOrder.payment-page.subtitle-two')}}</p>
              <p class="rs">{{ pharmacyOrder.pharmacy.rs }} </p>
              <p class="ph_phone">{{ pharmacyOrder.pharmacy.telephone }} </p>
            </div>
          </div>
        </div>
        <div class="additional_infos row">
          <div class="col _8">
            <div class="security">
              <img alt="" src="@/assets/images/picto-payment.svg" />
              <p>
                {{ $t('creditCardAddForm.security') }}
              </p>
            </div>
            <div class="security">
              <img alt="" src="@/assets/images/picto-patient.svg" />
              <p>
                {{$t('pharmacyOrder.payment-page.payment-infos')}}
              </p>
            </div>
          </div>
          <div class="col _4">
            <p class="price">{{ pharmacyOrder.transaction.amount }} €</p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import ButtonGrey from "../../components/ButtonGrey";
import ButtonGreen from "../../components/ButtonGreen";
import ButtonTransparent from "../../components/ButtonTransparent";
import FormInput from "../../components/Form/FormInput";
import AppLayout from "../../components/AppLayout";
import BreadCrumb from "../../components/Pharmacy/BreadCrumb";
import CardPayment from "../../components/Pharmacy/CardPayment";

export default {
  name: 'PharmacyOrderAddressPage',
  components: {CardPayment, ButtonGrey, ButtonGreen, FormInput, AppLayout, BreadCrumb, ButtonTransparent},
  computed: {
    profile() {
      return this.$store.state.patients.profile;
    }
  },
  data() {
    return {
      submitting: false,
      pharmacyOrder: null,
      paid: false
    }
  },
  mounted() {
    this.setPharmacyOrder();
    this.redirectToHomeIfNoAppointment();
    this.redirectToHomeIfNoPharmacyOrder();
  },
  methods: {
    setPharmacyOrder() {
      this.pharmacyOrder = JSON.parse(localStorage.getItem('currentPharmacyOrder'));
    },
    updatePaid(value) {
      this.paid = value;
    },
    redirectToHomeIfNoAppointment() {
      if (typeof localStorage.getItem('currentOrderAppointment') !== 'string') {
        this.$router.push({name: 'home'});
        this.$addError("Le rendez-vous ns'existe pas.");
      }
    },
    redirectToHomeIfNoPharmacyOrder() {
      if (typeof JSON.parse(localStorage.getItem('currentPharmacyOrder')) !== 'object') {
        this.$router.push({name: 'home'});
        this.$addError("La commande n'existe pas.");
      }
    },
    getStoredPharmacyOrder() {
      return JSON.parse(localStorage.getItem('currentPharmacyOrder'));
    },
    async goToSuccess() {
      let statusCode = 201;
      this.submitting = true;
      await this.$store.dispatch('pharmaciesOrderUpdate', {
        pharmacyOrderId: this.getStoredPharmacyOrder().id,
        complete: true,
        providerName: this.pharmacyOrder.provider.name
      }).catch((error)=>{
        statusCode = error.response.status
        this.$addError("Erreur lors de la mise à jour de la commande.");
        throw error;
      });
      if(statusCode<400) {
        this.submitting = false;
        return this.$router.push({name: 'pharmacy-success', params: { pharmacyrs: this.getStoredPharmacyOrder().pharmacy.rs }});
      }
    },
    breadCrumbPages() {
      return [
        {
          'name' : 'Choix de la pharmacie',
          'url' : 'pharmacy-selection',
          'current' : false
        },
        {
          'name' : 'Livraison ou click & collect ?',
          'url' : 'pharmacy-order-type',
          'current' : false
        },
        {
          'name' : "Choix de l'adresse",
          'url' : 'pharmacy-order-address',
          'current' : false
        },
        {
          'name' : "Paiement de la livraison",
          'url' : '',
          'current' : true
        }
      ]
    }
  }
  }
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
.delivery-block{
  max-width: 1024px;
  width: 100%;
  margin: auto;
}
.delivery_recap{
  .subtitle {
    font-weight: bold;
    color: black;
  }
}
.additional_infos{
  .security {
    margin-top: 3.5rem;
    padding: 2.5rem 1.5rem 1.5rem;
    border-top: 1px solid $lightGrey;
    text-align: justify;
    display: flex;
    align-items: flex-start;
    p {
      margin: 0 0 0 2rem;
    }
  }
  .price{
    margin-top: 3.5rem;
    padding: 2.5rem 1.5rem 1.5rem;
    border-top: 1px solid $lightGrey;
    text-align: right;
    color: black;
    font-weight: bold;
    font-size: 2rem;
  }
}
.col-separator {
  width: 1px;
  margin: 0 5rem;
  padding: 0;
  background-color: $lightGrey;
}
.card_already_saved{
  color: #27D7AB;
  font-weight: bold;
}
</style>

