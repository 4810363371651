import AccessTokens from '../../api/access_tokens';

export default {
  state: {
    all: [],
    isFetchingAll: false,
  },
  mutations: {
    ACCESS_TOKENS_FETCH_ALL() {
      this.isFetchingAll = true;
    },
    ACCESS_TOKENS_FETCH_ALL_SUCCESS(state, { accessTokens }) {
      this.isFetchingAll = false;
      state.all = accessTokens;
    },
    ACCESS_TOKENS_FETCH_ALL_FAIL() {
      this.isFetchingAll = false;
    },
    ACCESS_TOKENS_CHANGE_STATUS(state, { id, status }) {
      state.all.find(accessToken => accessToken.id === id).active = status;
    },
    ACCESS_TOKENS_CHANGE_STATUS_SUCCESS(state, { accessToken }) {
      state.all = state.all.map(currentAccessToken => currentAccessToken.id === accessToken.id ? accessToken : currentAccessToken);
    },
    ACCESS_TOKENS_CHANGE_STATUS_FAIL(state, { id, previousStatus }) {
      state.all.find(accessToken => accessToken.id === id).active = previousStatus;
    },
  },
  actions: {
    async accessTokensFetchAll({ commit }) {
      commit('ACCESS_TOKENS_FETCH_ALL');
      try {
        const accessTokens = await AccessTokens.fetchAll();
        commit('ACCESS_TOKENS_FETCH_ALL_SUCCESS', { accessTokens });
      } catch (e) {
        commit('ACCESS_TOKENS_FETCH_ALL_FAIL');
        throw e;
      }
    },
    async accessTokenChangeStatus({ state, commit }, { id, status }) {
      const previousStatus = state.all.find(accessToken => accessToken.id === id).active;

      commit('ACCESS_TOKENS_CHANGE_STATUS', { id, status });
      try {
        const accessToken = status ? await AccessTokens.enable(id) : await AccessTokens.disable(id);
        commit('ACCESS_TOKENS_CHANGE_STATUS_SUCCESS', { accessToken });
      } catch (e) {
        commit('ACCESS_TOKENS_CHANGE_STATUS_FAIL', { id, previousStatus});
        throw e;
      }
    },
  },
};
