<!--
Emitted events:
- change(file, fileName)
-->
<template>
  <div class="file-upload-button" :class="{ uploading }">
    <label class="container">
      <span class="text">{{ fileName || label || $t('fileUploadButton.label') }}</span>
      <span class="button">
        <FontAwesomeIcon :spin="uploading" :icon="uploading ? $icons.spinner : $icons.upload" />
      </span>
      <input type="file" @change="handleChange" :disabled="uploading" v-autoclick="autoclick" />
    </label>
    <div class="error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FileUploadButton',
    props: {
      autoclick: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        adding: false,
        errorMessage: null,
      };
    },
    computed: {
      uploading() {
        return this.$store.state.sharedFiles.isUploading;
      },
      fileName() {
        return this.$store.state.sharedFiles.uploadingFileName;
      },
    },
    methods: {
      async handleChange($event) {
        const file = $event.target.files[0];
        this.$emit('change', file, file.name);
        $event.target.value = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .uploading {
    opacity: 0.7;
  }

  label {
    cursor: pointer;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $fileUploadBackgroundColor;
    border-radius: 2.5rem;
    padding: 0.4rem;
    margin-bottom: 2rem;
    @include onMobile {
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 1rem;
    }

    .dz-drag-hover & {
      background-color: lighten($fileUploadBackgroundColor, 1%);
      box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
    }
  }

  .text {
    font-size: 1.3rem;
    padding: 0 2rem;
  }

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: $fileUploadButtonBackgroundColor;
    color: $fileUploadButtonColor;
    height: 5rem;
    padding: 0 5rem;
    border-radius: 2.5rem;

    .dz-drag-hover & {
      background-color: lighten($fileUploadButtonBackgroundColor, 10%);
    }
  }

  .error {
    color: $errorColor;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
  }

  input {
    display: none;
  }
</style>
