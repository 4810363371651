export const getTotalFees =  (
  firstTimeWithPractitioner,
  markup,
  patient,
  defaultCharge,
  practitioner
) =>  {
  return getConnectionFees(firstTimeWithPractitioner, markup, patient, practitioner)
    + getServiceCharge(patient, defaultCharge, practitioner);
}

export const getConnectionFees = (firstTimeWithPractitioner, markup, patient, practitioner) => {
  return hasTelegrafikPartner(patient) || hasSafeGPartner(practitioner) ?
      0 : getConnectionFastFee(markup, patient, firstTimeWithPractitioner);
}

const hasTelegrafikPartner = (patient) => patient.tokens.length > 0
    && patient.tokens.some(t => t.partner.slug === 'telegrafik');

const hasSafeGPartner = (practitioner) => practitioner.comeFrom === 'safeG';

const getConnectionFastFee = (markup, patient, firstTime) => {
  if (markup
      && !isPremium(patient)
      && firstTime
      && !hasHellocareWelcomeCode(patient)
  ) {
    return 300;
  }

  return 0;
}

export const getServiceCharge = (patient, defaultCharge, practitioner) => {
  console.log(practitioner.comeFrom, practitioner)
  if (isPremium(patient)
    || hasAquarialCode(patient)
    || hasHellocareWelcomeCode(patient)
    || hasTelegrafikPartner(patient)
    || hasSafeGPartner(practitioner)
  ) {
    return 0;
  }

  return defaultCharge;
}

const hasAquarialCode = (patient) => {
  const date = new Date();
  const limit = new Date('2024-09-12');

  return patient.discountCode !== null
      && patient.discountCode.trim().toLowerCase() === 'aquarelia 300'
      && date < limit;
}

const hasHellocareWelcomeCode = (patient) => {
  const date = new Date();
  const limit = new Date('2024-04-31');

  return patient.discountCode !== null
      && patient.discountCode.trim().toLowerCase() === 'hellocampus24'
      && date < limit;
}

const isPremium = (patient) => patient.premium
