<!--
Emitted events:
- change(selectedDateTime: Moment)
-->
<template>
  <div class="appointment-date-time-picker">
    <div class="container" :class="{ 'is-loading': isReloadingAvailabilities }">
      <MonthSelector @change="changeMonth"/>
      <hr>
      <div class="datetime" v-if="year">
        <Month
            :year="year"
            :month="month"
            :availableDays="availableDays"
            @change="selectedDay = $event"
        />
        <hr class="vertical">
        <Time
            :appointment-duration="appointmentDuration"
            :availableHours="availableHours"
            @change="$emit('change', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Month from './Month';
  import Time from './Time';
  import MonthSelector from './MonthSelector';
  import moment from 'moment';
  import Practitioners from '../../api/practitioners';
  import ModuleLoader from '../ModuleLoader';

  export default {
    name: 'AppointmentDateTimePicker',
    components: { ModuleLoader, MonthSelector, Time, Month },
    data() {
      return {
        isReloadingAvailabilities: false,
        selectedDay: null,
        year: null,
        month: null,
        availabilities: {},
      };
    },
    props: {
      practitionerId: {
        type: String,
        required: true,
      },
      productId: {
        type: String,
        required: true,
      },
      practitionerTimezone: {
        type: String,
        required: true,
      },
      appointmentDuration: {
        type: Number,
        required: true,
        default: 15,
      },
      channel: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.changeMonth(moment());
    },
    computed: {
      availableDays() {
        return Object.keys(this.availabilities).filter((key) => this.availabilities[key].length > 0);
      },
      availableHours() {
        if (!this.selectedDay) {
          return [];
        }

        return this.availabilities[this.selectedDay.format('YYYY-MM-DD')] || [];
      },
    },
    methods: {
      async reloadAvailabilities() {

        const startDate = moment().add(10, 'minutes');

        this.availabilities = {};
        this.isReloadingAvailabilities = true;
        this.availabilities = await Practitioners.availabilities(
          this.practitionerId,
          this.productId,
          this.appointmentDuration,
          startDate,
          moment({ year: this.year, month: this.month }).endOf('month'),
          this.channel,
        );
        this.isReloadingAvailabilities = false;
      },
      /**
       * @param {moment.Moment} dateTime
       */
      changeMonth(dateTime) {
        this.year = dateTime.year();
        this.month = dateTime.month();
        this.reloadAvailabilities();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .datetime {
    display: flex;
  }

  .time {
    margin-top: 44px;
    max-height: 244px;
    overflow: auto;
  }

  .module-loader {
    width: 336px;
    height: 240px;
  }

  .is-loading {
    opacity: 0.5;
    background-color: lightgrey;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #F0F0F0;
    margin: 1rem 0;

    &.vertical {
      height: auto;
      width: 1px;
      margin: 0 1rem;
    }
  }
</style>
