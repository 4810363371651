<template>
  <component :is="component" :to="to" class="options-menu-item" v-on="{ ...$listeners }">
    <FontAwesomeIcon :icon="icon" class="icon" fixed-width />
    <slot />
  </component>
</template>

<script>
  export default {
    name: 'OptionsMenuItem',
    props: {
      icon: {
        required: true,
      },
      to: {
        type: String | Object,
        required: false,
      },
      href: {
        type: String,
        required: false,
      },
    },
    computed: {
      component() {
        return this.to ? 'router-link' : this.href ? 'a' : 'span';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .options-menu-item {
    display: flex;
    align-items: center;
    padding: 1rem 1.4rem;
    text-decoration: none;
    color: $textColor;
    border-bottom: 1px solid $lightGrey;

    &:hover {
      background-color: $lighterGrey;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .icon {
    margin-right: 1rem;
  }
</style>
