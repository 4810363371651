var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    class: {
      small: _vm.small,
      medium: _vm.medium,
      xmedium: _vm.xmedium,
      large: _vm.large,
      xlarge: _vm.xlarge
    },
    attrs: { src: require("../assets/images/premium.png"), alt: "logo-premium" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }