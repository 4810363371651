<template>
  <div v-if="currentStep" class="app-guide">
    <div class="sub-mask" />
    <div :style="isMaskVisible ? maskStyle : {}" class="mask" />
    <AppGuideStep
      :key="currentStep.id"
      ref="popper"
      :actions="currentStep.actions"
      :content="currentStep.content"
      :title="currentStep.title"
      @action="refreshPopper"
    />
  </div>
</template>

<script>
  import AppGuideStep from '@/components/AppGuideStep';
  import { createPopper } from '@popperjs/core';
  import MoveTo from 'moveto';

  export default {
    name: 'AppGuide',
    popper: null,
    item: null,
    components: { AppGuideStep },
    data() {
      return {
        isMaskVisible: true,
        maskStyle: {
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        },
      };
    },
    computed: {
      currentStep() {
        const step = this.$store.getters.vueGuideCurrentStep;
        return step?.status === 'ready' ? step : null;
      },
    },
    methods: {
      handleMaskUpdate({ x, y, width, height }) {
        this.maskStyle.width = `${width + this.currentStep.offset.left + this.currentStep.offset.right}px`;
        this.maskStyle.height = `${height + this.currentStep.offset.top + this.currentStep.offset.bottom}px`;
        this.maskStyle.top = `${y - this.currentStep.offset.top}px`;
        this.maskStyle.left = `${x - this.currentStep.offset.left}px`;
      },
      refreshPopper() {
        if (this.$options.item) {
          new MoveTo({ tolerance: 110 }).move(this.$options.item);
        }

        this.$options.popper?.forceUpdate();
      },
      async createPopper(previousStep) {
        if (this.$options.popper) {
          this.$options.popper.destroy();
          this.$options.popper = null;
        }

        if (previousStep) {
          await previousStep.after?.(previousStep.context(), !this.currentStep);
        }

        if (!this.currentStep) {
          this.$options.item = null;
          return;
        }

        this.$options.item = this.currentStep.target();

        if(this.currentStep.before) {
          this.isMaskVisible = false;
          await this.currentStep.before?.(this.currentStep.context());
          this.isMaskVisible = true;
        }

        this.$options.popper = createPopper(
          this.$options.item,
          this.$refs.popper.$el,
          {
            placement: 'auto',
            onFirstUpdate: ({ rects: { reference } }) => {
              new MoveTo({ tolerance: 110 }).move(this.$options.item);
              this.handleMaskUpdate(reference);
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ placement }) => {
                    let offset = 0;

                    switch (true) {
                      case placement.startsWith('top'):
                        offset = this.currentStep.offset.top;
                        break;
                      case placement.startsWith('bottom'):
                        offset = this.currentStep.offset.bottom;
                        break;
                      case placement.startsWith('left'):
                        offset = this.currentStep.offset.left;
                        break;
                      case placement.startsWith('right'):
                        offset = this.currentStep.offset.right;
                        break;
                    }

                    return [0, 8 + offset];
                  },
                },
              },
              {
                name: 'mask',
                enabled: true,
                phase: 'afterWrite',
                fn: ({ state: { rects: { reference } } }) => {
                  this.handleMaskUpdate(reference);
                },
              },
            ],
          },
        );
      },
    },
    watch: {
      currentStep(newStep, previousStep) {
        if(newStep?.id !== previousStep?.id) {
          this.$nextTick(() => this.createPopper(previousStep));
        }
      },
    },
  };
</script>

<style scoped>
  .sub-mask {
    position: fixed;
    z-index: 3000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .mask {
    position: absolute;
    z-index: 3000;
    box-sizing: content-box;
    box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.7);
  }
</style>
