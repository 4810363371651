<template>
  <span :class="{ disabled: busy || disabled }" class="form-switch">
    <input
      :id="id"
      :aria-checked="checked"
      :aria-disabled="busy || disabled"
      :checked="checked"
      :disabled="busy || disabled"
      :readonly="busy"
      class="checkbox"
      role="switch"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
    >
    <span class="track" />
    <span class="knob" />
    <span v-if="busy" class="loader" />
  </span>
</template>

<script>
  export default {
    name: 'FormSwitch',
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      id: { type: String, required: false },
      checked: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      busy: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss" scoped>
  $width: 4rem;
  $knobDiameter: $width / 2;

  .form-switch {
    position: relative;
    display: inline-block;
    width: $width;
    height: $knobDiameter;

    &.disabled {
      opacity: 0.4;
    }
  }

  .track {
    $trackWidth: $width * 0.9;
    $trackHeight: $knobDiameter * 0.7;

    position: absolute;
    z-index: 1;
    top: ($knobDiameter - $trackHeight) / 2;
    left: ($width - $trackWidth) / 2;
    display: block;
    width: $trackWidth;
    height: $trackHeight;
    transition: background-color 250ms;
    border-radius: $trackHeight / 2;
    background-color: #00000020;
  }

  .knob, .loader {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: $knobDiameter;
    height: $knobDiameter;
    transition: all 250ms;
    border-radius: $knobDiameter / 2;
    box-shadow: 0 0.2rem 0.2rem #00000040;
  }

  .knob {
    background-color: #BBB;
  }

  .loader {
    animation: 600ms pulse alternate infinite;
    background-color: #888;
  }

  .checkbox {
    position: absolute;
    z-index: 3;
    display: block;
    width: $width;
    height: $knobDiameter;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;

    .disabled > & {
      cursor: not-allowed;
    }
  }

  .checkbox:checked {
    & + .track {
      background-color: transparentize($successColor, 0.5);
    }

    & ~ .knob, & ~ .loader {
      left: $width - $knobDiameter;
      background-color: $successColor;
    }

    & ~ .loader {
      background-color: darken($successColor, 15%);
    }
  }

  .checkbox:focus + .track {
    @include onFocus;
  }
</style>
