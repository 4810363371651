import api from './';
import { normalizeConnectTransaction, normalizeConnectTransactions } from '@/helpers/normalizers';

export default {
  get: (appointmentId, userId) => api.get(`transactions?appointmentId=${appointmentId}&userId=${userId}`).json().then(({ data }) => normalizeConnectTransactions(data)),
  getAll: () => api.get('transactions').json().then(({ data }) => normalizeConnectTransactions(data)),
  create: (orderId, stripePaymentMethodId) => api.post('transactions', {
    json: {
      order: orderId,
      stripePaymentMethodId,
    },
  }).json().then(normalizeConnectTransaction),
  confirm: (id) => api.post(`transactions/${id}/confirm`).json(),
};
