export default {
  state: {
    isVisible: false
  },
  mutations: {
    'APP_LOADER_SET_VISIBLE'(state, { visible }) {
      state.isVisible = visible;
    },
  },
  actions: {
    appLoaderShow({ commit }) {
      commit('APP_LOADER_SET_VISIBLE', { visible: true });
    },
    appLoaderHide({ commit }) {
      commit('APP_LOADER_SET_VISIBLE', { visible: false });
    },
  }
};
