var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row container" },
    [
      _c("div", { staticClass: "container-practitioner-details" }, [
        _vm.$media.desktop
          ? _c(
              "div",
              { staticClass: "col _2", staticStyle: { "min-width": "130px" } },
              [
                _c("UserAvatar", {
                  attrs: {
                    src: _vm.practitioner.picture,
                    "user-name": _vm.practitionerFullName
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "col _5 container-col" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              !_vm.$media.desktop
                ? _c("UserAvatar", {
                    attrs: {
                      src: _vm.practitioner.picture,
                      "user-name": _vm.practitionerFullName,
                      size: "7rem"
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "practitioner-details" }, [
                _c("p", { staticClass: "practitioner-name" }, [
                  _vm._v(_vm._s(_vm.practitionerFullName))
                ]),
                !_vm.practitioner.instantEncountersEnabled
                  ? _c("p", { staticClass: "no-new-patient" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("searchResultsItem.no-new-patient")) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.practitioner.mainSpecialty
                  ? _c("p", { staticClass: "practitioner-specialty" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.practitioner.mainSpecialty.name) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "practitioner-address" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.practitioner.practiceAddress)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.practitioner.practiceZipCode) +
                      " " +
                      _vm._s(_vm.practitioner.practiceCity) +
                      "\n        "
                  )
                ]),
                _vm.practitioner.convention
                  ? _c("p", { staticClass: "practitioner-convention" }, [
                      _vm._v(_vm._s(_vm.practitioner.convention.name))
                    ])
                  : _vm._e(),
                _vm.practitioner.languages.length
                  ? _c(
                      "div",
                      { staticClass: "practitioner-languages" },
                      [
                        _vm.practitioner.languages.length > 1
                          ? _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "searchResultsItem.language-spoken",
                                      2
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "searchResultsItem.language-spoken",
                                      1
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                        _vm._l(_vm.practitioner.languages, function(
                          language,
                          index
                        ) {
                          return _c("div", [
                            _c("p", [
                              _vm._v(" " + _vm._s(language.name)),
                              index < _vm.practitioner.languages.length - 1
                                ? _c("span", [_vm._v(", ")])
                                : _vm._e()
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "col _5 picker-column container-col" }, [
        _c(
          "form",
          {
            staticClass: "form-container",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.handleSubmit($event)
              }
            }
          },
          [
            _c(
              "FormSelect",
              {
                attrs: {
                  id: "product-" + _vm.practitioner.id,
                  disabled: !_vm.hasProducts,
                  label: _vm.$t("searchResultsItem.form.product.label")
                },
                model: {
                  value: _vm.selectedProduct,
                  callback: function($$v) {
                    _vm.selectedProduct = $$v
                  },
                  expression: "selectedProduct"
                }
              },
              [
                !_vm.selectedProduct
                  ? _c("option", { domProps: { value: null } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.hasProducts
                              ? ""
                              : _vm.$t(
                                  "searchResultsItem.form.product.no-result"
                                )
                          ) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._l(_vm.products, function(product) {
                  return _c("option", { domProps: { value: product } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(product.name) +
                        " (" +
                        _vm._s(_vm._f("channel")(product.channel)) +
                        ")\n        "
                    )
                  ])
                })
              ],
              2
            ),
            _c("FormInput", {
              attrs: {
                id: "date-" + _vm.practitioner.id,
                disabled:
                  _vm.isSearching || _vm.noResults || !_vm.selectedProduct,
                value: _vm.formattedSelectedAppointment,
                label: _vm.$t("searchResultsItem.form.appointment-date.label"),
                readonly: ""
              },
              on: {
                click: function($event) {
                  _vm.showDateTimePicker = true
                }
              }
            }),
            _c("small", { staticClass: "available-desc" }, [
              _vm._v(
                "Par défaut, la première disponibilité est automatiquement sélectionnée"
              )
            ]),
            _c("div", { staticClass: "price-container" }, [
              _c("p", { staticClass: "price-container__total-price" }, [
                _vm._v(" Honoraire du praticien:\n          "),
                _c("span", [_vm._v(_vm._s(_vm.unitPrice))]),
                _vm._v("€\n        ")
              ])
            ]),
            _c(
              "ButtonGreen",
              {
                staticClass: "button",
                attrs: {
                  busy: _vm.isCreating || _vm.isSearching,
                  disabled: _vm.noResults || !_vm.hasProducts,
                  icon: _vm.$icons.chevronRight,
                  block: "",
                  rounded: "",
                  type: "submit"
                },
                on: { click: _vm.confirmAdditionalInformation }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("searchResultsItem.form.submit")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm.showDateTimePicker
        ? _c(
            "div",
            {
              staticClass: "date-time-picker-container",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  _vm.showDateTimePicker = false
                }
              }
            },
            [
              _c("AppointmentDateTimePicker", {
                attrs: {
                  "appointment-duration": _vm.selectedProduct.duration || 15,
                  channel: _vm.selectedProduct.channel,
                  "practitioner-id": _vm.practitioner.id,
                  "practitioner-timezone": _vm.practitioner.timezone,
                  "product-id": _vm.selectedProduct.id
                },
                on: { change: _vm.selectAppointmentDate }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "AppConfirmation",
        {
          ref: "newPatientConfirmation",
          attrs: { title: _vm.$t("searchResultsItem.additional-information") },
          scopedSlots: _vm._u([
            {
              key: "description",
              fn: function(scoped) {
                return [_c("p", [_vm._v(" " + _vm._s(_vm.rules) + " ")])]
              }
            }
          ])
        },
        [
          _c(
            "ConfirmButton",
            {
              attrs: { color: "red", icon: _vm.$icons.no },
              on: {
                click: function($event) {
                  return _vm.handleFirstTime(false)
                }
              }
            },
            [_vm._v("Annuler")]
          ),
          _c(
            "ConfirmButton",
            {
              on: {
                click: function($event) {
                  return _vm.handleFirstTime(true)
                }
              }
            },
            [_vm._v("Continuer")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }