var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file",
      attrs: { title: _vm.label },
      on: { click: _vm.download }
    },
    [
      _c(
        "div",
        { staticClass: "thumbnail" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "file-icon",
            attrs: {
              icon: _vm.removing ? _vm.$icons.spinner : _vm.$icons.file,
              spin: _vm.removing
            }
          }),
          _vm.removable
            ? _c(
                "div",
                {
                  staticClass: "remove-icon",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.remove($event)
                    }
                  }
                },
                [
                  _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.trashOutlined }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "label" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }