<script>
  import BaseButton from './BaseButton';

  export default {
    name: 'ButtonGreen',
    extends: BaseButton
  };
</script>

<style lang="scss" scoped src="../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
    background-color: $submitButtonColor;

    &.outlined {
      border-color: $submitButtonColor;
      color: $submitButtonColor;
    }
  }
</style>
