<template>
  <div id="app">
    <AppLoader v-if="appLoaderVisible">
      CHARGEMENT
    </AppLoader>
    <AppNotification v-if="!appLoaderVisible" />
    <router-view />
    <portal-target name="app-progress" slim />
    <portal-target name="app-pop-in" />
    <portal-target name="app-confirmation" />
    <AppGuide />
    <AppPopIn :opened="isPopInOpened" @close="isPopInOpened = false" :is-closable="false">
      <h1 style="font-size: 3rem;">Nos conditions générales de vente évoluent&nbsp;🚀</h1>
      <p>Nous avons récemment mis à jour nos Conditions Générales de Vente et d'Utilisation (CGUV) pour mieux répondre à vos besoins et assurer une expérience transparente.</p>
      <p>
          Veuillez les consulter
          <a style="font-weight:bold;" target="_blank" href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu/">ici</a>
          et les accepter pour continuer à utiliser Hellocare.
      </p>
      <div>
      <span style="margin-right: 1rem;">
        <ButtonGreen @click="onConfirmCgu">J'accepte</ButtonGreen>
      </span>

        <ButtonGrey @click="onRefuseCgu">Je refuse</ButtonGrey>
      </div>
    </AppPopIn>
  </div>
</template>

<script>
  import AppGuide from '@/components/AppGuide.vue';
  import AppLoader from './components/AppLoader';
  import AppNotification from './components/AppNotification';
  import AppProgress from './components/AppProgress';
  import 'smartbanner.js/dist/smartbanner.min.js';
  import moment from "moment";
  import AppPopIn from "@/components/AppPopIn.vue";
  import ButtonGreen from "@/components/ButtonGreen.vue";
  import ButtonGrey from "@/components/ButtonGrey.vue";

  export default {
    components: {AppPopIn, ButtonGreen, ButtonGrey, AppGuide, AppProgress, AppLoader, AppNotification },
    data() {
      return {
        isPopInOpened: false,
      };
    },
     async mounted() {
       if (!localStorage.isCguConfirmed_2024_04_17) {
         this.isPopInOpened = true;
       }
       await this.isPremium
       this.$setInterval(() => { this.$store.dispatch('refreshCurrentTime'); }, 5000);
       this.$setInterval(() => { this.$store.dispatch('loadSettings'); }, 5 * 60 * 1000, true);
    },
    async updated() {
      let smartbanner = document.querySelector('.smartbanner');
      let html = document.querySelector('html')
      if(smartbanner) {
        html.style.marginTop = '0px'
      }
    },
    computed: {
      isPremium() {
        return this.patient.premium
      },
      appLoaderVisible() {
        return this.$store.state.appLoader.isVisible;
      },
      patient() {
        return this.$store.state.patients.profile;
      },
      isPreDeletedAccount() {
        if(this.patient.deletedAt !== null) {
          return this.patient.deletedAt;
        }
      }
    },
    watch: {
      isPreDeletedAccount(isPreDeletedAccount) {
        if(isPreDeletedAccount) {
          this.messageAfterDeleteAccount();
        }
      },
    },
    methods: {
      onConfirmCgu() {
        localStorage.isCguConfirmed_2024_04_17 = "true"
        this.isPopInOpened = false;
      },
      onRefuseCgu() {
        window.location.replace("https://hellocare.com")
      },
      messageAfterDeleteAccount() {
        let date = moment(this.patient.deletedAt).calendar()
        const deleteAccount = this.$t('app.delete-account', {date : date, link: `<a href="mailto:support@hellocare.com">support@hellocare.com</a>`});

        this.$addError(deleteAccount, false, 7000, true);
      },
    }
  };
</script>

<style lang="scss" src="pretty-checkbox/src/pretty-checkbox.scss" />
<style lang="scss" src="./assets/style.scss" />

<style lang="scss">
  @import "./assets/grid";
  @import "../node_modules/smartbanner.js/dist/smartbanner.min.css";

  #app {
    @include onMobile {
      min-height: calc(100vh - 70px);
      display: flex;
      flex-direction: column;
    };
  }

.smartbanner {
  top: auto;
  bottom: 0;
}

  .smartbanner.smartbanner--hellocare {
    position: fixed;
    z-index: 2147483002;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
    top: auto;
    bottom: 0;
    .smartbanner__icon {
      left: 1.5rem;
    }

    .smartbanner__info {
      font-family: $fontRoboto;
      right: 4rem;
      left: 9.2rem;
      width: auto;
    }

    .smartbanner__info__title,
    .smartbanner__info__author,
    .smartbanner__info__price {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .smartbanner__info__author,
    .smartbanner__info__price {
      font-weight: 300;
    }

    .smartbanner__button {
      top: 0;
      right: 3rem;
      bottom: 0;
      left: 0;
      padding: 0;
      background-color: transparent;
    }

    .smartbanner__button__label {
      display: none;
    }

    .smartbanner__exit {
      top: 0.5rem;
      right: 0.5rem;
      left: auto;
      padding: 10px;
      border-radius: 50%;
      background-color: black;

      &::before,
      &::after {
        top: 4px;
        left: 9px;
        width: 2px;
        background-color: white;
      }
    }
  }
</style>
