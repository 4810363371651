<template>
  <div class="appointment-details">
    <div v-if="isCancelledByPatient" class="cancelled">
      {{ $t('appointmentPanelDetails.cancelled.by-patient') }}
    </div>
    <div v-else-if="isCancelledByPractitioner" class="cancelled">
      {{ $t('appointmentPanelDetails.cancelled.by-practitioner') }}
    </div>

    <div :class="{ busy: isReplacingPatient }">
      <FontAwesomeIcon
        :icon="isReplacingPatient ? $icons.spinner : $icons.user"
        :spin="isReplacingPatient"
        fixed-width
      />
      <strong>{{ appointment.payload.patient | fullName }}</strong>
      <span v-if="canReplacePatient && !isReplacingPatient" class="replace-patient">
        ( <a href="#" @click.prevent="$confirm($refs.replacePatient)">{{ $t('appointmentPanelDetails.replace-patient.link-label') }}</a> )
      </span>
    </div>
    <div>
      <AppConfirmation ref="replacePatient" :icon="$icons.user" :title="$t('appointmentPanelDetails.replace-patient.confirmation-title')">
        <BeneficiaryList
          :patient="profile"
          :selected="appointment.payload.patient"
          @change="handleReplacePatient"
          @cancel="$refs.replacePatient.close()"
          cancelable
        />
      </AppConfirmation>
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.calendar" fixed-width />
      <strong>{{ appointment.payload.startAt | date('dddd DD MMMM YYYY') }}</strong>
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.clock" fixed-width />
      <strong>{{ appointment.time }}</strong>
      <strong v-if="isStartWarningVisible" class="start-warning">
        {{ $t('appointmentPanelDetails.start-warning') }}
      </strong>
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.eye" fixed-width />
      <strong>{{ appointment.payload.product.channel | channel }}</strong>
    </div>
    <div>
      <FontAwesomeIcon :icon="$icons.stethoscope" fixed-width />
      <strong>{{ productName }}</strong>
    </div>
    <div>
      <AppointmentStatusIcon :status="appointment.payload.status" />
      <strong>{{ appointment.payload.status | appointmentStatus(canBeStarted) }}</strong>
    </div>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation.vue';
  import AppointmentStatusIcon from '@/components/AppointmentStatusIcon';
  import AppPanel from '@/components/AppPanel.vue';
  import BeneficiaryList from '@/components/BeneficiaryList.vue';
  import moment from 'moment';

  export default {
    name: 'AppointmentPanelDetails',
    components: { AppConfirmation, AppPanel, BeneficiaryList, AppointmentStatusIcon },
    props: {
      canBeStarted: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    data() {
      return {
        isPatientReplacementPanelOpen: false,
      };
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      },
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      productName() {
        return this.appointment.payload.product
            ? this.appointment.payload.product.name
            : this.$t('appointmentPanelDetails.product-name') ;
      },
      status() {
        return this.appointment.payload.status;
      },
      isCancelledByPatient() {
        return this.status === 'CANCELLED_BY_PATIENT';
      },
      isCancelledByPractitioner() {
        return this.status === 'CANCELLED_BY_PRACTITIONER';
      },
      isRemote() {
        return this.appointment.payload.product.channel === 'remote';
      },
      appointmentMinimumStartTime() {
        return moment(this.appointment.payload.startAt).subtract(15, 'minutes');
      },
      isStartWarningVisible() {
        return this.status === 'CONFIRMED'
          && this.isRemote
          && this.$store.state.currentTime.isBefore(this.appointmentMinimumStartTime);
      },
      canReplacePatient() {
        return !this.appointment.payload.encounters?.[0];
      },
      isReplacingPatient() {
        return this.$store.state.appointments.isReplacingPatient;
      },
    },
    methods: {
      async handleReplacePatient(beneficiary) {
        this.$refs.replacePatient.close();

        if (beneficiary.id === this.appointment.payload.patient.id) {
          return;
        }

        try {
          await this.$store.dispatch('appointmentsReplacePatient', {
            appointmentId: this.appointment.payload.id,
            patientId: beneficiary.id,
          });

          await this.$addSuccess(this.$t('appointmentPanelDetails.success'));
        } catch (error) {
          await this.$addError(this.$t('appointmentPanelDetails.error'));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-details {
    color: $textColor;

    > div {
      margin-bottom: 1rem;

      &.cancelled {
        font-family: $fontRoboto;
        font-weight: 500;
        margin-bottom: 3rem;
        padding: 1.2rem 2rem;
        color: $errorColor;
        border: 1px solid $errorColor;
        border-radius: 0.8rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    svg {
      margin-right: 1rem;
    }

    .start-warning {
      margin-left: 2rem;
      color: $errorColor;
    }
  }

  .replace-patient {
    margin-left: 1rem;
    color: $grey;

    a {
      text-decoration: none;
      color: $successColor;
    }
  }

  .beneficiary-list {
    color: $lightTextColor;

    ::v-deep {
      .beneficiaries {
        overflow: auto;
        max-height: calc(100vh - 24rem);
      }

      .beneficiary {
        &:hover, &.selected {
          color: $textColor;
        }
      }

      .add-beneficiary, .cancel {
        font-size: 1.6rem;
      }
    }
  }

  .busy {
    font-style: italic;
    color: $grey;
  }
</style>
