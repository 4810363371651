import Practitioners from '../../api/practitioners';

export default {
  state: {
    current: null,
    isFetchingOne: false,
  },
  mutations: {
    PRACTITIONERS_FETCH_ONE(state) {
      state.isFetchingOne = true;
    },
    PRACTITIONERS_FETCH_ONE_SUCCESS(state, { practitioner }) {
      state.current = practitioner;
      state.isFetchingOne = false;
    },
    PRACTITIONERS_FETCH_ONE_FAIL(state) {
      state.current = null;
      state.isFetchingOne = false;
    },
  },
  actions: {
    async practitionersFetchOne({ commit }, { id }) {
      commit('PRACTITIONERS_FETCH_ONE');
      try {
        const practitioner = await Practitioners.getOne(id);
        commit('PRACTITIONERS_FETCH_ONE_SUCCESS', { practitioner });
      } catch (e) {
        commit('PRACTITIONERS_FETCH_ONE_FAIL');
        throw e;
      }
    },
  },
};
