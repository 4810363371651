import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArrowAltCircleLeft,
  faCalendarAlt,
  faCheckCircle as farCheckCircle,
  faClock as farClock,
  faEye,
  faEyeSlash,
  faCreditCard,
  faFilePdf,
  faHourglass,
  faMoneyBillAlt,
  faObjectGroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle,
  faThumbsDown,
  faThumbsUp,
  faTimesCircle as farTimesCircle,
  faUser,
  faWindowMinimize,
  faTrashAlt,
  faSave,
  faEdit,
  faListAlt,
  faArrowAltCircleRight,
  faFolderOpen,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faAllergies,
  faAngleDown,
  faAngleRight,
  faAward,
  faBars,
  faBookmark,
  faCamera,
  faCheckCircle,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faClock,
  faCog,
  faComment,
  faCrown,
  faEllipsisV,
  faExchangeAlt,
  faExclamationTriangle,
  faFile,
  faFileAlt,
  faFileExport,
  faGlobeEurope,
  faHeart,
  faIdBadge,
  faIdCardAlt,
  faInfo,
  faLock,
  faMapMarkerAlt,
  faMicrophone,
  faMicrophoneSlash,
  faMobileAlt,
  faMoneyCheckAlt,
  faPaperPlane,
  faPencilAlt,
  faPhone,
  faPhoneSlash,
  faPills,
  faPlusCircle,
  faPowerOff,
  faRedo,
  faRuler,
  faSearch,
  faShieldAlt,
  faTimesCircle,
  faUserLock,
  faUserMd,
  faUserPlus,
  faVideo,
  faVideoSlash,
  faExclamation,
  faSpinner,
  faFileSignature,
  faUpload,
  faCheck,
  faTrash,
  faClipboardList,
  faPlus,
  faArrowRight,
  faStethoscope,
  faBirthdayCake,
  faTint,
  faRulerVertical,
  faWeight,
  faArrowAltCircleRight as faArrowAltCircleRightSolid,
  faCheckSquare,
  faBox,
  faHeartbeat
} from '@fortawesome/free-solid-svg-icons';
import {
  faChrome,
  faFirefox,
  faSafari,
} from "@fortawesome/free-brands-svg-icons";

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.mixin({
  computed: {
    $icons: () => ({
      IBAN: faMoneyCheckAlt,
      accept: faCheckCircle,
      subscriptionActive: farCheckCircle,
      addressCard: faAddressCard,
      allergies: faAllergies,
      award: faAward,
      appointmentStatusCancelled: faTimesCircle,
      appointmentStatusClosed: faCheckCircle,
      appointmentStatusDefault: faPlusCircle,
      appointmentStatusNew: faClock,
      appointmentStatusReport: faEdit,
      appointmentStatusWaitingPayment: faCreditCard,
      arrowCircleRight: faArrowAltCircleRight,
      arrowCircleRightSolid: faArrowAltCircleRightSolid,
      arrowRight: faArrowRight,
      beneficiaryAdd: faUserPlus,
      biometrics: faRuler,
      birthDate: faBirthdayCake,
      bookmark: faBookmark,
      calendar: faCalendarAlt,
      camera: faCamera,
      chat: faComment,
      checkbox: faCheck,
      chevronLeft: faChevronLeft,
      chevronRight: faChevronRight,
      chevronUp: faChevronUp,
      chevronDown: faChevronDown,
      chrome: faChrome,
      claimForm: faClipboardList,
      clock: farClock,
      closePanel: farTimesCircle,
      creditCard: faCreditCard,
      dashboard: faObjectGroup,
      edit: faEdit,
      editCredential: faPencilAlt,
      encounterCameraToggle: faVideo,
      encounterCameraToggleDisabled: faVideoSlash,
      encounterMicrophoneToggle: faMicrophone,
      encounterMicrophoneToggleDisabled: faMicrophoneSlash,
      encounterReportSendAndBill: farCheckCircle,
      encounterStart: faVideo,
      encounterStartDisabled: faVideoSlash,
      encounterStop: faPhoneSlash,
      exclamation: faExclamation,
      eye: faEye,
      file: faFile,
      filesPanel: faFileExport,
      firefox: faFirefox,
      folder: faFolderOpen,
      globe: faGlobeEurope,
      goBack: faArrowAltCircleLeft,
      heart: faHeart,
      heartBeat: faHeartbeat,
      height: faRulerVertical,
      idBadge: faIdBadge,
      badgeCheck: faCheckSquare,
      idCard: faIdCardAlt,
      info: faInfo,
      inputError: faExclamationTriangle,
      lock: faLock,
      logout: faPowerOff,
      mail: faPaperPlane,
      mapMarker: faMapMarkerAlt,
      medic: faUserMd,
      medicalHistory: faFileAlt,
      mobile: faMobileAlt,
      myPatients: faUser,
      navigationToggle: faBars,
      navigationToggleOff: faWindowMinimize,
      newAppointment: faPlus,
      no: farTimesCircle,
      noResults: farTimesCircle,
      owner: faCrown,
      panelToggleClosed: faAngleRight,
      panelToggleOpened: faAngleDown,
      passwordHidden: faEyeSlash,
      passwordVisible: faEye,
      payments: faMoneyBillAlt,
      pdf: faFilePdf,
      phone: faPhone,
      planning: faListAlt,
      protectedMail: faShieldAlt,
      reload: faRedo,
      removeProduct: faTrash,
      removeQuotation: faTrash,
      removeQuotationCancel: farTimesCircle,
      removeQuotationConfirm: farCheckCircle,
      safari: faSafari,
      saveOutlined: faSave,
      search: faSearch,
      selectIndicator: faAngleDown,
      send: farPaperPlane,
      settings: faCog,
      signature: faFileSignature,
      spinner: faSpinner,
      stethoscope: faStethoscope,
      thumbsDown: faThumbsDown,
      thumbsUp: faThumbsUp,
      transaction: faExchangeAlt,
      transactionStatusCaptured: farCheckCircle,
      transactionStatusPending: faHourglass,
      transactionStatusRefunded: farTimesCircle,
      transactionStatusUnknown: faQuestionCircle,
      trashOutlined: faTrashAlt,
      treatment: faPills,
      upload: faUpload,
      user: faUser,
      userLock: faUserLock,
      verticalDots: faEllipsisV,
      viewPatient: faPlusCircle,
      weight: faWeight,
      doc2u: faStethoscope,
      box: faBox,
    }),
  }
})
