<!--
Emitted events:
- change(selectedDate: Moment)
-->

<template>
  <div class="month">
    <div class="header">
      <div class="day" v-for="dayLabel in dayLabels">{{ dayLabel }}</div>
    </div>
    <div class="weeks">
      <div class="week" v-for="days in daysByWeek">
        <template v-for="day in days">
          <div v-if="!isDayInMonth(day)" class="day"></div>
          <div v-else-if="!isDaySelectable(day)" class="day">{{ day.format('D') }}</div>
          <div v-else class="day selectable" :class="{ selected: isDaySelected(day) }" @click="selectedDay = day">
            {{ day.format('D') }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { splitArray } from '../../helpers/tools';
  import moment from 'moment';

  export default {
    name: 'Month',
    props: {
      year: {
        type: Number,
        required: true,
      },
      month: {
        type: Number,
        required: true,
      },
      availableDays: {
        type: Array,
        required: true,
        default: [],
      },
    },
    data() {
      return {
        selectedDay: null
      }
    },
    computed: {
      dayLabels() {
        return ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
      },
      firstCalendarDay() {
        return moment({ year: this.year, month: this.month }).startOf('week');
      },
      lastCalendarDay() {
        return moment({ year: this.year, month: this.month }).endOf('month').endOf('week');
      },
      daysByWeek() {
        return splitArray(Array.from(this.days), 7);
      },
      days() {
        const range = moment.range(this.firstCalendarDay, this.lastCalendarDay);

        return range.by('days');
      },
    },
    methods: {
      isDayInMonth(day) {
        return day.month() === this.month;
      },
      isDaySelectable(day) {
        if (day.isBefore(undefined, 'day')) {
          return false;
        }

        return this.isDayAvailable(day);
      },
      isDayAvailable(day) {
        return this.availableDays.includes(day.format('YYYY-MM-DD'));
      },
      isDaySelected(day) {
        return day.isSame(this.selectedDay, 'day');
      },
    },
    watch: {
      availableDays(availableDays) {
        if (availableDays.length === 0) {
          return;
        }

        this.selectedDay = moment(this.availableDays[0]);
      },
      selectedDay(selectedDay) {
        this.$emit('change', selectedDay);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .month {
    flex: 3;
  }

  .header, .week {
    display: flex;
  }

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 40px;
    border-radius: 25px;
    font-size: 1.7rem;
    margin: 4px;
    color: lightgrey;
    cursor: not-allowed;

    .header & {
      color: grey;
    }

    &.selectable {
      color: black;
      cursor: pointer;

      &:hover, &.selected {
        background-color: $successColor;
        color: #ffffff;
      }
    }
  }
</style>
