<template>
  <h3 class="group-title">
    <FontAwesomeIcon :icon="icon" class="icon" />
    <slot />
  </h3>
</template>

<script>
  export default {
    name: 'GroupTitle',
    props: {
      icon: {
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .group-title {
    font-family: $fontRoboto;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8rem;
    margin-bottom: 3rem;

    .icon {
      margin-right: 0.6rem;
      color: $successColor;
    }
  }
</style>
