var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.appLoaderVisible
        ? _c("AppLoader", [_vm._v("\n    CHARGEMENT\n  ")])
        : _vm._e(),
      !_vm.appLoaderVisible ? _c("AppNotification") : _vm._e(),
      _c("router-view"),
      _c("portal-target", { attrs: { name: "app-progress", slim: "" } }),
      _c("portal-target", { attrs: { name: "app-pop-in" } }),
      _c("portal-target", { attrs: { name: "app-confirmation" } }),
      _c("AppGuide"),
      _c(
        "AppPopIn",
        {
          attrs: { opened: _vm.isPopInOpened, "is-closable": false },
          on: {
            close: function($event) {
              _vm.isPopInOpened = false
            }
          }
        },
        [
          _c("h1", { staticStyle: { "font-size": "3rem" } }, [
            _vm._v("Nos conditions générales de vente évoluent 🚀")
          ]),
          _c("p", [
            _vm._v(
              "Nous avons récemment mis à jour nos Conditions Générales de Vente et d'Utilisation (CGUV) pour mieux répondre à vos besoins et assurer une expérience transparente."
            )
          ]),
          _c("p", [
            _vm._v("\n        Veuillez les consulter\n        "),
            _c(
              "a",
              {
                staticStyle: { "font-weight": "bold" },
                attrs: {
                  target: "_blank",
                  href:
                    "https://hellocare.com/blog/conditions-generales-dutilisation-cgu/"
                }
              },
              [_vm._v("ici")]
            ),
            _vm._v(
              "\n        et les accepter pour continuer à utiliser Hellocare.\n    "
            )
          ]),
          _c(
            "div",
            [
              _c(
                "span",
                { staticStyle: { "margin-right": "1rem" } },
                [
                  _c("ButtonGreen", { on: { click: _vm.onConfirmCgu } }, [
                    _vm._v("J'accepte")
                  ])
                ],
                1
              ),
              _c("ButtonGrey", { on: { click: _vm.onRefuseCgu } }, [
                _vm._v("Je refuse")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }