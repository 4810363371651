<template>
  <div class="recapAppointment">
    <span> {{ title }} </span>
    <span v-if="!$media.mobile && date"> | </span>
    <span> {{ date }} </span>
  </div>
</template>

<script>

  export default {
    name: 'Recap-title',
    props: {
      title: {
        required: true
      },
      date: {
        required: false
      }
    },
    computed: {
      practitioner() {
        return this.$store.state.newAppointment.practitioner;
      },
      newAppointment() {
        return this.$store.state.newAppointment;
      },
    },
  }

</script>

<style lang="scss" scoped>

  .recapAppointment {
    @include onMobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 1.5rem;
    }
    border-radius: 9px;
    border-color: #27D7AB;
    background-color: #DFF9F3;
    font-size: 2rem;
    font-weight: bold;
    padding: 1.5rem;
    text-align: center;
    margin-bottom: 6rem;
    margin-top: 0.2rem;
  }
</style>
