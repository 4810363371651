import api from './';
import { normalizeEncounter } from '@/helpers/normalizers';

export default {
  create: (appointmentId, practitionerId, productId) => api.post('encounters', {
    json: {
      appointment: appointmentId,
      practitioner: practitionerId,
      products: productId ? [productId] : [],
    },
  }).json().then(normalizeEncounter),
  getOne: (id) => api.get(`encounters/${id}`).json().then(normalizeEncounter),
  rate: (id, rate) => api.post(`encounters/${id}/rate`, { json: { rate } }),
  getPrescription: (id) => api.get(`encounters/${id}/prescription`).blob(),
  getPatientAdvice: (id) => api.get(`encounters/${id}/report`).blob(),
  getClaim: (id) => api.get(`claims/${id}`).blob(),
  authorize: (id, stripePaymentMethodId) => api.post(`encounters/${id}/authorize`, { json: { stripePaymentMethodId }}).json().then(normalizeEncounter),
};
