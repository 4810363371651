var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("div", {}, [
      _c("div", { staticClass: "w-100" }, [
        _c(
          "div",
          { staticClass: "p-5" },
          [_c("BreadCrumb", { attrs: { pages: _vm.breadCrumbPages() } })],
          1
        )
      ]),
      _vm.pharmacyOrder
        ? _c(
            "div",
            {
              staticClass:
                "delivery-block d-flex justify-content-center align-items-center flex-column p-5"
            },
            [
              _c("div", { staticClass: "p-5" }, [
                _c("img", {
                  attrs: {
                    alt: "handshake",
                    src: require("../../assets/images/picto_handShake.svg")
                  }
                })
              ]),
              _c("div", { staticClass: "row w-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col _6 d-flex justify-content-center align-items-center py-5"
                  },
                  [
                    _vm.paid
                      ? _c(
                          "div",
                          { staticClass: "next_button text-center" },
                          [
                            _c("p", { staticClass: "card_already_saved" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "pharmacyOrder.payment-page.card-saved"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c("br"),
                            _c(
                              "ButtonGreen",
                              {
                                ref: "nex_button",
                                attrs: { busy: _vm.submitting },
                                on: { click: _vm.goToSuccess }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "pharmacyOrder.payment-page.btn-finish"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "next_button" },
                          [
                            _c("CardPayment", {
                              attrs: {
                                providerName: this.pharmacyOrder.provider.name,
                                pharmacyOrder: _vm.pharmacyOrder,
                                paid: _vm.paid
                              },
                              on: { paid: _vm.updatePaid }
                            })
                          ],
                          1
                        )
                  ]
                ),
                _c("div", { staticClass: "col-separator" }),
                _c("div", { staticClass: "col _6 py-5" }, [
                  _c("div", { staticClass: "delivery_recap" }, [
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.payment-page.subtitle-one")
                        )
                      )
                    ]),
                    _c("p", { staticClass: "address" }, [
                      _vm._v(_vm._s(_vm.pharmacyOrder.delivery_address))
                    ]),
                    _c("p", { staticClass: "me_mail" }, [
                      _vm._v(_vm._s(_vm.profile.email))
                    ]),
                    _c("p", { staticClass: "me_phone" }, [
                      _vm._v(_vm._s(_vm.profile.phone))
                    ]),
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("pharmacyOrder.payment-page.subtitle-two")
                        )
                      )
                    ]),
                    _c("p", { staticClass: "rs" }, [
                      _vm._v(_vm._s(_vm.pharmacyOrder.pharmacy.rs) + " ")
                    ]),
                    _c("p", { staticClass: "ph_phone" }, [
                      _vm._v(_vm._s(_vm.pharmacyOrder.pharmacy.telephone) + " ")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "additional_infos row" }, [
                _c("div", { staticClass: "col _8" }, [
                  _c("div", { staticClass: "security" }, [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@/assets/images/picto-payment.svg")
                      }
                    }),
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("creditCardAddForm.security")) +
                          "\n            "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "security" }, [
                    _c("img", {
                      attrs: {
                        alt: "",
                        src: require("@/assets/images/picto-patient.svg")
                      }
                    }),
                    _c("p", [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("pharmacyOrder.payment-page.payment-infos")
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col _4" }, [
                  _c("p", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.pharmacyOrder.transaction.amount) + " €")
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }