<template>
  <div class="fullName" @click="$emit('change', card)">
    <span>{{ card | fullName }}</span>
    <span class="whatBeneficiary" v-if="card.isOwner">Moi</span>
    <span class="whatBeneficiary" v-else>Proche</span>
  </div>
</template>
<script>

export default {
  name: 'Beneficiary',
  props: {
    selectDefault : {
      default: false
    },
    cards: {
      required: false
    },
    card: {
      required: false
    },
  },
  created() {
    if (this.selectDefault) {
      this.$emit('change', this.cards[0]);
    }
  },
};
</script>
<style lang="scss" scoped>
.fullName {
  @include onMobile {
    font-size: 1.5rem;
  }
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.whatBeneficiary {
  @include onMobile {
    font-size: 1.3rem;
  }
  font-size: 1.5rem;
}
</style>