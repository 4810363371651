<!--
Emitted events:
- accept
- cancel
-->
<template>
  <div class="appointment-panel-actions">
    <div v-if="isNewByPatient" class="new-or-cancelled">
      {{ $t('appointmentPanelActions.not-validated') }}
    </div>
    <div v-else-if="isNewByPractitioner">
      <AppConfirmation
        ref="acceptAppointment"
        :title="$t('appointmentPanelActions.accept.confirmation')"
        @confirm="acceptAppointment"
      />

      <ButtonGreen :icon="$icons.accept" @click="$confirm($refs.acceptAppointment)">
        {{ $t('appointmentPanelActions.accept.label') }}
      </ButtonGreen>
    </div>
    <div v-else-if="canBeStarted">
      <ButtonGreen
        :link-to="{ name: 'consultation', params: { appointmentId: appointment.payload.id }}"
        class="start-encounter"
      >
        {{ $t('appointmentPanelActions.available') }}
        <span class="go">
          {{ $t('appointmentPanelActions.go') }}
          <FontAwesomeIcon :icon="$icons.arrowRight" />
        </span>
      </ButtonGreen>
    </div>

    <div v-if="canBeCancelled" class="cancel-postpone">
      <AppConfirmation
        ref="cancelAppointment"
        :title="cancelAppointmentDialogMessage"
        @confirm="cancelAppointment"
      >
        <template #description>
          <p>{{ $t('appointmentPanelActions.cancel.warning') }}</p>
          <AutoHeightTextarea
            v-model="cancelReason"
            class="cancel-reason"
            :placeholder="$t('appointmentPanelActions.cancel.reason')"
            :max-length="$options.cancelReasonMaxLength"
          />
          <div class="cancel-reason-tips">
            <p>{{ $t('appointmentPanelActions.cancel.reason-char-count', { num: cancelReason.length, max: $options.cancelReasonMaxLength }) }}</p>
            <p>{{ $t('appointmentPanelActions.cancel.warning-canceled') }} <a href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu/" target="_blank"><img class="icon-question" src="@/assets/images/question.png"/></a></p>
          </div>
        </template>

      </AppConfirmation>

      <span class="link-with-icon cancel-appointment" @click="$confirm($refs.cancelAppointment)">
        <span>{{ cancelAppointmentButtonMessage }}</span>
        <FontAwesomeIcon :icon="$icons.appointmentStatusCancelled" />
      </span>
    </div>
  </div>
</template>

<script>
  import AppConfirmation from '@/components/AppConfirmation';
  import ButtonGreen from '@/components/ButtonGreen';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';

  export default {
    name: 'AppointmentPanelActions',
    components: { AutoHeightTextarea, AppConfirmation, ButtonGreen },
    cancelReasonMaxLength: 300,
    props: {
      canBeStarted: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    data() {
      return {
        cancelReason: '',
      };
    },
    computed: {
      appointment() {
        return this.$store.getters.currentAppointmentWithMeta;
      },
      patient() {
        return this.appointment.payload.patient;
      },
      practitioner() {
        return this.appointment.payload.practitioner;
      },
      status() {
        return this.appointment.payload.status;
      },
      isNewByPatient() {
        return this.appointment.payload.status === 'NEW_BY_PATIENT';
      },
      isNewByPractitioner() {
        return this.appointment.payload.status === 'NEW_BY_PRACTITIONER';
      },
      isCancelledByPractitioner() {
        return this.appointment.payload.status === 'CANCELLED_BY_PRACTITIONER';
      },
      isCancelledByPatient() {
        return this.appointment.payload.status === 'CANCELLED_BY_PATIENT';
      },
      canBeCancelled() {
        return ['NEW_BY_PATIENT', 'NEW_BY_PRACTITIONER', 'CONFIRMED'].includes(this.status);
      },
      cancelAppointmentDialogMessage() {
        return this.isNewByPractitioner
          ? this.$t('appointmentPanelActions.refuse.confirmation')
          : this.$t('appointmentPanelActions.cancel.confirmation');
      },
      cancelAppointmentButtonMessage() {
        return this.isNewByPractitioner
          ? this.$t('appointmentPanelActions.refuse.label')
          : this.$t('appointmentPanelActions.cancel.label');
      },
    },
    methods: {
      async cancelAppointment() {
        try {
          await this.$store.dispatch('appointmentsCancel', {
            id: this.appointment.payload.id,
            reason: this.cancelReason,
          });
          this.$emit('cancel');
        } catch (e) {
          await this.$addError(this.$t('appointmentPanelActions.error.cancel-appointment-error', {msg: `${this.isNewByPatient ? 'du refus' : 'de l\'annulation'}`}));
        }
      },
      async acceptAppointment() {
        try {
          await this.$store.dispatch('appointmentsAccept', { id: this.appointment.payload.id });
          this.$emit('accept');
        } catch (e) {
          await this.$addError(this.$t('appointmentPanelActions.error.accept-appointment-error'));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointment-panel-actions {
    position: sticky;
    bottom: 0;
    padding: 2rem 0;

    text-align: center;
    background-color: #fff;
    box-shadow: 0 -1.6rem 1rem -1.4rem #00000030;
  }

  .start-encounter {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.8rem 3.2rem;
    border-radius: 3rem;

    ::v-deep > span {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    .go {
      margin-left: 2rem;
      padding: 1rem;
      white-space: nowrap;
      color: $textColor;
      border-radius: 3rem;
      background-color: white;

      svg {
        margin-left: 1rem;
      }
    }
  }

  .cancel-postpone {
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  .cancel-appointment {
    color: $errorColor;
  }

  .cancel-reason {
    width: 100%;
    padding: 0.5rem;
  }

  .cancel-reason-tips {
    text-align: left;
    p:nth-child(2) {
      margin-bottom: 0;
    }
  }

  .link-with-icon {
    cursor: pointer;

    span {
      text-decoration: underline;
    }

    svg {
      margin-left: 1rem;
    }
  }

  .new-or-cancelled {
    font-size: 1.4rem;
    padding: 2rem 0;
    text-align: center;
    color: $errorColor;
    border: 1px solid $errorColor;
    border-radius: 1rem;
  }

  .icon-question {
    width: 1.3rem;
  }

</style>
