var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    [
      _c("AppProgress", {
        attrs: {
          current: 4,
          total: 8,
          position: _vm.$media.mobileSmall ? "b-15" : "b-9-2"
        }
      }),
      _c(
        "main",
        { staticClass: "container" },
        [
          _c("RecapTitle", {
            attrs: {
              title: _vm.practitioner.title + " " + _vm.practitioner.lastName,
              date: _vm.dateAppointment(this.newAppointment.startAt)
            }
          }),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c("SectionTitle", [
                _vm._v(_vm._s(_vm.$t("newAppointmentReasonPage.title")))
              ]),
              _c("span", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.$t("newAppointmentReasonPage.text")))
              ]),
              _c("FormInput", {
                staticClass: "textarea",
                attrs: {
                  id: "reason",
                  type: "textarea",
                  placeholder: _vm.$t("newAppointmentReasonPage.write")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [_c("span", [_vm._v(_vm._s(_vm.label))])]
                      },
                      proxy: true
                    },
                    _vm.$v.reason.$error
                      ? {
                          key: "error",
                          fn: function() {
                            return [
                              !_vm.$v.reason.required
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "newAppointmentReasonPage.input.required"
                                        )
                                      )
                                    )
                                  ]
                                : !_vm.$v.reason.minLength
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "newAppointmentReasonPage.input.min-length"
                                        )
                                      )
                                    )
                                  ]
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.reason,
                  callback: function($$v) {
                    _vm.reason = $$v
                  },
                  expression: "reason"
                }
              }),
              _c("div", { staticClass: "information" }, [
                _c("img", {
                  attrs: {
                    src: require("../assets/images/valide.png"),
                    alt: "valide"
                  }
                }),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("newAppointmentReasonPage.information")
                    )
                  }
                })
              ]),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  _c(
                    "ButtonTransparent",
                    {
                      attrs: {
                        busy: _vm.busy,
                        icon: _vm.$icons.chevronLeft,
                        "icon-to-left": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("newAppointmentReasonPage.back")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "ButtonGreen",
                    {
                      attrs: {
                        busy: _vm.busy,
                        icon: _vm.$icons.chevronRight,
                        rounded: "",
                        type: "submit"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("newAppointmentReasonPage.continue")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }