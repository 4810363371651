var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { attrs: { fullscreen: "" } }, [
    _c("main", { staticClass: "incompatible-browser" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "icons" },
            [
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.chrome } }),
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.safari } }),
              _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.firefox } })
            ],
            1
          ),
          _c("h1", [
            _vm._v(" " + _vm._s(_vm.$t("incompatibleBrowserPage.title")) + " ")
          ]),
          _c("p", [
            _vm._v(
              "\n              " +
                _vm._s(_vm.$t("incompatibleBrowserPage.text")) +
                "\n            "
            )
          ]),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href:
                  "http://support.hellocare.com/fr/articles/2010972-navigateurs-compatibles"
              }
            },
            [
              _c("span", [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("incompatibleBrowserPage.download")) +
                    "\n               "
                )
              ])
            ]
          ),
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("span", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("incompatibleBrowserPage.return")) +
                  "\n               "
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }