var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fullName",
      on: {
        click: function($event) {
          return _vm.$emit("change", _vm.card)
        }
      }
    },
    [
      _c("span", [_vm._v(_vm._s(_vm._f("fullName")(_vm.card)))]),
      _vm.card.isOwner
        ? _c("span", { staticClass: "whatBeneficiary" }, [_vm._v("Moi")])
        : _c("span", { staticClass: "whatBeneficiary" }, [_vm._v("Proche")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }