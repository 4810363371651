<!--
Emitted events:
- create
- ignore
- close

Slots:
- submit-button
- cancel-button
- ignore-button
-->

<template>
  <form class="credit-card-add-form" @submit.prevent="handleSubmit">
    <div class="row">
      <FormInput id="cardNumber" ref="cardNumber" :value="cardNumber" class="col _12" :label="$t('creditCardAddForm.card-number')">
        <template #input>
          <div id="cardNumber" ref="cardNumberContainer" />
        </template>
        <template v-if="cardNumberError" #error>{{ cardNumberError }}</template>
      </FormInput>
    </div>

    <div class="row">
      <FormInput id="cardExpiry" ref="cardExpiry" :value="cardExpiry" class="col _6" :label="$t('creditCardAddForm.card-expiry')">
        <template #input>
          <div id="cardExpiry" ref="cardExpiryContainer" />
        </template>
        <template v-if="cardExpiryError" #error>{{ cardExpiryError }}</template>
      </FormInput>
      <FormInput id="cardCvc" ref="cardCvc" :value="cardCvc" class="col _6" :label="$t('creditCardAddForm.card-cvc')">
        <template #input>
          <div id="cardCvc" ref="cardCvcContainer" />
        </template>
        <template v-if="cardCvcError" #error>{{ cardCvcError }}</template>
      </FormInput>
    </div>

    <div class="buttons">
      <ButtonTransparent
        :busy="submitting"
        :icon="$icons.chevronLeft"
        icon-to-left
        rounded
        @click="$emit('close')"
      >
        <slot name="cancel-button"> {{ $t('creditCardAddForm.button.cancel') }} </slot>
      </ButtonTransparent>
      <ButtonGreen
        :busy="submitting"
        center
        class="submit-button"
        rounded
        type="submit"
      >
        <slot name="submit-button"> {{ $t('creditCardAddForm.button.submit') }} </slot>
      </ButtonGreen>
    </div>

    <div v-if="!required" class="ignore-button">
      <ButtonTransparent
        :busy="submitting"
        :icon="$icons.chevronRight"
        rounded
        @click="$emit('ignore')"
      >
        <slot name="ignore-button"> {{ $t('creditCardAddForm.button.ignore') }} </slot>
      </ButtonTransparent>
    </div>

    <div class="security">
      <img alt="" src="@/assets/images/picto-payment.svg" />
      <p>
        {{ $t('creditCardAddForm.security') }}
      </p>
    </div>
  </form>
</template>

<script>
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonTransparent from '@/components/ButtonTransparent';
  import FormInput from '@/components/Form/FormInput';
  import { cardCvc, cardExpiry, cardNumber, stripe } from '@/helpers/stripe';
  import SetupIntents from '@/api/setup_intents';

  export default {
    name: 'CreditCardAddForm',
    components: { FormInput, ButtonTransparent, ButtonGreen },
    props: {
      required: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    data() {
      return {
        submitting: false,
        cardNumber: '',
        cardNumberError: null,
        cardExpiry: '',
        cardExpiryError: null,
        cardCvc: '',
        cardCvcError: null,
      };
    },
    mounted() {
      for (let element of [cardNumber, cardExpiry, cardCvc]) {
        element.addEventListener('change', this.handleChange);
        element.addEventListener('focus', this.handleFocus);
        element.addEventListener('blur', this.handleBlur);
        element.mount(this.$refs[`${element._componentName}Container`]);
      }
    },
    beforeDestroy() {
      for (let element of [cardNumber, cardExpiry, cardCvc]) {
        element.removeEventListener('change', this.handleChange);
        element.removeEventListener('focus', this.handleFocus);
        element.removeEventListener('blur', this.handleBlur);
        element.unmount();
      }
    },
    methods: {
      handleChange({ elementType, empty, error }) {
        switch (elementType) {
          case 'cardNumber':
            this.cardNumberError = error?.message;
            return this.cardNumber = empty ? '' : 'fake-value';
          case 'cardExpiry':
            this.cardExpiryError = error?.message;
            return this.cardExpiry = empty ? '' : 'fake-value';
          case 'cardCvc':
            this.cardCvcError = error?.message;
            return this.cardCvc = empty ? '' : 'fake-value';
        }
      },
      handleFocus({ elementType }) {
        this.$refs[elementType].focused = true;

        switch (elementType) {
          case 'cardNumber':
            return cardNumber.update({ placeholder: '1234 1234 1234 1234' });
          case 'cardExpiry':
            return cardExpiry.update({ placeholder: this.$t('creditCardAddForm.date-expiry') });
          case 'cardCvc':
            return cardCvc.update({ placeholder: 'CVC' });
        }
      },
      handleBlur({ elementType }) {
        this.$refs[elementType].focused = false;

        switch (elementType) {
          case 'cardNumber':
            return cardNumber.update({ placeholder: '' });
          case 'cardExpiry':
            return cardExpiry.update({ placeholder: '' });
          case 'cardCvc':
            return cardCvc.update({ placeholder: '' });
        }
      },
      async handleSubmit() {
        this.submitting = true;

        try {
          const { clientSecret } = await SetupIntents.create();

          const { error } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardNumber,
            },
          });

          if (error) {
            await this.$addError(error.message);
          } else {
            await this.$store.dispatch('paymentMethodsFetchAll');
            await this.$emit('create');
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.submitting = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row {
    margin-bottom: 2rem;
  }

  .security {
    display: flex;
    align-items: flex-start;
    margin-top: 3.5rem;
    padding: 2.5rem 1.5rem 1.5rem;
    border-top: 1px solid $lightGrey;

    p {
      margin: 0 0 0 2rem;
    }
  }

  .StripeElement {
    width: 100%;
    padding: 0.5rem 1rem 1rem;
    color: #1F1F1F;
  }

  .ElementsApp {
    height: 100%;

    &::v-deep input, &::v-deep select, &::v-deep textarea {
      font-size: 1.5rem;
      width: 100%;
      padding: 0.5rem 1rem 1rem;
      color: #1F1F1F;
      border: none;
      outline: none;
      background-color: transparent;

      &[disabled], &.disabled {
        font-style: italic;
        color: #7C7C7C;
      }
    }

    &::v-deep div {
      outline: none;
    }
  }

  .details-card {
    .expire, .cvc {
      display: inline-block;
    }

    .expire {
      width: calc(50% - 15px);
      margin-right: 15px;
    }

    .cvc {
      width: 50%;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    text-align: center;
  }

  .ignore-button {
    margin-top: 1rem;
    text-align: center;
  }
</style>
