<!--
Available slots:
- error
- help
-->

<template>
  <div class="form-widget" :class="{ 'has-error': $slots.error, transparent }">
    <div class="form-widget-inner">
      <slot />
    </div>
    <small class="error" v-if="$slots.error">
      <FontAwesomeIcon class="error-icon" :icon="$icons.inputError" fixed-width />
      <slot name="error" />
    </small>
    <small class="help" v-if="$slots.help">
      <slot name="help" />
    </small>
  </div>
</template>

<script>
  export default {
    name: 'FormWidget',
    props: {
      transparent: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-widget {
    padding-bottom: 2.3rem;

    &.has-error {
      padding-bottom: 0;
    }
  }

  .form-widget-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #1F1F1F;
    background-color: $inputBackgroundColor;
    border-bottom: 1px solid $inputBorderColor;
    font-weight: 200;

    .transparent > & {
      background-color: transparent;
      border-color: transparent;
    }

    label {
      padding: 1rem 1rem 0.5rem;
    }

    .has-error > & {
      border-color: $errorColor;

      label {
        color: $errorColor;
      }
    }

    &::v-deep input, &::v-deep select, &::v-deep textarea {
      border: none;
      background-color: transparent;
      font-size: 1.5rem;
      outline: none;
      padding: 0.5rem 1rem 1rem;
      color: $inputColor;
      width: 100%;

      &[disabled], &.disabled {
        color: $inputDisabledColor;
        font-style: italic;
      }
    }

    &::v-deep div {
      outline: none;
    }
  }

  .help, .error {
    display: flex;
    margin-top: .3rem;
    font-weight: 300;
    align-items: center;
  }

  .help {
    font-size: 1rem;
    color: $inputHelpColor;
  }

  .error {
    font-size: 1.2rem;
    font-weight: bold;
    color: $errorColor;
  }

  .error-icon {
    margin-right: 0.5rem;
  }
</style>
