import Credentials from '@/api/credentials.js';

export default {
    state: {
        room: null,
        isCreatingRoom: false
    },
    mutations: {
        DOC_2_U_CREATE_ROOM(state) {
            state.room = null;
            state.isCreatingRoom = true;
        },
        DOC_2_U_CREATE_ROOM_SUCCESS(state, { room }) {
            state.isCreatingRoom = false;
            state.room = room;
        },
        DOC_2_U_CREATE_ROOM_FAIL(state) {
            state.isCreatingRoom = false;
            state.room = null;
        },
    },
    actions: {
        async createFullAccessRoom({commit}, {patientId}) {
            commit('DOC_2_U_CREATE_ROOM');
            try {
                const room = await Credentials.getDoc2uCredentials(patientId);
                commit('DOC_2_U_CREATE_ROOM_SUCCESS', { room });
            } catch (e) {
                commit('DOC_2_U_CREATE_ROOM_FAIL');
                throw e;
            }
        }
    }
}