<template>
  <AppLayout>
    <main class="appointments-page">
      <div class="title-with-button">
        <SectionTitle>{{ $t('appointmentsPage.title') }}</SectionTitle>
        <div>
          <ButtonRed :icon="$icons.newAppointment" link-to="/new-appointment" small>
            {{ $t('appointmentsPage.new-appointment-button') }}
          </ButtonRed>
        </div>
      </div>
      <div class="appointments" :class="{ busy: isBusy }">
        <template v-for="appointments in appointmentsForPlanning">
          <HorizontalDivider class="appointments-divider" left>
            {{ appointments[0].day }}
            {{ appointments[0].monthLong }}
            {{ appointments[0].year }}
          </HorizontalDivider>
          <div class="appointment-items">
            <AppointmentListItem
              v-for="appointment in appointments"
              :key="appointment.payload.id"
              :day="appointment.dayShort"
              :month="appointment.monthShort"
              :date="appointment.date"
              :time="appointment.time"
              :expired="appointment.expired"
              :channel="appointment.payload.product.channel"
              :practitioner-name="appointment.payload.practitioner | fullName"
              :patient-name="appointment.payload.patient | fullName"
              :description="appointment.payload.description"
              :can-be-started="canBeStarted(appointment.payload.startAt)"
              :status="appointment.payload.status"
              :path="`/appointments/${appointment.payload.id}`"
            />
          </div>
        </template>
        <div v-if="!hasAppointments" class="no-appointment">
          {{ $t('appointmentsPage.no-appointment') }}
        </div>
        <div v-else>
          <Paginator
            v-if="totalPages"
            v-model="currentPage"
            :no-li-surround="true"
            :page-count="totalPages"
            class="paginator"
            :next-text="$t('appointmentsPage.paginator.next')"
            :prev-text="$t('appointmentsPage.paginator.previous')"
          />
        </div>
      </div>
      <transition :duration="600">
        <router-view />
      </transition>
    </main>
  </AppLayout>
</template>

<script>
  import { pickElement, wait } from '@/helpers/tools.js';
  import moment from 'moment';
  import AppLayout from '../components/AppLayout';
  import AppointmentListItem from '../components/AppointmentListItem';
  import ButtonGreen from '../components/ButtonGreen';
  import ButtonRed from '../components/ButtonRed';
  import HorizontalDivider from '../components/HorizontalDivider';
  import SectionTitle from '../components/SectionTitle';
  import Paginator from 'vuejs-paginate';

  export default {
    name: 'AppointmentsPage',
    components: { AppLayout, SectionTitle, AppointmentListItem, HorizontalDivider, ButtonRed, ButtonGreen, Paginator },
    async mounted() {
      await this.$store.dispatch('appLoaderShow');
      await this.loadAppointments();
      await this.$store.dispatch('appLoaderHide');
      this.setupGuide();
      this.$setInterval(this.loadAppointments, 60000);
    },
    beforeRouteUpdate(to, from, next) {
      if (to.name === 'appointments') {
        this.loadAppointments();
      }
      next();
    },
    watch: {
      currentPage() {
        this.loadAppointments();
      },
    },
    data() {
      return {
        currentPage: 1,
      };
    },
    computed: {
      hasAppointments() {
        return this.$store.state.appointments.all.length > 0;
      },
      appointmentsForPlanning() {
        return this.$store.getters.appointmentsForPlanning;
      },
      totalPages() {
        return this.$store.state.appointments.totalPages;
      },
      isBusy() {
        return this.$store.state.appointments.isFetchingAll;
      },
    },
    methods: {
      async setupGuide() {
        await this.$store.dispatch('vueGuideAddStep', {
          id: 'appointment-detail',
          target: '.appointment-list-item',
          content: this.$t('appointmentsPage.infos-appointment'),
          offset: 10,
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'nav-profile',
          content: this.$t('appointmentsPage.infos-update'),
          target: '.app-navigation > .nav-item:nth-of-type(2)',
          before: async () => {
            if (!this.$store.state.appNavigation.isExpanded) {
              await this.$store.dispatch('appNavigationExpand');
              await wait(450);
            }
          },
          after: () => {
            this.$store.dispatch('appNavigationShrink');
          },
        });

        await this.$store.dispatch('vueGuideAddStep', {
          id: 'chat-icon',
          target: pickElement('#front-chat-holder > div > div'),
          content: this.$t('appointmentsPage.infos-questions'),
          offset: 10,
        });
      },
      async loadAppointments() {
        try {
          await this.$store.dispatch('appointmentsFetchAll', { page: this.currentPage });
        } catch (e) {
          this.$addError(this.$t('appointmentsPage.error-load'));
          throw e;
        }
      },
      canBeStarted(startAt) {
        const minimumStartTime = moment(startAt).subtract(15, 'm');

        return moment().isAfter(minimumStartTime);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appointments-page {
    padding-left: 15rem;
  }

  .appointment-items {
    display: flex;
    flex-wrap: wrap;
  }

  .appointments-divider {
    padding: 2rem 1rem;
  }

  .title-with-button {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .link-to-calendar {
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .no-appointment {
    font-size: 1.6rem;
    font-style: italic;
  }

  .busy .appointment-list-item {
    pointer-events: none;
    opacity: 0.5;
  }


</style>
