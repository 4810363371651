<template>
  <FormSelect
    :id="id"
    :label="loading ? $t('formSelectSpecialties.select.loading') : $t('formSelectSpecialties.select.label')"
    :value="value"
    :disabled="loading"
    @input="$emit('input', $event)"
  >
    <option value="" />
    <optgroup :label="$t('formSelectSpecialties.frequent-searches')">
      <option v-for="specialty in frequentSpecialties" :value="specialty.id">
        {{ specialty.name }}
      </option>
    </optgroup>
    <optgroup :label="$t('formSelectSpecialties.all-specialties')">
      <option v-for="specialty in specialties" :value="specialty.id">
        {{ specialty.name }}
      </option>
    </optgroup>
    <template v-if="$slots.help && !$slots.error" #help>
      <slot name="help" />
    </template>
    <template v-if="$slots.error" #error>
      <slot name="error" />
    </template>
  </FormSelect>
</template>

<script>
  import FormSelect from '@/components/Form/FormSelect';

  export default {
    name: 'FormSelectSpecialties',
    components: { FormSelect },
    props: {
      id: {
        type: String,
        required: true,
      },
      specialties: {
        type: Array,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      frequentSpecialties() {
        return [
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.general-practitioner')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.psychologist')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.psychiatre')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.pediatrician')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.dermatologist')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.gynecologist')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.sex-therapist')),
          this.specialties.find(({ name }) => name === this.$t('formSelectSpecialties.frequent-specialties.nutritionist')),
        ].filter(s => s);
      },
    },
  };
</script>

<style scoped>

</style>
