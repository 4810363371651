<template>
  <AppLayout class="profile-access-page">
    <main>
      <ProfileTabBar />
      <div class="container">
        <p class="information bordered">
          {{ $t('profileAccessPage.option-enable-info') }}
        </p>
        <div class="settings">
          <GroupTitle :icon="$icons.settings">{{ $t('profileAccessPage.title') }}</GroupTitle>
          <p v-if="accessTokens.length === 0" class="information">
            {{ $t('profileAccessPage.disable') }}
          </p>
          <div v-for="accessToken in accessTokens" v-else class="setting">
            <img :src="accessToken.partner.logo" alt="" class="setting-icon" />
            <div class="settings-content">
              <div class="setting-label">
                <label :for="accessToken.id">{{ $t('profileAccessPage.setting-label') }} {{ accessToken.partner.name }}</label>
                <div class="setting-switch">
                  <FormSwitch
                    :id="accessToken.id"
                    :busy="updating[accessToken.id]"
                    :checked="accessToken.active"
                    @change="changeAccessTokenStatus(accessToken.id, !accessToken.active)"
                  />
                </div>
              </div>
              <p class="setting-description">
                {{ accessToken.partner.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import SectionTitle from '@/components/SectionTitle';
  import AppLayout from '@/components/AppLayout';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import FormSwitch from '@/components/Form/FormSwitch';
  import GroupTitle from '@/components/GroupTitle';

  export default {
    name: 'ProfileAccessPage',
    components: { GroupTitle, FormSwitch, ProfileTabBar, AppLayout, SectionTitle },
    data() {
      return {
        updating: {},
      };
    },
    async mounted() {
      await this.loadData();

      if (!this.accessTokenId || !this.accessTokenAction) {
        return;
      }

      const accessToken = this.accessTokens.find(accessToken => accessToken.id === this.accessTokenId);

      if (!accessToken) {
        return this.$addError(this.$t('profileAccessPage.no-exist'));
      }

      const status = this.accessTokenAction === 'enable';

      if (accessToken.active === status) {
        return;
      }

      await this.changeAccessTokenStatus(this.accessTokenId, status);
      this.$addSuccess(this.$t('profileAccessPage.change-access',{msg: `${status ? 'activé' : 'désactivé'}`}));
    },
    props: {
      accessTokenId: {
        type: String,
        required: false,
      },
      accessTokenAction: {
        type: String,
        required: false,
        validator: function (action) {
          return ['enable', 'disable'].indexOf(action) !== -1;
        },
      },
    },
    computed: {
      accessTokens() {
        return this.$store.state.accessTokens.all;
      },
    },
    methods: {
      async loadData() {
        this.$store.dispatch('appLoaderShow');
        try {
          await this.$store.dispatch('accessTokensFetchAll');
          this.accessTokens.forEach(accessToken => {
            this.$set(this.updating, accessToken.id, false);
          });
        } finally {
          this.$store.dispatch('appLoaderHide');
        }
      },
      async changeAccessTokenStatus(id, status) {
        try {
          this.updating[id] = true;
          await this.$store.dispatch('accessTokenChangeStatus', { id, status });
        } catch (e) {
          this.$forceUpdate();
          throw e;
        } finally {
          this.updating[id] = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    display: block;
    max-width: 98rem;
    margin: 0 auto;
  }

  .information {
    font-weight: 400;
    font-style: italic;
    margin-bottom: 3rem;
    text-align: justify;

    &.bordered {
      padding: 2rem 0 3rem;
      border-bottom: 1px solid #E0E0E0;
    }
  }

  .settings {
    flex: 1;

    .setting-description {
      margin-right: 6rem;
    }
  }

  .setting {
    padding: 1rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    border-bottom: 1px dashed $lightGrey;

    &:hover {
      background-color: $lighterGrey;
    }
  }

  .settings-content {
    flex: 1;
  }

  .setting-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      font-weight: 500;
      padding-right: 3rem;
      cursor: pointer;
    }
  }

  .setting-icon {
    max-width: 5rem;
    max-height: 5rem;
    margin-right: 1rem;
  }

  .setting-description {
    font-weight: 400;
    font-style: italic;
    margin-top: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
