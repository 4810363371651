<template>
  <component :is="component" :busy="isBusy" :icon="icon || $icons.accept" outlined @click="handleClick">
    <slot>{{ $t('confirmButton.confirm') }}</slot>
  </component>
</template>

<script>
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonRed from '@/components/ButtonRed';

  export default {
    name: 'ConfirmButton',
    components: { ButtonGreen },
    inject: ['appConfirmationSetBusy', 'appConfirmationClose', 'appConfirmationStatus'],
    props: {
      icon: {
        type: Object,
        required: false,
      },
      color: {
        default: 'green',
        validator(color) {
          return [
            'green',
            'red',
          ].indexOf(color) !== -1;
        },
      }
    },
    computed: {
      component() {
        switch (this.color) {
          case 'red':
            return ButtonRed;
          default:
            return ButtonGreen;
        }
      },
      isBusy() {
        return this.appConfirmationStatus.isBusy;
      },
    },
    methods: {
      async handleClick() {
        this.appConfirmationSetBusy(true);

        try {
          await this.$listeners.click(this.appConfirmationStatus.payload);
          this.$emit('success');
        } catch (e) {
          this.$emit('error');
        } finally {
          await this.appConfirmationClose();
        }
      },
    },
  };
</script>
