var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shared-files" }, [
    _c(
      "div",
      { staticClass: "files-group" },
      [
        _c("label", [_vm._v(" " + _vm._s(_vm.$t("sharedFiles.title")) + " ")]),
        _vm.isFetching
          ? _c("ModuleLoader")
          : _c(
              "div",
              { staticClass: "files" },
              [
                _vm._l(_vm.patientFiles, function(file) {
                  return _c(
                    "File",
                    {
                      key: file.id,
                      attrs: { id: file.id, removable: "" },
                      on: { removed: _vm.reloadFiles }
                    },
                    [_vm._v("\n        " + _vm._s(file.name) + "\n      ")]
                  )
                }),
                _vm.isAttaching
                  ? _c(
                      "File",
                      { attrs: { id: "" } },
                      [
                        _c("FontAwesomeIcon", {
                          attrs: { icon: _vm.$icons.spinner, spin: "" }
                        })
                      ],
                      1
                    )
                  : _vm.patientFiles.length === 0
                  ? _c("p", { staticClass: "no-share" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("sharedFiles.not-shared")) + " "
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "files-group" },
      [
        _c("label", [
          _vm._v(
            " " + _vm._s(_vm.$t("sharedFiles.shared-by-practitioner")) + " "
          )
        ]),
        _vm.isFetching
          ? _c("ModuleLoader")
          : _c(
              "div",
              { staticClass: "files" },
              [
                _vm._l(_vm.practitionerFiles, function(file) {
                  return _c("File", { key: file.id, attrs: { id: file.id } }, [
                    _vm._v("\n        " + _vm._s(file.name) + "\n      ")
                  ])
                }),
                _vm.practitionerFiles.length === 0
                  ? _c("p", { staticClass: "no-share" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("sharedFiles.practitioner-not-shared")
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "files-group" },
      [
        _c("label", [
          _vm._v(" " + _vm._s(_vm.$t("sharedFiles.shared-new-file")) + " ")
        ]),
        _c("FileUploadButton", {
          attrs: { label: _vm.$t("sharedFiles.file-upload-button") },
          on: { change: _vm.handleFileUpload }
        }),
        _c(
          "a",
          {
            staticClass: "reload-files",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.reloadFiles($event)
              }
            }
          },
          [
            _c("FontAwesomeIcon", { attrs: { icon: _vm.$icons.reload } }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("sharedFiles.reload-list-document")) +
                "\n    "
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }