import api from './';
import { normalizePractitioner, normalizePractitioners } from '@/helpers/normalizers';

export default {
  search: (practitionerName, specialty, location, channel, page, seed, practiceCountry) => {
    const resultsByPage = 20;

    const searchParams = new URLSearchParams({
      '_page': page.toString(),
      '_count': resultsByPage.toString(),
    });

    const queryParams = {
      'q' : practitionerName,
      's' : specialty,
      'l' : location,
      'c' : channel,
      '_seed' : seed,
      'practice_country' : practiceCountry
    };

    Object.keys(queryParams).map((key) => {
      queryParams[key] && searchParams.set(key,queryParams[key])
    });

    return api.get('practitioners-search', { searchParams }).json().then(result => ({
      page: result.page,
      pages: Math.ceil(result.total / resultsByPage),
      data: normalizePractitioners(result.data),
      seed: result.seed,
    }));
  },
  getOne: (id) => {
    return api.get(`practitioners/${id}`).json().then(results => results[0]).then(normalizePractitioner);
  }
};
