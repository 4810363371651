import { render, staticRenderFns } from "./AppointmentPanelActions.vue?vue&type=template&id=5abfea3e&scoped=true&"
import script from "./AppointmentPanelActions.vue?vue&type=script&lang=js&"
export * from "./AppointmentPanelActions.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentPanelActions.vue?vue&type=style&index=0&id=5abfea3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5abfea3e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5abfea3e')) {
      api.createRecord('5abfea3e', component.options)
    } else {
      api.reload('5abfea3e', component.options)
    }
    module.hot.accept("./AppointmentPanelActions.vue?vue&type=template&id=5abfea3e&scoped=true&", function () {
      api.rerender('5abfea3e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AppointmentPanel/AppointmentPanelActions.vue"
export default component.exports