import ky from 'ky';
import moment from 'moment';
import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/config/store_modules';
import { isProduction } from '@/helpers/tools.js';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: !isProduction,
  modules,
  state: {
    apiKey: null,
    apiToken: null,
    currentTime: moment(),
    settings: {},
  },
  mutations: {
    API_KEY_FETCH_SUCCESS(state, { apiKey, apiToken }) {
      state.apiKey = apiKey;
      state.apiToken = apiToken;
    },
    SET_CURRENT_TIME(state, { currentTime }) {
      state.currentTime = currentTime;
    },
    UPDATE_SETTINGS() {},
    UPDATE_SETTINGS_SUCCESS(state, { settings }) {
      state.settings = settings;
    },
    UPDATE_SETTINGS_FAILED() {},
  },
  actions: {
    async loadSettings({ commit }) {
      commit('UPDATE_SETTINGS');
      try {
        const settings = await ky.get('/dynamic_settings.json').json();
        commit('UPDATE_SETTINGS_SUCCESS', { settings });
      } catch (e) {
        commit('UPDATE_SETTINGS_FAILED');
      }
    },
    refreshCurrentTime({ commit, state }) {
      const currentTime = moment();

      if (!currentTime.isSame(state.currentTime, 'minutes')) {
        commit('SET_CURRENT_TIME', { currentTime });
      }
    },
  },
});
