<template>

  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <div class="summary-interface">
          <div class="container">
            <div class="summary">
              <SectionTitle>{{ $t('profileSummaryPage.title') }}</SectionTitle>
              <div class="content">
                <p class="start-at">{{ $t('profileSummaryPage.start-at') }}</p>
                <ul>
                  <li class="advantage" v-html="$t('profileSummaryPage.advantage.1')"/>
                  <li class="advantage" v-html="$t('profileSummaryPage.advantage.2')"/>
                  <li class="advantage" v-html="$t('profileSummaryPage.advantage.3')"/>
                </ul>
              </div>

              <p class="amount-row" v-html="$t('profileSummaryPage.price')"/>

              <p class="content sub-infos" v-html="$t('profileSummaryPage.info')" />

            </div>
            <div class="payment">
              <div>
                <CardPayment />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import SectionTitle from '@/components/SectionTitle.vue';
  import ProfileTabBar from '@/components/ProfileTabBar.vue'
  import AppLayout from '@/components/AppLayout.vue'
  import CardPayment from "../../components/Premium/CardPayment.vue";

  export default {
    name: 'summary-payment',
    components: {
      SectionTitle,
      ProfileTabBar,
      AppLayout,
      CardPayment
    },
    data() {
      return {
        selectedPaymentMethodId: null,
        paymentError: null,
        submitting: false
      };
    },
    computed: {
      appointment() {
        return this.encounter.appointment;
      },
      profile() {
        return this.$store.state.patients.profile;
      },
      practitioner() {
        return this.encounter.practitioner;
      },
      busy() {
        return this.submitting || this.$store.state.encounters.isAuthorizing;
      },
    },
    methods: {
      handleSelectPaymentMethod(selectedPaymentMethodId) {
        this.selectedPaymentMethodId = selectedPaymentMethodId;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .summary-interface {
    height: 100%;
    background-color: #FFFFFF;
    width: 100%;
    @include onDesktop {
      padding: 4rem 0;
    }
  }

  p {
    font-weight: 200;
  }

  .start-at {
    font-weight: 400;
    font-size: 1.6rem;
  }

  .container {
    font-size: 1.5rem;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1.5rem 0;

    @include onDesktop {
      display: flex;
    }
  }

  .content {
    padding-right: 6rem;
  }

  .credit-cards {
    max-width: 50rem;
    margin: 0 auto;
  }

  .summary {
    flex: 1;
    padding: 1rem;
  }

  .payment {
    flex: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    @include onDesktop {
      padding-top: 6rem;
      padding-left: 2rem;
      border-left: 0.1rem solid $lightGrey;
    }

    @include onMobile {
      .credit-cards {
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }

    .error p {
      padding-left: 1.2rem;
      text-align: left;
    }
  }

  .amount-row {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background-color: $lightGrey;

    .amount {
      font-weight: 400;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    text-align: center;
    padding: 0 1rem;
  }

  .error {
    font-weight: 400;
    padding: 2rem;
    text-align: center;
    color: white;
    border-radius: 0.8rem;
    background-color: $errorColor;
  }

  .action {
    max-width: 38rem;
    margin: 0 auto;
  }

  .text_red {
    color: $errorColor;
    font-weight: 400;
  }

  .advantage {
    margin: 0;
    font-weight: normal;
  }
  .sub-infos {
    color: black;
    font-weight: normal;
  }
</style>
