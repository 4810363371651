import api from './';
import { normalizePractitioner } from '@/helpers/normalizers';
import moment from 'moment';

export default {
  getOne: (id) => {
    return api.get(`practitioners/${id}`).json().then(normalizePractitioner)
  },
  /**
   * @param {string} id Practitioner ID
   * @param {string} productId
   * @param {number} duration Estimated duration (in minutes)
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   * @param {string} channel
   * @returns {Promise<unknown>}
   */
  availabilities: (id, productId, duration, startDate, endDate, channel) => {
    if (!moment.isMoment(startDate)) {
      throw 'Availabilities dates must be instances of Moment'
    }

    let searchParams = new URLSearchParams({
      product: productId,
      duration: duration,
      start: startDate.format(),
      end: endDate.format(),
      channel: channel,
    });

    return api.get(`practitioners/${id}/available_slots`, { searchParams }).json();
  },
};
