<template>
  <div>
    <div v-if="orderIsObject">
      <div class="click-collect" v-if="this.$store.state.pharmacies_order.current.status === 'new' ">
        <PharmacyOrderCard :order="order" status-text="Commande enregistrée" :can-cancel="true" imageName="new"  status="new" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'sent' ">
        <PharmacyOrderCard :order="order"  status-text="Commande validée" :can-cancel="true" imageName="new"  status="sent" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'preparing' ">
        <PharmacyOrderCard :order="order"  status-text="En cous de préparation" :can-cancel="true" imageName="preparing"  status="preparing" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'ready' && this.$store.state.pharmacies_order.current.type === 'click_collect' ">
        <PharmacyOrderCard :order="order"  status-text="Commande prête" :can-cancel="false" imageName="preparing"  status="ready" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'ready' && this.$store.state.pharmacies_order.current.type === 'delivery'">
        <PharmacyOrderCard :order="order"  status-text="Commande en cours de récupération" :can-cancel="false" imageName="preparing"  status="readyForDelivery" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'collected' ">
        <PharmacyOrderCard :order="order"  status-text="Commande récupérée par le livreur" :can-cancel="true" imageName="delivering"  status="delivering" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'delivering' ">
        <PharmacyOrderCard :order="order"  status-text="Commande en cours de livraison" :can-cancel="true" imageName="delivering"  status="delivering" />
      </div>
      <div class="click-collect" v-else-if="this.$store.state.pharmacies_order.current.status === 'finished' ">
        <PharmacyOrderCard :order="order"  status-text="Commande reçue" :can-cancel="false" imageName="terminated"  status="finished" />
      </div>
      <div v-if="this.$store.state.pharmacies_order.current.status === 'external_order'">
        <div class="click-collect">
          <p><strong>{{$t('pharmacyOrder.appointment-panel-card.title')}}</strong></p>
          <p>{{$t('pharmacyOrder.appointment-panel-card.subtitle-before-external')}}</p>
          <p><b>{{$t('pharmacyOrder.appointment-panel-card.subtitle-partner')}}</b></p>
          <div class="btn_partner">
            <ButtonGreen @click="sendToPartner" small>{{$t('pharmacyOrder.appointment-panel-card.keep-partner')}}</ButtonGreen>
            <ButtonGreen :link-to="{ name: 'pharmacy-selection', params: { appointmentId: this.appointmentId } }" small>{{$t('pharmacyOrder.appointment-panel-card.pick-pharmacy')}}</ButtonGreen>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="hasPrescriptionForPharmacy">
        <div class="click-collect">
          <p><strong>{{$t('pharmacyOrder.appointment-panel-card.title')}}</strong></p>
          <p>{{$t('pharmacyOrder.appointment-panel-card.subtitle-before')}}</p>
          <ButtonGreen :link-to="{ name: 'pharmacy-selection', params: { appointmentId: appointmentId } }">{{$t('pharmacyOrder.appointment-panel-card.pick-pharmacy')}}</ButtonGreen>
        </div>
      </div>
      <div v-else>
        <p><strong>{{$t('pharmacyOrder.appointment-panel-card.title')}}</strong></p>
        <p>{{$t('pharmacyOrder.appointment-panel-card.no-prescription')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGreen from "../ButtonGreen";
import PharmacyOrderCard from "./PharmacyOrderCard";
import Pharmacies from "../../api/pharmacies_orders";
import {getSetting} from "../../helpers/tools";

export default {
  name: 'PanelPharmacyOrderCard',
  components: {PharmacyOrderCard, ButtonGreen},
  props: {
    order: {
      required: true
    },
    appointmentId: {
      type: String,
      required: true,
    },
    orderIsObject: null
  },
  mounted() {
    this.closedAppointmentHasPrescriptions()
  },
  data() {
    return {
      checking: false,
      hasPrescriptionForPharmacy: true
    }
  },
  methods: {
    async closedAppointmentHasPrescriptions() {
      this.checking = true;
      const hasPrescription = await Pharmacies.closedAppHasPrescriptions(this.appointmentId);
      if(hasPrescription.status === 204) this.hasPrescriptionForPharmacy = false
      if(hasPrescription.status === 200) this.hasPrescriptionForPharmacy = true
      this.checking = false;
    },
    sendToPartner() {
      window.location = this.order.pharmacy.provider.external_order_url+'?pharma='+this.order.pharmacy.pharmacy_provider_id;
    }
  }
}
</script>

<style lang="scss">
.btn_partner{
  display: flex;
  flex-wrap: wrap;
  button,a{
    margin: 5px 10px 5px 0;
  }
}
</style>