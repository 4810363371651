<script>
  import BaseButton from './BaseButton';

  export default {
    name: 'ButtonBlue',
    extends: BaseButton
  };
</script>

<style lang="scss" scoped src="../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
    background-color: #166387;

    &.outlined {
      border-color: #166387;
      color: #166387;
    }
  }
</style>
