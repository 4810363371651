var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.isCalling ? "show-navigation" : ""] }, [
    _c(
      "nav",
      _vm._g(
        {
          staticClass: "app-navigation",
          class: { expanded: _vm.expanded, desktop: _vm.desktop }
        },
        _vm.desktop
          ? {
              mouseenter: _vm.expandNavigation,
              mouseleave: _vm.shrinkNavigation
            }
          : {}
      ),
      [
        !_vm.desktop
          ? _c(
              "header",
              { staticClass: "mobile-header" },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "toggle",
                  attrs: { icon: _vm.toggleIcon },
                  on: { click: _vm.toggleNavigation }
                }),
                _c("AppLogoMobile", { attrs: { white: "" } }),
                _c("span", { staticClass: "spacer" })
              ],
              1
            )
          : _vm._e(),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.new-appointment"),
            to: "/new-appointment",
            icon: _vm.$icons.clock
          }
        }),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.profile"),
            to: "/profile/view",
            icon: _vm.$icons.user
          }
        }),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.appointments"),
            to: "/appointments",
            icon: _vm.$icons.calendar
          }
        }),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.constantes"),
            to: "/constantes",
            icon: _vm.$icons.heartBeat
          }
        }),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.help"),
            to: "https://support.hellocare.com/fr/",
            icon: _vm.$icons.info,
            external: ""
          }
        }),
        _c("AppNavigationItem", {
          attrs: {
            label: _vm.$t("appNavigation.logout"),
            to: "/logout",
            icon: _vm.$icons.logout
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }