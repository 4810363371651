<template>
  <AppLayout>
    <div class="d-flex justify-content-center align-items-center flex-column p-5 click_collect_container">
      <div class="p-5">
        <img alt="handshake" src="../../assets/images/picto_handShake.svg" />
      </div>
      <p class="text-center font-bold" v-html="$t('pharmacyOrder.exit-page.content-success', { pharmacyrs: pharmacyrs })"></p>
      <p>
        <ButtonGreen class="font-bold" :link-to="{name: 'home'}">{{$t('pharmacyOrder.exit-page.btn-to-home')}}</ButtonGreen>
      </p>
    </div>
  </AppLayout>
</template>

<script>

import ButtonGreen from "../../components/ButtonGreen";
import AppLayout from "../../components/AppLayout";

export default {
  name: 'PharmacySuccess',
  components: {AppLayout, ButtonGreen},
  props: {
    pharmacyrs: null
  },
  mounted(){
    this.redirectToHomeIfNoRS();
    this.clearCurrentOrderAppointment();
    this.clearCurrentPharmacyOrder();
  },
  methods: {
    redirectToHomeIfNoRS(){
      if(typeof this.pharmacyrs !== 'string') {
        this.$router.push({name: 'home'});
      }
    },
    async clearCurrentOrderAppointment(){
      localStorage.removeItem('currentOrderAppointment');
    },
    async clearCurrentPharmacyOrder(){
      localStorage.removeItem('currentPharmacyOrder');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
</style>