var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appointment-panel" },
    [
      _c("router-link", {
        staticClass: "backdrop",
        attrs: { to: _vm.backPath }
      }),
      _c(
        "div",
        { staticClass: "panel" },
        [
          _vm.isLoadingAppointment
            ? _c("AppLoader", [
                _vm._v(" " + _vm._s(_vm.$t("appointmentPanel.loader")) + " ")
              ])
            : _vm.appointment
            ? _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "SectionTitle",
                    { staticClass: "panel-title" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("appointmentPanel.title")) +
                          "\n        "
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "close-panel",
                          attrs: { to: _vm.backPath }
                        },
                        [
                          _c("FontAwesomeIcon", {
                            staticClass: "close-panel-icon",
                            attrs: { icon: _vm.$icons.closePanel }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "summary" },
                    [
                      _c("section", { staticClass: "section" }, [
                        _c("h4", { staticClass: "practitioner-full-name" }, [
                          _vm._v(_vm._s(_vm._f("fullName")(_vm.practitioner)))
                        ]),
                        _vm.practitioner.phone
                          ? _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("phone")(_vm.practitioner.phone))
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("AppointmentPanelDetails", {
                        staticClass: "section",
                        attrs: { "can-be-started": _vm.canBeStarted }
                      }),
                      _c("p", { staticClass: "section description" }, [
                        _vm._v(_vm._s(_vm.appointment.payload.description))
                      ]),
                      _c("div", { staticClass: "click_collect_container" }, [
                        this.$store.state.pharmacies_order.isFetchingOne ||
                        this.$store.state.pharmacies_order.isCancelling ||
                        this.$store.state.pharmacies_order.isFinishing
                          ? _c("div", [_c("Spinner")], 1)
                          : _c("div", [
                              _vm.appointment.payload.status === "CLOSED" ||
                              _vm.appointment.payload.status === "TERMINATED"
                                ? _c(
                                    "div",
                                    [
                                      _c("PanelPharmacyOrderCard", {
                                        attrs: {
                                          order: _vm.getOrder,
                                          orderIsObject: _vm.orderIsObject,
                                          appointmentId: _vm.appointmentId
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                      ]),
                      _c("div", { staticClass: "doc2u_container" }, [
                        this.$store.state.observations.isFetching
                          ? _c(
                              "div",
                              { staticClass: "container-spinner" },
                              [_c("Spinner")],
                              1
                            )
                          : _c("div", [
                              _vm.searchLength > 0
                                ? _c(
                                    "div",
                                    [
                                      _c("LastObservations", {
                                        attrs: {
                                          "search-length": _vm.searchLength,
                                          observations: this.$store.state
                                            .observations.search
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c("p", [
                                      _vm._v("Aucune mesure enregistrée.")
                                    ])
                                  ])
                            ])
                      ]),
                      _vm.isPaymentRequired
                        ? _c("AppointmentPanelPayment", {
                            staticClass: "section",
                            on: { paid: _vm.loadAppointment }
                          })
                        : _vm.isCancelled
                        ? _c("div", { staticClass: "section cancel-reason" }, [
                            _c("h4", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "appointmentPanel.title-cancel-reason"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.appointment.payload.cancelReason ||
                                    _vm.$t("appointmentPanel.no-reason-provide")
                                ) +
                                "\n        "
                            )
                          ])
                        : [
                            _vm.isClosed
                              ? [
                                  _vm.isLoadingEncounter
                                    ? _c(
                                        "p",
                                        { staticClass: "section" },
                                        [
                                          _c("FontAwesomeIcon", {
                                            attrs: {
                                              icon: _vm.$icons.spinner,
                                              spin: ""
                                            }
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  "appointmentPanel.verification-files"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ],
                                        1
                                      )
                                    : [
                                        _c("AppointmentPanelPrescriptions", {
                                          staticClass: "section"
                                        }),
                                        _c("AppointmentPanelPatientAdvice", {
                                          staticClass: "section"
                                        }),
                                        _c("AppointmentPanelClaims", {
                                          staticClass: "section"
                                        })
                                      ]
                                ]
                              : _vm._e(),
                            _c("SharedFiles", {
                              staticClass: "section",
                              attrs: {
                                "appointment-id": _vm.appointmentId,
                                "practitioner-id": _vm.practitioner.id
                              }
                            })
                          ]
                    ],
                    2
                  ),
                  !_vm.isPaymentRequired &&
                  !_vm.isClosed &&
                  !_vm.isTerminated &&
                  !_vm.isCancelled
                    ? _c("AppointmentPanelActions", {
                        attrs: { "can-be-started": _vm.canBeStarted },
                        on: {
                          accept: _vm.reloadAppointments,
                          cancel: _vm.reloadAppointments
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }