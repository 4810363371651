var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.color === "pink"
      ? _c("div", { staticClass: "lds-ring pink" }, [
          _c("div"),
          _c("div"),
          _c("div"),
          _c("div")
        ])
      : _vm._e(),
    _vm.color === "green"
      ? _c("div", { staticClass: "lds-ring green" }, [
          _c("div"),
          _c("div"),
          _c("div"),
          _c("div")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }