<!--
Emitted events:
- select(practitioner, product, appointmentDate, firstTime)
-->
<template>
  <div class="row container">
    <div class="container-practitioner-details">
    <div v-if="$media.desktop" class="col _2" style="min-width: 130px;">
      <UserAvatar :src="practitioner.picture" :user-name="practitionerFullName" />
    </div>
    <div class="col _5 container-col">
      <div style="display: flex">
        <UserAvatar v-if="!$media.desktop" :src="practitioner.picture" :user-name="practitionerFullName" size="7rem" />
        <div class="practitioner-details">
          <p class="practitioner-name">{{ practitionerFullName }}</p>
          <p class="no-new-patient" v-if="!practitioner.instantEncountersEnabled">
            {{ $t('searchResultsItem.no-new-patient') }}
          </p>
          <p v-if="practitioner.mainSpecialty" class="practitioner-specialty">
            {{ practitioner.mainSpecialty.name }}
          </p>
          <p class="practitioner-address">
            {{ practitioner.practiceAddress }}<br>
            {{ practitioner.practiceZipCode }} {{ practitioner.practiceCity }}
          </p>
          <p v-if="practitioner.convention" class="practitioner-convention">{{ practitioner.convention.name }}</p>
          <div v-if="practitioner.languages.length" class="practitioner-languages">
            <p v-if="practitioner.languages.length>1"> {{ $tc('searchResultsItem.language-spoken', 2) }} </p><p v-else> {{ $tc('searchResultsItem.language-spoken', 1) }} </p>
            <div v-for="(language, index) in practitioner.languages">
              <p>&nbsp;{{language.name}}<span v-if="index < practitioner.languages.length - 1">,&nbsp;</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="col _5 picker-column container-col">
      <form @submit.prevent="handleSubmit" class="form-container">
        <FormSelect
            :id="`product-${practitioner.id}`"
            v-model="selectedProduct"
            :disabled="!hasProducts"
            :label="$t('searchResultsItem.form.product.label')"
        >
          <option v-if="!selectedProduct" :value="null">
            {{ hasProducts ? '' : $t('searchResultsItem.form.product.no-result') }}
          </option>
          <option v-for="product in products" :value="product">
            {{ product.name }} ({{ product.channel | channel }})
          </option>
        </FormSelect>

        <FormInput
            :id="`date-${practitioner.id}`"
            :disabled="isSearching || noResults || !selectedProduct"
            :value="formattedSelectedAppointment"
            :label="$t('searchResultsItem.form.appointment-date.label')"
            readonly
            @click="showDateTimePicker = true"
        />
        <small class="available-desc">Par défaut, la première disponibilité est automatiquement sélectionnée</small>
        <div class="price-container">
          <p class="price-container__total-price"> Honoraire du praticien:
            <span>{{ unitPrice }}</span>€
          </p>
        </div>

        <ButtonGreen
          class="button"
          @click="confirmAdditionalInformation"
          :busy="isCreating || isSearching"
          :disabled="noResults || !hasProducts"
          :icon="$icons.chevronRight"
          block
          rounded
          type="submit"
        >
          {{ $t('searchResultsItem.form.submit') }}
        </ButtonGreen>
      </form>

    </div>
    <div v-if="showDateTimePicker" class="date-time-picker-container" @click.self="showDateTimePicker = false">
      <AppointmentDateTimePicker
          :appointment-duration="selectedProduct.duration || 15"
          :channel="selectedProduct.channel"
          :practitioner-id="practitioner.id"
          :practitioner-timezone="practitioner.timezone"
          :product-id="selectedProduct.id"
          @change="selectAppointmentDate"
      />
    </div>
    <AppConfirmation
        ref="newPatientConfirmation"
        :title="$t('searchResultsItem.additional-information')"
    >
      <template v-slot:description="scoped">
        <p> {{ rules }} </p>
      </template>
      <ConfirmButton color="red" :icon="$icons.no" @click="handleFirstTime(false)">Annuler</ConfirmButton>
      <ConfirmButton @click="handleFirstTime(true)">Continuer</ConfirmButton>
    </AppConfirmation>
  </div>
</template>
<script>
  import { find } from 'lodash-es';
  import moment from 'moment';
  import AppointmentDateTimePicker from '../AppointmentDateTimePicker/AppointmentDateTimePicker';
  import ButtonGreen from '../ButtonGreen';
  import UserAvatar from '@/components/UserAvatar.vue';
  import { formatFullName } from '@/helpers/format';
  import FormInput from '@/components/Form/FormInput';
  import FormSelect from '@/components/Form/FormSelect';
  import AppConfirmation from "@/components/AppConfirmation";
  import ConfirmButton from "@/components/AppConfirmation/ConfirmButton";
  import AppLogoPremium from"@/components/AppLogoPremium"
  import {frenchCountries} from "@/helpers/countries";

  export default {
    name: 'SearchResultsItem',
    components: {
      FormSelect,
      FormInput,
      UserAvatar,
      ButtonGreen,
      AppointmentDateTimePicker,
      AppConfirmation,
      ConfirmButton,
      AppLogoPremium
    },
    props: {
      practitioner: {
        type: Object,
        required: true,
      },
      preferredChannel: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        isSearching: false,
        noResults: false,
        selectedProduct: null,
        selectedAppointmentDate: null,
        channelForSelectedAppointment: null,
        nextAvailableAppointment: null,
        showDateTimePicker: false,
        markup: false,
        unitPrice: null
      };
    },
    async mounted() {
      this.selectedProduct = find(this.products, product => product.channel === (this.preferredChannel || 'remote')) || null;
    },
    updated() {
      this.price
    },
    computed: {
      isPremium() {
        return this.$store.state.patients.profile.premium
      },
      practitionerFullName() {
        return formatFullName(this.practitioner);
      },
      selectedProductId() {
        return this.selectedProduct?.id;
      },
      isCreating() {
        return this.$store.state.appointments.isCreating;
      },
      products() {
        return this.practitioner.products;
      },
      price() {
        return this.unitPrice = this.selectedProduct.unitPrice / 100;
      },
      hasProducts() {
        return this.products?.length > 0;
      },
      formattedSelectedAppointment() {
        if (this.isSearching) {
          return this.$t('searchResultsItem.form.appointment-date.searching');
        } else if (this.noResults || !this.hasProducts) {
          return this.$t('searchResultsItem.form.appointment-date.no-results');
        }

        return this.selectedAppointmentDate?.format('ddd DD MMM à HH:mm');
      },
      rules() {
        return this.selectedProduct.rules;
      },
      language() {
        return this.practitioner.languages.map(language => language.name).join(', ')
      },
      isFrench() {
          return frenchCountries().includes(this.$store.state.patients.profile.country);
      },
      getMarkup() {
          if (this.isPremium){
              return 0;
          }
          if (this.markup) {
              return 6;
          }

          return this.isFrench ? 3 : 4;
      }
    },
    methods: {
      async searchForAvailabilities() {
        if (!this.selectedProduct?.id) {
          return;
        }

      this.isSearching = true;
      this.noResults = false;

      const availabilities = {};

      this.practitioner.slots
          ?.filter(slot =>
              slot.channel === this.selectedProduct.channel
              && moment(slot.start).isSameOrAfter(undefined, 'days')
              && moment(slot.end).isAfter(),
          )
          .forEach(slot => {
            const date = moment(slot.start).format('YYYY-MM-DD');
            if (!availabilities[date]) { availabilities[date] = []; }
            availabilities[date].push({ start: slot.start, end: slot.end });
          });

      // Get the first day for which there are availabilities
      const firstAvailableDaySlots = find(availabilities, availability => availability.length > 0);

      // Get the first availability slot (if we are either between start & end OR before start)
      let firstAvailableSlot = find(firstAvailableDaySlots, slot => {
        return moment().isBetween(slot.start, slot.end) || moment().isBefore(slot.start);
      });

        if(firstAvailableSlot) {
          this.toggleMarkupMessage(firstAvailableSlot.start);
        }

        if (!firstAvailableSlot) {
          this.isSearching = false;
          this.noResults = true;
          return;
        }

      // Next "absolute" appointment time is either now (if we are inside the slot) or the start of the next available slot
      const nextAvailableAppointment = moment.max(moment(), moment(firstAvailableSlot.start));

      // Round up to the next time, based on the product duration
      const roundedUp = Math.ceil(nextAvailableAppointment.minute() / this.selectedProduct.duration) * this.selectedProduct.duration;
      nextAvailableAppointment.minute(roundedUp).second(0);

      if (this.channelForSelectedAppointment !== this.selectedProduct.channel) {
        this.channelForSelectedAppointment = this.selectedProduct.channel;
        this.selectedAppointmentDate = nextAvailableAppointment;
      }

        this.nextAvailableAppointment = nextAvailableAppointment;
        this.isSearching = false;
      },
      selectAppointmentDate(appointmentDate) {
        this.toggleMarkupMessage(appointmentDate);
        this.selectedAppointmentDate = moment(appointmentDate);
        this.showDateTimePicker = false;
      },
      toggleMarkupMessage(appointmentDate) {
        const now = moment();
        const appointmentDateMoment = moment(appointmentDate);
        this.markup = appointmentDateMoment.diff(now, 'minutes') <= 60;
      },
      async handleSubmit() {
        this.$emit('select', this.practitioner, this.selectedProduct, this.selectedAppointmentDate, this.rules);
      },

      async confirmAdditionalInformation() {
        if(this.rules) {
          this.$confirm(this.$refs.newPatientConfirmation);
        } else {
          this.handleSubmit()
        }
      },

      async handleFirstTime(close) {
        if (close) {
          await this.$router.push({ name: 'new-appointment-beneficiary' });
        } else {
          await this.$router.push({ name: 'new-appointment-search' });
        }
        this.$refs.newPatientConfirmation.close();

      },
    },
    watch: {
      selectedProductId: function () {
        this.searchForAvailabilities();
      },
    },
  };
</script>
<style lang="scss" scoped>

p {
  margin: 1rem 0;
}

.container-practitioner-details {
  display: flex;
  @include onMobile {
    display: block;
  }
}

.practitioner-details {
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.practitioner-name {
  font-size: 2rem;
  font-weight: 500;
  margin-top: 0;
}

.form-select {
  margin-bottom: 2rem;
}

.date-time-picker-container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(203, 75, 84, 0.7);
}

.col-separator {
  width: 1px;
  margin: 0.5rem;
  background-color: #E0E0E0;
}

.form-container {
  display: flex;
  flex-direction: column;
}

available-desc {
  padding-left: 1rem;
}

.price-container {
  margin: 2rem 0rem;
  display: flex;
  align-self: center;
  &__desc {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  &__title {
    margin: 0;
    white-space: nowrap;
  }
  span {
    font-weight: bold;
  }
  &__total-price {
    white-space: nowrap;
  }
}

.appointment-date-time-picker {
  width: 500px;
  height: 420px;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.avatar {
  display: block;
  margin: 1rem auto 0;
}

.no-new-patient {
  color: $errorColor;
}
.practitioner-languages {
  display: flex;
}

  .markup {
    color: black;
  }

  .markup {
    color: black;
  }

.markup-container {
  position: relative;
  &__logo-premium {
    position: absolute;
  }
  &__content {
    padding-left: 19px;
  }
  span {
    font-weight: bold;
  }
  &__link {
    color: #F46868;
    font-weight: bold;
  }
}

  @media (min-width: 1280px) {
    .picker-column {
      margin-right: calc(100% / 6 - 2rem);
    }
  }

@media (min-width:769px) AND (max-width: 1279px) {
  .container {
    flex-wrap: wrap;
  }
  .container-col{
    flex: auto;
  }
  ::v-deep .base-button.block{
    margin: auto;
    width: auto;
  }
}

</style>
