var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-confirmation" },
    [
      _vm.icon !== false
        ? _c("FontAwesomeIcon", {
            staticClass: "icon",
            attrs: { icon: _vm.icon || _vm.$icons.exclamation, size: "5x" }
          })
        : _vm._e(),
      _c(
        "SectionTitle",
        { attrs: { center: "", light: "" } },
        [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
        2
      ),
      _vm.hasDescription
        ? _c("div", { staticClass: "comment" }, [_vm._t("description")], 2)
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "buttons",
          class: { multiple: _vm.hasMultipleButtons, vertical: _vm.vertical }
        },
        [
          _vm._t("default", [
            _c("CancelButton"),
            _c("ConfirmButton", { on: { click: _vm.$listeners.confirm } })
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }