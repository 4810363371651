<template>
  <div @click="$emit('change', card)">
    <div v-if="expired" class="expired">Carte expirée</div>
    <span class="title">{{ title }}</span>
    <div>
      <div class="card">
        <img v-if="brandLogoPath" :src="brandLogoPath" />
        <span v-else>{{ card.brand }}</span>
        <div :class="{number : col}"><span>**** **** **** {{ card.lastFourDigit }}</span> <span v-if="!col"> | </span> <span>{{ expirationDate.format('MM/YY') }}</span></div>
      </div>
    </div>
  </div>

</template>
<script>
import AppPanel from '@/components/AppPanel.vue';
import ProfileEditForm from '@/components/Form/ProfileEditForm.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import FormCheckbox from "@/components/Form/FormCheckbox.vue";
import moment from "moment/moment";

export default {
  name: 'Card',
  components: {
    FormCheckbox,
    ProfileEditForm,
    AppPanel,
    UserAvatar
  },
  props: {
    card: {
      required: false
    },
    cards: {
      required: false
    },
    title: {
      type: String,
      required: false
    },
    selectDefault : {
      default: false
    },
    col: false,
    row: false
  },
  created() {
      this.$emit('change', this.cards[0]);
  },
  updated() {
      this.$emit('change', this.cards[0]);
  },
  computed: {
    brandLogoPath() {
      switch (this.card.brand) {
        case 'visa':
          return require('../../assets/images/visa-logo.svg');
        case 'mastercard':
          return require('../../assets/images/mastercard-logo.svg');
        case 'amex':
          return require('../../assets/images/amex-logo.svg');
        default:
          return null;
      }
    },
    expirationDate() {
      return moment().year(this.card.expirationYear).month(this.card.expirationMonth - 1).endOf('month');
    },
    expired() {
      return this.expirationDate.isBefore();
    },
  }
};
</script>
<style lang="scss" scoped>
  .title {
    color: #0000007B;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  }

  .expired {
    background: #ED696A;
    color: white;
    font-size: 1rem;
    padding: .5rem 2rem;
    border-radius: 4px;
    bottom: 117px;
    right: 20px;
    position: absolute;
  }

  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: .7rem;
  }

  .number {
    display: flex;
    flex-direction: column;
  }
</style>