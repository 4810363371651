<template>
  <div class="app-layout" :class="[isCalling ? 'has-navigation--show' : '', {'has-navigation': navigation, 'has-footer': footer, 'fullscreen': fullscreen }]">
    <template v-if="navigation">
      <AppNavigation v-if="$media.desktop" desktop key="desktop" />
      <AppNavigation v-else key="mobile" />
    </template>
    <AppHeader v-if="header" v-show="!$media.mobile && hidden === false" :withNavigation="navigation" />
    <div v-if="globalWarningMessage" class="global-warning-message">
      <FontAwesomeIcon class="icon" :icon="$icons.inputError" />
      {{ globalWarningMessage }}
    </div>
    <div v-if="isBrowserUnsupported" class="global-warning-message">
      <FontAwesomeIcon class="icon" :icon="$icons.inputError" />
      {{ $t('appLayout.unsupported-browser') }}
      <span style="white-space: nowrap">
        <a target="_blank" href="https://www.mozilla.org/fr/firefox/new/">Mozilla Firefox</a>,
        <a target="_blank" href="https://www.google.com/intl/fr_fr/chrome/">Google Chrome</a> ou
        <a target="_blank" href="https://www.microsoft.com/fr-fr/edge">Microsoft Edge</a>.
      </span>
    </div>
    <div class="main-container" :class="{'fullscreen-content': fullscreen}">
      <slot />
    </div>
    <NearestAppointment v-if="nearest" />
    <footer class="main-footer" v-if="footer" v-show="!$media.mobile && hidden === false">
      <router-link :to="{ name: 'home' }" class="logo">
        <AppLogo :text="$media.desktop" :beta="beta" />
      </router-link>
      <ul class="links">
        <li>
          <a href="https://hellocare.fr/mentions-legales/" target="_blank"><span>{{ $t('appLayout.legal-notice.label') }}</span></a>
        </li>
        <li>
          <a href="https://hellocare.com/confidentialite" target="_blank"><span>{{ $t('appLayout.privacy-policy.label') }}</span></a>
        </li>
        <li>
          <a href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu/" target="_blank"><span>{{ $t('appLayout.terms-of-use.label') }}</span></a>
        </li>
        <li>
          <a href="https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf" target="_blank"><span>{{ $t('appLayout.tarification.label') }}</span></a>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
  import AppNavigation from './AppNavigation';
  import BaseButton from './BaseButton';
  import AppLogo from './AppLogo';
  import NearestAppointment from './NearestAppointment';
  import AppHeader from './AppHeader';

  export default {
    name: 'AppLayout',
    components: { AppHeader, NearestAppointment, AppLogo, BaseButton, AppNavigation },
    props: {
      navigation: {
        type: Boolean,
        required: false,
        default: true,
      },
      header: {
        type: Boolean,
        required: false,
        default: true,
      },
      footer: {
        type: Boolean,
        required: false,
        default: true,
      },
      fullscreen: {
        type: Boolean,
        required: false,
        default: false,
      },
      nearest: {
        type: Boolean,
        required: false,
        default: true,
      },
      hidden: {
        type: Boolean,
        required: false,
      }
    },
    computed: {
      isBrowserUnsupported() {
        const userAgent = window.navigator.userAgent.toLowerCase();

        // User agent contains "edge" for old MS Edge (Edge Chromium contains "edg")
        return userAgent.indexOf('edge') !== -1 || userAgent.indexOf('trident') !== -1;
      },
      beta() {
        return this.$store.state.patients.profile.beta;
      },
      globalWarningMessage() {
        return this.$store.state.settings.GLOBAL_WARNING_MESSAGE;
      },
      isStepCalling() {
        if(typeof this.$store.state.sdk !== 'undefined')
          return this.$store.state.sdk.step;
      },
      isCalling() {
        return typeof this.$store.state.sdk !== 'undefined' && (this.isStepCalling === 'CALLING' && this.$media.desktop)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-layout {
    @include onMobile {
      height: inherit;
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
  }

  .has-navigation {
    @include onDesktop {
      padding-top: 0;
      padding-left: 7.4rem;
      &--show {
        padding-left: 0;
      }
    }
  }

  .fullscreen {
    height: 100vh;
    @include onMobile {
      height: auto;
    }
  }

  .fullscreen-content {
    height: 100%;
  }

  .has-footer {
    padding-bottom: 6rem;
    @include onMobile {
      padding-bottom: 0;
    }
  }

  .app-layout:not(.has-footer) .nearest-appointment {
    bottom: 0;
  }

  .main-container {
    flex: 100%;
    main {
      padding: 3rem;

      @include onDesktop {
        padding: 8rem;
      }

      &.fullscreen {
        min-height: calc(100vh - 7rem);

        @include onDesktop {
          min-height: 100vh;
        }
      }
    }
  }

  .main-footer {
    display: flex;
    height: 6rem;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: #FFFFFF;
    border-top: 1px solid #F6F6F6;
    padding: 0 2rem;

    @include onMobile {
      position: initial;
      height: 60px;
    }

    .links {
      margin-left: auto;
      display: flex;
      list-style: none;
      font-size: 1.5rem;

      @include onMobile {
        font-size: 1rem;
        text-align: center;
        padding-left: 0;
        margin: 0 auto;
      }

      li {
        @include onMobile {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        }

        &:not(:first-child) {
          border-left: 1px solid #1F1F1F;
        }
      }

      a {
        color: #1F1F1F;
        padding: 0 1rem;
      }
    }
  }

  .global-warning-message {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem;
    color: $lightTextColor;
    background-color: $errorColor;
    text-align: center;

    .icon {
      margin-right: 0.4rem;
    }

    a {
      color: white;
    }
  }
  .logo {
    @include onMobile {
      display: none;
    }
  }
</style>
