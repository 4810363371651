var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ButtonGrey",
    {
      attrs: {
        busy: _vm.isBusy,
        icon: _vm.$icons.goBack,
        "icon-to-left": "",
        outlined: ""
      },
      on: { click: _vm.close }
    },
    [_vm._t("default", [_vm._v(_vm._s(_vm.$t("cancelButton.cancel")))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }