var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col _7" },
              [
                _c(
                  "GroupTitle",
                  { attrs: { icon: _vm.$icons.medicalHistory } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("profileHealthPage.medical-history")) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "medical-history",
                        label: _vm.$t("profileHealthPage.medical-history"),
                        type: "textarea"
                      },
                      model: {
                        value: _vm.form.medicalHistory,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "medicalHistory", $$v)
                        },
                        expression: "form.medicalHistory"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "surgical-history",
                        label: _vm.$t("profileHealthPage.surgical-history"),
                        type: "textarea"
                      },
                      model: {
                        value: _vm.form.surgicalHistory,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "surgicalHistory", $$v)
                        },
                        expression: "form.surgicalHistory"
                      }
                    })
                  ],
                  1
                ),
                _c("GroupTitle", { attrs: { icon: _vm.$icons.allergies } }, [
                  _vm._v(_vm._s(_vm.$t("profileHealthPage.allergies")))
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "allergies",
                        label: _vm.$t("profileHealthPage.label-allergies"),
                        type: "textarea"
                      },
                      model: {
                        value: _vm.form.allergies,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "allergies", $$v)
                        },
                        expression: "form.allergies"
                      }
                    })
                  ],
                  1
                ),
                _c("GroupTitle", { attrs: { icon: _vm.$icons.treatment } }, [
                  _vm._v(_vm._s(_vm.$t("profileHealthPage.treatment")))
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "treatment",
                        label: _vm.$t("profileHealthPage.label-treatment"),
                        type: "textarea"
                      },
                      model: {
                        value: _vm.form.treatment,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "treatment", $$v)
                        },
                        expression: "form.treatment"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-separator" }),
            _c(
              "div",
              { staticClass: "col _5" },
              [
                _c("GroupTitle", { attrs: { icon: _vm.$icons.biometrics } }, [
                  _vm._v(_vm._s(_vm.$t("profileHealthPage.biometrics")))
                ]),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "weight",
                        label: _vm.$t("profileHealthPage.weight")
                      },
                      model: {
                        value: _vm.form.weight,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "weight", $$v)
                        },
                        expression: "form.weight"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("FormInput", {
                      staticClass: "col _12",
                      attrs: {
                        id: "height",
                        label: _vm.$t("profileHealthPage.height")
                      },
                      model: {
                        value: _vm.form.height,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "height", $$v)
                        },
                        expression: "form.height"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "FormSelect",
                      {
                        staticClass: "col _12",
                        attrs: {
                          id: "blood-group",
                          label: _vm.$t("profileHealthPage.blood-group")
                        },
                        model: {
                          value: _vm.form.bloodGroup,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "bloodGroup", $$v)
                          },
                          expression: "form.bloodGroup"
                        }
                      },
                      [
                        _c("option", { attrs: { value: "A+" } }, [
                          _vm._v("A+")
                        ]),
                        _c("option", { attrs: { value: "A-" } }, [
                          _vm._v("A-")
                        ]),
                        _c("option", { attrs: { value: "B+" } }, [
                          _vm._v("B+")
                        ]),
                        _c("option", { attrs: { value: "B-" } }, [
                          _vm._v("B-")
                        ]),
                        _c("option", { attrs: { value: "AB+" } }, [
                          _vm._v("AB+")
                        ]),
                        _c("option", { attrs: { value: "AB-" } }, [
                          _vm._v("AB-")
                        ]),
                        _c("option", { attrs: { value: "O+" } }, [
                          _vm._v("O+")
                        ]),
                        _c("option", { attrs: { value: "O-" } }, [_vm._v("O-")])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }