import PaymentMethods from '@/api/payment_methods';

export default {
  state: {
    isFetchingAll: false,
    all: [],
    selectedForAppointment: null,
    shouldSaveCard: true,
  },
  mutations: {
    SAVE_CARD(state, shouldSave) {
      state.shouldSaveCard = shouldSave;
    },
    PAYMENT_METHODS_SELECTED(state, card) {
      state.selectedForAppointment = card;
    },
    PAYMENT_METHODS_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    PAYMENT_METHODS_FETCH_ALL_SUCCESS(state, { paymentMethods }) {
      state.all = paymentMethods;
      state.isFetchingAll = false;
    },
    PAYMENT_METHODS_FETCH_ALL_FAIL(state) {
      state.all = [];
      state.isFetchingAll = false;
    },
    PAYMENT_METHODS_DELETE() {},
    PAYMENT_METHODS_DELETE_SUCCESS() {},
    PAYMENT_METHODS_DELETE_FAIL() {},
  },
  actions: {
    async paymentMethodsFetchAll({ commit }) {
      commit('PAYMENT_METHODS_FETCH_ALL');

      try {
        const paymentMethods = await PaymentMethods.fetchAll();
        commit('PAYMENT_METHODS_FETCH_ALL_SUCCESS', { paymentMethods });
      } catch (e) {
        commit('PAYMENT_METHODS_FETCH_ALL_FAIL');
        throw e;
      }
    },
    async paymentMethodsDelete({ commit }, { id }) {
      commit('PAYMENT_METHODS_DELETE');

      try {
        await PaymentMethods.delete(id);
        commit('PAYMENT_METHODS_DELETE_SUCCESS');
      } catch (e) {
        commit('PAYMENT_METHODS_DELETE_FAIL');
        throw e;
      }
    }
  },
};
