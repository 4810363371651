var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "credit-card-add-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "button" },
        [
          _c(
            "ButtonWhite",
            {
              staticClass: "status-premium__button",
              attrs: { busy: _vm.submitting, type: "submit", block: "" }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }