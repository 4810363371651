<template>
  <FullscreenNotification :icon="$icons.thumbsUp" :title="$t('appointmentRejectedPage.title')">
    <p> {{ $t('appointmentRejectedPage.refuse') }} </p>
    <p> {{ $t('appointmentRejectedPage.mailSend') }} </p>
    <p v-html="$t('appointmentRejectedPage.text')"/>
    <template #buttons>
      <ButtonGreen :link-to="{ name: 'home' }"> {{ $t('appointmentRejectedPage.confirm') }} </ButtonGreen>
    </template>
  </FullscreenNotification>
</template>

<script>
  import SectionTitle from '../components/SectionTitle';
  import AppLayout from '../components/AppLayout';
  import FullscreenNotification from '../components/FullscreenNotification';
  import ButtonGreen from '../components/ButtonGreen';

  export default {
    name: 'AppointmentRejectedPage',
    components: { ButtonGreen, FullscreenNotification, AppLayout, SectionTitle },
  };
</script>
