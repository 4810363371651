<template>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <div class="row">
          <div class="col _8">
            <GroupTitle :icon="$icons.payments">{{ $t('profilePaymentPage.payment-summary') }}</GroupTitle>
            <div class="list-payment">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>{{ $t('profilePaymentPage.practitioner') }}</th>
                  <th>{{ $t('profilePaymentPage.date') }}</th>
                  <th>{{ $t('profilePaymentPage.amount') }}</th>
                  <th>{{ $t('profilePaymentPage.status.label') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="transaction in transactions">
                  <td>
                    {{ transaction.order.appointment.practitioner.firstName }}
                    {{ transaction.order.appointment.practitioner.lastName }}
                  </td>
                  <td>{{ transaction.createdAt | date('DD/MM/YYYY') }}</td>
                  <td>{{ transaction.amount | money }}</td>
                  <td v-if="transaction.paymentStatus === 'paid'" class="paid">{{ $t('profilePaymentPage.status.validated') }}</td>
                  <td v-else class="unpaid">{{ $t('profilePaymentPage.status.not-validated') }}</td>
                  <td>
                    <OptionsMenu>
                      <OptionsMenuItem :icon="$icons.file" :to="{ name: 'appointment-view', params: { id: transaction.order.appointment.id } }">
                        {{ $t('profilePaymentPage.appointment-detail') }}
                      </OptionsMenuItem>
                    </OptionsMenu>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-separator" />
          <div class="col _4, payment-method">
            <GroupTitle :icon="$icons.creditCard">{{ $t('profilePaymentPage.payment-method') }}</GroupTitle>
            <CreditCards removable />
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout';
  import GroupTitle from '@/components/GroupTitle';
  import AutoHeightTextarea from '@/components/Form/AutoHeightTextarea';
  import FormWidget from '@/components/Form/FormWidget';
  import FormInput from '@/components/Form/FormInput';
  import FormSelect from '@/components/Form/FormSelect';
  import ProfileTabBar from '@/components/ProfileTabBar';
  import CreditCards from '@/components/CreditCards';
  import OptionsMenu from '@/components/OptionsMenu';
  import OptionsMenuItem from '@/components/OptionsMenuItem';
  import Transactions from '@/api/transactions.js';

  export default {
    name: 'ProfilePaymentPage',
    components: {
      OptionsMenuItem,
      OptionsMenu,
      CreditCards,
      ProfileTabBar,
      FormSelect,
      FormInput,
      FormWidget,
      AutoHeightTextarea,
      GroupTitle,
      AppLayout,
    },
    data() {
      return {
        transactions: [],
      };
    },
    async mounted() {
      this.transactions = await Transactions.getAll();
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    max-width: 140rem;
  }

  .row {
    margin-bottom: 2rem;
  }

  .col-separator {
    width: 1px;
    margin: 0 5rem;
    background-color: $lightGrey;
  }

  .paid {
    color: $successColor;
  }

  .unpaid {
    color: $errorColor;
  }

  .payment-method {
    @include onMobile {
      margin-top: 3rem;
    }
  }
  .list-payment {
    overflow-x: auto;
  }
</style>
