<template>
  <div class="app-guide-step">
    <h1 v-if="title" class="title">{{ title }}</h1>
    <div class="content">{{ content }}</div>
    <div v-if="actions.length > 0" class="actions">
      <template v-for="action in actions">
        <a v-if="!handledActions.includes(action)" href="#" @click.prevent="handleAction(action)">
          {{ action.label }}
        </a>
      </template>
    </div>
    <div class="buttons">
      <button v-if="hasPreviousStep" class="button-previous" type="button" @click="handlePrevious">Précédent</button>
      <button v-if="hasNextStep" class="button-next" type="button" @click="handleNext" :disabled="!isNextStepReady">
        <FontAwesomeIcon :icon="$icons.spinner" spin v-if="!isNextStepReady" />
        Suivant
      </button>
      <button v-if="hasNextStep" class="button-ignore" type="button" @click="handleTerminate">Ignorer</button>
      <button v-else class="button-terminate" type="button" @click="handleTerminate">Terminer</button>
    </div>
    <div class="arrow" data-popper-arrow />
  </div>
</template>

<script>
  export default {
    name: 'AppGuideStep',
    props: ['title', 'actions', 'content'],
    data() {
      return {
        handledActions: [],
      };
    },
    beforeDestroy() {
      this.handledActions.forEach(action => {
        action.undo?.();
      });
    },
    computed: {
      hasNextStep() {
        return this.$store.getters.vueGuideHasNextStep;
      },
      hasPreviousStep() {
        return this.$store.getters.vueGuideHasPreviousStep;
      },
      isNextStepReady() {
        return this.$store.getters.vueGuideIsNextStepReady;
      },
    },
    methods: {
      handlePrevious() {
        this.$store.dispatch('vueGuideGoToPreviousStep');
      },
      handleNext() {
        this.$store.dispatch('vueGuideGoToNextStep');
      },
      handleTerminate() {
        this.$store.dispatch('vueGuideTerminate');
      },
      async handleAction(action) {
        this.handledActions.push(action);

        await action.do();

        this.$emit('action');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-guide-step {
    font-size: 1.5rem;
    font-weight: 700;
    z-index: 3001;
    max-width: 30rem;
    padding: 1rem;
    color: $lightTextColor;
    border-radius: 0.5rem;
    background-color: #333;

    &[data-popper-placement^='top'] > .arrow {
      bottom: -4px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: -4px;
    }

    &[data-popper-placement^='left'] > .arrow {
      right: -4px;
    }

    &[data-popper-placement^='right'] > .arrow {
      left: -4px;
    }
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  .title {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid $lightTextColor;
  }

  .content {
    font-size: inherit;
    font-weight: inherit;
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    margin-top: 1rem;

    button {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: inherit;
      border: 1px solid $lightTextColor;
      border-radius: 0.3rem;
      background-color: inherit;

      &.button-previous,
      &.button-ignore {
        border-color: transparent;
      }

      &.button-ignore {
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }

      &:first-child {
        margin-right: 1rem;
      }

      &:last-child {
        margin-left: auto;
      }

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .actions a {
    font-size: inherit;
    color: inherit;
  }
</style>
