import ky from 'ky';

import { logout, redirectToAuth } from '@/helpers/auth';
import * as Sentry from '@sentry/browser';
import { getSetting } from '@/helpers/tools.js';

const api = ky.create({
  prefixUrl: getSetting('URL_API'),
  timeout: 300000000,
  retry: {
    statusCodes: [401, 403, 408, 413, 429, 500, 502, 503, 504],
  },
  hooks: {
    beforeRetry: [
      async ({ response }) => {
        if ([401, 403].includes(response?.status)) {
          logout();
          redirectToAuth();
        }
      },
    ],
    beforeRequest: [
      (request) => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          request.headers.set('Authorization', `Bearer ${accessToken}`);
        }
      },
    ],
    afterResponse: [
      async (request, _options, response) => {
        if (response.status >= 400 && getSetting('SENTRY_ENABLED')) {
          Sentry.configureScope(scope => scope.setTags({
            api_url: request.url,
            api_method: request.method,
          }));

          try {
            Sentry.setExtra('response', await response.clone().json());
          } catch {}
        }

        return response;
      },
    ],
  },
});

export default api;
