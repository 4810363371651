var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { staticClass: "profile-access-page" }, [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("p", { staticClass: "information bordered" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("profileAccessPage.option-enable-info")) +
                "\n      "
            )
          ]),
          _c(
            "div",
            { staticClass: "settings" },
            [
              _c("GroupTitle", { attrs: { icon: _vm.$icons.settings } }, [
                _vm._v(_vm._s(_vm.$t("profileAccessPage.title")))
              ]),
              _vm.accessTokens.length === 0
                ? _c("p", { staticClass: "information" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("profileAccessPage.disable")) +
                        "\n        "
                    )
                  ])
                : _vm._l(_vm.accessTokens, function(accessToken) {
                    return _c("div", { staticClass: "setting" }, [
                      _c("img", {
                        staticClass: "setting-icon",
                        attrs: { src: accessToken.partner.logo, alt: "" }
                      }),
                      _c("div", { staticClass: "settings-content" }, [
                        _c("div", { staticClass: "setting-label" }, [
                          _c("label", { attrs: { for: accessToken.id } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("profileAccessPage.setting-label")
                              ) +
                                " " +
                                _vm._s(accessToken.partner.name)
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "setting-switch" },
                            [
                              _c("FormSwitch", {
                                attrs: {
                                  id: accessToken.id,
                                  busy: _vm.updating[accessToken.id],
                                  checked: accessToken.active
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeAccessTokenStatus(
                                      accessToken.id,
                                      !accessToken.active
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("p", { staticClass: "setting-description" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(accessToken.partner.description) +
                              "\n            "
                          )
                        ])
                      ])
                    ])
                  })
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }