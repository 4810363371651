<template>
  <form class="credit-card-add-form" @submit.prevent="handleSubmit">
    <!-- always load for 1 sec so the first fetch have been made -->
    <div v-if="firstLoad">
      <ButtonGreen
          :busy="submitting"
          :icon="$icons.chevronRight"
          type="submit"
      >
        {{$t('pharmacyOrder.payment-page.save-card-cta')}}
      </ButtonGreen>
    </div>
    <div v-else>
      <Spinner color="green" />
    </div>
  </form>
</template>

<script>
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonTransparent from '@/components/ButtonTransparent';
  import FormInput from '@/components/Form/FormInput';
  import Spinner from "./Spinner";

  let countCall = 0;

  export default {
    name: 'CardPayment',
    components: {Spinner, FormInput, ButtonTransparent, ButtonGreen },
    props: {
      pharmacyOrder: {
        required: true
      },
      paid: {
        required: true
      },
      providerName: {
          required: true
      }
    },
    data() {
      return {
        submitting: false,
        firstLoad: false
      };
    },
    async mounted() {
      if(!this.paid) {
        this.firstLoadTime();
        await this.setUpdatedTransaction();
      }
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      }
    },
    methods: {
      firstLoadTime() {
        setTimeout(() => this.firstLoad = true, 1500);
      },
      async handleSubmit() {
        this.submitting = true;
         await this.$store.dispatch('pharmaciesOrderCheckout',{
           pharmacyOrderTransactionId: this.pharmacyOrder.transaction.id,
           providerName: this.providerName
        }).catch(( error) => {
          throw error;
        })
        const checkout = this.$store.state.pharmacies_order.checkout;
         if(checkout.data.url) {
           window.location.href = checkout.data.url;
           this.submitting = false;
         }else{
           this.$addError("L'url de redirection n'existe pas.");
         }
      },
      async setUpdatedTransaction() {

        countCall += 1
        if (countCall < 3000) {
          await this.$store.dispatch('pharmaciesOrderCheckoutUpdate', {
            pharmacyOrderTransactionId: this.pharmacyOrder.transaction.id,
            providerName: this.providerName
          }).catch((error) => {
              console.error(error.message, this.pharmacyOrder.transaction.id, this.providerName);
            throw error;
          })
          if (this.$store.state.pharmacies_order.checkout
              && this.$store.state.pharmacies_order.checkout.pharmacyOrderTransaction.paymentStatus === 'payment_setup'
          ) {
            this.$emit('paid', true);
          } else {
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.setUpdatedTransaction();
          }
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
.credit-card-add-form{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
