<template>
  <AppLayout v-if="practitioner">
    <AppProgress :current="8" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />

    <main class="container">
      <div class="summary">
        <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
        <SectionTitle>
          {{ $t('newAppointmentSummaryPage.title')}}
        </SectionTitle>
        <div class="content">
          <div class="details">
            <div>
              <img class="icon" src="../assets/images/person.png">
              <strong>{{ toFullName(newAppointment.beneficiary) }}</strong>
            </div>
            <div>
              <img class="icon" src="../assets/images/calendar.png">
              <strong>Le {{ newAppointment.startAt | date('dddd Do MMMM YYYY') }}</strong>
            </div>
            <div>
              <img class="icon" src="../assets/images/hour.png">
              <strong>{{ newAppointment.startAt | date('HH[h]mm') }}</strong>
            </div>
            <div>
              <img class="icon" src="../assets/images/camera.png">
              <strong>{{ newAppointment.product.channel | channel }}</strong>
            </div>
            <div>
              <img class="icon" src="../assets/images/folder.png">
              <strong>{{ productName }}</strong>
            </div>
            <div>
              <img class="icon" src="../assets/images/stethoscope.png">
              <strong>{{ `Avec ${practitioner.title} ${practitioner.lastName}`}}</strong>
            </div>
          </div>
          <CreditCards v-if="isFingerPrint" class="credit-card" :card="cardSelected" />
          <div class="info" :class="{'not-fingerprint': !isFingerPrint}">
            <div>
              <div>
                <span v-if="isFingerPrint">{{ $t('newAppointmentSummaryPage.fingerPrint') }} {{ formatMoney(fingerPrintPrice(totalPrice)) }} <a href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu/" target="_blank"><img class="icon-question" src="@/assets/images/question.png"/></a></span>
              </div>
            </div>
            <br>
            <div>
              <span>{{ $t('newAppointmentSummaryPage.no-show-fee') }} <a href="https://hellocare.com/blog/conditions-generales-dutilisation-cgu/" target="_blank"><img class="icon-question" src="@/assets/images/question.png"/></a></span>
            </div>
          </div>
        </div>

        <div class="buttons">
          <ButtonTransparent
            :busy="isCreating"
            :icon="$icons.chevronLeft"
            icon-to-left
            @click="$router.back()"
          >
            {{ $t('newAppointmentSummaryPage.cancel') }}
          </ButtonTransparent>
          <ButtonGreen
            :busy="isCreating"
            :icon="$icons.chevronRight"
            rounded
            @click="handleSubmit"
          >
            {{ $t('newAppointmentSummaryPage.validate-appointment') }}
          </ButtonGreen>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import Practitioners from '@/api/practitioners.js';
  import AppLayout from '@/components/AppLayout';
  import AppProgress from '@/components/AppProgress';
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonTransparent from '@/components/ButtonTransparent';
  import CreditCards from '@/components/NewAppointment/CreditCards';
  import PractitionerCard from '@/components/PractitionerCard';
  import SectionTitle from '@/components/SectionTitle';
  import {formatMoney, formatFullName} from '@/helpers/format';
  import moment from 'moment';
  import AppointmentStatusIcon from "@/components/AppointmentStatusIcon.vue";
  import AppConfirmation from "@/components/AppConfirmation.vue";
  import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
  import {fingerPrintPrice } from "@/price/fingerPrint";
  import { dateAppointment } from "@/date";
  import Transactions from '@/api/transactions.js';
  import {stripe} from "@/helpers/stripe";

  export default {
    name: 'new-appointment-summary',
    components: {
      RecapTitle,
      AppConfirmation, AppointmentStatusIcon,
      CreditCards,
      PractitionerCard,
      AppProgress,
      ButtonGreen,
      ButtonTransparent,
      SectionTitle,
      AppLayout,
    },
    props: {
      afterComponentPayment: Boolean,
    },
    beforeMount() {
      if (this.$store.state.newAppointment
          && null === this.$store.state.newAppointment.product) {
        return this.$router.push({ name: 'new-appointment-search' });
      }
    },
    mounted() {
      const now = moment();
      const appointmentStartAt = this.newAppointment.startAt;
      if(appointmentStartAt.diff(now, 'minutes') <= 60) {
        this.markup = true;
      }
      if(this.product.channel === 'remote') {
        return this.isFingerPrint = true
      }
    },
    data() {
      return {
        busy: false,
        markup: false,
        isFingerPrint: false
      };
    },
    computed: {
      productName() {
        return this.newAppointment.product
            ? this.newAppointment.product.name
            : this.$t('appointmentPanelDetails.product-name') ;
      },
      isPremium() {
        return this.patient.premium
      },
      newAppointment() {
        return this.$store.state.newAppointment;
      },
      isCreating() {
        return this.busy || this.newAppointment.isCreating;
      },
      patient() {
        return this.$store.state.patients.profile;
      },
      practitioner() {
        return this.newAppointment.practitioner;
      },
      product() {
        return this.newAppointment.product;
      },
      totalPrice() {
          return this.$store.state.newAppointment.totalPrice;
      },
      cardSelected() {
        return this.$store.state.paymentMethods.selectedForAppointment;
      }
    },
    methods: {
      dateAppointment,
      fingerPrintPrice,
      formatMoney,
      async handleSubmit() {
        const startAt = this.newAppointment.startAt;

        if (startAt.isBefore()) {
          return this.$addError(this.$t('newAppointmentSummaryPage.error'));
        }

        try {
          this.busy = true;

          const availabilities = await Practitioners.availabilities(
            this.practitioner.id,
            this.product.id,
            this.product.duration,
            startAt,
            moment(startAt).add(this.product.duration, 'minutes'),
            this.product.channel,
          );

          const data = {
            patient: this.patient.id,
            type: "markup_premium"
          };

          const date = startAt.format('YYYY-MM-DD');

          if (!availabilities[date]?.length) {
            return this.$addError(this.$t('newAppointmentSummaryPage.no-available'));
          }

          const found = availabilities[date].find(slot => moment(slot.start).isSame(startAt, 'minutes'));

          if (!found) {
            return this.$addError(this.$t('newAppointmentSummaryPage.no-available'));
          }

          if(this.isPremium && this.markup) {
            await this.$store.dispatch('newSlotCreate', {data});
          }

          await this.$store.dispatch('newAppointmentCreate', {
            'card': this.$store.state.paymentMethods.selectedForAppointment
          } );

          const transaction = await Transactions.get(
              this.$store.state.newAppointment.appointmentId,
              this.newAppointment.beneficiary.id
          );

          await this.validate3DSTransaction(transaction);
          await this.removeCreditCard(transaction);

          this.$removeLocalStorage('new-appointment-reason');
          this.$removeLocalStorage('new-appointment-search');
          this.$removeLocalStorage('new-appointment-status');
          await this.$router.push({ name: 'new-appointment-requested' });
        } catch (e) {
          await this.$addError(this.$t('newAppointmentSummaryPage.error-created-appointment'));
          throw e;
        } finally {
          this.busy = false;
        }
      },
      async validate3DSTransaction(transaction) {
          if (transaction[0]
              && transaction[0].paymentStatus === 'need_3ds'
              && transaction[0].stripeClientSecret
          ){
              const result = await stripe.handleCardAction(transaction[0].stripeClientSecret);

              if (result.error !== undefined) {
                  await this.$store.dispatch('appointmentsCancel', {
                    id: transaction[0].order.appointment.id,
                    reason: '3DS failed',
                  });

                  throw new Error(result.error.message);
              }

              await Transactions.confirm(transaction[0].id);
          }
      },
      async removeCreditCard(transaction) {
          if (!this.$store.state.paymentMethods.shouldSaveCard
              && transaction[0].stripePaymentMethodId
          ) {
              await this.$store.dispatch('paymentMethodsDelete', { id: transaction[0].stripePaymentMethodId });
          }
      },
      toFullName(user) {
        return formatFullName(user);
      },
    },
  };
</script>

<style lang="scss" scoped>
main.container {
  @include onMobile {
    margin: 3rem auto 8rem;
    padding: 0 3rem;
    display: block;
  }
}
  main {
    max-width: 74rem;
    margin: 0 auto;
  }

  .start-at {
    font-weight: 400;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .channel {
    margin-top: 0.2rem;
  }

  .amount-row {
    font-size: 2rem;
    padding: 1rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background-color: $lightGrey;

    ::v-deep b {
      font-weight: 400;
    }
  }

  .buttons {
    @include onMobile {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      justify-content: space-evenly;
      gap: 1rem;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }
    background: white;
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
  }

  .container {
    font-size: 1.5rem;
    max-width: 100rem;
    padding: 0 3rem;

    @include onDesktop {
      display: flex;
      padding: 8rem 1.5rem 0;
    }
  }

  .content {
    @include onMobile {
      padding-right: 0
    }
    padding-right: 6rem;
  }

  .summary {
    flex: 1;
  }

  .payment {
    flex: 0.7;

    @include onDesktop {
      padding-top: 6rem;
      padding-left: 2rem;
      border-left: 0.1rem solid $lightGrey;
    }

    @include onMobile {
      .credit-cards {
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }

    p {
      padding-left: 1.2rem;
      text-align: left;
    }
  }

  .icon {
    color: #F46868;
    margin-right: 1rem;
    width: 17px;
  }

  .details>div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .help {
    width: 1.75rem;
  }

  .info {
    @include onMobileSmall {
      margin-bottom: 20rem;
    }
    & > span {
      @include onMobile {
        display: inline-block;
        margin-bottom: 1rem;
      }
      font-weight: 500;
    }
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    div {
      display: flex;
    }
    a {
      margin-left: .5rem;
      width: fit-content;
    }
    .icon-question {
      width: 1.3rem;

    }
  }
  .not-fingerprint {
    flex-direction: row;
  }
  .credit-card {
    margin: 2rem 0 3rem 0;
    &::v-deep img {
      width: 4rem;
      margin-right: 1rem;
    }
  }
</style>
