var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-loader" }, [
    _c(
      "div",
      { staticClass: "app-loader-content" },
      [
        _c("AppLogoAnimated", { attrs: { white: "" } }),
        _c("h1", { staticClass: "app-loader-text" }, [_vm._t("default")], 2)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }