<template>
  <AppLayout :hidden="hidden">
    <AppProgress :current="3" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />
    <main class="container">
      <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
      <SectionTitle>{{ $t('newAppointmentBeneficiary.title') }}</SectionTitle>
      <div class="description">{{ $t('newAppointmentBeneficiary.appointment-for') }}</div>
      <div class="beneficiaries">
        <div>
          <Card
            v-for="beneficiary in beneficiaries"
            :class="{ selected: selectedBeneficiary && selectedBeneficiary.id === beneficiary.id }"
          >
            <Beneficiary
              :cards="beneficiaries"
              :card="beneficiary"
              :selectDefault="selectDefault"
              @change="handleSelect"
            />
          </Card>
          <Card>
            <a class="add-beneficiary" href="#" @click.prevent="isPanelOpened = true">
              {{ $t('beneficiaryList.add-beneficiary') }}
            </a>
          </Card>
        </div>
      </div>
      <FormCheckbox class="status" v-model="status">
        Si le bénéficiaire dispose d’un statut CMU, CSS, ALD ou AME cochez la case
      </FormCheckbox>
      <div class="buttons">
        <ButtonTransparent
          :icon="$icons.chevronLeft"
          icon-to-left
          @click="$router.back()"
        >
          {{ $t('newAppointmentBeneficiary.back') }}
        </ButtonTransparent>
        <ButtonGreen
          :disabled="!selectedBeneficiary"
          :icon="$icons.chevronRight"
          rounded
          @click="goToNextStep"
        >
          {{ $t('newAppointmentBeneficiary.continue') }}
        </ButtonGreen>
      </div>
      <AppPanel
          :opened="isPanelOpened"
          :title="$t('beneficiaryList.add-beneficiary')"
          @close="isPanelOpened = false"
      >
        <ProfileEditForm
            hide-avatar
            @create="handleCreate"
            :beneficiary="true"
        />
      </AppPanel>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '@/components/AppLayout.vue';
  import AppProgress from '@/components/AppProgress.vue';
  import ButtonGreen from '@/components/ButtonGreen.vue';
  import ButtonTransparent from '@/components/ButtonTransparent.vue';
  import SectionTitle from '@/components/SectionTitle.vue';
  import GroupTitle from "@/components/GroupTitle.vue";
  import FormCheckbox from "@/components/Form/FormCheckbox.vue";
  import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
  import Beneficiary from "@/components/NewAppointment/Beneficiary.vue";
  import AppPanel from "@/components/AppPanel.vue";
  import ProfileEditForm from "@/components/Form/ProfileEditForm.vue";
  import Card from "@/components/Card.vue";
  import { dateAppointment } from "@/date";

  export default {
    name: 'Template1',
    components: {
      ProfileEditForm,
      AppPanel,
      Beneficiary,
      FormCheckbox,
      GroupTitle,
      ButtonGreen,
      ButtonTransparent,
      Card,
      SectionTitle,
      AppProgress,
      AppLayout,
      RecapTitle
    },
    data() {
      return {
        isPanelOpened: false,
        selectDefault: true,
        status: false,
        hidden: false
      };
    },

    async beforeMount() {
      if (this.$store.state.newAppointment
          && null === this.$store.state.newAppointment.product) {
        return this.$router.push({ name: 'new-appointment-search' });
      }

      if($media.mobile) {
        return this.hidden === true;
      }

      const { autoMode, autoModeData } = this.$store.state.newAppointment;

      try {
        if (autoMode && autoModeData.patientId) {
          const beneficiary = this.beneficiaries.find((beneficiary) => beneficiary.id === autoModeData.patientId);

          if (beneficiary) {
            await this.handleSelect(beneficiary);
            await this.goToNextStep();
          }
        }
      } finally {
        await this.$store.dispatch('newAppointmentUnsetAutoMode');
      }
    },
    computed: {
      selectedBeneficiary() {
        return this.$store.state.newAppointment.beneficiary;
      },
      practitioner() {
        return this.$store.state.newAppointment.practitioner;
      },
      newAppointment() {
        return this.$store.state.newAppointment;
      },
      profile() {
        return this.$store.state.patients.profile;
      },
      beneficiaries() {
        return [this.profile, ...this.profile.children];
      },
    },
    methods: {
      dateAppointment,
      async goToNextStep() {
        if (this.$store.state.newAppointment.product.channel === 'remote') {
          this.$setLocalStorage('new-appointment-status', this.status)
          await this.$router.push({ name: 'new-appointment-reason' });
        } else {
          await this.$store.dispatch('newAppointmentSetReason', { reason: '' });
          await this.$router.push({ name: 'new-appointment-summary' });
        }
      },
      handleSelect(beneficiary) {
        return this.$store.dispatch('newAppointmentSetBeneficiary', { beneficiary });
      },
      handleCreate(beneficiary) {
        this.$emit('change', beneficiary);
        this.isPanelOpened = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
main.container {
  padding: 1rem 8rem 8rem;
  @include onMobile {
    margin: 3rem auto 8rem;
    padding: 0 3rem;
    display: block;
  }
}

  .description {
    @include onMobile {
      font-size: 1.3rem;
    }
    font-weight: 500;
    margin-bottom: 3rem;
    color: #0000007B;
  }

  .buttons {
    @include onMobile {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      justify-content: space-evenly;
      gap: 1rem;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    background: white;
  }

.status {
  @include onMobile {
    flex-direction: row-reverse;
    margin-bottom: 14rem;
    font-size: 1.3rem;
  }
  @media (max-width: 321px) {
    margin-bottom: 18rem;
  }
  font-weight: bold;
  margin-top: 5rem;
  display: flex;
  flex-direction: initial;
  justify-content: flex-start;
  gap: 1rem;
}

.section-title {
  margin-bottom: 0;
}

.add-beneficiary {
  @media (max-width: 379px) {
    width: 140px;
  }
  @include onMobile {
    width: 150px;
    padding: 3rem 0;
    height: 100px;
  }
  font-size: 1.8rem;
  font-weight: 500;
  height: inherit;
  width: inherit;
  text-align: center;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
}

.beneficiaries {
  @include onMobile {
    max-width: 32rem;
    margin: auto;
  }
  &>div {
    @include onMobile {
      justify-content: center;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}
</style>
