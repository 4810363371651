<template>
  <router-link
    :to="to"
    class="nav-item-child-label"
  >
    <span class="nav-item-child-label-text">{{ label }}</span>
    <FontAwesomeIcon class="nav-item-child-label-icon" :icon="$icons.chevronRight" />
  </router-link>
</template>

<script>
  export default {
    name: 'AppNavigationItemChild',
    props: {
      to: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .nav-item-child-label {
    display: flex;
    color: #fff;
    justify-content: space-between;
    padding-left: 12rem;
    opacity: 0.7;
    font-size: 1.5rem;
  }

  .nav-item-child-label-icon {
    visibility: hidden;
    padding: 2.7rem;
    width: 7.4rem;
    height: 7.4rem;
  }

  .router-link-exact-active {
    opacity: 1;

    .nav-item-child-label-icon {
      visibility: visible;
    }
  }
</style>
