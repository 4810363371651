<template>
  <AppPopIn @close="close" max-width="50rem">
    <template v-if="!isLoaded">
      <FontAwesomeIcon :icon="$icons.spinner" class="icon" spin />
      {{ $t('avatarForm.loader') }}
    </template>
    <template v-else>
      <div v-if="!imageSource">
        <SectionTitle center>{{ $t('avatarForm.title') }}</SectionTitle>
        <p class="description">
          {{ $t('avatarForm.description') }}
        </p>
        <FileUploadButton @change="handleFileSelected" :label="$t('avatarForm.file-upload')" />
      </div>
      <div class="crop-container" v-else>
        <img :src="imageSource" @load="initCropper" alt="" class="crop-preview" ref="preview">
      </div>
      <div class="buttons">
        <ButtonRed :busy="isUploading" @click="close" no-border outlined small>Annuler</ButtonRed>
        <ButtonGreen :busy="isUploading" @click="upload" small v-if="imageSource">Valider</ButtonGreen>
      </div>
    </template>
  </AppPopIn>
</template>

<script>
import AppPopIn from './AppPopIn.vue';
import ButtonRed from './ButtonRed.vue';
import ButtonGreen from './ButtonGreen.vue';
import FileUploadButton from '@/components/Consultation/CallingInterface/FileUploadButton.vue';
import SectionTitle from '@/components/SectionTitle.vue';

let croppie;

export default {
  name: 'AvatarForm',
  components: { SectionTitle, FileUploadButton, ButtonGreen, ButtonRed, AppPopIn },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCroppieScriptLoaded: false,
      isCroppieStyleLoaded: false,
      imageSource: null,
    };
  },
  async beforeMount() {
    if (window.Croppie) {
      this.isCroppieScriptLoaded = true;
      this.isCroppieStyleLoaded = true;
      return;
    }

    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.5/croppie.min.js';
    scriptElement.async = true;
    scriptElement.onload = () => this.isCroppieScriptLoaded = true;
    document.head.appendChild(scriptElement);

    const linkElement = document.createElement('link');
    linkElement.rel = 'stylesheet';
    linkElement.href = 'https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.5/croppie.min.css';
    linkElement.async = true;
    linkElement.onload = () => { this.isCroppieStyleLoaded = true; };
    document.head.appendChild(linkElement);
  },
  beforeDestroy() {
    this.imageSource = null;
    croppie?.destroy();
    croppie = undefined;
  },
  computed: {
    isLoaded() {
      return this.isCroppieScriptLoaded && this.isCroppieStyleLoaded;
    },
    isUploading() {
      return this.$store.state.patients.isUploadingAvatar;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('patientAvatarCloseForm');
    },
    async upload() {
      if (!croppie) {
        return;
      }

      let dataUrl = croppie.data.url;
      const file = await croppie.result({
        type: 'blob',
        format: dataUrl.substring(dataUrl.indexOf("/")+1, dataUrl.indexOf(";")),
        circle: false,
      });

      try {
        await this.$store.dispatch('patientAvatarUpload', { id: this.userId, file });

        const fileAsData = await croppie.result({
          circle: false,
        });

        this.$emit('change', fileAsData);

        this.close();
      } catch (e) {
        this.$addError(this.$t('avatarForm.error'));
        throw e;
      }
    },
    async handleFileSelected(file) {
      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = ({ target }) => {
        this.imageSource = target.result;
      };

      reader.readAsDataURL(file);
    },
    initCropper() {
      croppie = new Croppie(this.$refs.preview, {
        viewport: { width: 384, height: 384, type: 'circle' },
      });
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.crop-container {
  margin: 0 auto;
  width: 384px;
  height: 384px;
}

.crop-preview {
  opacity: 0;
}

.buttons {
  text-align: center;
  margin-top: 5rem;
}

.icon {
  margin-right: 1rem;
}

.section-title {
  margin-bottom: 2.5rem;
}

.description {
  text-align: center;
  margin-bottom: 2.5rem;
  font-style: italic;
}
</style>
