import { render, staticRenderFns } from "./ProfileStatusPage.vue?vue&type=template&id=576c0d30&scoped=true&"
import script from "./ProfileStatusPage.vue?vue&type=script&lang=js&"
export * from "./ProfileStatusPage.vue?vue&type=script&lang=js&"
import style0 from "./ProfileStatusPage.vue?vue&type=style&index=0&id=576c0d30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576c0d30",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('576c0d30')) {
      api.createRecord('576c0d30', component.options)
    } else {
      api.reload('576c0d30', component.options)
    }
    module.hot.accept("./ProfileStatusPage.vue?vue&type=template&id=576c0d30&scoped=true&", function () {
      api.rerender('576c0d30', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Profile/ProfileStatusPage.vue"
export default component.exports