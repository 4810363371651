<template>
  <AppLayout>
    <AppProgress :current="6" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />
    <main class="container">
      <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
      <SectionTitle>Tarif de la consultation</SectionTitle>
      <span class="description">Ce tarif prévisionnel repose sur le motif que vous avez sélectionné lors de la prise de rendez-vous et sur les honoraires spécifiques du praticien. Le montant final peut varier si le motif choisi ne correspond pas à l’acte réalisé. Si tel est le cas, le praticien vous en informera au début de la téléconsultation.</span>

      <div class="recap-container">
        <div class="recap">
          <div class="recap__title">
            <span class="price">{{productPrice}}</span>
            <span>Motif choisi: {{product.name}}</span>
          </div>
          <div class="collapse" :class="{'collapse-open': openCollapse}">
            <div class="price-cols">Honoraires <span>{{ formatMoney(this.product.unitPrice) }}</span></div>
            <div class="price-cols">
              <span>Frais de service
                <a href="https://hellocare.com/blog/wp-content/uploads/2024/04/Page_Tarif_V3.pdf" target="_blank"><img class="icon-question" alt="icon-question" src="@/assets/images/question.png"/></a>
              </span>
              <span>
                  {{ formatMoney(
                      getServiceCharge(
                          patient,
                          serviceCharge,
                          this.practitioner
                      )
                    )
                  }}
              </span>
            </div>
            <div class="price-cols">
              Frais de mise en relation
              <span>
                {{ formatMoney(
                    getConnectionFees(
                      firstTimeWithPractitioner,
                      markup,
                      patient,
                      this.practitioner
                    )
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="recap-container__buttons">
          <span @click="goToSearch" class="change-pattern">Changer de motif</span>
          <input type="checkbox" class="details-button" @click="openCollapse = !openCollapse"/>
        </div>
      </div>

      <div class="buttons">
        <ButtonTransparent
            :icon="$icons.chevronLeft"
            icon-to-left
            @click="$router.back()"
        >
          {{ $t('newAppointmentReasonPage.back')}}
        </ButtonTransparent>
        <ButtonGreen
            :busy="busy"
            :icon="$icons.chevronRight"
            rounded
            type="submit"
            @click="handleFirstTime(true)"
        >
          {{ $t('newAppointmentReasonPage.continue') }}
        </ButtonGreen>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from '../components/AppLayout';
import SectionTitle from '../components/SectionTitle';
import ButtonTransparent from '../components/ButtonTransparent';
import ButtonGreen from '../components/ButtonGreen';
import AppProgress from '../components/AppProgress';
import { minLength, required } from '@byscripts/vuelidate/lib/validators';
import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
import FormRadio from "@/components/Form/FormRadio.vue";
import {formatMoney} from "@/helpers/format";
import { isFrench } from "@/helpers/countries";
import {getConnectionFees, getServiceCharge, getTotalFees} from "@/price/markup";
import {mapActions} from "vuex";
import moment from "moment";
import { dateAppointment } from "@/date";

export default {
  name: 'new-appointment-price-product',
  components: {
    FormRadio,
    RecapTitle,
    AppProgress,
    ButtonGreen,
    ButtonTransparent,
    SectionTitle,
    AppLayout,
  },
  data() {
    return {
      reason: '',
      acceptToBeContacted: null,
      openCollapse: false,
      markup: false,
      busy: false
    };
  },
  validations: {
    reason: {
      required: required,
      minLength: minLength(5),
    },
  },
  beforeMount() {
    if (this.$store.state.newAppointment
        && null === this.$store.state.newAppointment.product) {
      return this.$router.push({ name: 'new-appointment-search' });
    }
  },
  mounted() {
    const now = moment();
    const appointmentStartAt = this.newAppointment.startAt;
    if(appointmentStartAt.diff(now, 'minutes')<=60) {
      this.markup = true;
    }
  },
  computed: {
    firstTimeWithPractitioner() {
      return this.$store.state.newAppointment.firstTimeWithPractitioner
    },
    hasPaymentMethod() {
      return this.$store.state.paymentMethods.all.length > 0;
    },
    isPremium() {
      return this.patient.premium
    },
    practitioner() {
      return this.$store.state.newAppointment.practitioner;
    },
    patient() {
      return this.$store.state.patients.profile;
    },
    newAppointment() {
      return this.$store.state.newAppointment;
    },
    product() {
      return this.newAppointment.product;
    },
    productPrice() {
      let totalFees = getTotalFees(
          this.firstTimeWithPractitioner,
          this.markup,
          this.patient,
          this.serviceCharge,
          this.practitioner
      );

      if (this.hasTelegrafikPartner() || this.hasSafeGPartner()) {
        totalFees = 0;
      }

      const totalPrice = this.product.unitPrice + totalFees;

      const authorizationProductPrice = this.product.unitPrice < 2500 ? 2500 : this.product.unitPrice;
      const authorizationPrice = authorizationProductPrice + totalFees;

      this.$store.dispatch('newAppointmentSetTotalPrice', { totalPrice: authorizationPrice });
      return formatMoney(totalPrice);
    },
    serviceCharge() {
      return !isFrench(this.patient) ? 400 : 300;
    },
  },
  methods: {
    getServiceCharge,
    dateAppointment,
    getConnectionFees,
    ...mapActions(['NEW_APPOINTMENT_FIRST_TIME']),
    formatMoney,
    async handleFirstTime() {
      this.busy = true

      await this.goToNextStep();
      this.busy = false

    },
    async goToNextStep() {
      if (this.hasTelegrafikPartner()
          || (this.hasSafeGPartner() && this.product.unitPrice === 0)
      ) {
          await this.$router.push(
              {
                  name: 'new-appointment-summary',
                  params: { afterComponentPayment: true }
              }
          );
      } else {
        await this.$router.push({ name: 'new-appointment-payment' });
      }
    },
    hasTelegrafikPartner() {
        return this.patient.tokens.some(t => t.partner.slug === 'telegrafik');
    },
    hasSafeGPartner() {
      console.log(this.$store.state.newAppointment.practitioner.comeFrom);
      return this.$store.state.newAppointment.practitioner.comeFrom === 'safeG';
    },
    async goToSearch() {
      window.history.go(-4);
    }
  },
};
</script>

<style lang="scss" scoped>
main {
  margin: 0 auto;
}

.main-container {
  margin-top: 1rem;
}
main.container {
  @include onMobile {
    margin: 3rem auto 8rem;
    padding: 0 3rem;
    display: block;
  }
}

.section-title {
  margin-bottom: 0;
}

.description {
  @include onMobile {
    font-size: 1.3rem;
  }
  color: #0000007B;
  font-weight: 500;
  display: flex;
  margin-bottom: 3rem;
}

.recap-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__buttons {
    @include onMobileSmall {
      margin-bottom: 12rem;
    }
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    align-items: center;
  }
}

.recap {
  @include onMobile {
    width: 100%;
  }
  background-color: #DFF9F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 3rem;
  border-radius: 15px;
  border: 1px solid #28DBB6;
  width: 45rem;
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }
}

.collapse {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: bold;
  justify-content: space-between;
  width: inherit;
  overflow: hidden;
  max-height: 0;
}

.collapse-open {
  overflow: visible;
  max-height: 1500px;
  margin-top: 3rem;
}

.price-cols {
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
}

.price {
  font-weight: bold;
}

.buttons {
  @include onMobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    justify-content: space-evenly;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }
  background: white;
  display: flex;
  justify-content: space-between;
  margin-top: 20rem;
}

.details-button {
  appearance: none;
  cursor: pointer;
}

.details-button::before {
  content: "Voir le détail";
}

.details-button:checked::before {
  content: "Fermer le détail";
}

.change-pattern, .details-button {
  color: #797979;
  text-decoration: underline;
  cursor: pointer;
}

.help {
  width: 1.2rem;
  height: 1.2rem;
}

.icon-question {
  width: 1.3rem;

}
</style>