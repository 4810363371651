<script>
  import BaseButton from './BaseButton';

  export default {
    name: 'ButtonBlack',
    extends: BaseButton
  };
</script>

<style lang="scss" scoped src="../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
    background-color: $textColor;

    &.outlined {
      border-color: $textColor;
      color: $textColor;
    }
  }
</style>
