export default {
  options: {},
  install(Vue, options) {
    if (!options.store) {
      throw new Error('`store` option is mandatory');
    }
    this.options = options;
    this.registerStoreModule();
  },
  registerStoreModule() {
    this.options.store.registerModule('vueGuide', {
      state: {
        viewedStepsIds: [],
        steps: [],
        currentIndex: 0,
      },
      mutations: {
        VUE_GUIDE_SET_VIEWED_STEPS_IDS: (state, { ids }) => {
          state.viewedStepsIds = ids;
        },
        VUE_GUIDE_ADD_STEP: (state, step) => {
          state.steps.push(step);
        },
        VUE_GUIDE_STEP_ALREADY_VIEWED: () => {},
        VUE_GUIDE_TERMINATE: (state) => {
          state.steps = [];
          state.currentIndex = 0;
        },
        VUE_GUIDE_GO_TO_NEXT_STEP: (state) => {
          state.currentIndex += 1;
        },
        VUE_GUIDE_GO_TO_PREVIOUS_STEP: (state) => {
          state.currentIndex -= 1;
        },
        VUE_GUIDE_REMOVE_STEP: (state, { stepId }) => {
          state.steps = state.steps.filter(s => s.id !== stepId);
        },
        VUE_GUIDE_SET_STEP_STATUS: (state, { stepId, status }) => {
          const step = state.steps.find(s => s.id === stepId);
          if (step) {
            step.status = status;
          }
        },
        VUE_GUIDE_SET_STEP_TARGET: (state, { stepId, target }) => {
          const step = state.steps.find(s => s.id === stepId);
          if (step) {
            step.target = () => target;
            step.status = 'ready';
          }
        },
      },
      actions: {
        vueGuideSetup: ({ commit }, payload) => {
          commit('VUE_GUIDE_SET_VIEWED_STEPS_IDS', { ids: payload.viewedStepsIds });
        },
        vueGuideTerminate: ({ commit, state }) => {
          const ids = state.steps.filter(step => step.status === 'ready').map(step => step.id);
          commit('VUE_GUIDE_TERMINATE', { ids });
        },
        vueGuideActivateDeferredStep: ({ state }, payload) => {
          const stepId = typeof payload === 'string' ? payload : payload.id;
          const target = typeof payload === 'string' ? null : payload.target;
          const step = state.steps.find(s => s.id === stepId);
          step?.ready(target);
        },
        vueGuideCancelDeferredStep: ({ state }, stepId) => {
          const step = state.steps.find(s => s.id === stepId);
          step?.cancel();
        },
        vueGuideAddStep: ({ commit, dispatch, state }, config) => {
          if (!config.id) {
            throw new Error('vueGuideAddStep: id is required');
          }
          let ready = () => { };
          let cancel = () => { };
          if (state.viewedStepsIds.includes(config.id)) {
            commit('VUE_GUIDE_STEP_ALREADY_VIEWED', { id: config.id });
            return config.defer ? { ready, cancel } : null;
          }
          const context = config.setup?.();
          const step = {
            id: config.id,
            status: 'pending',
            context: () => context,
            target: null,
            title: config.title || null,
            content: config.content || null,
            offset: this.toOffset(config.offset),
            before: config.before,
            after: config.after,
            actions: config.actions || [],
            ready: (targetOverride) => dispatch('vueGuideResolveTarget', {
              stepId: config.id,
              target: targetOverride || config.target,
            }),
            cancel: () => commit('VUE_GUIDE_REMOVE_STEP', { stepId: config.id }),
          };
          commit('VUE_GUIDE_ADD_STEP', step);
          if (!config.defer) {
            step.ready();
            return;
          }
          return {
            ready: step.ready,
            cancel: step.cancel,
          };
        },
        vueGuideResolveTarget: async ({ state, commit, dispatch }, payload) => {
          const step = state.steps.find(s => s.id === payload.stepId);
          if (!step || step.status === 'ready' || step.status === 'resolving') {
            return;
          }
          commit('VUE_GUIDE_SET_STEP_STATUS', { stepId: payload.stepId, status: 'resolving' });
          const resolve = async (target) => {
            if ('function' === typeof target) {
              return resolve(target(step.context()));
            }
            if (target instanceof Promise) {
              target.then(resolve).catch();
              return;
            }
            if ('string' === typeof target) {
              return resolve(document.querySelector(target));
            }
            if (target instanceof Element) {
              commit('VUE_GUIDE_SET_STEP_TARGET', { stepId: payload.stepId, target });
              return;
            }
            commit('VUE_GUIDE_REMOVE_STEP', { stepId: payload.stepId });
          };
          await resolve(payload.target);
        },
        vueGuideGoToNextStep: ({ commit }) => {
          commit('VUE_GUIDE_GO_TO_NEXT_STEP');
        },
        vueGuideGoToPreviousStep: ({ commit }) => {
          commit('VUE_GUIDE_GO_TO_PREVIOUS_STEP');
        },
      },
      getters: {
        vueGuideHasSteps: (state) => state.steps.length > 0,
        vueGuideCurrentStep: (state) => state.steps[state.currentIndex],
        vueGuideHasNextStep: (state) => state.currentIndex < state.steps.length - 1,
        vueGuideHasPreviousStep: (state) => state.currentIndex > 0,
        vueGuideGetNextStep: (state) => state.steps[state.currentIndex + 1],
        vueGuideIsNextStepReady: (state, getters) => getters.vueGuideGetNextStep?.status === 'ready',
      },
    });
  },
  toOffset: (offsetConfig) => {
    let offset = { left: 0, right: 0, top: 0, bottom: 0 };
    if (!offsetConfig) {
      return offset;
    }
    if ('number' === typeof offsetConfig) {
      return {
        left: offsetConfig,
        right: offsetConfig,
        top: offsetConfig,
        bottom: offsetConfig,
      };
    }
    if ('number' === typeof offsetConfig.x) {
      offset.left = offset.right = offsetConfig.x;
    } else {
      offset.left = offsetConfig.left || 0;
      offset.right = offsetConfig.right || 0;
    }
    if ('number' === typeof offsetConfig.y) {
      offset.top = offset.bottom = offsetConfig.y;
    } else {
      offset.top = offsetConfig.top || 0;
      offset.bottom = offsetConfig.bottom || 0;
    }
    return offset;
  },
};
