<template>
  <AppLayout :hidden=" hidden">
    <AppProgress :current="7" :total="8" :position="$media.mobileSmall ? 'b-15' : 'b-9-2'" />
    <main class="container">
      <RecapTitle :title="`${practitioner.title} ${practitioner.lastName}`" :date="dateAppointment(this.newAppointment.startAt)" />
      <SectionTitle> {{ $t('newAppointmentPaymentPage.title') }} </SectionTitle>
      <div class="description"> {{ $t('newAppointmentPaymentPage.description') }} </div>
      <div v-if="cardPaymentLoader && (!hasPaymentMethod || isAddingCard)">
        <div class="card-accepted">
          <span> {{ $t('newAppointmentPaymentPage.list-card') }} </span>
          <CreditCardsPictograms small />
        </div>
        <CreditCardAddForm
            :shouldSave="shouldSave"
            @close="returnMainPaymentPage"
            @create="goToSummaryPage(true)"
            @change="handleSelect"
            @ignore="goToSummaryPage"
        >
          <template #check-card-registered>
            <FormCheckbox class="card-registered" @change="handleClick"> {{ $t('newAppointmentPaymentPage.accepted-registered-card') }} </FormCheckbox>
          </template>
          <template #submit-button> {{ $t('newAppointmentPaymentPage.continue') }} </template>
          <template #cancel-button> {{ $t('newAppointmentPaymentPage.return') }} </template>
        </CreditCardAddForm>
      </div>

      <div v-else>
       <div v-if="hasPaymentMethod" class="container-slide">
         <div class="select-card">
           <Card
               v-for="card in cards"
               :class="{ selected: selectedCard && selectedCard.id === card.id }"
               :card-slide="true"
           >
             <CreditCards
                 :cards="cards"
                 :card="card"
                 :title= "$t('newAppointmentPaymentPage.creditCard.title')"
                 @change="handleSelect"
                 class="card-slide"
                 :col= true
             />
           </Card>
           <Card v-if="$media.desktop">
             <a class="add-card" href="#" @click.prevent="handleCardRegistered(true)">
               {{ $t('newAppointmentPaymentPage.add-card') }}
               <CreditCardsPictograms />
             </a>
           </Card>
         </div>
       </div>
        <FontAwesomeIcon
            v-else
            v-if="busy"
            :icon="busy ? $icons.spinner : icon"
            :spin="busy"
            fixedWidth
        />
        <a v-if="$media.mobile" href="#" class="add-card" @click.prevent="handleCardRegistered(true)">
          {{ $t('newAppointmentPaymentPage.add-card-mobile') }}
        </a>

        <div class="infos"> {{ $t('newAppointmentPaymentPage.information-payment') }} </div>

        <div class="buttons">
          <ButtonTransparent
              :icon="$icons.chevronLeft"
              icon-to-left
              @click="$router.back()"
          >
            {{ $t('newAppointmentReasonPage.back')}}
          </ButtonTransparent>
          <ButtonGreen
              @click="goToSummaryPage"
              :icon="$icons.chevronRight"
              rounded
              type="submit"
          >
            {{ $t('newAppointmentReasonPage.continue') }}
          </ButtonGreen>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
  import AppLayout from '../components/AppLayout';
  import SectionTitle from '../components/SectionTitle';
  import ButtonTransparent from '../components/ButtonTransparent';
  import ButtonGreen from '../components/ButtonGreen';
  import AppProgress from '../components/AppProgress';
  import CreditCardAddForm from '@/components/NewAppointment/CreditCardAddForm';
  import CreditCardsPictograms from '@/components/CreditCardsPictograms';
  import RecapTitle from "@/components/NewAppointment/RecapTitle.vue";
  import FormCheckbox from "@/components/Form/FormCheckbox.vue";
  import CreditCards from "@/components/NewAppointment/CreditCards.vue";
  import Card from "@/components/Card.vue";
  import { dateAppointment } from "@/date";

  export default {
    name: 'new-appointment-payment',
    components: {
      RecapTitle,
      CreditCardsPictograms,
      CreditCardAddForm,
      AppProgress,
      ButtonGreen,
      ButtonTransparent,
      SectionTitle,
      AppLayout,
      FormCheckbox,
      CreditCards,
      Card
    },
    data() {
      return {
        afterComponentPayment: true,
        hidden: false,
        isAddingCard: false,
        selectedCardId:null,
        selectedCard:null,
        cardPaymentLoader: false,
        busy: false,
        shouldSave: true,
      }
    },
    async beforeMount() {
      if (!this.hasPaymentMethod) {
        this.busy = true
        await this.$store.dispatch('paymentMethodsFetchAll');
        this.cardPaymentLoader = true;
        this.busy = false
      }
      if (this.$store.state.newAppointment
          && null === this.$store.state.newAppointment.reason) {
        return this.$router.push({ name: 'new-appointment-reason' });
      }
      if($media.mobile) {
        return this.hidden === true;
      }
    },
    methods: {
      dateAppointment,
      handleSelect(card) {
        this.selectedCard = card
      },
      goToSummaryPage(created) {
        this.$store.commit('PAYMENT_METHODS_SELECTED', this.selectedCard)
        this.$router[created ? 'replace' : 'push'](
            {
                name: 'new-appointment-summary',
                params: {afterComponentPayment: this.afterComponentPayment}
            }
        );
      },
      handleCardRegistered(isAdded) {
        this.shouldSave = false
        this.cardPaymentLoader = true
        this.isAddingCard= isAdded
      },
      handleClick(checked) {
        this.shouldSave = checked;
      },
      returnMainPaymentPage() {
        this.shouldSave = true
        if (this.hasPaymentMethod) {
          return this.isAddingCard = false
        }
        return this.$router.back()
      }
    },
    computed: {
      newAppointment() {
        return this.$store.state.newAppointment;
      },
      cards() {
        return this.$store.state.paymentMethods.all;
      },
      hasPaymentMethod() {
        return this.$store.state.paymentMethods.all.length > 0;
      },
      practitioner() {
        return this.$store.state.newAppointment.practitioner;
      },
    },
  };
</script>

<style lang="scss" scoped>
  main {
    margin: 0 auto;
  }

  main.container {
    @include onMobile {
      margin: 3rem auto 8rem;
      padding: 0 3rem;
      display: block;
    }
  }

  .card-registered {
    @include onMobileSmall {
      margin-bottom: 20rem;
    }
    font-weight: 500;
  }

  .credit-cards-pictograms {
    margin: 0 0 3rem;
  }
  .section-title {
    margin-bottom: 0;
  }

  .description {
    @include onMobile {
      font-size: 1.3rem;
    }
    font-weight: 500;
    color: #0000007B;
    margin-bottom: 3rem;
  }

  .buttons {
    @include onMobile {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      justify-content: space-evenly;
      gap: 1rem;
      flex-wrap: wrap;
      padding-bottom: 2rem;
    }
    background: white;
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
    width: 100%;
  }

  .add-card {
    @include onMobile {
      color: #0000007B;
      font-weight: bold;
      width: 150px;
      flex: 1;
      border:none;
      width: max-content;
      height: max-content;
      padding: 0;
      text-decoration: underline;
      margin:2rem auto;
    }
    display: flex;
    flex-direction: column;
    font-weight: 500;
    justify-content: center;
    color: black;
    align-items: center;
    gap: .75rem;
  }

  .select-card {
    @include onMobile {
      flex-wrap: initial;
      overflow-x: auto;
      margin-bottom: -20px;
      padding-bottom: 30px;
      display: flex;
      align-items: center;
      height: 180px;
    }
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .infos {
    color: #707070;
    margin: 3rem 0 0 0;
    @include onMobile {
      margin: 3rem 0 12rem 0;
      font-size: 1.3rem;
    }
    @include onMobileSmall {
      margin: 3rem 0 20rem 0;
    }
  }

  .credit-cards-pictograms::v-deep {
    margin: 0;
  }

  .card-accepted {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 2.6rem;
  }

  .informations {
    color: #0000007B;
  }

  .container-slide {
    @include onMobile {
      overflow: auto;
      overflow-y: hidden;
    }
  }

  .card-slide {
    @include onMobile {
      display: inline-flex;
      border-radius: 5px;
      width: 237px;
      height: 130px;
      padding: 2rem 1rem;
    }
    position: relative;
    text-align: initial;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap:.75rem;
    display: inline-flex;
    flex-wrap: wrap;
  }
</style>
