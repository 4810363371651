var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "summary-interface" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "summary" },
                [
                  _c("SectionTitle", [
                    _vm._v(_vm._s(_vm.$t("profileSummaryPage.title")))
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "start-at" }, [
                      _vm._v(_vm._s(_vm.$t("profileSummaryPage.start-at")))
                    ]),
                    _c("ul", [
                      _c("li", {
                        staticClass: "advantage",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("profileSummaryPage.advantage.1")
                          )
                        }
                      }),
                      _c("li", {
                        staticClass: "advantage",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("profileSummaryPage.advantage.2")
                          )
                        }
                      }),
                      _c("li", {
                        staticClass: "advantage",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("profileSummaryPage.advantage.3")
                          )
                        }
                      })
                    ])
                  ]),
                  _c("p", {
                    staticClass: "amount-row",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("profileSummaryPage.price"))
                    }
                  }),
                  _c("p", {
                    staticClass: "content sub-infos",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("profileSummaryPage.info"))
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "payment" }, [
                _c("div", [_c("CardPayment")], 1)
              ])
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }