var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auto-completed-input" }, [
    _c("input", {
      ref: "textField",
      attrs: { id: _vm.id, autocomplete: "off" },
      domProps: { value: _vm.typedText },
      on: {
        keydown: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.moveSelectionUp($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.moveSelectionDown($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.confirmSelection($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            ) {
              return null
            }
            return _vm.handleTab($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            $event.preventDefault()
            _vm.foundResults = []
          }
        ],
        input: function($event) {
          return _vm.handleInput($event.target.value)
        }
      }
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.areResultsVisible,
            expression: "areResultsVisible"
          }
        ],
        staticClass: "found-results",
        on: { mouseenter: _vm.focus }
      },
      _vm._l(_vm.foundResults, function(foundResult, index) {
        return _c(
          "div",
          {
            ref: "result",
            refInFor: true,
            class: { selected: _vm.selectionIndex === index },
            on: {
              mouseenter: function($event) {
                _vm.selectionIndex = index
              },
              click: _vm.confirmSelection
            }
          },
          [
            _vm._t("default", [_vm._v(_vm._s(foundResult))], {
              result: foundResult
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }