var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-guide-step" }, [
    _vm.title
      ? _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c("div", { staticClass: "content" }, [_vm._v(_vm._s(_vm.content))]),
    _vm.actions.length > 0
      ? _c(
          "div",
          { staticClass: "actions" },
          [
            _vm._l(_vm.actions, function(action) {
              return [
                !_vm.handledActions.includes(action)
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleAction(action)
                          }
                        }
                      },
                      [_vm._v("\n        " + _vm._s(action.label) + "\n      ")]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "buttons" }, [
      _vm.hasPreviousStep
        ? _c(
            "button",
            {
              staticClass: "button-previous",
              attrs: { type: "button" },
              on: { click: _vm.handlePrevious }
            },
            [_vm._v("Précédent")]
          )
        : _vm._e(),
      _vm.hasNextStep
        ? _c(
            "button",
            {
              staticClass: "button-next",
              attrs: { type: "button", disabled: !_vm.isNextStepReady },
              on: { click: _vm.handleNext }
            },
            [
              !_vm.isNextStepReady
                ? _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.$icons.spinner, spin: "" }
                  })
                : _vm._e(),
              _vm._v("\n      Suivant\n    ")
            ],
            1
          )
        : _vm._e(),
      _vm.hasNextStep
        ? _c(
            "button",
            {
              staticClass: "button-ignore",
              attrs: { type: "button" },
              on: { click: _vm.handleTerminate }
            },
            [_vm._v("Ignorer")]
          )
        : _c(
            "button",
            {
              staticClass: "button-terminate",
              attrs: { type: "button" },
              on: { click: _vm.handleTerminate }
            },
            [_vm._v("Terminer")]
          )
    ]),
    _c("div", { staticClass: "arrow", attrs: { "data-popper-arrow": "" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }