var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-avatar",
      class: { editable: _vm.editable },
      style: _vm.style,
      on: { click: _vm.openAvatarForm }
    },
    [
      !_vm.style.backgroundImage
        ? _c("img", {
            attrs: {
              alt: "",
              src: require("../assets/images/picto_no_avatar.svg")
            }
          })
        : _vm._e(),
      _vm.editable
        ? _c("div", {
            staticClass: "change-avatar",
            domProps: { innerHTML: _vm._s(_vm.$t("userAvatar.change-avatar")) }
          })
        : _vm._e(),
      _vm.editable
        ? _c("div", { staticClass: "pictogram" }, [
            _c("img", {
              attrs: {
                alt: "",
                src: require("../assets/images/picto-change-avatar.svg")
              }
            })
          ])
        : _vm._e(),
      _vm.$store.state.patients.isAvatarFormOpened
        ? _c("AvatarForm", {
            attrs: { "user-id": _vm.userId },
            on: { change: _vm.handleNewAvatar }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }