<template>
  <div
    :class="type"
    class="app-notification-item animated bounceInRight"
    @click="remove"
  >
    <div v-if="icon" class="icon-container">
      <FontAwesomeIcon :icon="icon" />
    </div>
    <div class="message">
      <slot />
    </div>
    <div v-if="duration">
      <AppPieTimer ref="timer" :duration="duration" :paused="paused" @ended="remove" />
    </div>
  </div>
</template>

<script>
  import AppPieTimer from '@/components/AppPieTimer';

  export default {
    name: 'AppNotificationItem',
    components: { AppPieTimer },
    props: {
      id: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      duration: {
        type: Number,
        required: false,
      },
      icon: {
        type: Object,
        required: false,
      },
      paused: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      remove() {
        this.$store.dispatch('appNotificationRemove', { id: this.id });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~animate.css/source/_vars.css";
  @import "~animate.css/source/_base.css";
  @import "~animate.css/source/bouncing_entrances/bounceInRight.css";

  .app-notification-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: #FFFFFF;
    border-radius: 1rem;

    &.error {
      background-color: $notificationErrorBackgroundColor;
    }

    &.success {
      background-color: $notificationSuccessBackgroundColor;
    }

    ::v-deep a {
      text-decoration: underline;
      color: #FFFFFF;
    }
  }

  .icon-container {
    font-size: 2.4rem;
    width: fit-content;
  }

  .message {
    font-family: $textFont;
    font-size: 1.5rem;
    font-weight: 400;
    flex: 1;
    text-transform: uppercase;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  }

  .icon-container + .message {
    padding-left: 1rem;
  }

  .app-pie-timer {
    width: 2rem;
  }
</style>
