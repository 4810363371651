var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FullscreenNotification",
    {
      attrs: {
        title: _vm.$t("newAppointmentRequestedPage.title"),
        imgIcon: _vm.$t("newAppointmentRequestedPage.img-icon")
      }
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("newAppointmentRequestedPage.text"))
            }
          }),
          _c(
            "div",
            { staticClass: "container__cards" },
            [
              _c("CardWithRedirection", {
                attrs: {
                  imgCard: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.test-device.img-card"
                  ),
                  title: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.test-device.title"
                  ),
                  description: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.test-device.description"
                  ),
                  button: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.test-device.button"
                  ),
                  "to-link": _vm.checkDevice
                }
              }),
              _c("CardWithRedirection", {
                attrs: {
                  imgCard: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.exchange-document.img-card"
                  ),
                  title: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.exchange-document.title"
                  ),
                  description: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.exchange-document.description"
                  ),
                  button: _vm.$t(
                    "newAppointmentRequestedPage.redirect-card.exchange-document.button"
                  ),
                  "to-link": _vm.appointmentPanel
                }
              })
            ],
            1
          ),
          _c(
            "ButtonGreen",
            {
              staticClass: "button",
              attrs: { rounded: "" },
              on: { click: _vm.appointmentPanel }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("newAppointmentRequestedPage.skip-step")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }