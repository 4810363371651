import store from '@/config/store';
import VueGuide from '@/plugins/VueGuide';
import Vue from 'vue';

store.subscribe(({ type, payload }) => {
  if (type !== 'VUE_GUIDE_TERMINATE') {
    return;
  }

  const alreadyViewedIds = store.state.patients.profile.metadata.viewedGuideStepsIds || [];

  const viewedGuideStepsIds = [...alreadyViewedIds, ...payload.ids];

  store.dispatch('vueGuideSetup', { viewedStepsIds: viewedGuideStepsIds });

  store.dispatch('patientEditMetadata', {
    metadata: {
      viewedGuideStepsIds,
    },
  });
});

Vue.use(VueGuide, { store });
