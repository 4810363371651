var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      [
        _c("ProfileTabBar"),
        _c("div", { staticClass: "container" }, [
          _c(
            "header",
            { staticClass: "header" },
            [
              _c("GroupTitle", { attrs: { icon: _vm.$icons.box } }, [
                _vm._v("\n          Liste de mes commandes\n        ")
              ])
            ],
            1
          ),
          _c("div", { staticClass: "listCommands" }, [
            _c("table", { staticClass: "table table-hover beneficiaries" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Statut de la commande")]),
                  _c("th", [_vm._v("Nom de la pharmacie")]),
                  _c("th", [_vm._v("Adresse de la pharmacie")]),
                  _c("th", [_vm._v("Numéro de la pharmacie")]),
                  _c("th", [_vm._v("Actions")])
                ])
              ]),
              _vm.orders.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.orders, function(order) {
                      return _c("tr", { staticClass: "beneficiary" }, [
                        _c("td", { staticClass: "action-status" }, [
                          order.status === "new"
                            ? _c("p", [_vm._v("Commande enregistrée")])
                            : _vm._e(),
                          order.status === "sent"
                            ? _c("p", [_vm._v("Commande validée")])
                            : _vm._e(),
                          order.status === "preparing"
                            ? _c("p", [_vm._v("En cous de préparation")])
                            : _vm._e(),
                          order.status === "ready"
                            ? _c("p", [_vm._v("Commande prête")])
                            : _vm._e(),
                          order.status === "finished"
                            ? _c("p", [_vm._v("Commande reçue")])
                            : _vm._e(),
                          order.status === "cancelled_by_pharmacy"
                            ? _c("p", [_vm._v("Commande annulée")])
                            : _vm._e(),
                          order.status === "cancelled_by_patient"
                            ? _c("p", [_vm._v("Commande annulée")])
                            : _vm._e(),
                          order.status === "cancelled_by_hellocare"
                            ? _c("p", [_vm._v("Commande annulée")])
                            : _vm._e()
                        ]),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                order.pharmacy.rs_longue
                                  ? order.pharmacy.rs_longue
                                  : order.pharmacy.rs
                              ) +
                              "\n            "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                order.pharmacy.num_voie
                                  ? order.pharmacy.num_voie
                                  : ""
                              ) +
                              "\n              " +
                              _vm._s(
                                order.pharmacy.typ_voie
                                  ? order.pharmacy.typ_voie
                                  : ""
                              ) +
                              "\n              " +
                              _vm._s(
                                order.pharmacy.voie ? order.pharmacy.voie : ""
                              ) +
                              "\n              " +
                              _vm._s(order.pharmacy.libdepartement) +
                              "\n            "
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(order.pharmacy.telephone) +
                              "\n            "
                          )
                        ]),
                        _c(
                          "td",
                          { staticClass: "action-column" },
                          [
                            _c(
                              "ButtonGreen",
                              {
                                attrs: {
                                  small: "",
                                  "link-to": {
                                    name: "appointment-view",
                                    params: { id: order.appointment.id }
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                Mon rendez-vous\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }