<template>
  <label
    :class="{ focused, 'has-value': !!value, 'has-error': !!$slots.error }"
    @focusin="focused = true"
    @focusout="focused = false"
    class="form-select"
  >
    <FontAwesomeIcon :icon="$icons.selectIndicator" class="icon" />
    <span :id="id" class="label">{{ label }}<template v-if="required"> *</template></span>
    <select
      :aria-labelledby="id"
      ref="input"
      v-bind="$attrs"
      v-model="internalValue"
    >
      <slot />
    </select>
    <span class="help" v-if="$slots.help && !$slots.error"><slot name="help" /></span>
    <span class="error" v-if="$slots.error"><slot name="error" /></span>
  </label>
</template>

<script>
  export default {
    name: 'FormSelect',
    inheritAttrs: false,
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
        default: function() {
          return this.$t('formSelect.label-default')
        },
      },
      value: {
        required: false,
        default: null,
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        focused: false,
      };
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form-select {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: $inputFont;
    font-weight: 300;
    background-color: $inputBackgroundColor;
    border-bottom: 1px solid $inputBorderColor;
    padding: 0.3rem 0.5rem;

    &.focused {
      border-bottom-color: $inputLabelFocusedColor;
    }

    &.has-error {
      border-bottom-color: $inputErrorColor;
    }
  }

  .label {
    width: fit-content;
    transform-origin: left top;
    font-size: $inputLabelFontSize;
    color: $inputLabelColor;
    transition: transform 150ms linear, color 150ms linear;
    pointer-events: none;
    padding: 0.8rem 0.5rem 0.5rem;
    transform: translateY(1.1rem) scale(1.2);
    will-change: transform, color;
    font-weight: 200;

    .has-value &, .focused & {
      transform: translateY(0) scale(1);
    }

    .has-value & {
      color: $inputLabelFilledColor;
    }

    .focused & {
      color: $inputLabelFocusedColor;
    }

    .has-error & {
      color: $inputErrorColor;
    }
  }

  select {
    background-color: transparent;
    appearance: none;
    border: none;
    outline: none;
    padding: 0.4rem 0.5rem;
    transition: border-color 150ms linear;
    will-change: border-bottom-color;
    font-size: 1.5rem;
  }

  .help, .error {
    font-size: 1.4rem;
    padding-top: 0.5rem;
  }

  .help {
    color: $inputHelpColor;
  }

  .error {
    color: $inputErrorColor;
    font-weight: bold;
  }

  .icon {
    position: absolute;
    right: 0.8rem;
    top: 2.5rem;
    pointer-events: none;
    transition: transform 150ms linear;
    will-change: transform;

    .has-value &, .focused & {
      transform: translateY(1.2rem);
    }
  }
</style>
