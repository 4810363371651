<!--
Emitted events:
- select
-->
<template>
  <div :class="{ expired, selectable }" class="credit-card-row" @click="select">
    <div v-if="selectable" class="checkbox">
      <FormCheckbox v-if="!expired" :checked="selected" />
    </div>
    <div class="logo">
      <img v-if="brandLogoPath" :alt="brand" :src="brandLogoPath" />
      <span v-else>{{ brand }}</span>
    </div>
    <div class="number">**** **** **** {{ lastFourDigit }}</div>
    <div>{{ expired ? $t('creditCardRow.expired') : expirationDate.format('MM/YY') }}</div>
    <div v-if="removable" class="remove">
      <a href="#" @click.prevent.stop="$emit('remove')">{{ $t('creditCardRow.remove') }}</a>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import FormCheckbox from '@/components/Form/FormCheckbox';

  export default {
    name: 'CreditCardRow',
    components: { FormCheckbox },
    props: {
      removable: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      brand: {
        type: String,
        required: true,
      },
      expirationMonth: {
        type: Number,
        required: true,
      },
      expirationYear: {
        type: Number,
        required: true,
      },
      lastFourDigit: {
        type: String,
        required: true,
      },
    },
    computed: {
      brandLogoPath() {
        switch (this.brand) {
          case 'visa':
            return require('../assets/images/visa-logo.svg');
          case 'mastercard':
            return require('../assets/images/mastercard-logo.svg');
          case 'amex':
            return require('../assets/images/amex-logo.svg');
          default:
            return null;
        }
      },
      expirationDate() {
        return moment().year(this.expirationYear).month(this.expirationMonth - 1).endOf('month');
      },
      expired() {
        return this.expirationDate.isBefore();
      },
    },
    methods: {
      select() {
        if (!this.selectable || this.expired) {
          return;
        }
        this.$emit('select');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .credit-card-row {
    font-weight: 300;
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;

    &.selectable {
      cursor: pointer;
    }

    &.expired {
      cursor: default;
      color: $grey;
    }

    &:hover {
      background-color: $lightTextColor;
    }
  }

  .number {
    padding: 0 2rem;
  }

  .logo img {
    width: 3rem;
  }

  .remove {
    margin-left: auto;
  }

  .form-checkbox {
    transform: scale(0.8);
    pointer-events: none;
  }

  .checkbox {
    min-width: 3.5rem;
  }

  .remove a {
    text-decoration: underline;
    color: $successColor;
  }
</style>
