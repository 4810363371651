
<template v-else>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <div class="row">
          <div class="col _6 left">
            <ProfileEditForm :user="profile"/>
          </div>
          <div class="col-separator" />
          <div class="col _6 right">
            <GroupTitle :icon="$icons.lock">{{ $t('profileEditPage.credentials') }}</GroupTitle>

            <div class="row">
              <FormInput id="email" :value="profile.email" class="col _12" disabled :label="$t('profileEditPage.email')" type="email" />
            </div>
            <div class="edit-credential">
              <!-- TODO Enable when available -->
              <!--              <a href="#" @click.prevent>-->
              <!--                <FontAwesomeIcon :icon="$icons.editCredential" class="icon" fixed-width />-->
              <!--                <span>Modifier mon email</span>-->
              <!--              </a>-->
            </div>

            <div class="row">
              <FormInput
                id="phone"
                :value="profile.phone | phone"
                class="col _12"
                disabled
                :label="$t('profileEditPage.mobile-phone')"
                type="phone"
              />
            </div>

            <div class="edit-credential">
              <!-- TODO Enable when available -->
              <!--              <a href="#" @click.prevent>-->
              <!--                <FontAwesomeIcon :icon="$icons.editCredential" class="icon" fixed-width />-->
              <!--                <span>Modifier mon numéro de téléphone</span>-->
              <!--              </a>-->
            </div>

            <div class="row">
              <FormInput id="password" class="col _12" disabled :label="$t('profileEditPage.password')" value="************" />
            </div>

            <div class="edit-credential">
              <!-- TODO Enable when available -->
              <!--              <a href="#" @click.prevent>-->
              <!--                <FontAwesomeIcon :icon="$icons.editCredential" class="icon" fixed-width />-->
              <!--                <span>Modifier mon mot de passe</span>-->
              <!--              </a>-->
            </div>
            <div v-if = "!this.profile.deletedAt">
              <a
                 href="#"
                 @click.prevent
                 @click="$confirm($refs.deleteProfile)"
                 class="delete"
              >
                Supprimer mon compte
              </a>
            </div>
            <AppConfirmation
                ref="deleteProfile"
                @confirm="deleteAccount"
                :title="$t('profileEditPage.title-confirmation')"
            >
              <template v-slot:description="scoped"> <span v-html="$t('profileEditPage.description-confirmation')"></span> </template>
              </AppConfirmation>
          </div>
        </div>
      </div>
    </main>
  </AppLayout>
</template>


<script>
  import AppLayout from '@/components/AppLayout.vue';
  import FormInput from '@/components/Form/FormInput.vue';
  import ProfileEditForm from '@/components/Form/ProfileEditForm.vue';
  import GroupTitle from '@/components/GroupTitle.vue';
  import ProfileTabBar from '@/components/ProfileTabBar.vue';
  import SectionTitle from "@/components/SectionTitle";
  import ButtonGreen from '@/components/ButtonGreen';
  import ButtonWhite from '@/components/ButtonWhite';
  import AppConfirmation from "@/components/AppConfirmation";

  export default {
    name: 'Template2',
    components: {
      SectionTitle,
      FormInput,
      GroupTitle,
      ProfileEditForm,
      ProfileTabBar,
      AppLayout,
      ButtonGreen,
      ButtonWhite,
      AppConfirmation
    },
    computed: {
      profile() {
        return this.$store.state.patients.profile;
      },
    },
    methods: {
       async deleteAccount() {
         try {
           await this.$store.dispatch('patientDeleteAccount');
           await this.$router.push('/logout')
         } catch (e) {
           await this.$addError(this.$t('profileEditPage.error-delete-account'));
           throw e;
         }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .right {
    .row {
      margin-bottom: 1rem;
    }

    .edit-credential {
      margin-bottom: 3rem;

      a {
        color: $successColor;

        .icon {
          margin-right: 0.5rem;
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .col-separator {
    width: 1px;
    margin: 0 5rem;
    background-color: $lightGrey;
  }

  //.actions {
  //  text-align: center;
  //}
  .main-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    background: $gradientBackground;
  }
  .page-delete {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $gradientBackground;
  }

  .section-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .text {
    max-width: 38rem;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: #FFFFFF;
    margin: 3rem 0 4rem;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 60rem;

    @include onDesktop {
      flex-direction: row;
    }
  }

  .action {
    margin: 0.5rem 1rem;
    flex: 1;

    .single & {
      flex: none;
    }
  }
  .delete {
    color: $errorColor;
    text-decoration: underline;
  }
</style>
