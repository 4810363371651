import moment from "moment/moment";

export const fingerPrintPrice = (totalPrice) => {
    if (isDay() && totalPrice <= 2500) {
        return 2500;
    } else if (!isDay() && totalPrice <= 8000) {
        return 8000;
    }
    return totalPrice;
}

const isDay = () => {
    const now = moment()
    const startOfDay = moment().set({ hour: 6, minute: 0, second: 0 });
    const endOfDay = moment().set({ hour: 20, minute: 0, second: 0 });

    return now.isBetween(startOfDay, endOfDay);
}