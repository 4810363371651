var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("div", { staticClass: "row g-0 justify-content-md-center" }, [
      _c("div", { staticClass: "card-block order-1 col-lg-4 col-xl-3" }, [
        this.$store.state.pharmacies.isFetching
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "p-5 w-100 d-flex justify-content-center align-items-center"
                },
                [_c("Spinner")],
                1
              )
            ])
          : _c("div", [
              !this.$store.state.pharmacies.search.length
                ? _c("div", [
                    _c("p", {
                      staticClass: "text-center p-5",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("pharmacyOrder.main-page.no-pharmacy")
                        )
                      }
                    })
                  ])
                : _c(
                    "div",
                    _vm._l(this.$store.state.pharmacies.search, function(
                      pharmacy
                    ) {
                      return _c(
                        "div",
                        [
                          pharmacy
                            ? _c("PharmacyCard", {
                                attrs: {
                                  pharmacy: pharmacy,
                                  profileId: _vm.profile.id,
                                  premium: _vm.profile.premium,
                                  appointmentId: _vm.localAppointmentId
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
            ])
      ]),
      _c(
        "div",
        {
          staticClass: "order-lg-1 order-xl-1 col-lg-8 col-xl-9",
          attrs: { id: "map-container" }
        },
        [_c("GoogleMapLoader", { attrs: { mapConfig: _vm.mapConfig } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }