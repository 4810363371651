import Observation from '../../api/observations';

export default {
    state: {
        search: [],
        isFetchingOne: false,
        isFetching: false,
        current: null
    },
    mutations: {
        OBSERVATIONS_FETCH_ONE(state) {
            state.current = null;
            state.isFetchingOne = true;
        },
        OBSERVATIONS_FETCH_ONE_SUCCESS(state, { observation }) {
            state.isFetchingOne = false;
            state.current = observation;
        },
        OBSERVATIONS_FETCH_ONE_FAIL(state) {
            state.isFetchingOne = false;
            state.current = null;
        },
        OBSERVATIONS_CREATE() {},
        OBSERVATIONS_CREATE_SUCCESS(state, { observation }) {
            state.current = observation;
        },
        OBSERVATIONS_CREATE_FAIL() {},
        OBSERVATIONS_FETCH(state) {
            state.isFetching = true;
        },
        OBSERVATIONS_FETCH_SUCCESS(state, { observations }) {
            state.isFetching = false;
            state.search = observations;
        },
        OBSERVATIONS_FETCH_FAIL(state) {
            state.isFetching = false;
        },
        OBSERVATIONS_DELETE() {},
        OBSERVATIONS_DELETE_SUCCESS() {},
        OBSERVATIONS_DELETE_FAIL() {},
    },
    actions: {
        async observationsFetchOne({ commit }, { id }) {
            commit('OBSERVATIONS_FETCH_ONE');
            try {
                const observation = await Observation.getOne(id);
                commit('OBSERVATIONS_FETCH_ONE_SUCCESS', { observation });
            } catch (e) {
                commit('OBSERVATIONS_FETCH_ONE_FAIL');
                throw e;
            }
        },
        async observationsCreate({ commit }, { patient, data, encounter }) {
            commit('OBSERVATIONS_CREATE');
            try {
                const observation = await Observation.create(patient, data, encounter);
                commit('OBSERVATIONS_CREATE_SUCCESS', { observation });
            } catch (e) {
                commit('OBSERVATIONS_CREATE_FAIL');
                throw e;
            }
        },
        async observationsFetch({ state, commit }, {code, patient, lastObs} ) {
            commit('OBSERVATIONS_FETCH');
            try {
                const observations = await Observation.search(code, patient, lastObs);
                commit('OBSERVATIONS_FETCH_SUCCESS', { observations });
            } catch (e) {
                commit('OBSERVATIONS_FETCH_FAIL');
                throw e;
            }
        },
        async observationsDelete({ commit }, { id }) {
            commit('OBSERVATIONS_DELETE');
            try {
                await Observation.delete(id);
                commit('OBSERVATIONS_DELETE_SUCCESS');
            } catch (e) {
                commit('OBSERVATIONS_DELETE_FAIL');
                throw e;
            }
        }
    },
};
