import moment from 'moment';

export function checkDate(value) {
  if (!value) {
    return true;
  }

  if (!value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return false;
  }

  return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') === value;
}

export function hasValue(value) {
  if (value === undefined || value === null) {
    return false;
  }

  if (value === false) {
    return true;
  }

  return value.trim().length > 0;
}

export function checkSsn(value) {
  if (!value) {
    return true;
  }

  return !!value.replace(/\s+/g, '').match(/^\d{6}[\dAB]\d{8}$/);
}
