<template>
  <AppLayout>
    <main>
      <ProfileTabBar />
      <div class="container">
        <header class="header">
          <GroupTitle :icon="$icons.box">
            Liste de mes commandes
          </GroupTitle>
        </header>
        <div class="listCommands">
        <table class="table table-hover beneficiaries">
          <thead>
          <tr>
            <th>Statut de la commande</th>
            <th>Nom de la pharmacie</th>
            <th>Adresse de la pharmacie</th>
            <th>Numéro de la pharmacie</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody v-if="orders.length > 0">
            <tr v-for="order in orders" class="beneficiary">
              <td class="action-status">
                <p v-if="order.status==='new'">Commande enregistrée</p>
                <p v-if="order.status==='sent'">Commande validée</p>
                <p v-if="order.status==='preparing'">En cous de préparation</p>
                <p v-if="order.status==='ready'">Commande prête</p>
                <p v-if="order.status==='finished'">Commande reçue</p>
                <p v-if="order.status==='cancelled_by_pharmacy'">Commande annulée</p>
                <p v-if="order.status==='cancelled_by_patient'">Commande annulée</p>
                <p v-if="order.status==='cancelled_by_hellocare'">Commande annulée</p>
              </td>
              <td>
                {{ order.pharmacy.rs_longue ? order.pharmacy.rs_longue : order.pharmacy.rs }}
              </td>
              <td>
                {{ order.pharmacy.num_voie ? order.pharmacy.num_voie : '' }}
                {{ order.pharmacy.typ_voie ? order.pharmacy.typ_voie : '' }}
                {{ order.pharmacy.voie ? order.pharmacy.voie : '' }}
                {{ order.pharmacy.libdepartement }}
              </td>
              <td>
                {{ order.pharmacy.telephone }}
              </td>
              <td class="action-column">
                <ButtonGreen
                    small
                    :link-to="{ name: 'appointment-view', params: { id: order.appointment.id } }"
                >
                  Mon rendez-vous
                </ButtonGreen>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </main>
  </AppLayout>
</template>

<script>
import ProfileTabBar from '@/components/ProfileTabBar';
import AppLayout from '@/components/AppLayout';
import GroupTitle from '@/components/GroupTitle';
import ButtonGreen from "../../components/ButtonGreen";

export default {
  name: 'ProfileCommandsPage',
  components: {
    ButtonGreen,
    ProfileTabBar,
    AppLayout,
    GroupTitle
  },
  computed: {
    orders() {
      return this.$store.state.patients.profile.pharmacyOrders.filter( (order) => {
        return order.status !== 'no_provider' && order.status !== 'creating';
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.click-collect__image{
  img{
    width: 50px;
    height: auto;
  }
}
.action-column{
  min-width: 190px;
}
.action-status{
  display: flex;
  text-align: center;
  p{
    font-weight: bold;
  }
  img{
    width: 50px;
  }
}
.listCommands {
  width: auto;
  overflow-x: auto;
}
</style>