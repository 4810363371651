<script>
  import BaseButton from './BaseButton';

  export default {
    name: 'ButtonTransparent',
    extends: BaseButton
  };
</script>

<style lang="scss" scoped src="../assets/base-button.scss"/>

<style lang="scss" scoped>
  .base-button {
      background-color: transparent;
      color: $grey;
      border: transparent;
  }
</style>
