<template>
  <img src="../assets/images/premium.png"
       alt="logo-premium"
       :class="{small, medium, xmedium, large, xlarge}"

  />
</template>

<script>


export default {
  name: 'app-logo-premium',
  props: {
    small : {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    xmedium: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xlarge: {
      type: Boolean,
      default: false
    }
  }

};

</script>

<style lang="scss" scoped>
.small {
  width: 14px;
}
.medium {
  width: 19px;
}
.xmedium {
  width: 35.5px;
}
.large {
  width: 44px;
}
.xlarge {
  width: 56px;
}
</style>