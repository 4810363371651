var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "textarea",
    _vm._g(
      { staticClass: "auto-height-textarea", domProps: { value: _vm.value } },
      Object.assign({}, _vm.$listeners, { input: _vm.handleInput })
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }