import Vue from 'vue';
import Patients from '../../api/patients';

export default {
  state: {
    isFetching: false,
    isCreating: false,
    isAddingChild: false,
    isAvatarFormOpened: false,
    isUploadingAvatar: false,
    profile: {},
  },
  mutations: {
    PATIENT_FETCH_PROFILE(state) {
      state.isFetching = true;
    },
    PATIENT_FETCH_PROFILE_SUCCESS(state, { profile }) {
      state.profile = profile;
      state.isFetching = false;
    },
    PATIENT_FETCH_PROFILE_FAIL(state) {
      state.profile = {};
      state.isFetching = false;
    },
    PATIENT_EDIT_PROFILE(state) {
      state.isCreating = true;
    },
    PATIENT_EDIT_PROFILE_SUCCESS(state, { profile }) {
      if (profile.isOwner) {
        state.profile = profile;
      } else {
        const index = state.profile.children.findIndex(child => child.id === profile.id);
        Vue.set(state.profile.children, index, profile);
      }
      state.isCreating = false;
    },
    PATIENT_EDIT_PROFILE_FAIL(state) {
      state.isCreating = false;
    },
    PATIENT_AVATAR_OPEN_FORM(state) {
      state.isAvatarFormOpened = true;
    },
    PATIENT_AVATAR_CLOSE_FORM(state) {
      state.isAvatarFormOpened = false;
    },
    PATIENT_AVATAR_UPLOAD(state) {
      state.isUploadingAvatar = true;
    },
    PATIENT_AVATAR_UPLOAD_SUCCESS(state) {
      state.isUploadingAvatar = false;
    },
    PATIENT_AVATAR_UPLOAD_FAIL(state) {
      state.isUploadingAvatar = false;
    },
    PATIENT_ADD_CHILD(state) {
      state.isAddingChild = true;
    },
    PATIENT_ADD_CHILD_SUCCESS(state, { child }) {
      state.isAddingChild = false;
      state.profile.children.push(child);
    },
    PATIENT_ADD_CHILD_FAIL(state) {
      state.isAddingChild = false;
    },
    PATIENT_DELETE_ACCOUNT() {},
    PATIENT_DELETE_ACCOUNT_SUCCESS() {},
    PATIENT_DELETE_ACCOUNT_FAIL() {}
  },
  actions: {
    async patientFetchProfile({ commit }) {
      commit('PATIENT_FETCH_PROFILE');
      try {
        const profile = await Patients.me();
        commit('PATIENT_FETCH_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PATIENT_FETCH_PROFILE_FAIL');
        throw e;
      }
    },
    async patientEditProfile({ commit }, { id, data }) {
      commit('PATIENT_EDIT_PROFILE');
      try {
        const profile = await Patients.update(id, data);
        commit('PATIENT_EDIT_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PATIENT_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
    async patientEditMetadata({ state, commit }, { id = 'me', metadata }) {
      commit('PATIENT_EDIT_PROFILE');
      try {
        const profile = await Patients.update(id, {
          metadata: {
            ...state.profile.metadata,
            ...metadata,
          },
        });
        commit('PATIENT_EDIT_PROFILE_SUCCESS', { profile });
      } catch (e) {
        commit('PATIENT_EDIT_PROFILE_FAIL');
        throw e;
      }
    },
    patientAvatarOpenForm({ commit }) {
      commit('PATIENT_AVATAR_OPEN_FORM');
    },
    patientAvatarCloseForm({ commit }) {
      commit('PATIENT_AVATAR_CLOSE_FORM');
    },
    async patientAvatarUpload({ commit }, { id, file }) {
      commit('PATIENT_AVATAR_UPLOAD');
      try {
        const uploadedFile = await Patients.uploadAvatar(id, file);
        commit('PATIENT_AVATAR_UPLOAD_SUCCESS');
        return uploadedFile;
      } catch (e) {
        commit('PATIENT_AVATAR_UPLOAD_FAIL');
        throw e;
      }
    },
    async patientDeleteAccount({commit}) {
      commit('PATIENT_DELETE_ACCOUNT');
      try {
         await Patients.delete();
        commit("PATIENT_DELETE_ACCOUNT_SUCCESS")
      } catch (e) {
        commit('PATIENT_DELETE_ACCOUNT_FAIL');
        throw e;
      }
    },
    /**
     * @param {string} data.firstName
     * @param {string} data.lastName
     * @param {string} data.birthDate
     * @param {string} data.gender
     * @param {string} data.email
     * @param {string} data.phone
     * @param {string} data.weight
     * @param {boolean} data.acceptEmailCommunication
     * @param {boolean} data.acceptSmsCommunication
     * @param {string} data.timezone
     * @return {Promise<*>}
     */
    async patientCreateChild({ commit }, { data }) {
      commit('PATIENT_ADD_CHILD');
      try {
        const child = await Patients.createChild(data);
        commit('PATIENT_ADD_CHILD_SUCCESS', { child });
        return child;
      } catch (e) {
        commit('PATIENT_ADD_CHILD_FAIL');
        throw e;
      }
    },
    async patientDeleteChild() {
      // TODO
      // console.log('TODO: patientDeleteChild');
    },
  },
};
