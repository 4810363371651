var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "credit-card-add-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.handleSubmit($event)
        }
      }
    },
    [
      _vm._m(0),
      _vm._t("check-card-registered"),
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _c(
            "ButtonTransparent",
            {
              attrs: {
                busy: _vm.submitting,
                icon: _vm.$icons.chevronLeft,
                "icon-to-left": "",
                rounded: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [
              _vm._t("cancel-button", [
                _vm._v(
                  " " + _vm._s(_vm.$t("creditCardAddForm.button.cancel")) + " "
                )
              ])
            ],
            2
          ),
          _c(
            "ButtonGreen",
            {
              staticClass: "submit-button",
              attrs: {
                busy: _vm.submitting,
                center: "",
                rounded: "",
                type: "submit"
              }
            },
            [
              _vm._t("submit-button", [
                _vm._v(
                  " " + _vm._s(_vm.$t("creditCardAddForm.button.submit")) + " "
                )
              ])
            ],
            2
          )
        ],
        1
      ),
      !_vm.required
        ? _c(
            "div",
            { staticClass: "ignore-button" },
            [
              _c(
                "ButtonTransparent",
                {
                  attrs: {
                    busy: _vm.submitting,
                    icon: _vm.$icons.chevronRight,
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("ignore")
                    }
                  }
                },
                [
                  _vm._t("ignore-button", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("creditCardAddForm.button.ignore")) +
                        " "
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-information" }, [
      _c("div", { attrs: { id: "card-number" } }),
      _c("div", { attrs: { id: "card-expiry" } }),
      _c("div", { attrs: { id: "card-cvc" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }