<template>
  <portal v-if="isOpen" to="app-confirmation">
    <AppConfirmationInner
      :busy="isBusy"
      :payload="payload"
      v-bind="$attrs"
      v-on="{...$listeners, close, busy}"
    >
      <template v-slot:default="scope">
        <slot name="default" v-bind="scope" />
      </template>
      <template v-slot:title="scope">
        <slot name="title" v-bind="scope" />
      </template>
      <template v-slot:description="scope">
        <slot name="description" v-bind="scope" />
      </template>
    </AppConfirmationInner>
  </portal>
</template>

<script>
  import AppConfirmationInner from '@/components/AppConfirmationInner';

  export default {
    name: 'AppConfirmation',
    components: { AppConfirmationInner },
    data() {
      return {
        isBusy: false,
        isOpen: false,
        payload: null,
      };
    },
    methods: {
      open(payload) {
        this.isOpen = true;
        this.payload = payload;
      },
      close() {
        this.isOpen = false;
        this.isBusy = false;
        this.payload = null;
      },
      busy(isBusy) {
        this.isBusy = isBusy;
      },
    },
  };
</script>

<style scoped>

</style>
