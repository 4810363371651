import store from '@/config/store.js';
import Pusher from 'pusher-js';
import { getSetting } from '@/helpers/tools';

/** @type {Pusher} */
let instance;

/**
 * @return {Promise<Pusher>}
 */
export default async function getInstance() {
  if (instance) {
    return instance;
  }

  const jwt = await store.getters.platformJwtForCurrentPatient;

  instance = new Pusher(getSetting('PUSHER_APP_KEY'), {
    cluster: 'eu',
    authEndpoint: getSetting('PUSHER_AUTH_URL'),
    auth: {
      headers: {
        Authorization: `Bearer ${jwt}`,
        'X-Api-Key': getSetting('PLATFORM_API_PUBLIC_KEY'),
      },
    },
  });

  return instance;
};
