var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c(
      "main",
      { staticClass: "appointments-page" },
      [
        _c(
          "div",
          { staticClass: "title-with-button" },
          [
            _c("SectionTitle", [
              _vm._v(_vm._s(_vm.$t("appointmentsPage.title")))
            ]),
            _c(
              "div",
              [
                _c(
                  "ButtonRed",
                  {
                    attrs: {
                      icon: _vm.$icons.newAppointment,
                      "link-to": "/new-appointment",
                      small: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("appointmentsPage.new-appointment-button")
                        ) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "appointments", class: { busy: _vm.isBusy } },
          [
            _vm._l(_vm.appointmentsForPlanning, function(appointments) {
              return [
                _c(
                  "HorizontalDivider",
                  { staticClass: "appointments-divider", attrs: { left: "" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(appointments[0].day) +
                        "\n          " +
                        _vm._s(appointments[0].monthLong) +
                        "\n          " +
                        _vm._s(appointments[0].year) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "appointment-items" },
                  _vm._l(appointments, function(appointment) {
                    return _c("AppointmentListItem", {
                      key: appointment.payload.id,
                      attrs: {
                        day: appointment.dayShort,
                        month: appointment.monthShort,
                        date: appointment.date,
                        time: appointment.time,
                        expired: appointment.expired,
                        channel: appointment.payload.product.channel,
                        "practitioner-name": _vm._f("fullName")(
                          appointment.payload.practitioner
                        ),
                        "patient-name": _vm._f("fullName")(
                          appointment.payload.patient
                        ),
                        description: appointment.payload.description,
                        "can-be-started": _vm.canBeStarted(
                          appointment.payload.startAt
                        ),
                        status: appointment.payload.status,
                        path: "/appointments/" + appointment.payload.id
                      }
                    })
                  }),
                  1
                )
              ]
            }),
            !_vm.hasAppointments
              ? _c("div", { staticClass: "no-appointment" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("appointmentsPage.no-appointment")) +
                      "\n      "
                  )
                ])
              : _c(
                  "div",
                  [
                    _vm.totalPages
                      ? _c("Paginator", {
                          staticClass: "paginator",
                          attrs: {
                            "no-li-surround": true,
                            "page-count": _vm.totalPages,
                            "next-text": _vm.$t(
                              "appointmentsPage.paginator.next"
                            ),
                            "prev-text": _vm.$t(
                              "appointmentsPage.paginator.previous"
                            )
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
          ],
          2
        ),
        _c("transition", { attrs: { duration: 600 } }, [_c("router-view")], 1)
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }