<template>
  <AppLayout>
    <div v-if="patient.id" class="profile-view">
      <main class="container-body">
        <div class="profile">
          <SectionTitle> {{ $t('profileViewPage.title') }} </SectionTitle>
          <div class="userProfile">
            <div class="userProfile__container-avatar">
              <UserAvatar
                  :size="$media.desktop ? '13rem' : '8rem'"
                  :src="avatarURL"
                  :user-name="patientFullName"
                  class="avatar"
                  editable
              />
<!--
              <AppLogoPremium v-if="isPremium" alt="premium" class="userProfile__logo-premium" large/>
-->
            </div>

            <div>
              <div class="name">
                <p>{{ patient.firstName | capitalize }}</p>
                <p>{{ patient.lastName | uppercase }}</p>
              </div>
<!--              <div v-if="!isPremium" class="premium">
                <p class="premium__title">{{ $t('profileViewPage.title-standart') }}</p>
                <router-link :to="{ name: 'profile-status' }" class="premium__link">{{ $t('profileViewPage.link-standart') }}</router-link>
              </div>
              <div v-if="isPremium" class="premium">
                <p class="premium__title">{{ $t('profileViewPage.title-premium') }}</p>
                <router-link :to="{ name: 'profile-status' }" class="premium__link">{{ $t('profileViewPage.link-premium') }}</router-link>
              </div>-->

            </div>
          </div>
          <ButtonGreen bold class="edit" link-to="/profile/edit" long small>{{ $t('profileViewPage.profile-edit') }}</ButtonGreen>
        </div>
        <div class="infos">
          <SectionTitle>{{ $t('profileViewPage.title-info') }}</SectionTitle>

          <p v-if="patient.ssn" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.idCard" class="profile-label-icon" />
            {{ patient.ssn }}
          </p>

          <p v-if="patient.birthDate" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.birthDate" class="profile-label-icon" />
            {{ patient.birthDate | date('DD/MM/YYYY') }} ({{ patient.birthDate | age }})
          </p>

          <p v-if="patient.phone" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.mobile" class="profile-label-icon" />
            {{ patient.phone | phone }}
          </p>

          <p v-if="patient.email" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.mail" class="profile-label-icon" />
            {{ patient.email }}
          </p>

          <p v-if="patient.weight" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.weight" class="profile-label-icon" />
            {{ patient.weight }} kg
          </p>

          <p v-if="patient.timezone" class="profile-label-text">
            <FontAwesomeIcon :icon="$icons.globe" class="profile-label-icon" />
            {{ patient.timezone }}
          </p>
        </div>
      </main>
    </div>
  </AppLayout>
</template>

<script>
  import SectionTitle from '@/components/SectionTitle';
  import ButtonGreen from '@/components/ButtonGreen';
  import UserAvatar from '@/components/UserAvatar';
  import { formatFullName } from '@/helpers/format';
  import AppLayout from '@/components/AppLayout';
  import AvatarForm from '@/components/AvatarForm.vue';
  import AppLogoPremium from "@/components/AppLogoPremium.vue";

  export default {
    name: 'ProfileViewPage',
    components: {AppLogoPremium, AvatarForm, AppLayout, UserAvatar, ButtonGreen, SectionTitle },
    mounted() {
        this.unsubscribe();
    },
    computed: {
      patient() {
        return this.$store.state.patients.profile;
      },
      isPremium() {
        return this.patient.premium
      },
      patientFullName() {
        return formatFullName(this.patient.firstName);
      },
      avatarURL() {
        return this.patient.picture;
      },
    },
      methods: {
        unsubscribe() {
            if (window.location.href.includes('?premium=cancel')) {
                this.$addSuccess("Votre abonnement a bien été annulé.");
                window.history.pushState({}, document.title, "/profile/view");
            }
          },
      },
  };
</script>

<style lang="scss" scoped>
  main.container-body {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;

    @include onMobile {
      padding: 1rem;
    }

    .profile {
      margin: 15px;

      .userProfile {
        position: relative;
        display: flex;
        align-items: center;

        .avatar {
          margin-right: 25px;
        }

        .name p {
          font-size: 2.5em;
          margin: 0;

          @include onDesktop {
            font-size: 3em;
          }

          &:first-child {
            font-weight: bold;
          }
        }
        .premium {
          &__title {
            color: #F46868;
            font-weight: bold;
            margin-bottom: 0;
          }
          &__link {
            font-weight: bold;
            color: black;
          }
        }
        &__container-avatar {
          position: relative;
        }
        &__logo-premium {
          position: absolute;
          top: 0;
          right: 12.5px;
          @include onMobile {
            width: 27px;
            height: 27px;
            right: 13.5px;
          }
        }
      }

      .edit {
        margin-top: 30px;
      }
    }

    .bio {
      margin: 15px;
    }

    .infos {
      margin: 15px;

      .profile-label-text {
        line-height: 30px;
      }
    }
  }

  .container-body {
    padding-left: 1.8rem;
    grid-template-columns: 1fr;

    .profile {
      padding-bottom: 40px;
      border-bottom: 1px solid $lightTextColor;
      grid-area: 1 / 1 / 2 / 2;
    }

    .infos {
      border: 0;
      border-bottom: 1px solid $lightTextColor;
      grid-area: 2 / 1 / 4 / 2;

      .profile-label-text {
        font-size: 1.6rem;
        line-height: 45px;
      }

      .profile-label-icon {
        width: 25px;
        margin-right: 1rem;
      }
    }

    .bio {
      padding-top: 10px;
      grid-area: 4 / 1 / 5 / 2;
    }
  }

  @include onDesktop {
    .container-body {
      padding-left: 13rem;
      grid-template-columns: 1.2fr .8fr 1fr;

      .profile {
        grid-area: 1 / 1 / 2 / 2;
      }

      .infos {
        padding-left: 40px;
        border-left: 1px solid $lightTextColor;
        grid-area: 1 / 2 / 4 / 4;

        .profile-label-icon {
          margin-right: 3rem;
        }
      }

      .bio {
        padding-top: 40px;
        border-top: 1px solid $lightTextColor;
        grid-area: 2 / 1 / 3 / 2;
      }
    }
  }
</style>
