var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-widget",
      class: { "has-error": _vm.$slots.error, transparent: _vm.transparent }
    },
    [
      _c("div", { staticClass: "form-widget-inner" }, [_vm._t("default")], 2),
      _vm.$slots.error
        ? _c(
            "small",
            { staticClass: "error" },
            [
              _c("FontAwesomeIcon", {
                staticClass: "error-icon",
                attrs: { icon: _vm.$icons.inputError, "fixed-width": "" }
              }),
              _vm._t("error")
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.help
        ? _c("small", { staticClass: "help" }, [_vm._t("help")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }