var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-notification",
      on: {
        mouseenter: function($event) {
          _vm.paused = true
        },
        mouseleave: function($event) {
          _vm.paused = false
        }
      }
    },
    _vm._l(_vm.notifications, function(notification) {
      return _c(
        "AppNotificationItem",
        {
          key: notification.id,
          attrs: {
            id: notification.id,
            duration: notification.duration,
            icon: notification.icon,
            paused: _vm.paused,
            type: notification.type
          }
        },
        [
          notification.asHTML
            ? _c("span", {
                domProps: { innerHTML: _vm._s(notification.message) }
              })
            : _c("span", [_vm._v(_vm._s(notification.message))])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }