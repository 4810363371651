import Specialties from '../../api/specialties';

export default {
  state: {
    all: [],
    isFetchingAll: false,
  },
  mutations: {
    SPECIALTIES_FETCH_ALL(state) {
      state.isFetchingAll = true;
    },
    SPECIALTIES_FETCH_ALL_SUCCESS(state, { specialties }) {
      state.isFetchingAll = false;
      state.all = specialties;
    },
    SPECIALTIES_FETCH_ALL_FAIL(state) {
      state.isFetchingAll = false;
    },
  },
  actions: {
    async specialtiesFetchAll({ commit }) {
      commit('SPECIALTIES_FETCH_ALL');
      try {
        const specialties = await Specialties.fetchAll();
        commit('SPECIALTIES_FETCH_ALL_SUCCESS', { specialties });
      } catch (e) {
        commit('SPECIALTIES_FETCH_ALL_FAIL');
        throw e;
      }
    },
  },
};
