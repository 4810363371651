var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", { attrs: { header: false, footer: false } }, [
    _c("main", { staticClass: "not-found-page" }, [
      _c("div", [
        _c("img", {
          attrs: { src: require("../assets/images/logo.png"), alt: "Hellocare" }
        })
      ]),
      _vm._v("\n    " + _vm._s(_vm.$t("notFoundPage.not-found")) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }