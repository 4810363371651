<template>
  <AppLayout>
    <div class="click_collect_container">
      <div class="w-100">
        <div class="p-5">
          <BreadCrumb :pages="breadCrumbPages()"/>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column p-5 h-100">
        <div class="p-5">
          <img alt="handshake" src="../../assets/images/picto_handShake.svg" />
        </div>
        <p class="text-center font-bold">
          {{$t('pharmacyOrder.type-page.choice')}}
        </p>
        <p v-if="!getStoredPharmacyOrder().provider.has_delivery">
          {{$t('pharmacyOrder.type-page.no-delivery')}}
        </p>
        <p v-if="!getStoredPharmacyOrder().provider.has_click_collect">
          {{$t('pharmacyOrder.type-page.no-click-collect')}}
        </p>
        <div class="d-flex justify-content-center flex-wrap align-items-center type-button py-5">
          <div v-if="getStoredPharmacyOrder().provider.has_delivery">
            <ButtonGreen :busy="submitting" @click="updatePharmacyOrderDelivery" class="font-bold">{{$t('pharmacyOrder.type-page.btn-delivery')}}</ButtonGreen>
          </div>
          <div v-else>
            <ButtonGrey class="font-bold" disabled>{{$t('pharmacyOrder.type-page.btn-delivery')}}</ButtonGrey>
          </div>
          <div v-if="getStoredPharmacyOrder().provider.has_click_collect">
            <ButtonGreen :busy="submitting"  @click="updatePharmacyOrder" class="font-bold">{{$t('pharmacyOrder.type-page.btn-clickcollect')}}</ButtonGreen>
          </div>
          <div v-else>
            <ButtonGrey  class="font-bold" disabled>{{$t('pharmacyOrder.type-page.btn-clickcollect')}}</ButtonGrey>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "../../components/AppLayout";
import ButtonGreen from "../../components/ButtonGreen";
import ButtonGrey from "../../components/ButtonGrey";
import BreadCrumb from "../../components/Pharmacy/BreadCrumb";

export default {
  name: 'PharmacyOrderTypePage',
  components: {AppLayout, ButtonGreen, ButtonGrey, BreadCrumb},
  mounted() {
    this.redirectToHomeIfNoAppointment();
    this.redirectToHomeIfNoPharmacyOrder();
  },
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    redirectToHomeIfNoAppointment() {
      if (typeof localStorage.getItem('currentOrderAppointment') !== 'string') {
        this.$router.push({name: 'home'});
        this.$addError("Le rendez-vous n'existe pas.");
      }
    },
    redirectToHomeIfNoPharmacyOrder() {
      if (typeof JSON.parse(localStorage.getItem('currentPharmacyOrder')) !== 'object') {
        this.$router.push({name: 'home'});
        this.$addError("La commande n'existe pas.");
      }
    },
    getStoredPharmacyOrder() {
      return JSON.parse(localStorage.getItem('currentPharmacyOrder'));
    },
    async updatePharmacyOrderDelivery() {
      let statusCode = 201;
      await this.$store.dispatch('pharmaciesOrderUpdate', {
        pharmacyOrderId: this.getStoredPharmacyOrder().id,
        type: 'delivery',
        complete: false,
        providerName: this.getStoredPharmacyOrder().provider.name
      }).catch((error)=>{
        statusCode = error.response.status
        this.$addError("Erreur lors de la mise à jour de la commande.");
        throw error;
      });
      if(statusCode<400) {
        await this.$addSuccess("Vous avez choisi la livraison.");
        return this.$router.push({name: 'pharmacy-order-address'});
      }
    },
    async updatePharmacyOrder() {
      let statusCode = 201;
      this.submitting = true;
      await this.$store.dispatch('pharmaciesOrderUpdate', {
        type: 'click_collect',
        pharmacyOrderId: this.getStoredPharmacyOrder().id,
        complete: true,
        providerName: this.getStoredPharmacyOrder().provider.name
      }).catch((error)=>{
        statusCode = error.response.status
        this.$addError("Erreur lors de la mise à jour de la commande.");
        throw error;
      });
      if(statusCode<400) {
        await this.$addSuccess("Vous avez choisi le click & collect.");
        this.submitting = false;
        return this.$router.push({name: 'pharmacy-success', params: { pharmacyrs: this.getStoredPharmacyOrder().pharmacy.rs }});
      }
    },
    breadCrumbPages() {
      return [
        {
          'name' : 'Choix de la pharmacie',
          'url' : 'pharmacy-selection',
          'current' : false
        },
        {
          'name' : 'Livraison ou click & collect ?',
          'url' : '',
          'current' : true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/utilities";
.type-button{
  button,a{
    width: 300px;
    margin: 0 15px 30px 15px;
    @include onMobile{
      margin: 0 15px 15px 15px;
    }
  }
}
</style>