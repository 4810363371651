var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppLayout", [
    _c("div", { staticClass: "click_collect_container" }, [
      _c("div", { staticClass: "w-100" }, [
        _c(
          "div",
          { staticClass: "p-5" },
          [_c("BreadCrumb", { attrs: { pages: _vm.breadCrumbPages() } })],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center flex-column p-5 h-100"
        },
        [
          _c("div", { staticClass: "p-5" }, [
            _c("img", {
              attrs: {
                alt: "handshake",
                src: require("../../assets/images/picto_handShake.svg")
              }
            })
          ]),
          _c("p", { staticClass: "text-center font-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("pharmacyOrder.type-page.choice")) +
                "\n      "
            )
          ]),
          !_vm.getStoredPharmacyOrder().provider.has_delivery
            ? _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pharmacyOrder.type-page.no-delivery")) +
                    "\n      "
                )
              ])
            : _vm._e(),
          !_vm.getStoredPharmacyOrder().provider.has_click_collect
            ? _c("p", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("pharmacyOrder.type-page.no-click-collect")) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center flex-wrap align-items-center type-button py-5"
            },
            [
              _vm.getStoredPharmacyOrder().provider.has_delivery
                ? _c(
                    "div",
                    [
                      _c(
                        "ButtonGreen",
                        {
                          staticClass: "font-bold",
                          attrs: { busy: _vm.submitting },
                          on: { click: _vm.updatePharmacyOrderDelivery }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.type-page.btn-delivery")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "ButtonGrey",
                        { staticClass: "font-bold", attrs: { disabled: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.type-page.btn-delivery")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
              _vm.getStoredPharmacyOrder().provider.has_click_collect
                ? _c(
                    "div",
                    [
                      _c(
                        "ButtonGreen",
                        {
                          staticClass: "font-bold",
                          attrs: { busy: _vm.submitting },
                          on: { click: _vm.updatePharmacyOrder }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.type-page.btn-clickcollect")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "ButtonGrey",
                        { staticClass: "font-bold", attrs: { disabled: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pharmacyOrder.type-page.btn-clickcollect")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }