<template>
    <AppLayout fullscreen>
        <main class="incompatible-browser">
            <div class="content">
                <div class="icons">
                    <FontAwesomeIcon :icon="$icons.chrome"/>
                    <FontAwesomeIcon :icon="$icons.safari"/>
                    <FontAwesomeIcon :icon="$icons.firefox"/>
                </div>
                <h1> {{ $t('incompatibleBrowserPage.title') }} </h1>
                <p>
                  {{ $t('incompatibleBrowserPage.text') }}
                </p>
                <a target="_blank" href="http://support.hellocare.com/fr/articles/2010972-navigateurs-compatibles">
                   <span>
                        {{ $t('incompatibleBrowserPage.download') }}
                   </span>
                </a>
                <router-link :to="{ name: 'home' }">
                   <span>
                        {{ $t('incompatibleBrowserPage.return') }}
                   </span>
                </router-link>
            </div>
        </main>
    </AppLayout>
</template>

<script>
    import AppLayout from '../components/AppLayout';

    export default {
        name: 'IncompatibleBrowserPage',
        components: {AppLayout},
    };
</script>


<style lang="scss" scoped>
    .content {
        width: 40rem;
    }
    .incompatible-browser {
        background: $gradientBackground;
        color: #fff;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        line-height: 25px;
        font-size: 15px;
        .icons {
            text-align: center;
            font-size: 50px;
            > svg {
                margin: 25px 20px;
            }
        }
        h1 {
            text-align: center;
            position: relative;
            font-size: 20px;
            &::after {
                content: "";
                width: 14rem;
                height: 0.5rem;
                background-color: #FFF;
                display: block;
                margin: 0 auto;
                margin-top: 10px;
            }
        }

        p {
            margin-top: 25px;
        }

        a  {
            text-align: center;
            display: block;
            margin-bottom: 15px;

            > span {
                padding-bottom: 4px;
                font-weight: 200;
                border-bottom: 1px solid #fff;
                color: #fff;
            }
        }
    }
</style>
